import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { language } from '../../utils/helpers';

const styles = makeStyles((theme) => ({
  sectionsHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
}));

const SectionHeader = ({ names, title, children }) => {
  const classes = styles();
  return (
    <Box className={classes.sectionsHeader}>
      <Typography variant="h6">
        {names ? language(names) : title}
      </Typography>
      {children}
    </Box>
  );
};

SectionHeader.propTypes = {
  names: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
  children: PropTypes.node,
};

export default SectionHeader;
