import React, { useState, useEffect } from 'react';
import { MuiThemeProvider, useMediaQuery } from '@material-ui/core';
import ThemeContext from './ThemeContext';
import createTheme from '../../theme';

const ThemeProvider = (props) => {
  const [darkMode, _setDarkMode] = useState(
    localStorage.getItem('darkMode') === 'true',
  );
  const setDarkMode = (_darkMode) => {
    _setDarkMode(_darkMode);
    localStorage.setItem('darkMode', _darkMode);
  };

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  useEffect(() => {
    if (!localStorage.getItem('darkMode')) _setDarkMode(prefersDarkMode);
  }, [prefersDarkMode]);

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <MuiThemeProvider theme={createTheme(darkMode)}>
        {props.children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
