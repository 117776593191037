import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchField from '../../common/SearchField';
import ArticlePagination, { TopArticlesEmpty } from '../ArticlePagination';
import { searchArticlesByKeyword } from '../../../actions/news';

const styles = makeStyles((theme) => ({
  modal: {
    overflow: 'hidden',
    height: ' 102vh',
  },
  dialog: {
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    position: 'relative',
    padding: '80px 10px',
    borderRadius: 20,
  },
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '& > svg': {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      top: 45,
    },
  },
}));

const ArticleSearchDialog = ({ show, onCloseClick, channelId }) => {
  const classes = styles();
  const [searchText, setSearchText] = useState(null);

  const onSearchChange = (value) => {
    setSearchText(value);
  };

  const clearSearch = () => {
    setSearchText(null);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={show}
        className={classes.modal}
      >
        <Box className={classes.dialog}>
          <Box className={classes.closeIcon}><CloseIcon onClick={() => onCloseClick()} /></Box>

          <Box className={classes.searchContainer}>
            <SearchField
              placeholder="Search Articles"
              clearSearch={clearSearch}
              onSearchChange={onSearchChange}
              style={{ marginTop: 0, width: '100%' }}
              searchFieldStyle={{ width: '100%' }}
              fieldValue={searchText}
              autocomplete
              setCurrentSearchValue={() => { }}
            />
          </Box>

          {searchText && (
            <ArticlePagination
              loadsContext={`searchArticles-${searchText}`}
              load={(guid, page) => searchArticlesByKeyword(searchText, page, channelId)}
              label={`Results for ${searchText}`}
              EmptyStateComponent={TopArticlesEmpty}
              key={`searchArticles-${searchText}`}
            />
          )}
        </Box>
      </Dialog>
    </>
  );
};

ArticleSearchDialog.propTypes = {
  show: PropTypes.bool,
  onCloseClick: PropTypes.func,
  channelId: PropTypes.number,
};

export default ArticleSearchDialog;
