import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  makeStyles,
  Grid,
  Typography,
  TextField,
  Box,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import PublisherBox from '../Publisher/PublisherBox';
import { useQuery } from '../../hooks/useQuery';
import { searchPublishers } from '../../actions/channel';
import { searchWriters as searchWritersAction } from '../../actions/writers';
import WriterBox from '../Writer/WriterBox/WriterBox';
import InterestList from '../Interest/InterestList';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  headingRoot: {
    paddingTop: 27,
    marginBottom: 27,
  },
  subscriptionCount: {
    opacity: 0.5,
    marginLeft: 10,
  },
  searchResult: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 15,
    },
  },
  boxFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      display: (props) => (props.searchActive ? 'block' : 'flex'),
    },
  },
  searchContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: (props) => (props.searchActive ? 27 : 0),
    },
  },
  textFieldRoot: {
    marginRight: 15,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
    },
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonOk: {
    marginRight: 15,
  },
  searchField: {
    marginRight: 15,
  },
}));

const Subscriptions = ({
  subscriptions, refetchSubscriptions,
  EmptyStateComponent, onEmptyButtonClick,
  type, userId,
}) => {
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const classes = styles({ searchActive });

  const label = type === 'channels' ? 'Search Publishers' : (type === 'interests' ? 'Search Topics' : 'Search Writers');

  const searchPublishersQuery = useQuery('searchPublishers', searchPublishers, {
    variables: [searchValue],
    enabled: false,
  });

  const searchWritersQuery = useQuery('searchWriters', searchWritersAction, {
    variables: [userId, searchValue],
    enabled: false,
  });

  useEffect(() => {
    setSubscriptionList(searchResults.length > 0 ? searchResults : subscriptions);
  }, [searchResults, subscriptions]);

  const searchChannels = async () => {
    const searchResult = await searchPublishersQuery.refetch();
    setSearchResults(searchResult?.channels);
    setSearchActive(false);
  };

  const searchWriters = async () => {
    const searchResult = await searchWritersQuery.refetch();
    setSearchResults(searchResult?.data);
    setSearchActive(false);
  };

  const handleCloseClick = () => {
    refetchSubscriptions();
    setSearchValue('');
    setSearchActive(false);
    setSearchResults([]);
  };

  const renderPublisherBoxes = () => subscriptionList.map((subscription) => (
    <Grid
      item
      // eslint-disable-next-line no-underscore-dangle
      key={`subscription-${subscription._id}`}
      lg={2}
      md={3}
      sm={4}
      xs={6}
    >
      <PublisherBox publisher={subscription} onToggleSubscription={() => refetchSubscriptions()} />
    </Grid>
  ));

  const renderWriters = () => subscriptionList.map((subscription) => (
    <WriterBox
      // eslint-disable-next-line no-underscore-dangle
      key={subscription?._id}
      writer={subscription}
      onToggleSubscription={() => refetchSubscriptions()}
    />
  ));

  const renderInterests = () => <InterestList searchValue={searchValue} />;

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <Box className={classes.boxFlex}>
        <Box />

        {searchActive
          ? (
            <div className={classes.searchContainer}>
              <TextField
                onChange={(event) => setSearchValue(event.target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    if (type === 'channels') {
                      return searchChannels(searchValue);
                    }
                    return searchWriters(searchValue);
                  }
                  return null;
                }}
                autoFocus
                placeholder={label}
                classes={{
                  root: classes.textFieldRoot,
                }}
                inputProps={{
                  className: classes.textField,
                }}
              />

              <Button
                variant="contained"
                color="primary"
                className={classes.buttonOk}
                onClick={() => ((type === 'channels') ? searchChannels(searchValue) : searchWriters(searchValue))}
              >
                <SearchIcon />
              </Button>

              <Button
                color="primary"
                onClick={handleCloseClick}
              >
                <CloseIcon />
              </Button>
            </div>
          ) : (
            <div className={classes.searchContainer}>
              {searchValue && searchResults.length > 0
                ? (
                  <Box className={classes.searchResult}>
                    <Typography className={classes.searchField}>
                      Search results for &quot;<i>{searchValue}</i>&quot;
                    </Typography>
                    <Button
                      color="primary"
                      onClick={handleCloseClick}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                ) : (
                  <>
                    {searchValue
                      ? (
                        <Box className={classes.searchResult}>
                          <Typography className={classes.searchField}>
                            No results found for &quot;<i>{searchValue}</i>&quot;
                          </Typography>
                          <Button
                            color="primary"
                            onClick={handleCloseClick}
                          >
                            <CloseIcon />
                          </Button>
                        </Box>
                      )
                      : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setSearchActive(true)}
                        >
                          <SearchIcon />
                        </Button>
                      )}
                  </>
                )}

            </div>
          )}
      </Box>
      {type === 'channels' && (
        <Grid container spacing={3}>
          {renderPublisherBoxes()}
        </Grid>
      )}
      {type === 'writers' && (
        <Box>
          {renderWriters()}
        </Box>
      )}
      {type === 'interests' && (
        <Box>
          {renderInterests()}
        </Box>
      )}
      {subscriptionList.length === 0
        && (
          <Grid>
            <EmptyStateComponent onButtonClick={onEmptyButtonClick} />
          </Grid>
        )}
    </Container>
  );
};

Subscriptions.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.shape()),
  refetchSubscriptions: PropTypes.func,
  EmptyStateComponent: PropTypes.elementType,
  onEmptyButtonClick: PropTypes.func,
  type: PropTypes.string,
  userId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userId: state.user?.profile?.id,
});

export default connect(mapStateToProps, null)(Subscriptions);
