import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import SpotlightLogo from '../../components/common/SpotlightLogo';
import routes from '../../routes';
import Link from '../../components/common/Link';
import { useQuery } from '../../hooks/useQuery';
import { getOnboardChannels } from '../../actions/onboarding';

const styles = makeStyles((theme) => ({
  containerRoot: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    marginTop: 50,
  },
  content: {
    maxWidth: 430,
    marginTop: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    paddingBottom: theme.spacing(6),
  },
  title: {
    fontFamily: 'GT Walsheim',
    fontSize: 64,
    lineHeight: '74px',
    padding: '0px 40px',
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontSize: 16,
    color: '#817f86',
    marginTop: 10,
  },
  publishers: {
    marginTop: 50,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  publisher: {
    width: 75,
    height: 75,
    margin: '7px',
    borderRadius: 40,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  button: {
    textTransform: 'uppercase',
    maxWidth: 350,
    marginTop: 50,
    color: '#ffffff',
  },
}));

const Onboard = ({ user }) => {
  const history = useHistory();
  if (user?.isOnboarded) {
    history.push(routes.news.main);
  }
  const location = useLocation();
  const redirectPath = location?.state?.redirectPath;

  const classes = styles();

  const onboardChannelsQuery = useQuery('getOnboardChannels', getOnboardChannels);
  const channels = onboardChannelsQuery.data;

  return (
    <>
      <Box className={classes.onboardContainer}>
        <Container classes={{ root: classes.containerRoot }}>
          <SpotlightLogo height={45} />
        </Container>
        <Container component="main">
          <Box className={classes.content}>
            <Typography variant="h1" className={classes.title}>Control Your News.</Typography>
            <Typography variant="h2" className={classes.subtitle}>Start building your newsfeed.</Typography>

            {channels && (
            <Box className={classes.publishers}>
              {channels.map((channel) => (
                <Box
                  className={classes.publisher}
                  style={{ backgroundImage: `url(${channel?.avatar})` }}
                />
              ))}
            </Box>
            )}

            <Typography variant="h2" className={classes.subtitle}>..and so much more!</Typography>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              component={Link}
              onClick={() => history.push(routes.onboard.interests, { redirectPath })}
            >
              Continue
            </Button>
          </Box>
        </Container>
      </Box>

    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.profile,
  };
}

Onboard.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, null)(Onboard);
