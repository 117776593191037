import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  iconCenter: {
    textAlign: 'center',
  },
}));

const ToastDialog = ({
  active, activateIcon, deactivateIcon,
  activateText, deactivateText, title,
}) => {
  const classes = styles();

  return (
    <>
      <Box className={classes.iconCenter}>{active ? activateIcon : deactivateIcon}</Box>
      <Typography paragraph align="center"><b>{active ? activateText : deactivateText}</b></Typography>
      <Typography paragraph align="left">{title}</Typography>
    </>
  );
};

ToastDialog.propTypes = {
  active: PropTypes.bool,
  activateIcon: PropTypes.element,
  deactivateIcon: PropTypes.element,
  activateText: PropTypes.string,
  deactivateText: PropTypes.string,
  title: PropTypes.string,
};

export default ToastDialog;
