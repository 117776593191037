import client from '../utils/client';

// eslint-disable-next-line import/prefer-default-export
const elections = async () => {
  const url = '/elections';
  try {
    const response = await client().get(url);
    const { data } = response;
    return data;
  } catch (err) {
    return err;
  }
};

const getTotal = ((type, ratings) => {
  if (!ratings) return 0;

  let total = 0;
  Object.entries(ratings)
    .filter((a) => {
      if (type === 'T') return (a[1].label.includes('Toss'));
      return (a[1].label.includes(type) && !a[1].label.includes('Toss'));
    })
    .forEach((b) => {
      const subtotal = b[1];
      total += subtotal?.total;
    });

  return total;
});

const getPercentages = (eData) => {
  const percentages = [
    { color: '#245c94', value: getTotal('Solid D', eData?.ratings) },
    { color: '#347cbc', value: getTotal('Likely D', eData?.ratings) },
    { color: '#74a4d4', value: getTotal('Lean D', eData?.ratings) },
    { color: '#acacac', value: getTotal('T', eData?.ratings) },
    { color: '#f47484', value: getTotal('Lean R', eData?.ratings) },
    { color: '#ec3c4c', value: getTotal('Likely R', eData?.ratings) },
    { color: '#bc2c3c', value: getTotal('Solid R', eData?.ratings) },
  ];
  switch (eData?.name) {
    case 'US Senate':
      return [
        { color: "url('/images/bg-democrat.jpg') center/cover", value: 28 }, // Democrats
        ...percentages,
        { color: "url('/images/bg-republican.jpg') center/cover", value: 38 }, // Republicans
      ];
    case 'Governors':
      return [
        { color: "url('/images/bg-democrat.jpg') center/cover", value: 20 }, // Democrats
        ...percentages,
        { color: "url('/images/bg-republican.jpg') center/cover", value: 19 }, // Republicans
      ];
    default:
      return percentages;
  }
};

export { elections, getTotal, getPercentages };
