import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, ListItemText, makeStyles, Modal, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalDialog: {
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    position: 'relative',
    width: 500,
    padding: '16px 24px',
    outline: 'none',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
    '& > li': {
      padding: 0,
    },
  },
  modalTitle: {
    fontFamily: 'Roboto',
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 15,
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 8,
    top: 8,
  },
  listItemText: {
    color: theme.palette.text,
  },
}));

const SearchInfoDialog = ({ show, onCloseClick }) => {
  const classes = styles();

  return (
    <>
      <Modal
        open={show}
        className={classes.modal}
      >
        <Box className={classes.modalDialog}>

          <CloseIcon className={classes.closeIcon} onClick={() => onCloseClick(false)} />

          <Typography className={classes.modalTitle}>Search info</Typography>

          <ListItem>
            <ListItemText
              className={classes.listItemText}
              primary="Use quotes to search for specific phrases as in college football playoffs. Add multiple words or phrases to search for any of them."
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="Add multiple words or phrases to search for any of them. Separate words or phrases by using commas or spaces. For example: College, football playoffs, would show results with any of those words."
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="Use a plus sign between multiple words or phrases to refine the search. For example: college + football + playoffs, would show results that include college AND football AND playoffs."
            />
          </ListItem>
        </Box>
      </Modal>
    </>
  );
};

SearchInfoDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default SearchInfoDialog;
