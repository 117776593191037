import React from 'react';
import { Box, Button, Link, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  h1: {
    fontFamily: 'Roboto',
    fontSize: 38,
    fontWeight: 'bold',
    marginBottom: 60,
  },
  premiumContainer: {
    textAlign: 'center',
    paddingTop: 50,
  },
  button: {
    padding: '10px 20px',
    color: '#ffffff',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const PremiumConfirmation = () => {
  const classes = styles();
  const history = useHistory();

  return (
    <Box className={classes.premiumContainer}>
      <Typography className={classes.h1} variant="h1">Your subscription was confirmed.</Typography>
      <Button className={classes.button} onClick={() => history.push(routes.news.main)} component={Link} variant="contained" color="primary">Go to My News</Button>
    </Box>
  );
};

export default PremiumConfirmation;
