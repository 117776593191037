import React from 'react';
import PropTypes from 'prop-types';
import ElectionsDetail from '../../components/Elections/ElectionsDetail';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const ElectionsInternal = ({ match }) => {
  useBackgroundClass('elections');
  return (<ElectionsDetail match={match} />);
};

ElectionsInternal.propTypes = {
  match: PropTypes.shape(),
};

export default ElectionsInternal;
