import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

const CanceledHeartIcon = ({ width = 80 }) => (
  <Container
    style={{
      display: 'inline-block',
      width: `${width}px`,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="vector"
    >
      <path
        id="path"
        d="M 1 4.27 L 2.28 3 L 20 20.72 L 18.73 22 L 15.18 18.44 L 13.45 20.03 L 12 21.35 L 10.55 20.03 C 5.4 15.36 2 12.27 2 8.5 C 2 7.55 2.23 6.67 2.63 5.9 L 1 4.27 M 7.5 3 C 9.24 3 10.91 3.81 12 5.08 C 13.09 3.81 14.76 3 16.5 3 C 19.58 3 22 5.41 22 8.5 C 22 11.07 20.42 13.32 17.79 15.97 L 5.27 3.45 C 5.95 3.16 6.7 3 7.5 3 Z"
        fill="#ffffff"
      />
    </svg>
  </Container>
);

CanceledHeartIcon.propTypes = {
  width: PropTypes.number,
};

export default CanceledHeartIcon;
