import client from '../utils/client';

const bracketChallenge = async (userId) => {
  const url = `/contests/${userId}/bracket_challenge`;
  try {
    const response = await client().get(url);
    const { data } = response;
    return data;
  } catch (err) {
    return err;
  }
};

const getContests = async (contestId = null) => {
  const url = '/contests';
  try {
    const response = await client().get(url);
    const { data, messages } = response;

    if (contestId) {
      return {
        // eslint-disable-next-line no-underscore-dangle
        data: data.filter((c) => c._id === contestId),
        messages,
      };
    }

    return {
      data,
      messages,
    };
  } catch (err) {
    return err;
  }
};

const contestEntering = async (contestId) => {
  const url = `/contests/${contestId}/entering`;
  try {
    const response = await client().get(url, { contest_id: contestId });
    const { data } = response;
    return data;
  } catch (e) {
    if (e.status !== 200) {
      return Promise.reject(e.data?.message || e);
    }
    throw e;
  }
};

export {
  bracketChallenge, getContests, contestEntering,
};
