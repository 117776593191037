import { combineReducers } from 'redux';

import app from './app';
import user from './user';
import news from './news';
import discover from './discover';
import onboarding from './onboarding';

const appReducer = combineReducers({
  app,
  user,
  news,
  discover,
  onboarding,
});

const rootReducer = (state, action) => {
  // Clear state on logout
  if (action.type === 'USER_SIGN_OUT') {
    return appReducer(undefined, { type: 'SET_INITIALIZED' });
  }
  return appReducer(state, action);
};

export default rootReducer;
