import client from '../utils/client';

const readArticle = (userId, articleId) => {
  const url = `/users/${userId}/read_articles/${articleId}`;
  return async (dispatch) => {
    try {
      client().put(url);
    } catch (err) {
      if (err.status !== 404) {
        console.log('error');
      }
    }
  };
};

const fetchReadArticles = async (userId, page = 1) => {
  const url = `/users/${userId}/read_articles`;
  const response = await client().get(url, { params: { page } });
  return {
    articles: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const fetchReadArticlesByPublisher = async (userId, channelId, page = 1) => {
  const url = `/users/${userId}/read_articles`;
  const response = await client().get(url, { params: { page, publisher_id: channelId } });
  return {
    articles: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const clearAllReadArticles = async (userId) => {
  const url = `/users/${userId}/read_articles`;
  await client().delete(url);
};

const registerArticleHistory = async (
  userId,
  token,
  articleId,
  readingTime,
  scrollness,
  currentScrollness,
  instanceId,
  finished = false,
) => {
  const url = `/history/${userId}/read_articles/${token}`;

  await client().patch(url, {
    article_id: articleId,
    reading_time: readingTime,
    scrollness,
    current_scrollness: currentScrollness,
    instance_id: instanceId,
    finished,
  });
};

export {
  readArticle, fetchReadArticles, clearAllReadArticles,
  fetchReadArticlesByPublisher, registerArticleHistory,
};
