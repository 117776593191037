import client from '../utils/client';

const trackEvent = async (data) => {
  try {
    await client(true, { isEvent: true }).put('/client', {
      Data: data,
    });
  } catch (err) {
    console.log(err.message);
  }
};

export default trackEvent;
