import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';

import {
  CircularProgress,
  Grid,
  makeStyles,
} from '@material-ui/core';

import ArticleCard, { ArticleCardSkeleton } from '../ArticleCard';

const styles = makeStyles((theme) => ({
  progressContainer: {
    padding: 10,
    textAlign: 'center',
    width: '100%',
  },
  skeletonRoot: {
    marginBottom: 32,
    marginTop: 16,
  },
}));

const ArticleList = ({
  showPlaceholders, isLoading, articles,
  loadMore, hasMore,
}) => {
  const classes = styles();

  const renderArticles = () => (
    articles.map((article) => (
      <Grid item md={3} key={`grid-${article.id}`}>
        <ArticleCard key={article.id} article={article} />
      </Grid>
    ))
  );

  if (showPlaceholders) {
    return (
      <Grid container wrap="wrap" spacing={3} classes={{ root: classes.skeletonRoot }}>
        <Grid key="article-skeleton-1" item sm={4} lg={3}>
          <ArticleCardSkeleton />
        </Grid>
        <Grid key="article-skeleton-2" item sm={4} lg={3}>
          <ArticleCardSkeleton />
        </Grid>
        <Grid key="article-skeleton-3" item sm={4} lg={3}>
          <ArticleCardSkeleton />
        </Grid>
        <Grid key="article-skeleton-4" item sm={4} lg={3}>
          <ArticleCardSkeleton />
        </Grid>
      </Grid>
    );
  }

  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={() => (!isLoading && loadMore())}
      loader={(
        <div key="loader" className={classes.progressContainer}>
          <CircularProgress />
        </div>
      )}
      hasMore={hasMore}
      useWindow
      initialLoad={false}
      element="section"
    >
      {renderArticles()}
    </InfiniteScroll>
  );
};

ArticleList.propTypes = {
  showPlaceholders: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default ArticleList;
