import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Hidden, makeStyles, Typography, Link as LinkMaterial } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PublisherBox, { PublisherBoxSkeleton } from '../Publisher/PublisherBox';
import SectionHeader from './SectionHeader';
import routes from '../../routes';
import SearchField from '../common/SearchField';
import { useQuery } from '../../hooks/useQuery';
import { searchPublishers } from '../../actions/channel';
import { getSections } from '../../actions/discover';
import SearchInfoDialog from './SearchInfoDialog';
import { language } from '../../utils/helpers';
import { getSearchHistory, saveSearchExpression } from '../../actions/search';

const styles = makeStyles((theme) => ({
  sectionsRoot: {
    marginBottom: theme.spacing(3),
    position: 'relative',
    paddingBottom: 60,
    [theme.breakpoints.up('lg')]: {
      '& > .MuiGrid-item': {
        display: 'none',
      },
      '& > .MuiGrid-item:nth-child(-n+6)': {
        display: 'block',
      },
    },
    [theme.breakpoints.up('md')]: {
      '& > .MuiGrid-item': {
        display: 'none',
      },
      '& > .MuiGrid-item:nth-child(-n+4)': {
        display: 'block',
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& > .MuiGrid-item': {
        display: 'none',
      },
      '& > .MuiGrid-item:nth-child(-n+3)': {
        display: 'block',
      },
    },
    [theme.breakpoints.up('xs')]: {
      '& > .MuiGrid-item': {
        display: 'none',
      },
      '& > .MuiGrid-item:nth-child(-n+2)': {
        display: 'block',
      },
    },
  },
  allPublishersButton: {
    marginBottom: theme.spacing(3),
    float: 'right',
  },
  searchContainer: {
    textAlign: 'center',
    marginBottom: 30,
    display: 'flex',
    alignItems: 'baseline',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'end',
    },
  },
  noPublishersFound: {
    padding: 15,
  },
  infoOutlinedIcon: {
    marginLeft: 20,
    cursor: 'pointer',
  },
  viewAllButton: {
    marginBottom: 20,
    cursor: 'pointer',
    color: '#37bc98',
    background: 'none',
    boxShadow: 'none',
    position: 'absolute',
    right: 0,
    bottom: 0,
    '&:hover': {
      background: 'none',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  viewAllContainer: {
    textAlign: 'center',
  },
  learnMoreButton: {
    marginLeft: 15,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      marginTop: 15,
    },
  },
  tips: {
    textAlign: 'center',
    color: '#37BC98',
    fontWeight: 'bold',
    marginBottom: 15,
    display: 'block',
  },
  discoverHeaderRoot: {
    marginBottom: 30,
  },
}));

const DiscoverChannels = ({
  searchText,
  updateSearchText,
  userId,
}) => {
  const classes = styles();
  const [variables, setVariables] = useState([searchText]);
  const [showDialog, setShowDialog] = useState(false);
  const [viewMore, setViewMore] = useState([]);
  const [currentSearchValue, setCurrentSearchValue] = useState(null);
  const [saveText, setSaveText] = useState(null);

  const sectionsQuery = useQuery('discoverSections', getSections);
  const { data: sections, isFetching } = sectionsQuery;

  const querySearchPublishers = useQuery('searchPublishers', searchPublishers, {
    enabled: false,
    variables,
  });
  const searchResults = querySearchPublishers.data;
  const searchIsLoading = querySearchPublishers.isFetching;

  const saveSearchExpressionQuery = useQuery('saveSearchExpressionChannel', saveSearchExpression, {
    variables: [userId, 'channels', saveText],
    enabled: false,
  });

  const getSearchExpressionQuery = useQuery('getSearchExpressionChannels', getSearchHistory, {
    variables: [userId, 'channels'],
  });
  const expressions = getSearchExpressionQuery?.data?.history;

  const history = currentSearchValue
    ? expressions.filter((ex) => ex.expression.includes(currentSearchValue))
    : expressions;

  const onSearchChange = (value) => {
    updateSearchText(value);
    setVariables([value]);
    setSaveText(value);
  };

  useEffect(() => {
    if (saveText) {
      saveSearchExpressionQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveText]);

  useEffect(() => {
    if (saveSearchExpressionQuery.isFetched) {
      getSearchExpressionQuery.refetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSearchExpressionQuery.isFetched]);

  const clearSearch = () => {
    updateSearchText(null);
  };

  useEffect(() => {
    if (searchText) {
      setVariables([searchText]);
      querySearchPublishers.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const renderSearchField = () => (
    <Box>
      <Box className={classes.discoverHeaderRoot}>
        <Box className={classes.searchContainer}>
          {getSearchExpressionQuery.isFetched && (
            <SearchField
              placeholder="Search Publishers"
              searchIsLoading={searchIsLoading}
              clearSearch={clearSearch}
              onSearchChange={onSearchChange}
              style={{ marginTop: 0, width: '100%' }}
              searchFieldStyle={{ width: '100%' }}
              fieldValue={searchText}
              autocomplete
              setCurrentSearchValue={setCurrentSearchValue}
              expressions={history}
              allExpressions={expressions}
              type="channels"
              refreshExpressions={() => getSearchExpressionQuery.refetch()}
            />
          )}
        </Box>

        <Box>
          <LinkMaterial target="_blank" href="https://spotlightnews.us/learn-more.html" className={classes.tips}>Helpful tips on how to search</LinkMaterial>
        </Box>
      </Box>
      <Button className={classes.allPublishersButton} to={routes.allPublishers} variant="contained" color="primary" component={Link}>
        Show All
      </Button>
    </Box>
  );

  const showSkeleton = () => Array(12).fill().map((_, i) => (
    <Grid key={`publisher-skeleton-${i}`} item xs={6} sm={4} md={3} lg={2}>
      <PublisherBoxSkeleton key={`publisher-skeleton-item-${i}`} />
    </Grid>
  ));

  if (!sections && isFetching) {
    return (
      <Grid container spacing={3} classes={{ root: classes.sectionsRoot }}>
        {showSkeleton()}
      </Grid>
    );
  }

  const onCloseSearchInfoDialog = () => {
    setShowDialog(false);
  };

  return searchText
    ? (
      <>
        {renderSearchField()}

        <SearchInfoDialog show={showDialog} onCloseClick={onCloseSearchInfoDialog} />
        <Grid container spacing={3} classes={{ root: classes.sectionsRoot }}>
          {searchResults?.channels.length > 0 && searchResults.channels.map((channel) => (
            <Grid item key={channel.id} lg={2} md={3} sm={4} xs={6}>
              <PublisherBox
                publisher={channel}
                onToggleSubscription={() => sectionsQuery.refetch()}
              />
            </Grid>
          ))}
          {searchResults?.channels.length < 1 && (
            <Grid lg={12} md={12} sm={12} xs={12} classes={{ root: classes.noPublishersFound }}>
              <Typography paragraph>{`No publishers found for ${searchText}.`}</Typography>
            </Grid>
          )}
        </Grid>
      </>
    )
    : (
      <>
        <SearchInfoDialog show={showDialog} onCloseClick={onCloseSearchInfoDialog} />
        {renderSearchField()}

        {sections && sections.filter((s) => s.type === 'channels').map((section) => (
          <>
            <SectionHeader key={`sectionHeader-${section.id}`} names={section.names} />
            <Grid key={`grid-${section.id}`} container spacing={3} classes={{ root: classes.sectionsRoot }}>
              {section.resources.map((channel) => (
                <Grid item key={channel.id} lg={2} md={3} sm={4} xs={6} style={viewMore.includes(section.id) ? { display: 'block' } : {}}>
                  <PublisherBox
                    publisher={channel}
                    onToggleSubscription={() => sectionsQuery.refetch()}
                  />
                </Grid>
              ))}
              {!viewMore.includes(section.id) && (
                <Hidden
                  xsUp={section.resources.length <= 2}
                  smUp={section.resources.length <= 3}
                  mdUp={section.resources.length <= 4}
                  lgUp={section.resources.length <= 6}
                  className={classes.viewAllContainer}
                >
                  <Button className={classes.viewAllButton} variant="contained" color="primary" onClick={() => setViewMore([...viewMore, section.id])}>More {language(section.names)}</Button>
                </Hidden>
              )}
            </Grid>
          </>
        ))}
      </>
    );
};

DiscoverChannels.propTypes = {
  searchText: PropTypes.string,
  updateSearchText: PropTypes.func,
  userId: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    userId: state.user.profile.id,
  };
}

export default connect(mapStateToProps)(DiscoverChannels);
