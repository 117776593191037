import Cookies from 'js-cookie';
import config from '../config';

export function getAuthToken() {
  let token = sessionStorage.getItem(config.tokenKey);
  if (!token) {
    token = localStorage.getItem(config.tokenKey);
    if (token) {
      sessionStorage.setItem(config.tokenKey, token);
    }
  }
  return token;
}

export function setAuthToken(token, rememberMe) {
  const storage = rememberMe ? localStorage : sessionStorage;
  storage.setItem(config.tokenKey, token);
}

export function removeAuthToken() {
  localStorage.removeItem(config.tokenKey);
  sessionStorage.removeItem(config.tokenKey);
  Cookies.remove(config.tokenKey, { domain: '.spotlightnews.us' });
}

export function setAuthTokenFromCookie() {
  const authToken = getAuthToken();
  if (!authToken) {
    const cookiesToken = Cookies.get(config.tokenKey);
    if (cookiesToken) {
      setAuthToken(cookiesToken);
      Cookies.remove(config.tokenKey, { domain: '.spotlightnews.us' });
    }
  }
}
