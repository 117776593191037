import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Box,
  CircularProgress,
  Link,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { toggleTopicSubscription as toggleTopicSubscriptionAction } from '../../actions/topics';
import { language } from '../../utils/helpers';
import ToastDialog from '../ToastDialog/ToastDialog';
import routes from '../../routes';
import InterestIcon from '../common/InterestIcon';

const styles = makeStyles((theme) => ({
  articlesCount: {
    marginRight: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  interestTitle: {
    display: 'block',
    color: theme.palette.text.primary,
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  expansionPanelSummary: {
    justifyContent: 'space-between',
    margin: 0,
  },
  expansionPanelSummaryRoot: {
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .Mui-expanded': {
      margin: 0,
    },
    '& > .MuiIconButton-edgeEnd': {
      display: 'none',
    },
  },
  expansionPanel: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  expansionPanelDetails: {
    display: 'block',
    padding: 0,
    paddingLeft: 14,
  },
  interestContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    margin: '11px 0px',
  },
  iconPlaceholder: {
    width: 24,
    height: 24,
  },
  articlesIconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  row: {
    borderBottom: '2px solid #f6f6f6',
    '&:last-child': {
      border: 'none',
    },
  },
  spotlightToast: theme.toastDialog,
  interestLink: {
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
  },
  interestIcon: {
    backgroundColor: '#34a788',
    borderRadius: 20,
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Interest = ({
  data,
  userId,
  onChangeInterest,
  isLoadingChildren,
  expanded,
  toggleTopicSubscription,
  fullName,
}) => {
  const classes = styles();
  const [isSubscribed, setIsSubscribed] = useState(data.is_subscribed);

  useEffect(() => {
    setIsSubscribed(data.is_subscribed);
  }, [data.is_subscribed]);

  useEffect(() => () => {
    toast.dismiss();
  }, []);

  const handleIconClick = (event) => {
    event.stopPropagation();
    toggleTopicSubscription(userId, data.id, !isSubscribed);
    setIsSubscribed(!isSubscribed);

    toast.success(
      <ToastDialog
        active={!isSubscribed}
        activateIcon={<CheckIcon fontSize="large" />}
        deactivateIcon={<CancelIcon />}
        activateText="Now Following"
        deactivateText="Unfollowed"
        title={`#${language(data.names)}`}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );
  };
  const startIcon = isSubscribed
    ? <CheckCircleIcon className={classes.icon} onClick={(event) => handleIconClick(event)} />
    : <AddIcon className={classes.icon} onClick={(event) => handleIconClick(event)} />;

  return (
    <Box className={classes.row}>
      <Box className={classes.interestContainer}>
        <ExpansionPanel
          onChange={() => onChangeInterest(data)}
          className={classes.expansionPanel}
          square
          TransitionProps={{
            timeout: 0,
          }}
          expanded={expanded}
        >
          <ExpansionPanelSummary
            expandIcon={
              data.children?.length
                ? <ExpandMoreIcon />
                : (isLoadingChildren && isLoadingChildren === data.parent_id)
                  ? <CircularProgress size={24} />
                  : (<div className={classes.iconPlaceholder} />)
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            classes={{
              root: classes.expansionPanelSummaryRoot,
              content: classes.expansionPanelSummary,
            }}
          >
            <Box className={classes.articlesIconContainer}>
              <Link href={routes.interest.feed(data.id)} className={classes.interestLink}>
                <Box className={classes.interestIcon}>
                  <InterestIcon size={25} color="#ffffff" />
                </Box>
                <Typography className={classes.interestTitle}>
                  {fullName ? language(data.names) : language(data.short_names)}
                </Typography>
              </Link>
            </Box>
            <Box className={classes.articlesIconContainer}>{startIcon}</Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {data.children && data.children.map((childrenInterest) => (
              <Interest
                isLoadingChildren={isLoadingChildren}
                userId={userId}
                key={childrenInterest.id}
                data={childrenInterest}
                onChangeInterest={onChangeInterest}
                toggleTopicSubscription={toggleTopicSubscription}
              />
            ))}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
    </Box>
  );
};

Interest.propTypes = {
  data: PropTypes.shape().isRequired,
  userId: PropTypes.string.isRequired,
  onChangeInterest: PropTypes.func.isRequired,
  isLoadingChildren: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  expanded: PropTypes.bool,
  toggleTopicSubscription: PropTypes.func.isRequired,
  fullName: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  toggleTopicSubscription: (userId, topicId, isSubscribed) => {
    dispatch(toggleTopicSubscriptionAction(userId, topicId, isSubscribed));
  },
});

export default connect(null, mapDispatchToProps)(Interest);
