import client from '../utils/client';

const fetchCurrentState = async (user) => {
  const urlBulkOrManaged = `/subscriptions/${user?.id}/current_state`;
  try {
    const responseBulkOrManaged = await client().get(urlBulkOrManaged, { params: { platform: 'webapp' } });
    return responseBulkOrManaged.data?.managed_subscriptions.length > 0
      ? responseBulkOrManaged.data?.managed_subscriptions
      : responseBulkOrManaged.data?.bulk_subscriptions;
  } catch (err) {
    console.log(err);
  }
  return null;
};

const fetchRcOfferings = async (user) => {
  const url = `/subscriptions/${user?.id}/offerings`;

  try {
    const response = await client().get(url, { params: { platform: 'webapp' } });
    const data = response?.data;

    return {
      offerings: data?.offerings,
      currentOfferingType: data?.current_offering_type,
    };
  } catch (err) {
    console.log(err);
  }

  return null;
};

const findOrCreateStripeSubscription = async (userId, priceId) => {
  const url = `/stripe/${userId}/subscriptions`;

  try {
    const response = await client(true, { returnStatus: true }).post(url, { price_id: priceId });
    return response;
  } catch (err) {
    console.log(err);
  }

  return null;
};

const createIntentSubscription = async (userId, productId) => {
  const url = `/subscriptions/${userId}/intents`;
  try {
    const response = await client().post(url, { platform: 'webapp', action: 'select', user_id: userId, product_id: productId });
    return response.data;
  } catch (err) {
    console.log(err);
  }

  return null;
};

const createIntentCancellation = async (userId, productId) => {
  const url = `/subscriptions/${userId}/intents`;
  try {
    const response = await client().post(url, { platform: 'webapp', action: 'cancel', user_id: userId, product_id: productId });
    return response.data;
  } catch (err) {
    console.log(err);
  }

  return null;
};

const cancelSubscription = async (userId, subscriptionId) => {
  const url = `/stripe/${userId}/subscriptions/${subscriptionId}`;

  try {
    const response = await client().delete(url);
    return response.data;
  } catch (err) {
    console.log(err);
  }

  return null;
};

const setupIntentSubscription = async (userId) => {
  const url = `/stripe/${userId}/setup_intent`;
  try {
    const response = await client().post(url);
    return response.data;
  } catch (err) {
    console.log(err);
  }

  return null;
};

const setDefaultPaymentMethod = async (userId, paymentMethodId) => {
  const url = `/stripe/${userId}/default_payment_method`;
  try {
    const response = await client().put(url, { payment_method_id: paymentMethodId });
    return response.data;
  } catch (err) {
    console.log(err);
  }

  return null;
};

export {
  findOrCreateStripeSubscription, fetchRcOfferings,
  cancelSubscription, fetchCurrentState,
  createIntentSubscription, createIntentCancellation,
  setupIntentSubscription, setDefaultPaymentMethod,
};
