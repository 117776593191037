import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, withRouter, Link } from 'react-router-dom';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { getBrandTextColor } from '../../../utils/helpers';
import routes from '../../../routes';

import Subscription from '../../Publisher/Subscription';
import DownloadDialog from '../../Article/ArticleDetail/DownloadDialog';
import { useQuery } from '../../../hooks/useQuery';
import LoadingIcon from '../../common/LoadingIcon';
import { downloadArticle } from '../../../actions/download';
import ArticleSearchDialog from '../../Article/ArticleSearchDialog/ArticleSearchDialog';

const styles = makeStyles((theme) => ({
  containerRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
    padding: '5px 0px',
  },
  iconColorClass: {
    color: (branding) => getBrandTextColor(branding),
  },
  publisherLogo: {
    maxHeight: 38,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
    },
  },
  appBar: {
    backgroundColor: (branding) => branding.color,
  },
  moreVertIcon: {
    minWidth: '0px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingIcon: {
    width: 40,
    height: 60,
    alignItems: 'center',
  },
}));

const PublisherNavBar = ({
  isSignedIn,
  channel,
  isFavorite,
  isBookmarked,
  isNotifiable,
  onBackClick,
  onFavoriteClick,
  onBookmarkClick,
  onNotificationClick,
  publisherUrl,
  subscribable,
  article,
  user,
  showAboutMenu,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showArticleSearchDialog, setShowArticleSearchDialog] = useState(false);

  const menuItems = (channel.publishers) ? ({
    about: {
      label: 'About',
      path: routes.publishers.about(channel.slug),
    },
    categories: {
      label: 'Categories',
      path: routes.publishers.categories(channel.slug),
    },
    members: {
      label: 'Members',
      path: routes.publishers.members(channel.slug),
    },
  }) : ({
    about: {
      label: 'About',
      path: routes.publishers.about(channel.slug),
    },
    categories: {
      label: 'Categories',
      path: routes.publishers.categories(channel.slug),
    },
    recent: {
      label: 'Recent',
      path: routes.publishers.recent(channel.slug),
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();
  const handleMenuItemClick = (item) => {
    history.push(item.path);
  };

  const branding = channel?.branding;
  const classes = styles({ ...branding });

  const [isSubscribed, setIsSubscribed] = useState(channel.is_subscribed);
  const [showDialog, setShowDialog] = useState(false);

  const isGuest = !user?.profile?.email;

  const downloadArticleQuery = useQuery('downloadArticle', downloadArticle, {
    variables: [article],
    enabled: false,
  });

  const downloadArticleClick = (articleObj) => {
    if (!user.isSignedIn || isGuest) {
      setShowDialog(true);
    } else {
      downloadArticleQuery.refetch();
    }
  };

  const onCloseArticleSearchDialog = () => {
    setShowArticleSearchDialog(false);
  };

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Container classes={{ root: classes.containerRoot }}>
        <DownloadDialog
          guest={user.isSignedIn && isGuest}
          show={showDialog}
          onCloseClick={() => setShowDialog(false)}
        />
        <IconButton
          color="inherit"
          onClick={onBackClick}
          classes={{ colorInherit: classes.iconColorClass }}
        >
          <ChevronLeftIcon />
        </IconButton>
        {branding?.logo && (
          <Link to={publisherUrl}>
            <img
              src={branding?.logo}
              alt="logo"
              className={classes.publisherLogo}
            />
          </Link>
        )}
        <Box className={classes.iconContainer}>
          {isSignedIn && (
            <>
              {downloadArticleQuery.isFetching
                ? (
                  <Box className={classes.loadingIcon} style={{ display: article ? 'flex' : 'none' }}>
                    <LoadingIcon />
                  </Box>
                )
                : (
                  <IconButton
                    color="inherit"
                    classes={{ colorInherit: classes.iconColorClass }}
                    aria-label="Download"
                    title="Download"
                    onClick={() => downloadArticleClick(article)}
                    style={{ display: article ? 'block' : 'none' }}
                  >
                    <GetAppIcon />
                  </IconButton>
                )}
              {onFavoriteClick && (
                <IconButton
                  color={isFavorite ? 'primary' : 'inherit'}
                  onClick={onFavoriteClick}
                  classes={{ colorInherit: classes.iconColorClass }}
                  aria-label="Like"
                  title="Like"
                >
                  <FavoriteIcon />
                </IconButton>
              )}
              {onBookmarkClick && (
                <IconButton
                  color={isBookmarked ? 'primary' : 'inherit'}
                  onClick={onBookmarkClick}
                  classes={{ colorInherit: classes.iconColorClass }}
                  aria-label="Bookmark"
                  title="Bookmark"
                >
                  <BookmarkIcon />
                </IconButton>
              )}

              {subscribable && (
                <>
                  <Subscription
                    classes={{ root: classes.iconColorClass }}
                    publisher={channel}
                    isSubscribed={channel.is_subscribed}
                    onChanged={setIsSubscribed}
                  />

                  {isSubscribed
                    && (
                      <IconButton onClick={onNotificationClick}>
                        {(isNotifiable) ? (
                          <NotificationsIcon classes={{ root: classes.iconColorClass }} />
                        ) : (
                          <NotificationsOffIcon classes={{ root: classes.iconColorClass }} />
                        )}
                      </IconButton>
                    )}
                </>
              )}

              {!article && (
                <IconButton>
                  <SearchIcon
                    classes={{ root: classes.iconColorClass }}
                    onClick={() => setShowArticleSearchDialog(true)}
                  />
                </IconButton>
              )}

              <ArticleSearchDialog
                show={showArticleSearchDialog}
                channelId={channel?.id}
                onCloseClick={onCloseArticleSearchDialog}
              />

              {showAboutMenu && (
                <IconButton className={classes.moreVertIcon} onClick={handleClick}>
                  <MoreVertIcon classes={{ root: classes.iconColorClass }} />
                </IconButton>
              )}

              {(menuItems) && (
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {Object.keys(menuItems).map((key) => (
                    <MenuItem
                      onClick={() => handleMenuItemClick(menuItems[key])}
                      key={menuItems[key].label}
                    >
                      {menuItems[key].label}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </>
          )}
        </Box>
      </Container>
    </AppBar>
  );
};

PublisherNavBar.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  channel: PropTypes.shape().isRequired,
  isFavorite: PropTypes.bool,
  isBookmarked: PropTypes.bool,
  isNotifiable: PropTypes.bool,
  onBackClick: PropTypes.func.isRequired,
  onFavoriteClick: PropTypes.func,
  onBookmarkClick: PropTypes.func,
  onNotificationClick: PropTypes.func,
  publisherUrl: PropTypes.string.isRequired,
  subscribable: PropTypes.bool,
  article: PropTypes.shape(),
  user: PropTypes.shape(),
  showAboutMenu: PropTypes.bool,
};

PublisherNavBar.defaultProps = {
  subscribable: true,
  showAboutMenu: true,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(withRouter(PublisherNavBar));
