import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../../routes';
import { withErrorReporting } from '../../../utils/errors';
import ErrorState from '../../common/ErrorState';
import ArticleCardList from '../ArticleCardList';
import { usePaginationQuery, useQuery } from '../../../hooks/useQuery';

const ArticlePagination = ({
  userId,
  loadsContext,
  load,
  clearAll,
  label,
  EmptyStateComponent,
  channel,
  biggerCard,
  type,
}) => {
  const history = useHistory();

  const query = usePaginationQuery(loadsContext, load, {
    variables: [userId],
  });

  const clearAllQuery = useQuery('clearAll', withErrorReporting(async () => {
    await clearAll(userId);
    query.refetch();
  }), {
    enabled: false,
  });

  if (query.error) return <ErrorState retry={query.refetch} height="90vh" />;

  const { canFetchMore, fetchMore, data, isFetching, isFetchingMore } = query;
  const totalCount = (data) ? data[0].totalCount : 0;
  const articles = (data ?? []).flatMap((page) => page.articles);

  const handleEmptyButtonClick = () => {
    history.push(routes.discover);
  };

  return (
    <ArticleCardList
      initialLoad={!isFetchingMore}
      label={label}
      isLoading={isFetching}
      hasMore={Boolean(canFetchMore)}
      loadMore={() => fetchMore()}
      EmptyStateComponent={EmptyStateComponent}
      onEmptyButtonClick={handleEmptyButtonClick}
      onClearAllClick={clearAll && clearAllQuery.refetch}
      articles={articles}
      articleCount={totalCount}
      channel={channel}
      bigger={biggerCard}
      type={type}
    />
  );
};

ArticlePagination.propTypes = {
  userId: PropTypes.string,
  label: PropTypes.string.isRequired,
  loadsContext: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  clearAll: PropTypes.func,
  EmptyStateComponent: PropTypes.elementType.isRequired,
  channel: PropTypes.shape(),
  biggerCard: PropTypes.bool,
  type: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userId: state.user?.profile?.id,
});

export default connect(mapStateToProps)(ArticlePagination);
