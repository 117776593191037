/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatDistance } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import {
  makeStyles,
  Typography,
  Box,
  Link,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { findImageSources, getOptimalSource } from '../../../utils/media';
import useComponentWidth from '../../../hooks/useComponentWidth';
import routes from '../../../routes';
import PremiumDialog from '../../Premium';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    opacity: 1,
    borderRadius: 8,
    border: '1px solid rgba(112, 112, 112, .2)',
    padding: theme.spacing(2),
    marginBottom: 10,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      borderRadius: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBottom: 0,
    },
    '&:hover $focusHighlight': {
      opacity: 0.07,
    },
  },
  textContent: {
    width: 550,
    marginRight: 15,
  },
  publisherTitleRoot: {
    color: theme.palette.text.secondary,
    textTransform: 'capitalize',
    lineHeight: 2,
  },
  articleTitleRoot: {
    height: '56px',
    fontSize: '1.125rem',
    lineHeight: 1.5,
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
  },
  photo: {
    display: 'inline-block',
    backgroundColor: theme.palette.background.default,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: 100,
    height: 100,
    minWidth: 100,
    borderRadius: 4,
  },
  focusHighlight: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 'inherit',
    pointerEvents: 'none',
    backgroundColor: 'currentcolor',
  },
  premiumLabel: {
    color: '#37BC98',
    paddingBottom: 8,
    fontWeight: 500,
    letterSpacing: 2,
    fontSize: 12,
  },
}));

const ArticleListItem = ({ article }) => {
  const classes = styles();
  const { componentRef, componentWidth } = useComponentWidth();
  const history = useHistory();
  const timeSince = formatDistance(parseISO(article.published_at), new Date());
  const source = getOptimalSource(findImageSources(article.media), componentWidth);

  const [showPremiumDialog, setShowPremiumDialog] = useState(false);

  const handleArticleClick = (event) => {
    event.preventDefault();
    if (!article.publisher.subscription.allowed) {
      setShowPremiumDialog(true);
      return false;
    }
    history.push(routes.news.article(article.id));
    return null;
  };

  return (
    <>
      <PremiumDialog
        channel={article?.publisher}
        show={showPremiumDialog}
        onCloseClick={() => setShowPremiumDialog(false)}
      />
      <Box className={classes.root}>
        <div className={classes.textContent}>
          <Link
            href={routes.publishers.main(article.publisher.slug)}
            variant="overline"
            classes={{ root: classes.publisherTitleRoot }}
          >
            {article.publisher.title}
          </Link>
          {article.publisher.premium
              && <Box className={classes.premiumLabel}>Premium</Box>}
          <Link
            href="#"
            onClick={handleArticleClick}
            color="inherit"
          >
            <Typography
              gutterBottom
              variant="h6"
              classes={{ root: classes.articleTitleRoot }}
            >
              {article.tidy_title}
            </Typography>
          </Link>
          <Typography variant="caption">{timeSince} ago</Typography>
        </div>
        <div
          className={classes.photo}
          style={{ backgroundImage: `url(${source || article.publisher.default_image || 'data:,'})` }}
          ref={componentRef}
        />
        <span className={classes.focusHighlight} />
      </Box>
    </>
  );
};

ArticleListItem.propTypes = {
  article: PropTypes.shape().isRequired,
};

export default ArticleListItem;
