import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import red from '@material-ui/core/colors/red';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles({
  deleteButton: {
    color: red[500],
    border: `1px solid ${fade(red[500], 0.5)}`,
    '&:hover': {
      backgroundColor: 'white',
      border: `1px solid ${red[500]}`,
    },
  },
});

export default function DeleteDialog({
  title, description, children, onConfirm, buttonLabel,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = styles();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    onConfirm();
    setIsOpen(false);
  };

  return (
    <div>
      {children({ isOpen, open: handleOpen, close: handleClose })}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {title || 'Are you sure you want to DELETE this item?'}
        </DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            className={classes.deleteButton}
            variant="outlined"
          >
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  children: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

DeleteDialog.defaultProps = {
  buttonLabel: 'Delete',
};
