import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { Divider, makeStyles, Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FeedCards from './FeedCards';
import InfiniteList from '../../common/InfiniteList';
import BannerAd from '../../BannerAd';
import routes from '../../../routes';
import Title from '../Title';
import UserFeedsList from '../../UserFeeds/UserFeedsList';
import { fetchWriter } from '../../../actions/writers';
import SpotlightTooltip from '../../SpotlightTooltip/SpotlightTooltip';

const styles = makeStyles((theme) => ({
  emptyDivider: {
    marginBottom: 32,
  },
  bannerWrapperRoot: {
    margin: '0px auto',
    marginTop: 16,
    marginBottom: 32,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  icon: {
    marginLeft: 15,
    cursor: 'pointer',
  },
  reorderIcon: {
    color: theme.palette.text.primary,
  },
}));

const dummyFeedCards = Array.from({ length: 4 }).map(() => ({
  _id: Math.random().toString(),
  articles: [],
  names: [],
  type: '',
  subject: {},
}));

const FeedCardsList = ({
  feedsCards, feedsCardsIsLoading, hasMore, loadMore, EmptyStateComponent,
  onChannelSubscribeClick, onTopicSubscribeClick,
  onWriterSubscribeClick, onCloseEditMode,
}) => {
  const classes = styles();
  const history = useHistory();
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    if (editMode && onCloseEditMode !== undefined) onCloseEditMode();
    setEditMode(!editMode);
  };

  if (!feedsCardsIsLoading && feedsCards.length === 0) {
    return (
      <>
        <Divider classes={{ root: classes.emptyDivider }} />
        {EmptyStateComponent && <EmptyStateComponent />}
      </>
    );
  }

  const handleClickMore = async (feedCard) => {
    if (feedCard.type === 'topic') {
      // eslint-disable-next-line no-underscore-dangle
      const subjectId = feedCard._id;
      history.push(routes.news.feed(subjectId));
    } else if (feedCard.type === 'writer') {
      // eslint-disable-next-line no-underscore-dangle
      const subjectId = feedCard.subject._id;
      const writer = await fetchWriter(subjectId);
      history.push(routes.writers.main(writer.slug));
    } else {
      const publisher = feedCard?.subject;
      history.push(routes.publishers.main(publisher.slug));
    }
  };

  const showPlaceholders = feedsCardsIsLoading && feedsCards.length === 0;
  const feedsCardsItems = showPlaceholders
    ? dummyFeedCards
    : feedsCards.filter((item) => item.articles.length);
  return (
    <>
      <Box>
        <Box className={classes.titleContainer}>
          <Title title="Feeds" />
          {editMode
            ? <ArrowBackIcon className={classes.icon} onClick={toggleEditMode} />
            : (
              <SpotlightTooltip tooltipKey="mynews-reorder" text="Tap here to easily re-order or remove your follows">
                <Button
                  color="secondary"
                  startIcon={<ImportExportIcon />}
                  onClick={toggleEditMode}
                  className={classes.reorderIcon}
                >
                  Reorder
                </Button>
              </SpotlightTooltip>
            )}
        </Box>
        {(editMode)
          ? (
            <UserFeedsList />
          )
          : (
            <InfiniteList isLoading={feedsCardsIsLoading} loadMore={loadMore} hasMore={hasMore}>
              {feedsCardsItems.map((feedCards, index) => (
                // eslint-disable-next-line no-underscore-dangle
                <Box key={feedCards._id}>
                  <Divider />
                  {index === 2 && (
                    <Box className={classes.bannerWrapperRoot}>
                      <BannerAd
                        placement="user_feeds"
                        minWidth={700}
                        maxWidth={800}
                        numberOfAds={1}
                      />
                    </Box>
                  )}
                  <FeedCards
                    feedCards={feedCards}
                    showPlaceholders={showPlaceholders}
                    onClickMore={handleClickMore}
                    onChannelSubscribeClick={onChannelSubscribeClick}
                    onTopicSubscribeClick={onTopicSubscribeClick}
                    onWriterSubscribeClick={onWriterSubscribeClick}
                  />
                </Box>
              ))}
            </InfiniteList>
          )}
      </Box>
    </>
  );
};

FeedCardsList.propTypes = {
  feedsCards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  feedsCardsIsLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  EmptyStateComponent: PropTypes.elementType.isRequired,
  onChannelSubscribeClick: PropTypes.func.isRequired,
  onTopicSubscribeClick: PropTypes.func.isRequired,
  onWriterSubscribeClick: PropTypes.func.isRequired,
  onCloseEditMode: PropTypes.func,
};

export default FeedCardsList;
