import { Box, Container, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '../../hooks/useQuery';
import { getContests } from '../../actions/promotions';
import ContestItem from './ContestItem';
import ContestEmpty from './ContestEmpty';

const styles = makeStyles((theme) => ({
  containerRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  banner: {
    width: '100%',
  },
  bannerImage: {
    width: '100%',
  },
  messages: {
    padding: '25px 0px',
    paddingLeft: 21,
    paddingRight: 21,
    font: 'normal normal bold 18px/24px Roboto',
    letterSpacing: 1.29,
    color: theme.palette.text.secondary,
  },
  contestList: {
    paddingLeft: 21,
    paddingRight: 21,
  },
}));

const ContestList = ({ webView }) => {
  const classes = styles();

  const contestsData = useQuery('getContest', getContests);

  const contests = contestsData?.data?.data;
  const messages = contestsData?.data?.messages;

  if ((!contests || contests?.error) && !contestsData.isFetching) {
    return (
      <ContestEmpty />
    );
  }

  return contestsData.isFetched && (
    <Container classes={{ root: classes.containerRoot }}>
      <Box className={classes.banner}>
        <img
          className={classes.bannerImage}
          src="images/spotlight-banner-contests.jpg"
          alt="Challenge Lobby - Read Articles, enter challenges, beat your friends and win prizes"
        />
      </Box>

      {messages && (
        <Box className={classes.messages}>
          {messages?.qualification_message}
        </Box>
      )}

      <Box className={classes.contestList}>
        {contests && contests.map((contest) => (
          <ContestItem contest={contest} webView={webView} />
        ))}
      </Box>
    </Container>
  );
};

ContestList.propTypes = {
  webView: PropTypes.bool,
};

export default ContestList;
