import client from '../utils/client';

const fetchAds = async (params) => {
  const ads = await client().get('/ads', { params: {
    ...params, platform: 'WebApp',
  } });

  return ads.data;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  fetchAds,
};
