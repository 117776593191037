import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import StudentIcon from './StudentIcon';

const styles = makeStyles((theme) => ({
  studentModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  studentDialog: {
    backgroundColor: theme.palette.background.paper,
    width: 1000,
    textAlign: 'center',
    position: 'relative',
    padding: '80px 10px',
    borderRadius: 20,
  },
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '& > svg': {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      top: 45,
    },
  },
  title: {
    textAlign: 'center',
    font: 'normal normal bold 32px/32px Roboto',
    letterSpacing: '-0.32px',
    color: theme.palette.text.primary,
    paddingTop: 30,
  },
  studentButton: {
    width: 350,
    padding: 10,
    fontFamily: 'Roboto',
    fontSize: 18,
    marginTop: 30,
  },
  notStudentButton: {
    width: 350,
    padding: 10,
    fontFamily: 'Roboto',
    fontSize: 18,
    marginTop: 30,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
}));

const StudentDialog = ({ show, onCloseClick, plan }) => {
  const classes = styles();

  return (
    <>
      <Modal
        open={show}
        className={classes.studentModal}
      >
        <Box className={classes.studentDialog}>
          <Box className={classes.closeIcon}><CloseIcon onClick={() => onCloseClick(false)} /></Box>
          <StudentIcon />
          <Typography className={classes.title}>Are you a college student or alum?</Typography>
          <Box className={classes.buttonContainer}>
            <Button className={classes.studentButton} variant="contained" color="primary" onClick={() => onCloseClick(true, plan)}>Yes, I&lsquo;m a college student or alum</Button>
            <Button className={classes.notStudentButton} variant="contained" color="primary" onClick={() => onCloseClick(false)}>No, I&lsquo;m not a college student or alum</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

StudentDialog.propTypes = {
  show: PropTypes.bool,
  onCloseClick: PropTypes.func,
  plan: PropTypes.shape(),
};

export default StudentDialog;
