import React from 'react';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';

export const reportError = (error) => {
  console.error(error);
  Sentry.captureException(error);
};

export const renderError = (error, title) => (
  toast.error(
    <div>
      <p>{title || 'An error occured'}:</p>
      <p className="p-2">{`${error}`}</p>
    </div>,
  )
);

export const reportAndRenderError = (error, title) => {
  reportError(error);
  renderError(error, title);
};

export const withErrorReporting = (fn, title) => (
  (...props) => (
    fn(...props).catch((error) => {
      reportAndRenderError(error, title);
      return Promise.reject(error);
    })
  )
);
