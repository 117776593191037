import React from 'react';
import Cookies from 'js-cookie';
import { Box, Button, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
import { deleteAccount } from '../../actions/user';
import routes from '../../routes';
import DeleteAccountContent from '../../components/DeleteAccount/DeleteAccountContent';
import DeleteDialog from '../../components/common/DeleteDialog';
import config from '../../config';

const styles = makeStyles((theme) => ({
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
  },
  cancelButton: {
    marginRight: 15,
  },
  contentBox: {
    padding: '15px 0px',
    textAlign: 'left',
  },
}));

const DeleteAccount = () => {
  const classes = styles();
  const history = useHistory();

  const cookieToken = Cookies.get(config.tokenKeyDelete);
  const deleteToken = cookieToken || history?.location?.state?.deleteToken;

  const cancel = () => {
    Cookies.remove(config.tokenKeyDelete, { domain: '.spotlightnews.us' });
    history.push(routes.auth.deleteAccountVerification);
  };

  const deleteAccountSubmit = async () => {
    if (!deleteToken) {
      history.push(routes.auth.deleteAccountVerification, { flash: { message: 'User could not be deleted', severity: 'warning' }, deleteToken: null });
      return null;
    }
    try {
      await deleteAccount(deleteToken);
      Cookies.remove(config.tokenKeyDelete, { domain: '.spotlightnews.us' });
      history.push(routes.auth.deleteAccountSuccess);
    } catch (e) {
      history.push(routes.auth.deleteAccountVerification, { flash: { message: e, severity: 'warning' } });
    }
    return null;
  };

  return (
    <>
      <DeleteAccountContent />
      {deleteToken && (
        <>
          <Box className={classes.buttonBox}>
            <DeleteDialog
              title="Are you sure you want to delete your account?"
              buttonLabel="Delete my account"
              onConfirm={deleteAccountSubmit}
            >
              {({ open }) => (
                <Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={cancel}
                    className={classes.cancelButton}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={open}
                  >
                    Delete My Account
                  </Button>
                </Box>
              )}
            </DeleteDialog>
          </Box>
        </>
      )}
    </>
  );
};

export default DeleteAccount;
