import React from 'react';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import routes from '../../routes';
import SpotlightLogo from '../common/SpotlightLogo';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: '-0.32px',
    color: theme.palette.text.primary,
    marginTop: 30,
    marginBottom: 15,
  },
  paragraph: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    color: theme.palette.text.primary,
  },
  button: {
    marginTop: 15,
    color: '#ffffff !important',
  },
}));

const ArticleNotFoundMessage = () => {
  const classes = styles();

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.iconCenter}><SpotlightLogo small /></Box>
        <Typography className={classes.title}>
          Article not found
        </Typography>
        <Typography className={classes.paragraph}>
          You still can have access to dozens of leading publications and newspapers.
        </Typography>
        <Button className={classes.button} href={routes.discover} component={Link} variant="contained" color="primary">Explore now!</Button>
      </Box>
    </>
  );
};

export default ArticleNotFoundMessage;
