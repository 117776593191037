import React from 'react';
import {
  Box, Typography, makeStyles,
} from '@material-ui/core';

import emptyGraphic from './empty-graphic.svg';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  graphic: {
    marginBottom: 34,
  },
  textRoot1: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: 9,
  },
}));

const ContestEmpty = () => {
  const classes = styles();
  return (
    <Box className={classes.container}>
      <img src={emptyGraphic} alt="Contests" className={classes.graphic} />
      <Typography classes={{ root: classes.textRoot1 }}>
        Currently there is no Contests available for your profile.
      </Typography>
    </Box>
  );
};

export default ContestEmpty;
