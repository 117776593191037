import React from 'react';
import PropTypes from 'prop-types';
import { Box, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Link, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import routes from '../../routes';
import { language } from '../../utils/helpers';

const styles = makeStyles((theme) => ({
  category: {
    color: theme.palette.text.primary,
  },
  expansionPanelSummary: {
    justifyContent: 'space-between',
    margin: 0,
  },
  expansionPanelSummaryRoot: {
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .Mui-expanded': {
      margin: 0,
    },
  },
  expansionPanel: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  expansionPanelDetails: {
    display: 'block',
    padding: 0,
    paddingLeft: 14,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.text.primary,
  },
  title: {
    display: 'block',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
}));

const ChannelCategory = ({
  channel, category,
}) => {
  const classes = styles();
  const { names } = category;

  return (
    <>
      <ExpansionPanel
        className={classes.expansionPanel}
        square
        TransitionProps={{
          timeout: 0,
        }}
      >
        <ExpansionPanelSummary
          expandIcon={category.children.length > 0 ? <ExpandMoreIcon /> : ''}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          classes={{
            root: classes.expansionPanelSummaryRoot,
            content: classes.expansionPanelSummary,
          }}
        >
          <Box className={classes.iconContainer}>
            <Link
              href={routes.publishers.topic(channel.slug, category.id)}
              className={classes.link}
            >
              <Typography className={classes.title}>
                {language(names)}
              </Typography>
            </Link>
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          {category.children.length > 0 && category.children.map((childrenCategory) => (
            <ChannelCategory
              key={childrenCategory.id}
              channel={channel}
              category={childrenCategory}
            />
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
};

ChannelCategory.propTypes = {
  channel: PropTypes.shape().isRequired,
  category: PropTypes.shape().isRequired,
};

export default ChannelCategory;
