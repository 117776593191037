import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchUserFeeds, setUserFeedsOrder } from '../../actions/news';
import { reportAndRenderError } from '../../utils/errors';
import SortableList from '../SortableList';
import EmptyUserFeedsList from './EmptyUserFeedsList';
import { useQuery } from '../../hooks/useQuery';

const UserFeedsList = ({ userId }) => {
  const { data: response, isFetching } = useQuery('userFeedsList', fetchUserFeeds, {
    variables: [userId],
  });

  const onChangeOrder = async (orderedItemsIds) => {
    try {
      await setUserFeedsOrder(userId, orderedItemsIds);
    } catch (err) {
      reportAndRenderError(err);
    }
  };

  return (
    <SortableList
      items={response ?? []}
      isLoading={isFetching}
      onChangeOrder={onChangeOrder}
      EmptyListComponent={EmptyUserFeedsList}
    />
  );
};

UserFeedsList.propTypes = {
  userId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    userId: state.user.profile.id,
  };
}

export default connect(mapStateToProps)(UserFeedsList);
