import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import InfiniteList from '../common/InfiniteList';
import DownloadCard from './DownloadCard';
import DownloadCardSkeleton from './DownloadCardSkeleton';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  boxRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 50,
    marginBottom: 27,
  },
  headingRoot: {
    marginBottom: 27,
  },
  articleCount: {
    opacity: 0.5,
    marginLeft: 10,
  },
}));

const DownloadCardList = ({
  downloads, downloadCount, label, isLoading,
  hasMore, loadMore, initialLoad,
  EmptyStateComponent, onEmptyButtonClick,
}) => {
  const classes = styles();

  const renderDownloads = () => {
    const colSize = {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
    };
    if (isLoading && initialLoad) {
      return Array(12).fill().map((_, i) => (
        <Grid key={`download-skeleton-${i}`} item xs={colSize.xs} sm={colSize.sm} md={colSize.md} lg={colSize.lg}>
          <DownloadCardSkeleton />
        </Grid>
      ));
    }
    return downloads.map((download, i) => (
      <Grid key={`download-card-${i}`} item xs={colSize.xs} sm={colSize.sm} md={colSize.md} lg={colSize.lg}>
        <DownloadCard download={download} />
      </Grid>
    ));
  };

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <Box className={classes.boxRoot}>
        <Typography variant="h6">
          {label}
          {!isLoading
            && <span className={classes.articleCount}>({downloadCount})</span>}
        </Typography>
      </Box>
      {downloads.length === 0 && !isLoading
        ? <EmptyStateComponent onButtonClick={onEmptyButtonClick} />
        : (
          <InfiniteList isLoading={isLoading} loadMore={loadMore} hasMore={hasMore}>
            <Grid container spacing={3}>
              {renderDownloads()}
            </Grid>
          </InfiniteList>
        )}
    </Container>
  );
};

DownloadCardList.propTypes = {
  downloads: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  downloadCount: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  initialLoad: PropTypes.bool.isRequired,
  EmptyStateComponent: PropTypes.elementType.isRequired,
  onEmptyButtonClick: PropTypes.func.isRequired,
};

export default DownloadCardList;
