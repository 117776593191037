import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';

import { toggleSubscription } from '../../../actions/channel';

import WritterToggler from '../WriterToggler/WriterToggler';

const WriterSubscription = ({
  writer,
  isSubscribed,
  toggleSubscriptionAction,
  showTooltip,
  onToggleSubscription,
  ...params
}) => (
  <WritterToggler
    loadsContext="toggleChannelSubscription"
    trueButton={<CheckCircleIcon titleAccess="Unsubscribe" />}
    falseButton={<AddIcon titleAccess="Subscribe" />}
    // eslint-disable-next-line no-underscore-dangle
    variables={{ writerId: writer?._id }}
    active={isSubscribed}
    writer={writer}
    onToggle={toggleSubscriptionAction}
    onToggleSubscription={onToggleSubscription}
    {...params}
    showTooltip={showTooltip}
  />
);

WriterSubscription.propTypes = {
  writer: PropTypes.shape(),
  isSubscribed: PropTypes.bool.isRequired,
  toggleSubscriptionAction: PropTypes.func,
  showTooltip: PropTypes.bool,
  onToggleSubscription: PropTypes.func,
};

WriterSubscription.defaultProps = {
  toggleSubscriptionAction: ({ userId, writerId, active }) => (
    toggleSubscription(userId, 'writers', writerId, active)
  ),
};

export default WriterSubscription;
