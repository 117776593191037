/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import NavigationBar from '../../components/Nav/NavigationBar';
import MainContent from '../../components/MainContent';
import ScrollToTop from './ScrollTop';
import Alert from '../../components/Alert';
import useAuthentication from '../../hooks/useAuthentication';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  contentBox: {
    paddingLeft: (t) => (t.useContainer ? 21 : 0),
    paddingRight: (t) => (t.useContainer ? 21 : 0),
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px !important',
      paddingLeft: '0px !important',
    },
  },
  guestAlert: {
    display: 'flex',
    background: 'rgb(255, 244, 229)',
    padding: 15,
    alignItems: 'center',
  },
  guestMessage: {
    fontSize: 14,
    paddingLeft: 10,
    color: '#000000',
  },
  warningIcon: {
    color: '#000000',
  },
}));

const NavigationLayout = ({
  component: Component, user, backgroundImage, useContainer = true, noPadding = false, ...rest
}) => {
  const [paddingLeft, setPaddingLeft] = useState(0);
  const classes = styles({ useContainer });
  const location = useLocation();
  const flash = location.state?.flash;
  const style = noPadding
    ? {
      paddingLeft: 0,
      paddingRight: 0,
    } : {};

  return useAuthentication(() => (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <ScrollToTop />
          <NavigationBar user={user} updatePaddingLeft={setPaddingLeft} />
          {useContainer
            ? (
              <MainContent style={style} backgroundImage={backgroundImage} paddingLeft={paddingLeft}>
                {flash ? <Alert {...flash} /> : null}
                {!user?.profile?.email && (
                  <Box className={classes.guestAlert}>
                    <WarningIcon className={classes.warningIcon} />
                    <Typography className={classes.guestMessage}>
                      You&apos;re using a Guest Account.
                      Please <Link href={routes.auth.signup}>create an account</Link>
                      &nbsp;to not lose your subscriptions or topics.
                    </Typography>
                  </Box>
                )}
                <Box className={classes.contentBox} style={style}>
                  <Component {...matchProps} />
                </Box>
              </MainContent>
            )
            : (
              <Box backgroundImage={backgroundImage}>
                {flash ? <Alert {...flash} /> : null}
                <Box style={style} className={classes.contentBox}>
                  <Component {...matchProps} />
                </Box>
              </Box>
            )}
        </>
      )}
    />
  ));
};

NavigationLayout.propTypes = {
  component: PropTypes.elementType.isRequired,
  user: PropTypes.shape().isRequired,
  backgroundImage: PropTypes.string.isRequired,
  noPadding: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  backgroundImage: state.app.backgroundImage,
  user: state.user,
});

export default connect(mapStateToProps)(NavigationLayout);
