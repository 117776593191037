import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  heroImage: {
    width: 700,
    margin: '0px auto',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const ChannelProfileHeroImage = ({ channel }) => {
  const classes = useStyles();

  return (
    (channel.hero_image)
      ? (
        <Box>
          <img className={classes.heroImage} src={channel.hero_image} alt="" />
        </Box>
      ) : (<></>)
  );
};

ChannelProfileHeroImage.propTypes = {
  channel: PropTypes.shape().isRequired,
};

export default ChannelProfileHeroImage;
