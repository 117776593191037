import React, { useState } from 'react';

import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import GetAppDialog from '../GetAppDialog/GetAppDialog';

const styles = makeStyles((theme) => ({
  outerWrapper: {
    background: (bg) => ((bg.background) ? `linear-gradient(180deg, ${bg?.background?.primary} 0%, ${bg?.background?.secondary} 50%)` : ''),
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
    },
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px !important',
      paddingBottom: (mobile) => (mobile?.mobilePadding ? '15px !important' : '0px !important'),
    },
  },
  innerWrapper: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  mainContent: {
    paddingRight: 0,
    paddingLeft: 0,
    backgroundColor: theme.palette.backgroundAlpha,
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    paddingBottom: '1px',
  },
  fab: {
    bottom: 10,
    position: 'fixed',
    border: 10,
    zIndex: 10000,
    right: 10,
    textTransform: 'capitalize',
    background: '#000000',
    color: '#eaeaea',
    borderColor: '#eaeaea',
  },
  paddingBox: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: (mobile) => (mobile?.mobilePadding ? '15px !important' : '0px !important'),
    },
  },
}));

const MainContent = ({
  includeBackgroundImage = true, backgroundImage, background,
  children, paddingTop = 100, mobilePadding = true, showGetApp = true, paddingLeft = 0,
}) => {
  const classes = styles({ background, mobilePadding });

  const [showDialog, setShowDialog] = useState(false);

  return (
    <Box
      component="main"
      className={classes.outerWrapper}
      style={{ backgroundImage, paddingLeft }}
    >
      <Box className={classes.paddingBox} style={{ paddingTop }} />
      <Container classes={{ root: classes.innerWrapper }}>
        <Container classes={{ root: classes.mainContent }}>
          {children}
        </Container>

        {showGetApp && (
          <GetAppDialog show={showDialog} setShow={setShowDialog} />
        )}
      </Container>
    </Box>
  );
};

export default MainContent;
