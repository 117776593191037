const config = () => {
  const environments = {
    development: {
      environment: 'development',
      apiServer: 'https://api.dev.spotlightnews.us',
      apiEventServer: 'https://event-api.dev.spotlightnews.us',
      newsServer: 'https://news.dev.spotlightnews.us',
      sentryDSN: null,
      version: 'development',
      tokenKey: 'dev-my.spotlightnews.us-token',
      tokenKeyDelete: 'dev-my.spotlightnews.us-delete-account-token',
      stripePk: 'pk_test_hhERP5OpsuaF9MhgIQny0N74',
    },
    staging: {
      environment: 'staging',
      apiServer: 'https://api.stg.spotlightnews.us',
      apiEventServer: 'https://event-api.stg.spotlightnews.us',
      newsServer: 'https://news.stg.spotlightnews.us',
      sentryDSN: 'https://f2d17adc064f44928221ff18ba2caceb@sentry.io/1536597',
      version: `${process.env.REACT_APP_RELEASE_VERSION}+${process.env.REACT_APP_COMMIT_SHA1_SHORT}`,
      tokenKey: 'stg-my.spotlightnews.us-token',
      tokenKeyDelete: 'stg-my.spotlightnews.us-delete-account-token',
      stripePk: 'pk_test_hhERP5OpsuaF9MhgIQny0N74',
    },
    publisher: {
      environment: 'staging',
      apiServer: 'https://api.pbl.spotlightnews.us',
      apiEventServer: 'https://event-api.prd.spotlightnews.us',
      newsServer: 'https://news.pbl.spotlightnews.us',
      sentryDSN: 'https://f2d17adc064f44928221ff18ba2caceb@sentry.io/1536597',
      version: `${process.env.REACT_APP_RELEASE_VERSION}+${process.env.REACT_APP_COMMIT_SHA1_SHORT}`,
      tokenKey: 'pbl-my.spotlightnews.us-token',
      tokenKeyDelete: 'pbl-my.spotlightnews.us-delete-account-token',
      stripePk: 'pk_test_hhERP5OpsuaF9MhgIQny0N74',
    },
    production: {
      environment: 'production',
      apiServer: 'https://api.spotlightnews.us',
      apiEventServer: 'https://event-api.spotlightnews.us',
      newsServer: 'https://news.spotlightnews.us',
      sentryDSN: 'https://f2d17adc064f44928221ff18ba2caceb@sentry.io/1536597',
      version: process.env.REACT_APP_RELEASE_VERSION,
      tokenKey: 'my.spotlightnews.us-token',
      tokenKeyDelete: 'my.spotlightnews.us-delete-account-token',
      stripePk: 'pk_live_r6qpAgrVOuqwQJiMR8hP6a8o',
    },
  };

  switch (window.location.origin) {
    case 'https://my.spotlightnews.us': {
      return environments.production;
    }
    case 'https://my.stg.spotlightnews.us': {
      return environments.staging;
    }
    case 'https://my.pbl.spotlightnews.us': {
      return environments.publisher;
    }
    default: {
      return environments.development;
    }
  }
};

export default config();
