import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

const MyNewsIcon = ({ color = '#ffffff', mobile = false }) => (
  <Container
    style={{
      display: 'inline-block',
      width: 30,
      padding: 0,
      margin: mobile ? '0px 15px 0px 0px' : 0,
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 32" id="vector">
      <path id="path" d="M 15.759 10.589 C 14.355 10.59 13.007 10.033 12.014 9.041 C 11.02 8.049 10.461 6.702 10.46 5.298 C 10.459 3.895 11.016 2.546 12.008 1.553 C 12.999 0.56 14.347 0 15.75 -0.001 C 17.154 -0.002 18.502 0.555 19.496 1.546 C 20.489 2.538 21.049 3.885 21.05 5.289 C 21.05 6.693 20.492 8.04 19.501 9.033 C 18.509 10.027 17.163 10.587 15.759 10.589 Z M 15.759 2.333 C 15.089 2.333 14.438 2.561 13.914 2.979 C 13.39 3.396 13.023 3.98 12.874 4.634 C 12.725 5.287 12.802 5.972 13.093 6.576 C 13.384 7.18 13.871 7.667 14.475 7.958 C 15.079 8.249 15.764 8.326 16.417 8.177 C 17.071 8.028 17.655 7.661 18.072 7.137 C 18.49 6.613 18.718 5.962 18.718 5.292 C 18.717 4.508 18.405 3.755 17.85 3.201 C 17.296 2.646 16.543 2.334 15.759 2.333 Z" fill={color} />
      <path id="path_1" d="M 30.286 11.373 C 27.849 10.552 25.256 10.302 22.707 10.643 C 20.158 10.984 17.721 11.907 15.586 13.34 C 13.452 11.904 11.015 10.98 8.466 10.638 C 5.917 10.297 3.322 10.549 0.886 11.373 C 0.641 11.442 0.424 11.585 0.265 11.784 C 0.106 11.982 0.013 12.226 0 12.48 L 0 28.419 C 0.006 28.624 0.067 28.823 0.176 28.997 C 0.285 29.17 0.438 29.311 0.62 29.406 C 0.82 29.516 1.041 29.583 1.269 29.604 C 1.496 29.624 1.725 29.598 1.942 29.526 C 3.865 28.877 5.91 28.671 7.923 28.923 C 9.937 29.175 11.868 29.878 13.572 30.981 C 14.78 31.797 14.824 31.848 15.121 31.936 L 15.121 31.936 C 15.271 31.979 15.427 32 15.583 31.999 L 15.583 31.999 C 16.234 31.999 16.57 31.67 17.594 30.974 C 19.298 29.873 21.229 29.17 23.243 28.919 C 25.256 28.667 27.301 28.872 29.224 29.519 C 29.441 29.591 29.67 29.617 29.897 29.597 C 30.125 29.576 30.346 29.509 30.546 29.399 C 30.729 29.305 30.882 29.164 30.992 28.991 C 31.101 28.817 31.161 28.617 31.166 28.412 L 31.166 12.48 C 31.157 12.226 31.067 11.98 30.909 11.781 C 30.75 11.582 30.532 11.439 30.286 11.373 Z M 14.172 28.401 C 11.743 27.099 9.027 26.425 6.272 26.44 C 5.118 26.438 3.968 26.555 2.838 26.788 L 2.838 13.322 C 4.767 12.816 6.785 12.744 8.745 13.11 C 10.705 13.477 12.561 14.273 14.177 15.441 Z M 28.344 26.782 C 24.493 25.985 20.481 26.555 17.005 28.395 L 17.005 15.441 C 18.621 14.272 20.476 13.476 22.437 13.109 C 24.397 12.743 26.415 12.816 28.344 13.322 Z" fill={color} />
    </svg>

  </Container>
);

MyNewsIcon.propTypes = {
  color: PropTypes.string,
  mobile: PropTypes.bool,
};

export default MyNewsIcon;
