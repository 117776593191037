import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const styles = makeStyles((theme) => ({
  contentBox: {
    padding: '15px 0px',
  },
}));

const DeleteAccountSuccess = () => {
  const classes = styles();

  return (
    <>
      <Box className={classes.contentBox}>
        <Typography variant="h6">Deleting Your Account</Typography>
        <p>
          We&apos;re sorry to see you go!
          Your account will then be scheduled for deletion in 3 days.
        </p>
        <p>We Wishing you all the best!</p>
        <p>The SpotlightNews Team</p>
      </Box>
    </>
  );
};

export default DeleteAccountSuccess;
