import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import { contestEntering } from '../../actions/promotions';

const ContestFormLink = ({ contest, onSuccess = null, style = null, label }) => {
  const [formMethod, setFormMethod] = useState(null);
  const [formAction, setFormAction] = useState(null);
  const [formData, setFormData] = useState([]);
  const formRef = useRef(null);

  const playClick = async () => {
    try {
      let contestData;
      if (contest?.entering) {
        contestData = contest?.entering;
      } else {
        // eslint-disable-next-line no-underscore-dangle
        contestData = await contestEntering(contest?._id);
      }
      setFormMethod(contestData?.http_method);
      setFormAction(contestData?.url);
      setFormData(Object.entries(contestData?.parameters));
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    if (formData.length > 0) formRef.current.submit();
    if (onSuccess) onSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <>
      <form action={formAction} ref={formRef} method={formMethod}>
        {formData.map((fData) => <input type="hidden" name={fData[0]} value={fData[1]} />)}
      </form>

      {contest?.enterable && (
        <Button
          variant="contained"
          color="primary"
          style={style}
          onClick={playClick}
        >
          {label}
        </Button>
      )}
    </>

  );
};

ContestFormLink.propTypes = {
  contest: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func,
  style: PropTypes.shape(),
  label: PropTypes.string.isRequired,
};

export default ContestFormLink;
