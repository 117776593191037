import { Box, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import { Carousel } from 'react-responsive-carousel';
import { connect } from 'react-redux';
import { chunk } from 'lodash-es';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { toast } from 'react-toastify';
import { customQueryTopicUnsubscribe, fetchUserInterests, toggleTopicNotification, toggleTopicSubscription as toggleTopicSubscriptionAction } from '../../actions/topics';
import LoadingIcon from '../common/LoadingIcon';
import { useQuery } from '../../hooks/useQuery';
import { formatArticleCount } from '../../utils/helpers';
import routes from '../../routes';
import InterestIcon from '../common/InterestIcon';
import ToastDialog from '../ToastDialog/ToastDialog';

const styles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingBottom: 35,
  },
  listItem: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    padding: '10px 0px',
    [theme.breakpoints.down('xs')]: {
      flex: '100%',
    },
  },
  listItemText: {
    fontSize: 14,
    fontWeight: 500,
    flex: 'auto',
  },
  listIcon: {
    minWidth: 'auto',
    color: '#cecece',
    alignItems: 'center',
  },
  unsubscribeIcon: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
  },
  iconColorClass: {
    color: theme.palette.text.primary,
  },
  carousel: {
    '& li.dot': {
      background: `${theme.palette.text.primary} !important`,
      boxShadow: 'none !important',
    },
    '& li.dot.selected': {
      background: '#34a788 !important',
    },
    '& .slide': {
      background: 'transparent !important',
    },
  },
  loading: {
    display: 'flex',
    padding: '15px 0px',
    justifyContent: 'center',
  },
  noInterests: {
    color: theme.palette.text.primary,
    opacity: 0.7,
    fontSize: 18,
    padding: 0,
    paddingBottom: 15,
  },
  interestSubTitle: {
    color: theme.palette.text.primary,
    opacity: 0.7,
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  articleCount: {
    fontSize: 12,
  },
  interestLink: {
    color: theme.palette.text.primary,
  },
  interestContainer: {
    display: 'flex',
    alignItems: 'top',
  },
  interestName: {
    marginLeft: 10,
  },
  interestIcon: {
    backgroundColor: '#34a788',
    borderRadius: 20,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spotlightToast: theme.toastDialog,
}));

const InterestList = ({
  userId,
  toggleTopicSubscription,
  searchValue,
}) => {
  const classes = styles();
  const [userInterests, setUserInterests] = useState([]);

  const userInterestsQuery = useQuery('userInterests', fetchUserInterests, {
    variables: [userId],
  });
  const { data: response } = userInterestsQuery;
  const { isFetching } = userInterestsQuery;
  useEffect(() => {
    if (!response) return;
    setUserInterests(response);
  }, [response]);

  useEffect(() => {
    if (!searchValue) {
      setUserInterests(userInterestsQuery?.data);
      return;
    }
    setUserInterests(
      userInterests.filter((int) => int.name.toLowerCase().includes(searchValue.toLowerCase())),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const userInterestsChunks = chunk(userInterests, 10);

  const handleNotificationClick = (interest, isNotifiable) => {
    toggleTopicNotification(userId, interest.subjectId, isNotifiable);

    toast.success(
      <ToastDialog
        active={isNotifiable}
        activateIcon={<NotificationsIcon fontSize="large" />}
        deactivateIcon={<NotificationsOffIcon fontSize="large" />}
        activateText="Enabled Notifications"
        deactivateText="Disabled Notifications"
        title={interest.name}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );

    const currentInterests = [...userInterests];

    currentInterests.forEach((currentInterest) => {
      // eslint-disable-next-line max-len
      const interestToChangeNotification = (currentInterest.id === interest.id) ? currentInterest : null;
      const interestChangeNotification = currentInterests.indexOf(interestToChangeNotification);
      if (interestChangeNotification >= 0) {
        currentInterests[interestChangeNotification].is_notifiable = isNotifiable;
      }
    });

    setUserInterests(currentInterests);
  };

  const handleUnsubscribe = (interest) => {
    const currentInterests = [...userInterests];

    currentInterests.forEach((currentInterest) => {
      const interestToRemove = (currentInterest.id === interest.id) ? currentInterest : null;
      const indexRemove = currentInterests.indexOf(interestToRemove);
      if (indexRemove >= 0) currentInterests.splice(indexRemove, 1);
    });

    setUserInterests(currentInterests);

    if (interest.parent_id) {
      toggleTopicSubscription(userId, interest.parent_id, false);
    } else {
      customQueryTopicUnsubscribe(userId, interest.id);
    }

    toast.success(
      <ToastDialog
        active={false}
        deactivateIcon={<CancelIcon fontSize="large" />}
        deactivateText="Unsubscribed"
        title={interest.name}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );
  };

  return (userInterestsChunks.length > 0)
    ? (
      <Box className={classes.interestContent}>
        <Carousel
          dynamicHeight
          useKeyboardArrows
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={userInterestsChunks.length > 1}
          swipeable
          emulateTouch
          className={classes.carousel}
        >
          {(userInterestsChunks.map((interests, j) => (
            <List key={`list-${j}`} className={classes.listContainer}>
              {interests.map((interest) => (
                <ListItem key={interest.id} className={classes.listItem}>
                  <ListItemText
                    className={classes.listItemText}
                    disableTypography
                  >
                    <Link
                      className={classes.interestLink}
                      href={routes.news.feed(interest.id)}
                    >
                      <Box className={classes.interestContainer}>
                        <Box className={classes.interestIcon}>
                          <InterestIcon size={25} color="#ffffff" />
                        </Box>
                        <Box className={classes.interestName}>
                          {interest.name}
                          <Typography className={classes.articleCount}>
                            {formatArticleCount(interest.articles_count)}
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  </ListItemText>
                  <ListItemIcon className={classes.listIcon}>
                    <CheckCircleIcon
                      titleAccess="Unsubscribe"
                      className={classes.unsubscribeIcon}
                      onClick={() => handleUnsubscribe(interest)}
                    />

                    <IconButton
                      onClick={() => handleNotificationClick(interest, !interest.is_notifiable)}
                    >
                      {(interest.is_notifiable) ? (
                        <NotificationsIcon classes={{ root: classes.iconColorClass }} />
                      ) : (
                        <NotificationsOffIcon classes={{ root: classes.iconColorClass }} />
                      )}
                    </IconButton>
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          ))
          )}
        </Carousel>
      </Box>
    )
    : (
      (isFetching)
        ? (
          <Box className={classes.loading}><LoadingIcon /></Box>
        )
        : (
          <Typography className={classes.noInterests}>
            You are currently not following any topic.<br />
          </Typography>
        )

    );
};

const mapDispatchToProps = (dispatch) => ({
  toggleTopicSubscription: (userId, topicId, isSubscribed) => {
    dispatch(toggleTopicSubscriptionAction(userId, topicId, isSubscribed));
  },
});

function mapStateToProps(state) {
  return {
    userId: state.user.profile.id,
  };
}

InterestList.propTypes = {
  userId: PropTypes.string.isRequired,
  toggleTopicSubscription: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterestList);
