import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { signOut as signOutAction } from '../../actions/user';
import routes from '../../routes';

const SignOut = (props) => {
  props.signOut();
  return (
    <Redirect to={routes.auth.signin} />
  );
};

const mapDispatchToProps = (dispatch) => ({
  signOut: () => {
    dispatch(signOutAction());
  },
});

export default connect(null, mapDispatchToProps)(SignOut);
