import {
  Box, Button, makeStyles, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { getBrandTextColor } from '../../../../utils/helpers';
import emptyGraphic from './empty-graphic.svg';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  graphic: {
    marginBottom: 34,
  },
  textRoot1: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: 9,
    color: (channel) => (channel?.branding ? getBrandTextColor(channel?.branding) : 'inherit'),
  },
  textRoot2: {
    fontSize: '16px',
    color: (channel) => (channel?.branding ? getBrandTextColor(channel?.branding) : '#666'),
    marginBottom: 34,
  },
}));

const RecentlyReadEmpty = ({ onButtonClick, channel }) => {
  const classes = styles({ ...channel });

  return (
    <Box className={classes.container}>
      <img src={emptyGraphic} alt="Bookmarks" className={classes.graphic} />
      <Typography classes={{ root: classes.textRoot1 }}>Recently Spotlighted</Typography>
      <Typography classes={{ root: classes.textRoot2 }}>
        We&#39;ll keep track of articles you recently read
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
      >
        Find Great Stories
      </Button>
    </Box>
  );
};

RecentlyReadEmpty.propTypes = {
  onButtonClick: PropTypes.func,
  channel: PropTypes.shape(),
};

export default RecentlyReadEmpty;
