import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const styles = makeStyles({
  publisherSkeletonRoot: {
    paddingTop: '100%',
  },
});

const PublisherBoxSkeleton = () => {
  const classes = styles();
  return <Skeleton classes={{ root: classes.publisherSkeletonRoot }} />;
};

export default PublisherBoxSkeleton;
