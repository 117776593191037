import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { connect } from 'react-redux';
import { customQueryTopicUnsubscribe, fetchUserInterests } from '../../actions/topics';
import { reportAndRenderError } from '../../utils/errors';
import { useQuery } from '../../hooks/useQuery';
import ToastDialog from '../ToastDialog/ToastDialog';
import LoadingIcon from '../common/LoadingIcon';
import ArticlePagination, { TopArticlesEmpty } from '../Article/ArticlePagination';
import { searchArticlesByKeyword } from '../../actions/news';
import InterestCustomForm from './InterestCustomForm';
import InterestIcon from '../common/InterestIcon';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0px',
    borderBottom: '2px solid #f6f6f6',
  },
  title: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  icon: {
    margin: '11px 0px',
    cursor: 'pointer',
  },
  spotlightToast: theme.toastDialog,
  loading: {
    display: 'flex',
    padding: '15px 0px',
    justifyContent: 'center',
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  interestCustomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  newInterestLabel: {
    fontSize: 12,
  },
  interestIcon: {
    backgroundColor: '#34a788',
    borderRadius: 20,
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  interestIconLabel: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const InterestCustom = ({
  searchQuery,
  userId,
  discover,
  preview,
}) => {
  const classes = styles();
  const [isSubscribed, setIsSubscribed] = useState();
  const [currentInterests, setCurrentInterests] = useState();
  const [showCustomInterestForm, setShowCustomInterestForm] = useState(false);

  const currentInterestsQuery = useQuery('currentInterests', fetchUserInterests, {
    variables: [userId],
  });
  const response = currentInterestsQuery.data;

  useEffect(() => {
    setCurrentInterests(response);
  }, [response]);

  useEffect(() => {
    if (!currentInterests) return;
    const subscribed = currentInterests.filter((interest) => interest.name === searchQuery);
    setIsSubscribed(subscribed.length > 0);
  }, [currentInterests, searchQuery]);

  useEffect(() => () => {
    toast.dismiss();
  }, []);

  const handleUnsubscribe = async () => {
    const currentTopic = currentInterests.filter((interest) => interest.name === searchQuery);
    if (currentTopic[0]) {
      try {
        await customQueryTopicUnsubscribe(userId, currentTopic[0].id);
        const refreshInterests = await fetchUserInterests(userId);
        setCurrentInterests(refreshInterests);
        setIsSubscribed(false);

        toast.success(
          <ToastDialog
            active={false}
            deactivateIcon={<CancelIcon fontSize="large" />}
            deactivateText="Unfollowed"
            title={`#${searchQuery}`}
          />, {
            position: toast.POSITION.BOTTOM_CENTER,
            className: classes.spotlightToast,
            autoClose: 2500,
          },
        );
      } catch (err) {
        reportAndRenderError(err);
      }
    }
  };

  return currentInterests && !currentInterestsQuery.isFetching
    ? (
      <>
        <Box className={classes.boxContainer}>
          {showCustomInterestForm && (
            <InterestCustomForm
              interests={currentInterests}
              reloadInterests={() => currentInterestsQuery.refetch()}
              searchQuery={searchQuery}
              userId={userId}
              show={setShowCustomInterestForm}
              setIsSubscribed={setIsSubscribed}
              toastClassName={classes.spotlightToast}
            />
          )}

          {!showCustomInterestForm && isSubscribed && (
            <>
              <Typography className={classes.title}>{searchQuery}</Typography>
              <CheckCircleIcon className={classes.icon} onClick={handleUnsubscribe} />
            </>
          )}

          {!showCustomInterestForm && !isSubscribed && (
            <>
              <Box className={classes.interestCustomContainer}>
                <Box className={classes.interestIconLabel}>
                  <Box className={classes.interestIcon}>
                    <InterestIcon size={25} color="#ffffff" />
                  </Box>
                  <Box>
                    <Typography className={classes.title}>{searchQuery}</Typography>
                    <Typography className={classes.newInterestLabel}>New Interest</Typography>
                  </Box>
                </Box>
                <AddIcon
                  className={classes.icon}
                  onClick={() => setShowCustomInterestForm(true)}
                />
              </Box>
            </>
          )}
        </Box>
        {preview && (
          <Box>
            <ArticlePagination
              loadsContext={`searchArticlesByKeyword${searchQuery}`}
              load={(guid, page) => searchArticlesByKeyword(searchQuery, page)}
              label={`Preview of articles for "${searchQuery}":`}
              EmptyStateComponent={TopArticlesEmpty}
              biggerCard={!discover}
            />
          </Box>
        )}
      </>
    ) : (
      <Box className={classes.loading}><LoadingIcon /></Box>
    );
};

InterestCustom.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  discover: PropTypes.bool,
  preview: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    userId: state.user.profile.id,
  };
}

InterestCustom.defaultProps = {
  preview: true,
};

export default connect(mapStateToProps)(InterestCustom);
