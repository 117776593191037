import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import AppleIcon from './AppleIcon';
import routes from '../../../../routes';
import config from '../../../../config';
import { getAuthToken } from '../../../../utils/authToken';

const styles = {
  root: {
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor: '#333',
    },
  },
};

const AppleSignInButton = (props) => {
  const isDelete = props.delete;

  return (
    <form action={routes.auth.apple(config.environment)} method="post">
      {isDelete && (
        <input type="hidden" name="action" value="delete" />
      )}
      <input name="guest_token" type="hidden" value={getAuthToken()} />
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        {...props}
      >
        <AppleIcon viewBox="-4 0 24 24" />
      </Button>
    </form>
  );
};

export default withStyles((theme) => styles)(AppleSignInButton);
