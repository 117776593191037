export function getOptimalSource(sources, componentWidth) {
  if (!componentWidth || !sources?.length) {
    return null;
  }
  const sorted = sources.sort((a, b) => a.width - b.width);
  let bestSource = sorted.find((source) => source.width >= componentWidth);
  if (!bestSource) {
    bestSource = sorted[sorted?.length - 1];
  }
  return bestSource.source_url;
}

export function findMediaSources(media, mediaType) {
  if (!media) return null;
  const found = media.find((i) => i.type === mediaType && i.sources.length > 0);
  return found?.sources || [];
}

export function findImageSources(media) {
  return findMediaSources(media, 'image');
}
