import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Title from '../../components/Article/Title';
import { language } from '../../utils/helpers';

const MyNewsTopicTitle = ({ topic, isTitle }) => (isTitle
  ? (
    <Title title={`#${language(topic.names)}`} />
  )
  : (
    <Box>#{language(topic.names)}</Box>
  ));

MyNewsTopicTitle.propTypes = {
  topic: PropTypes.shape().isRequired,
  isTitle: PropTypes.bool,
};

export default MyNewsTopicTitle;
