import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchFeedsCards } from '../../actions/news';
import FeedCardsList from '../../components/Article/FeedCardsList';
import MyNewsEmpty from '../../components/MyNewsEmpty';
import { toggleSubscription } from '../../actions/channel';
import { toggleTopicSubscription } from '../../actions/topics';
import { usePaginationQuery } from '../../hooks/useQuery';

const MyNewsFeedCards = ({ userId }) => {
  const dispatch = useDispatch();

  const { data, fetchMore, canFetchMore, isFetching, refetch, remove } = usePaginationQuery(
    'myNewsFeedsCards',
    fetchFeedsCards,
    {
      variables: [userId],
    },
  );

  const feedsCards = (data ?? []).flatMap((page) => page.feedsCards);

  const history = useHistory();
  const isDialogClosed = history.location.state?.isInterestDialogClosed;
  useEffect(() => {
    if (isDialogClosed) {
      remove();
      refetch();
      history.replace({
        ...history.location,
        state: {
          isInterestDialogClosed: false,
        },
      });
    }
  }, [isDialogClosed, history, refetch, remove]);

  const handleChannelSubscribeClick = (publisherId, isSubscribed) => {
    const callChannelSubscribe = async () => {
      await toggleSubscription(userId, 'channels', publisherId, isSubscribed);
      remove();
      refetch();
    };
    callChannelSubscribe();
  };

  const handleWriterSubscribeClick = (publisherId, isSubscribed) => {
    const callChannelSubscribe = async () => {
      await toggleSubscription(userId, 'writers', publisherId, isSubscribed);
      remove();
      refetch();
    };
    callChannelSubscribe();
  };

  const handleTopicSubscribeClick = (topicId, isSubscribed) => {
    const callTopicSubscribe = async () => {
      await dispatch(toggleTopicSubscription(userId, topicId, isSubscribed));
      remove();
      refetch();
    };
    callTopicSubscribe();
  };

  const onCloseEditMode = () => {
    remove();
    refetch();
  };

  return feedsCards
    ? (
      <FeedCardsList
        feedsCards={feedsCards}
        feedsCardsIsLoading={isFetching}
        hasMore={Boolean(canFetchMore)}
        loadMore={() => fetchMore()}
        EmptyStateComponent={MyNewsEmpty}
        onChannelSubscribeClick={handleChannelSubscribeClick}
        onTopicSubscribeClick={handleTopicSubscribeClick}
        onWriterSubscribeClick={handleWriterSubscribeClick}
        onCloseEditMode={onCloseEditMode}
      />
    ) : (<></>);
};

MyNewsFeedCards.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default MyNewsFeedCards;
