import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  container: {
    padding: '10px',
  },
});

const SearchPublishersEmpty = ({ searchValue }) => {
  const classes = styles();
  return (
    <Box className={classes.container}>
      <Typography paragraph>No publishers found for &quot;{searchValue}&quot;.</Typography>
    </Box>
  );
};

SearchPublishersEmpty.propTypes = {
  searchValue: PropTypes.string.isRequired,
};

export default SearchPublishersEmpty;
