import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, List, makeStyles, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainContent from '../../components/MainContent';
import PublisherNavBar from '../../components/Nav/PublisherNavBar';
import PremiumBar from '../../components/PremiumBar';
import { fetchChannel, toggleChannelNotification } from '../../actions/channel';
import routes from '../../routes';
import { useQuery } from '../../hooks/useQuery';
import ToastDialog from '../../components/ToastDialog/ToastDialog';
import ChannelCategory from '../../components/ChannelCategory';

const styles = makeStyles((theme) => ({
  categories: {
    padding: 25,
  },
  title: {
    marginBottom: 15,
  },
  spotlightToast: theme.toastDialog,
}));

const ChannelCategories = ({ user, match }) => {
  const channelId = match.params.slug;
  const { data: channel } = useQuery('channelCategories', fetchChannel, {
    variables: [channelId],
  });
  const classes = styles({ ...channel });
  const [isNotifiable, setIsNotifiable] = useState(false);

  useEffect(() => {
    if (channel) {
      setIsNotifiable(channel.is_notifiable);
    }
  }, [channel]);

  useEffect(() => () => {
    toast.dismiss();
  }, []);

  const history = useHistory();
  const onBackClick = () => {
    history.push(routes.publishers.main(channel.slug));
  };

  const handleNotificationClick = () => {
    toggleChannelNotification(user.profile.id, channel.id, !isNotifiable);
    setIsNotifiable(!isNotifiable);

    toast.success(
      <ToastDialog
        active={!isNotifiable}
        activateIcon={<NotificationsIcon fontSize="large" />}
        deactivateIcon={<NotificationsOffIcon fontSize="large" />}
        activateText="Enabled Notifications"
        deactivateText="Disabled Notifications"
        title={channel.title}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );
  };

  return channel ? (
    <>
      <PublisherNavBar
        isSignedIn
        channel={channel}
        onBackClick={onBackClick}
        onNotificationClick={handleNotificationClick}
        publisherUrl={routes.publishers.main(channel.slug)}
        isNotifiable={isNotifiable}
      />
      <MainContent includeBackgroundImage={false} paddingTop={0}>
        <PremiumBar publisher={channel} />

        <Box className={classes.categories}>
          <Typography variant="h6" className={classes.title}>Categories</Typography>
          <List component="nav" aria-label="Categories">
            {(
              channel.topics.map((category) => (
                <ChannelCategory key={category.id} channel={channel} category={category} />
              ))
            )}
          </List>
        </Box>
      </MainContent>
    </>
  ) : (<></>);
};

ChannelCategories.propTypes = {
  user: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ChannelCategories);
