import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Button, Container, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { parseISO, format as formatDate } from 'date-fns';
import ScrollToTop from '../NavigationLayout/ScrollTop';
import NavigationBar from '../../components/Nav/NavigationBar';
import PlanBox from '../../components/Premium/PlanBox';
import { useQuery } from '../../hooks/useQuery';
import { fetchCurrentState, fetchRcOfferings, setupIntentSubscription } from '../../actions/subscription';
import { language } from '../../utils/helpers';
import UpdateCardDialog from '../../components/Premium/UpdateCardDialog';

const styles = makeStyles((theme) => ({
  containerRoot: {
    backgroundColor: theme.palette.background.default,
  },
  h1: {
    fontFamily: 'Roboto',
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 30,
  },
  h2: {
    fontFamily: 'Roboto',
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  premiumContainer: {
    textAlign: 'center',
    paddingTop: 50,
  },
  buttons: {
    marginTop: 30,
    marginBottom: 30,
    '& > button': {
      margin: '10px 10px',
      minWidth: 145,
    },
  },
  buttonOff: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    fontSize: 16,
    color: '#757575',
    '&:hover': {
      backgroundColor: '#37BC98',
      color: '#ffffff',
    },
  },
  buttonOn: {
    fontFamily: 'Roboto',
    fontSize: 16,
    backgroundColor: '#37BC98',
    color: '#ffffff',
    borderColor: '#37BC98',
    '&:hover': {
      backgroundColor: '#37BC98',
      color: '#ffffff',
    },
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  accountInfo: {
    textAlign: 'left',
    width: 350,
    margin: '0px auto',
    paddingBottom: 25,
  },
  accountInfoItem: {
    '& > p': {
      margin: 0,
    },
    marginBottom: 10,
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 300,
    lineHeight: 1.2,
  },
  listItem: {
    alignItems: 'flex-start',
    padding: '5px 0px',
  },
  listItemIcon: {
    minWidth: 'auto',
    paddingRight: 10,
  },
  listItemText: {
    margin: 0,
    '& > p': {
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 300,
    },
  },
  storeBox: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      width: 25,
    },
    '& > p': {
      margin: 0,
      marginLeft: 10,
    },
  },
  accountInfoPaymentBox: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  defaultPaymentMethod: {
    display: 'flex',
  },
  cardFlagAndLastFour: {
    display: 'flex',
  },
  cardInfo: {
    textAlign: 'left',
  },
  cardFlag: {
    textTransform: 'uppercase',
  },
  cardIcon: {
    marginRight: 15,
  },
  cardLastFour: {
    marginLeft: 15,
  },
  cardExpireDate: {
    fontSize: 12,
  },
}));

const Premium = ({ user }) => {
  const classes = styles();
  const [planType, setPlanType] = useState('regular');
  const [viewMore, setViewMore] = useState(false);
  const [showUpdateCardDialog, setShowUpdateCardDialog] = useState(false);

  const setIntentSubscriptionQuery = useQuery('setIntentSubscriptionQuery', setupIntentSubscription, {
    variables: [user?.id],
    enabled: false,
  });
  const clientSecret = setIntentSubscriptionQuery?.data?.client_secret;

  const onCloseCardDialogClick = () => {
    setShowUpdateCardDialog(false);
  };

  const showUpdateCardDialogModal = async () => {
    await setIntentSubscriptionQuery.refetch();
    setShowUpdateCardDialog(true);
  };

  const fetchRcOfferingsQuery = useQuery('fetchRcOfferings', fetchRcOfferings, {
    variables: [user],
  });
  const offerings = fetchRcOfferingsQuery?.data?.offerings;
  const currentOfferingType = fetchRcOfferingsQuery?.data?.currentOfferingType;

  useEffect(() => {
    if (currentOfferingType) {
      setPlanType(currentOfferingType);
    }
  }, [currentOfferingType]);

  const fetchCurrentStateQuery = useQuery('fetchCurrentState', fetchCurrentState, {
    variables: [user],
  });
  const currentState = fetchCurrentStateQuery?.data;

  const refreshState = () => {
    fetchCurrentStateQuery.refetch();
  };

  if (!offerings) return (<></>);

  const filteredOfferings = offerings.filter((of) => (of?.type === planType || ['free', 'guest', 'premium', 'bulk'].includes(of?.type)));

  const productsArr = filteredOfferings.map((fOf) => ([...fOf?.products]));
  const products = productsArr.flat();
  return (
    <>
      {user && (
        <>
          <ScrollToTop />
          <NavigationBar user={{ profile: user }} />
        </>
      )}
      <Box className={classes.premiumContainer}>
        {currentState && (
          <Box className={classes.accountInfoPaymentBox}>
            <Box className={classes.accountInfoBox}>
              <Typography className={classes.h1} variant="h1">Account Level</Typography>
              {currentState.map((state) => {
                const stateDetails = state.details ? language(state.details) : [];
                const date = state?.expires_at ? parseISO(state?.expires_at) : null;
                const autoRenewDate = date ? formatDate(date, 'LLLL dd, yyyy') : null;
                return (
                  <Box className={classes.accountInfo}>
                    <Box className={classes.accountInfoItem}>
                      <Typography className={classes.h2} variant="h2">Current:</Typography>
                      <Typography className={classes.text}>{language(state.title)}</Typography>
                    </Box>
                    <Box style={{ display: viewMore ? 'block' : 'none' }}>
                      {state.store_label && (
                        <Box className={classes.accountInfoItem}>
                          <Typography className={classes.h2} variant="h2">Store:</Typography>
                          <Box className={classes.storeBox}>
                            <img src={state.store_label?.logo} title={state.store_label?.name} alt="" />
                            <Typography className={classes.text}>
                              {state.store_label?.name}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {autoRenewDate && (
                        <Box className={classes.accountInfoItem}>
                          <Typography className={classes.h2} variant="h2">{state?.cancellation ? 'Expires At:' : 'Auto Renews On:'}</Typography>
                          <Typography className={classes.text}>{autoRenewDate}</Typography>
                        </Box>
                      )}
                      <Box className={classes.accountInfoItem}>
                        <Typography className={classes.h2} variant="h2">Description:</Typography>
                        {stateDetails.map((sd) => (
                          <ListItem className={classes.listItem}>
                            <ListItemIcon className={classes.listItemIcon}>
                              <CheckIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              className={classes.listItemText}
                              primary={(
                                <Typography className={classes.listItemTextTypography}>
                                  {sd}
                                </Typography>
                              )}
                            />
                          </ListItem>
                        ))}
                      </Box>
                    </Box>
                    <Box style={{ display: viewMore ? 'none' : 'flex', justifyContent: 'center', padding: '10px 0px' }}>
                      <Button
                        onClick={() => setViewMore(true)}
                        variant="outlined"
                      >
                        View More
                      </Button>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            {currentState.map((state) => {
              const card = state?.default_payment_method?.card;
              return card && (
                <>
                  {clientSecret && (
                    <UpdateCardDialog
                      show={showUpdateCardDialog}
                      onCloseClick={onCloseCardDialogClick}
                      user={user}
                      clientSecret={clientSecret}
                      refreshState={refreshState}
                    />
                  )}
                  <Box className={classes.paymentBox}>
                    <Typography className={classes.h1} variant="h1">Payment Method</Typography>
                    <Box className={classes.defaultPaymentMethod}>
                      <Box className={classes.cardIcon}><CreditCardIcon /></Box>
                      <Box className={classes.cardInfo}>
                        <Box className={classes.cardFlagAndLastFour}>
                          <Typography className={classes.cardFlag}>{card?.brand}</Typography>
                          <Typography className={classes.cardLastFour}>
                            **** {card?.last4}
                          </Typography>
                        </Box>
                        <Box className={classes.cardExpireDate}>
                          Expires in {card?.exp_month}/{card?.exp_year}
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ justifyContent: 'center', padding: '10px 0px' }}>
                      <Button
                        onClick={showUpdateCardDialogModal}
                        variant="outlined"
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
        )}

        {products && (
          <>
            {currentOfferingType && planType && (
              <Box className={classes.buttons}>
                <Button
                  onClick={() => setPlanType('regular')}
                  className={(planType === 'regular') ? classes.buttonOn : classes.buttonOff}
                  variant={(planType === 'regular') ? 'outlined' : 'contained'}
                >
                  Standard
                </Button>
                <Button
                  onClick={() => setPlanType('student')}
                  className={(planType === 'student') ? classes.buttonOn : classes.buttonOff}
                  variant={(planType === 'student') ? 'outlined' : 'contained'}
                >
                  College Student/Alum
                </Button>
              </Box>
            )}

            <Container component="main" classes={{ root: classes.containerRoot }}>
              <Box className={classes.listContainer}>
                {products.map((p) => (
                  <PlanBox
                    key={p.product_id}
                    plan={p}
                    type={planType}
                    currentState={currentState}
                  />
                ))}
              </Box>
            </Container>
          </>
        )}
      </Box>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.profile,
  };
}

Premium.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, null)(Premium);
