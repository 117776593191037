import React from 'react';
import PropTypes from 'prop-types';
import { Link, makeStyles } from '@material-ui/core';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  menuImageMobileRoot: {
    marginRight: 0,
  },
  menuImageDesktopRoot: {
    marginRight: 20,
  },
}));

const ContestIcon = ({ user, mobile = false }) => {
  const classes = styles();

  return (
    <>
      <Link
        className={mobile ? classes.menuImageMobileRoot : classes.menuImageDesktopRoot}
        href={routes.contests}
      >
        <img
          alt=""
          width="50"
          src={user?.profile?.current_contest?.button_image}
        />
      </Link>
    </>
  );
};

ContestIcon.propTypes = {
  user: PropTypes.shape().isRequired,
  mobile: PropTypes.bool,
};

export default ContestIcon;
