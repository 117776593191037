import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';

import Form from '../Form/Form';

const styles = makeStyles((theme) => ({
  submitButtonRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

const ForgotPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8).required().label('Password'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password')], 'Passwords must match',
  ).required('Password should be confirmed'),
});

const initialValues = { password: '', confirmPassword: '' };

const ForgotPasswordForm = ({ onSubmit }) => {
  const classes = styles();
  return (
    <>
      <Typography variant="subtitle2" paragraph>
        Please enter a new password below.
      </Typography>
      <Form
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema}
        onSubmit={onSubmit}
      >
        {({ Submit }) => (
          <>
            <Field
              id="password"
              name="password"
              label="Password"
              type="password"
              component={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              required
            />
            <Field
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              component={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              required
            />
            <Submit
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              classes={{ root: classes.submitButtonRoot }}
            >
              Reset Password
            </Submit>
          </>
        )}
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
