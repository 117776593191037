import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';

import routes from '../../routes';

import Link from '../common/Link';
import Form from '../Form/Form';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});
const initialValues = {
  email: '',
};

const styles = makeStyles((theme) => ({
  submitButtonRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  alignLeft: {
    textAlign: 'left',
  },
  marginBottom: {
    marginBottom: 15,
  },
}));

const ForgotPasswordForm = ({ onSubmit }) => {
  const classes = styles();
  return (
    <>
      <Box className={classes.alignLeft}>
        <Typography variant="h5" className={classes.marginBottom}>Forgot password</Typography>
        <Typography variant="subtitle2" paragraph>
          Enter your email and we&#x27;ll send you instructions on how to reset your password.
        </Typography>
      </Box>
      <Form
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema}
        onSubmit={onSubmit}
      >
        {({ Submit }) => (
          <>
            <Field
              id="email"
              name="email"
              label="Email"
              type="email"
              component={TextField}
              variant="outlined"
              classes={{ root: classes.inputRoot }}
              margin="dense"
              fullWidth
              required
            />
            <Submit
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              classes={{ root: classes.submitButtonRoot }}
            >
              Reset Password
            </Submit>
          </>
        )}
      </Form>
      <Typography variant="body2" component="span">
        Remember your password?&nbsp;
        <Link to={routes.auth.signin}>Sign In</Link>
      </Typography>
    </>
  );
};

export default ForgotPasswordForm;
