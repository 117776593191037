import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { connect } from 'react-redux';
import { useQuery } from '../../../hooks/useQuery';
import { clearAllSearchHistory, clearSearchHistory } from '../../../actions/discover';

const styles = makeStyles((theme) => ({
  searchFieldContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: 23,
  },
  searchField: {
    margin: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      width: 360,
    },
  },
  searchButton: {
    padding: '15px 30px',
    marginLeft: '10px',
    boxShadow: 'none',
  },
  searchButtonProgress: {
    color: '#fff',
  },
  label: {
    display: 'block',
  },
  input: {
    width: 200,
  },
  listbox: {
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#4a8df6',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white',
    },
  },
  autoComplete: {
    width: '100%',
  },
  boxAutoComplete: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  boxButtonKeyword: {
    width: '100%',
  },
  buttonKeyword: {
    width: '100%',
    '& > span': {
      textAlign: 'left',
      display: 'block',
    },
  },
  buttonDelete: {

  },
  labelRecentSearches: {
    padding: '10px 8px',
    fontSize: 11,
  },
}));

const SearchField = ({
  placeholder,
  searchIsLoading,
  clearSearch,
  onSearchChange,
  style = {},
  searchFieldStyle = {},
  fieldValue,
  autocomplete,
  expressions = [],
  allExpressions = [],
  refreshExpressions,
  setCurrentSearchValue,
  type,
  userId,
}) => {
  const classes = styles();
  const [searchValue, setSearchValue] = useState('');
  const [acValue, setAcValue] = useState(fieldValue);
  const [openAC, setOpenAC] = useState(false);
  const [queryVars, setQueryVars] = useState([]);
  const [isOpenRemoveDialog, setIsOpenRemoveDialog] = useState(false);

  const handleSearchChange = (event, value) => {
    const newSearchValue = value ?? event?.target?.value;
    setSearchValue(newSearchValue);
    setCurrentSearchValue(newSearchValue);
    if (newSearchValue === '' || newSearchValue === undefined || newSearchValue === null) {
      clearSearch();
    }
  };

  const handleSearchKeyPress = (event) => {
    setCurrentSearchValue(event.target.value);
    if (event.target.value) {
      setOpenAC(true);
    } else {
      setOpenAC(false);
    }
    if (event.key === 'Enter') {
      onSearchChange(searchValue);
    }
  };

  const handleFocus = () => {
    setOpenAC(true);
  };

  const handleBlur = () => {
    setOpenAC(false);
  };

  const deleteExpressionQuery = useQuery('deleteExpressionQuery', clearSearchHistory, {
    variables: queryVars,
    enabled: false,
  });

  const deleteAllExpressionQuery = useQuery('deleteExpressionQuery', clearAllSearchHistory, {
    variables: [userId, type],
    enabled: false,
  });

  const removeExpression = (expresionId) => {
    setQueryVars([userId, type, expresionId]);
  };

  const refreshAutocomplete = () => {
    window.setTimeout(() => {
      setAcValue(' ');
      setAcValue(null);
      setSearchValue(null);
      setCurrentSearchValue(null);
      setOpenAC(true);
    }, 100);
  };

  useEffect(() => {
    if (queryVars.length > 0) {
      deleteExpressionQuery.refetch();
      if (deleteExpressionQuery?.isFetched) {
        setQueryVars([]);
        refreshExpressions();
        refreshAutocomplete();
        setIsOpenRemoveDialog(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVars, deleteExpressionQuery?.isFetched]);

  const expressionsArr = expressions.length ? [{ _id: 0, expression: '' }, ...expressions] : [];

  const handleDelete = async () => {
    await deleteAllExpressionQuery.refetch();
    refreshExpressions();
    refreshAutocomplete();
    setIsOpenRemoveDialog(false);
  };

  const openDeleteDialog = () => {
    setIsOpenRemoveDialog(true);
  };

  return (
    <>
      <Dialog
        open={isOpenRemoveDialog}
        onClose={async () => {
          await setIsOpenRemoveDialog(false);
          await refreshAutocomplete();
        }}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        style={{ position: 'absolute', zIndex: 10000 }}
      >
        <DialogTitle id="delete-dialog-title">
          Are you sure you want to delete all items?
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={async () => {
              await setIsOpenRemoveDialog(false);
              await refreshAutocomplete();
            }}
            color="default"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            className={classes.deleteButton}
            variant="outlined"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.searchFieldContainer} style={style}>

        {autocomplete
          ? (
            <Autocomplete
              value={acValue}
              id="autocomplete-solo"
              freeSolo
              className={classes.autoComplete}
              options={expressionsArr?.slice(0, 10).map((option) => option.expression)}
              defaultValue={fieldValue ?? ''}
              open={openAC}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={placeholder}
                  margin="normal"
                  variant="outlined"
                  id="search"
                  classes={{ root: classes.searchField }}
                  onChange={handleSearchChange}
                  onKeyPress={handleSearchKeyPress}
                  style={searchFieldStyle}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={acValue}
                />
              )}
              renderOption={(props, option) => (
                <Box className={classes.boxAutoComplete}>
                  <Box className={classes.boxButtonKeyword}>
                    {props
                      ? (
                        <Button
                          className={classes.buttonKeyword}
                          onClick={() => {
                            setOpenAC(false);
                            handleSearchChange(null, props);
                          }}
                        >
                          {props}
                        </Button>
                      ) : (
                        <Box
                          style={{ display: expressionsArr.length > 0 ? 'block' : 'none' }}
                          className={classes.labelRecentSearches}
                        >
                          Your Recent Searches
                        </Box>
                      )}
                  </Box>
                  <Box>
                    {props
                      ? (
                        <Button
                          className={classes.buttonDelete}
                          onClick={() => {
                            // eslint-disable-next-line max-len
                            const expression = allExpressions.filter((ex) => ex.expression === props);
                            // eslint-disable-next-line no-underscore-dangle
                            const expressionId = expression[0]?._id;
                            removeExpression(expressionId);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      )
                      : (
                        <Button
                          className={classes.buttonDeleteAll}
                          onClick={() => {
                            openDeleteDialog();
                          }}
                        >
                          Clear
                        </Button>
                      )}
                  </Box>
                </Box>
              )}
              onChange={handleSearchChange}
            />
          )
          : (
            <TextField
              id="search"
              type="search"
              margin="normal"
              variant="outlined"
              placeholder={placeholder}
              classes={{ root: classes.searchField }}
              onChange={handleSearchChange}
              onKeyPress={handleSearchKeyPress}
              style={searchFieldStyle}
              defaultValue={fieldValue ?? ''}
            />
          )}

        <Button
          variant="contained"
          color="primary"
          classes={{ root: classes.searchButton }}
          disabled={searchValue?.length < 1}
          onClick={() => onSearchChange(searchValue)}
        >
          {searchIsLoading ? (
            <CircularProgress
              size={24}
              classes={{ colorPrimary: classes.searchButtonProgress }}
            />
          ) : (
            <SearchIcon />
          )}
        </Button>
      </Box>
    </>
  );
};

SearchField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  searchIsLoading: PropTypes.bool,
  clearSearch: PropTypes.func.isRequired,
  style: PropTypes.shape(),
  searchFieldStyle: PropTypes.shape(),
  fieldValue: PropTypes.string,
  autocomplete: PropTypes.bool,
  expressions: PropTypes.arrayOf(PropTypes.shape()),
  allExpressions: PropTypes.arrayOf(PropTypes.shape()),
  setCurrentSearchValue: PropTypes.func.isRequired,
  type: PropTypes.string,
  refreshExpressions: PropTypes.func,
  userId: PropTypes.shape(),
};

function mapStateToProps(state) {
  return {
    userId: state.user.profile.id,
  };
}

export default connect(mapStateToProps)(SearchField);
