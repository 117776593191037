/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

const ReadTimeIcon = ({ color = '#37bc98', height = 50, width = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50.000000 50.000000"
    preserveAspectRatio="xMidYMid meet"
  >

    <g
      transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path d="M160 454 c-148 -64 -181 -264 -63 -376 81 -78 225 -78 306 0 119 113
84 314 -65 376 -47 20 -132 20 -178 0z m207 -37 c103 -71 120 -215 37 -307
-45 -49 -90 -70 -154 -70 -187 0 -278 230 -142 358 44 40 81 53 153 49 53 -2
74 -8 106 -30z"
      />
      <path d="M162 409 c-171 -85 -105 -349 88 -349 77 0 151 51 177 122 24 67 23
68 -82 68 l-95 0 0 90 c0 87 -1 90 -22 90 -13 0 -42 -10 -66 -21z"
      />
    </g>
  </svg>
);

ReadTimeIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ReadTimeIcon;
