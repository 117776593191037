import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import routes from '../routes';
import SignInForm from '../components/Auth/SignInForm';

import {
  loginSubmit as loginSubmitAction,
} from '../actions/user';

const SignIn = ({ loginSubmit }) => {
  const history = useHistory();
  const location = useLocation();
  const redirectPath = location?.state?.redirectPath;

  const handleSubmit = async (data) => {
    const loginData = await loginSubmit(data);
    if (loginData.is_onboarded) {
      if (redirectPath) {
        history.push(redirectPath);
      } else {
        history.push(routes.news.main);
      }
    } else {
      history.push(routes.onboard.main);
    }
  };

  const handleGuestLogin = () => history.push(routes.onboard.main);

  return (
    <SignInForm onSubmit={handleSubmit} handleGuestLogin={handleGuestLogin} />
  );
};

SignIn.propTypes = {
  loginSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  loginSubmit: (data) => (
    dispatch(loginSubmitAction(data))
  ),
});

export default connect(null, mapDispatchToProps)(SignIn);
