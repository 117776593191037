import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwapVertSharpIcon from '@material-ui/icons/SwapVertSharp';
import { Box, Button, makeStyles } from '@material-ui/core';
import { fetchAllPublishers, searchPublishers } from '../../../actions/channel';
import ErrorState from '../../common/ErrorState';
import PublisherBoxList from '../PublisherBoxList';
import AllPublishersEmpty from './AllPublishersEmpty';
import SearchField from '../../common/SearchField';
import SearchPublishersEmpty from './SearchPublishersEmpty';
import { usePaginationQuery } from '../../../hooks/useQuery';

const styles = makeStyles((theme) => ({
  orderButtonContainer: {
    marginTop: 23,
    marginLeft: 15,
  },
  orderButton: {
    marginLeft: 10,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const fetchOrSearchPublishers = (keyword, currentPage, asc) => {
  if (keyword) return searchPublishers(keyword, currentPage);
  return fetchAllPublishers(currentPage, asc);
};

const PublisherPagination = ({ userId }) => {
  const classes = styles();

  const [searchValue, setSearchValue] = useState('');
  const [sortAsc, setSortAsc] = useState(true);

  const query = usePaginationQuery('allPublishers', fetchOrSearchPublishers, {
    variables: [searchValue, null, sortAsc],
  });
  if (query.error) return <ErrorState retry={query.refetch} height="90vh" />;

  const { canFetchMore, fetchMore, data, isFetching, isFetchingMore } = query;
  const totalCount = (data) ? data[0].totalCount : 0;
  const channels = (data ?? []).flatMap((page) => page.channels);

  const EmptyStateComponent = searchValue
    ? () => <SearchPublishersEmpty searchValue={searchValue} />
    : AllPublishersEmpty;

  return (
    <PublisherBoxList
      initialLoad={!isFetchingMore}
      label="All Publishers"
      isLoading={isFetching}
      hasMore={Boolean(canFetchMore)}
      loadMore={() => fetchMore()}
      EmptyStateComponent={EmptyStateComponent}
      channels={channels}
      channelCount={totalCount}
    >
      <Box className={classes.searchContainer}>
        <SearchField
          placeholder="Search publishers"
          clearSearch={() => setSearchValue('')}
          onSearchChange={(value) => setSearchValue(value)}
          searchIsLoading={Boolean(searchValue) && isFetching && !isFetchingMore}
          setCurrentSearchValue={setSearchValue}
        />

        {!searchValue && (
          <Box className={classes.orderButtonContainer}>
            Sort:
            <Button className={classes.orderButton} variant="contained" color="primary" onClick={() => setSortAsc(!sortAsc)}>
              <SwapVertSharpIcon />
            </Button>
          </Box>
        )}
      </Box>
    </PublisherBoxList>
  );
};

PublisherPagination.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default PublisherPagination;
