import client from '../utils/client';
import { language } from '../utils/helpers';

const toggleTopicSubscription = (userId, topicId, isSubscribed) => (
  async (dispatch) => {
    const url = `/users/${userId}/topics`;
    if (isSubscribed) {
      client().post(url, { parent_id: topicId });
    } else {
      const userTopics = await client().get(url);
      let topic = userTopics.data.find((item) => `${item.parent_id}` === `${topicId}`);
      if (!topic) {
        topic = userTopics.data.find((item) => `${item.id}` === `${topicId}`);
      }
      client().delete(`/users/${userId}/topics/${topic.id}`);
    }
  }
);

const toggleChannelTopicSubscription = async (userId, topicId, isSubscribed) => {
  if (!isSubscribed) {
    const url = `/users/${userId}/feeds/topics/${topicId}`;
    client().put(url);
    return;
  }
  const url = `/users/${userId}/feeds/topics/${topicId}`;
  client().delete(url);
};

const customQueryTopicSubscribe = async (userId, keywords, name) => {
  const url = `/users/${userId}/feeds/by_query`;
  return client().post(url, {
    en_name: name,
    search_query: {
      query: keywords,
    },
  });
};

const customQueryTopicUnsubscribe = async (userId, topicId) => (
  client().delete(`/users/${userId}/feeds/${topicId}`)
);

const fetchUserInterests = async (userId) => {
  const topics = await client().get(`/users/${userId}/feeds/topics/cards`);
  const data = topics.data.filter((topic) => topic?.subject?.topic_type !== 'channel');

  return data.map((topic) => ({
    id: topic._id, // eslint-disable-line no-underscore-dangle
    parent_id: topic.parent_id,
    name: language(topic.names),
    articles_count: topic.articles_count,
    is_notifiable: topic.is_notifiable,
    // eslint-disable-next-line no-underscore-dangle
    subjectId: topic.subject._id,
  }));
};

const fetchInterests = async (nesting = 1) => {
  const url = '/topics';
  const response = await client().get(url, { params: { nesting } });

  return response.data;
};

const fetchChildrenInterests = async (topicId, nesting = 1) => {
  const url = `/topics/${topicId}/children`;
  const response = await client().get(url, { params: { nesting } });

  return response.data;
};

const searchInterests = async (s, page = 1) => {
  if (!s) return null;
  const url = '/search/topics';
  const response = await client().get(url, { params: { s, page } });
  return {
    topics: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const toggleTopicNotification = async (userId, topicId, isNotifiable) => {
  const url = `notifications/${userId}/settings/topics/${topicId}`;
  try {
    await client().patch(url, { disabled: !isNotifiable });
  } catch (err) {
    console.log(err.message);
  }
};

const topicEdit = async (userId, topicId, data) => {
  const url = `users/${userId}/topics/${topicId}`;
  try {
    await client().patch(url, {
      en_name: data.name,
      search_query: data.keywords,
    });
  } catch (err) {
    console.log(err.message);
  }
};

const fetchInterest = async (userId, topicId) => {
  try {
    const topics = await client().get(`/users/${userId}/feeds/${topicId}`);
    return topics.data;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

const fetchTopicInterests = async () => {
  try {
    const topics = await client().get('/topics/interests');
    return topics.data;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

const fetchInterestArticles = async (interestId, page = 1) => {
  const url = `/topics/${interestId}/articles`;
  const response = await client().get(url, { params: { page } });
  return {
    articles: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

export {
  toggleTopicSubscription, fetchInterests, fetchChildrenInterests,
  searchInterests, fetchUserInterests, customQueryTopicSubscribe,
  customQueryTopicUnsubscribe, toggleTopicNotification, topicEdit,
  fetchInterest, fetchInterestArticles, fetchTopicInterests,
  toggleChannelTopicSubscription,
};
