import React from 'react';
import PropTypes from 'prop-types';

const ratio = 174.167 / 34.833;

const Regular = React.memo(({ id = Date.now(), color, height, small }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={small ? '0 0 59 59' : '0 0 295 59'}
    width={`${small ? height : (height * ratio)}px`}
    style={{ color }}
  >
    <defs>
      <style>
        {'.cls-1{fill: currentColor !important;'}
      </style>
      <path id={`spotlilght_grn-a-${id}`} d="M36.06,31.97 C37.06,32.97 38.06,34.14 39.18,35.25 C41.78916,37.96417 45.80264,38.78663 49.26907,37.31751 C52.7355,35.84838 54.93573,32.39245 54.8,28.63 C54.78731,25.04507 52.56955,21.83766 49.22,20.56 C45.95636,19.03482 42.08508,19.74088 39.57,22.32 C36.22,25.5 32.97,28.8 29.7,32.06 C27.7,34.06 25.7,36.15 23.61,38.15 C20.19462,41.80403 14.95369,43.11524 10.22,41.5 C5.880724,40.14373 2.548801,36.64382 1.4074295,32.24314 C0.2660582,27.84245 1.4769104,23.16432 4.61,19.87 C9.95,14.4 15.38,9.01 20.83,3.64 C25.99951,-1.28024 34.14233,-1.20497 39.22,3.81 C40.4,4.98 41.61,6.1 42.76,7.19 L23.9,26.02 C22.82,24.87 21.76,23.6 20.56,22.5 C17.67881,19.63184 13.2447,19.0227 9.696809,21.00766 C6.148917,22.99262 4.348036,27.09008 5.284805,31.0461 C6.221574,35.00211 9.668713,37.85687 13.73,38.04 C16.42713,38.1507 19.03952,37.08531 20.89,35.12 C26.22,29.79 31.44,24.5 36.8,19.25 C39.80996,16.30216 44.115,15.09207 48.22,16.04 C54.72033,17.21389 59.28837,23.10187 58.81,29.69 C58.60069,33.23711 57.01046,36.56117 54.38,38.95 C49.38,43.89667 44.38,48.87 39.38,53.87 C36.90917,56.426971 33.50571,57.8706457 29.95,57.87 C26.7558,57.9593337 23.65023,56.813106 21.28,54.67 C19.85,53.37 18.57,51.9 17.28,50.59 L36.06,31.97 Z M23.92,19.97 L36.7,7.21 C33.85,3.32 27.47,2.94 23.7,6.57 C21.55,8.64 19.46,10.78 17.35,12.9 L14.53,15.74 C18.0898,15.88031 21.45618,17.39679 23.92,19.97 L23.92,19.97 Z M35.85,37.75 C35.61,37.91 27.53,45.93 23.55,49.85 C22.91,50.48 23.19,50.85 23.67,51.32 C27.24896,54.698643 32.8619,54.632296 36.36,51.17 C38.92,48.69 45.16,42.36 45.05,42.17 C41.53009,41.93058 38.23663,40.34828 35.85,37.75 L35.85,37.75 Z" />
      <path id={`spotlilght_grn-c-${id}`} d="M0.05,21.68 L1.43,16.95 C2.40492,17.46674 3.43468,17.87261 4.5,18.16 C5.5187,18.47025 6.57532,18.6385 7.64,18.66 C8.496,18.70853 9.34926,18.52544 10.11,18.13 C10.64083,17.86165 10.94816,17.29089 10.88,16.7 C10.82388,16.20992 10.55271,15.77018 10.14,15.5 C9.31287,15.02209 8.44231,14.62364 7.54,14.31 L6.86,14.05 C3.69,12.85 1.95,10.83 1.62,7.98 C1.25249,5.96056 1.9428,3.89339 3.45,2.5 C5.26286,1.05068 7.55476,0.33669 9.87,0.5 C11.26675,0.50253 12.65765,0.68059 14.01,1.03 C15.23135,1.31706 16.39699,1.80359 17.46,2.47 L16.06,7.23 C14.32089,6.34255 12.40217,5.86373 10.45,5.83 C9.75459,5.77702 9.05897,5.93006 8.45,6.27 C8.04806,6.54113 7.84101,7.02164 7.92,7.5 C7.95095,7.88332 8.12605,8.24065 8.41,8.5 C8.93629,8.88825 9.51999,9.19191 10.14,9.4 L10.82,9.66 C12.53233,10.2326 14.10839,11.15169 15.45,12.36 C16.42469,13.36263 17.02795,14.66793 17.16,16.06 C17.37197,17.51286 17.1347,18.9958 16.48,20.31 C15.81481,21.53569 14.7588,22.5037 13.48,23.06 C11.96326,23.72797 10.31659,24.04911 8.66,24 C7.0379,24.01223 5.42106,23.81391 3.85,23.41 C2.49536,23.05028 1.21081,22.46547 0.05,21.68 L0.05,21.68 Z" />
      <path id={`spotlilght_grn-e-${id}`} d="M0.42,25.6 L2.82,1.31 L7.22,1.31 L7.49,3.1 C8.13491,2.40812 8.90519,1.84484 9.76,1.44 C10.59784,1.0862 11.50069,0.91244 12.41,0.93 C13.47696,0.91671 14.5229,1.22701 15.41,1.82 C16.33353,2.4422 17.09197,3.27958 17.62,4.26 C18.2079,5.34018 18.5751,6.52654 18.7,7.75 C18.9713,9.71917 18.769,11.72465 18.11,13.6 C17.56244,15.19735 16.54454,16.59174 15.19,17.6 C13.86919,18.54036 12.28106,19.03117 10.66,19 C9.32667,19.01344 8.02984,18.56467 6.99,17.73 L6.23,25.62 L0.42,25.6 Z M7.42,13.6 C7.7869,13.79426 8.17657,13.94206 8.58,14.04 C8.95724,14.12534 9.34327,14.16562 9.73,14.16 C10.80368,14.18417 11.79725,13.59424 12.29,12.64 C12.91854,11.40703 13.12913,10.00312 12.89,8.64 C12.84092,7.85439 12.57812,7.09713 12.13,6.45 C11.76804,5.96461 11.19544,5.68203 10.59,5.69 C9.66161,5.70235 8.7708,6.05867 8.09,6.69 L7.42,13.6 Z" />
      <path id={`spotlilght_grn-g-${id}`} d="M0.07,9.62 L0.51,5.31 L3.36,5.31 L4.73,0.85 L9.34,0.85 L8.92,5.31 L13.38,5.31 L12.94,9.62 L8.48,9.62 L7.96,15.07 C7.9058,15.6621 7.9058,16.2579 7.96,16.85 C7.9831,17.22703 8.1432,17.58264 8.41,17.85 C8.6862,18.0904 9.0441,18.21567 9.41,18.2 C10.2659,18.16885 11.0908,17.87174 11.77,17.35 L13.17,21.35 C12.4046,21.87931 11.5514,22.26867 10.65,22.5 C9.6513,22.80057 8.6129,22.94891 7.57,22.94 C6.2144,22.99246 4.8857,22.55194 3.83,21.7 C2.867,20.89379 2.2511,19.74797 2.11,18.5 C2.0197,17.58222 2.0197,16.65778 2.11,15.74 L2.66,9.67 L0.07,9.62 Z" />
      <polygon id={`spotlilght_grn-i-${id}`} points=".46 24.59 2.85 .24 8.7 .24 6.31 24.59" />
      <path id={`spotlilght_grn-k-${id}`} d="M0.45,24.59 L2.16,7.31 L8.01,7.31 L6.3,24.59 L0.45,24.59 Z M2.3,3.4 C2.1889,2.57282 2.4962,1.74452 3.12,1.19 C4.424,0.07124 6.3145,-0.03924 7.74,0.92 C8.3297,1.40678 8.6834,2.12226 8.712,2.88636 C8.7407,3.65047 8.4416,4.39043 7.89,4.92 C6.5869,6.05057 4.6857,6.16144 3.26,5.19 C2.6991,4.75775 2.3498,4.10636 2.3,3.4 L2.3,3.4 Z" />
      <path id={`spotlilght_grn-m-${id}`} d="M0.84,24.6 L2.15,20.11 C3.6247,20.77238 5.2234,21.11327 6.84,21.11 C7.8564,21.19061 8.8679,20.90312 9.69,20.3 C10.3795,19.60577 10.7828,18.67773 10.82,17.7 L10.82,17.36 C10.2382,17.79491 9.5898,18.13262 8.9,18.36 C8.1984,18.56442 7.4708,18.66548 6.74,18.66 C5.1344,18.67459 3.6004,17.99684 2.53,16.8 C1.3236,15.47492 0.5919,13.78639 0.45,12 C0.1768,10.05981 0.3828,8.08222 1.05,6.24 C1.602,4.66402 2.6248,3.29565 3.98,2.32 C5.3005,1.39649 6.8788,0.91353 8.49,0.94 C9.3424,0.9327 10.187,1.10299 10.97,1.44 C11.7524,1.76209 12.4578,2.24601 13.04,2.86 L13.7,1.32 L18.08,1.32 L16.52,17.2 C16.24,20.15333 15.3233,22.34667 13.77,23.78 C12.2167,25.21333 10.0333,25.93 7.22,25.93 C5.0206,25.96995 2.8403,25.51543 0.84,24.6 L0.84,24.6 Z M6.31,11.06 C6.3627,11.80823 6.6339,12.52453 7.09,13.12 C7.4776,13.59175 8.0595,13.8606 8.67,13.85 C9.6371,13.85305 10.5709,13.49666 11.29,12.85 L11.91,6.35 C11.1985,5.95914 10.4017,5.74963 9.59,5.74 C8.5121,5.71868 7.5096,6.29099 6.98,7.23 C6.3303,8.39429 6.0942,9.7443 6.31,11.06 L6.31,11.06 Z" />
      <path id={`spotlilght_grn-o-${id}`} d="M0.55,24.59 L2.95,0.24 L8.8,0.24 L7.93,9.01 C8.5226,8.38083 9.2282,7.86861 10.01,7.5 C10.739,7.145 11.5392,6.96035 12.35,6.96 C13.5869,6.92443 14.7888,7.3729 15.7,8.21 C16.6211,9.04671 17.1925,10.20024 17.3,11.44 C17.3959,12.57553 17.3792,13.71777 17.25,14.85 L16.25,24.62 L10.4,24.62 L11.35,15.01 C11.4144,14.41507 11.4144,13.81493 11.35,13.22 C11.3263,12.83901 11.1582,12.48139 10.88,12.22 C10.584,11.98083 10.21,11.85972 9.83,11.88 C8.9583,11.909 8.1296,12.26616 7.51,12.88 L6.35,24.63 L0.55,24.59 Z" />
      <path id={`spotlilght_grn-q-${id}`} d="M0.63,9.62 L1.07,5.31 L3.92,5.31 L5.29,0.85 L9.9,0.85 L9.48,5.31 L13.94,5.31 L13.5,9.62 L9.04,9.62 L8.52,15.07 C8.4658,15.6621 8.4658,16.2579 8.52,16.85 C8.5431,17.22703 8.7032,17.58264 8.97,17.85 C9.2462,18.0904 9.6041,18.21567 9.97,18.2 C10.8259,18.16885 11.6508,17.87174 12.33,17.35 L13.73,21.35 C12.9687,21.8801 12.1186,22.26958 11.22,22.5 C10.2213,22.80057 9.1829,22.94891 8.14,22.94 C6.7844,22.99246 5.4557,22.55194 4.4,21.7 C3.4333,20.8955 2.8137,19.74945 2.67,18.5 C2.5797,17.58222 2.5797,16.65778 2.67,15.74 L3.22,9.67 L0.63,9.62 Z" />
      <polygon id={`spotlilght_grn-s-${id}`} points=".22 23.59 2.47 .85 4.94 .85 14.57 19.31 16.41 .85 18.97 .85 16.74 23.59 14.22 23.59 4.62 5.15 2.78 23.59" />
      <path id={`spotlilght_grn-u-${id}`} d="M0.31,10.83 C0.069,9.01148 0.3536,7.16199 1.13,5.5 C1.8399,3.95527 2.9783,2.64678 4.41,1.73 C5.8217,0.8387 7.4605,0.37344 9.13,0.39 C10.4069,0.33226 11.6634,0.72516 12.68,1.5 C13.6095,2.23608 14.1982,3.31965 14.31,4.5 C14.5446,6.34118 13.6066,8.13342 11.96,8.99 C9.6774,10.23142 7.0944,10.81312 4.5,10.67 C3.9085,10.67506 3.3172,10.64165 2.73,10.57 L2.73,10.7 C2.8008,12.12293 3.3832,13.47241 4.37,14.5 C5.3357,15.38327 6.6125,15.84722 7.92,15.79 C9.697,15.79797 11.4204,15.1822 12.79,14.05 L13.56,15.97 C11.9402,17.3304 9.874,18.04286 7.76,17.97 C5.8805,18.05593 4.0433,17.39442 2.65,16.13 C1.2519,14.70639 0.42,12.82232 0.31,10.83 Z M2.71,8.6 C3.23,8.6 3.71,8.66 4.11,8.66 C6.1035,8.76342 8.095,8.42182 9.94,7.66 C11.38,6.95 12.03,6.02 11.94,4.84 C11.8851,4.18935 11.5718,3.58783 11.07,3.17 C10.4915,2.74829 9.7848,2.53982 9.07,2.58 C7.487,2.56085 5.964,3.18519 4.85,4.31 C3.654,5.44354 2.8961,6.96269 2.71,8.6 Z" />
      <polygon id={`spotlilght_grn-w-${id}`} points=".62 .73 3.12 .73 5.71 13.99 10.77 1.35 12.98 1.35 15.61 13.89 20.71 .73 23.4 .73 16.46 17.59 14.33 17.59 11.58 4.92 6.44 17.59 4.31 17.59" />
      <path id={`spotlilght_grn-y-${id}`} d="M0.56,16.03 L1.16,14.18 C2.7371,15.22002 4.5809,15.78255 6.47,15.8 C7.463,15.86211 8.4484,15.59113 9.27,15.03 C9.9187,14.55871 10.2441,13.76049 10.11,12.97 C10.0717,12.57383 9.9303,12.19459 9.7,11.87 C9.4237,11.51484 9.0848,11.21321 8.7,10.98 C8.0644,10.58791 7.395,10.25326 6.7,9.98 L5.81,9.62 C4.6972,9.23189 3.6761,8.61922 2.81,7.82 C2.183,7.14816 1.7992,6.28556 1.72,5.37 C1.5988,4.44607 1.8039,3.50881 2.3,2.72 C2.8234,1.93048 3.5727,1.31712 4.45,0.96 C5.4728,0.53528 6.5727,0.32756 7.68,0.35 C9.5143,0.3281 11.3161,0.83496 12.87,1.81 L12.22,3.73 C10.8514,2.93711 9.3016,2.51006 7.72,2.49 C6.7333,2.431 5.7541,2.69421 4.93,3.24 C4.2876,3.68929 3.9576,4.46565 4.08,5.24 C4.129,5.76105 4.3803,6.24212 4.78,6.58 C5.3847,7.05454 6.0594,7.43237 6.78,7.7 L7.68,8.06 C8.9824,8.50261 10.1924,9.18051 11.25,10.06 C11.9576,10.7651 12.3962,11.69549 12.49,12.69 C12.6202,13.64044 12.4227,14.60687 11.93,15.43 C11.4317,16.23504 10.7038,16.87242 9.84,17.26 C8.8336,17.70688 7.7408,17.92545 6.64,17.9 C5.5355,17.90249 4.4369,17.74072 3.38,17.42 C2.369,17.11738 1.4158,16.64755 0.56,16.03 L0.56,16.03 Z" />
      <path id={`spotlilght_grn-A-${id}`} d="M0.489994392,9.89 C0.489994392,6.14781 2.7448,2.7743 6.2025,1.34316 C9.6602,-0.08797 13.6396,0.70525 16.2843,3.35281 C18.929,6.00036 19.7179,9.98059 18.283,13.43676 C16.8481,16.89292 13.4722,19.14405 9.73,19.1400154 C7.2777,19.1400154 4.9259,18.16513 3.1928,16.43013 C1.4597,14.69513 0.4873,12.34233 0.489994392,9.89 Z M9.73,14.1100219 C11.4377,14.11405 12.9796,13.08845 13.6359,11.51187 C14.2922,9.93528 13.9336,8.11855 12.7275,6.90956 C11.5214,5.70058 9.7055,5.3377 8.1274,5.99028 C6.5493,6.64287 5.52,8.18226 5.52,9.89 C5.52,12.21674 7.4033,14.10449 9.73,14.1100219 L9.73,14.1100219 Z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1)">
        <mask id={`spotlilght_grn-b-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-a-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.014 -5.001 63.844 -5.001 63.844 62.875 -4.014 62.875" mask={`url(#spotlilght_grn-b-${id})`} />
      </g>
      <g transform="translate(63 14)">
        <mask id={`spotlilght_grn-d-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-c-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.95 -4.523 22.46 -4.523 22.46 29.005 -4.95 29.005" mask={`url(#spotlilght_grn-d-${id})`} />
      </g>
      <g transform="translate(82 19)">
        <mask id={`spotlilght_grn-f-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-e-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.58 -4.071 23.817 -4.071 23.817 30.62 -4.58 30.62" mask={`url(#spotlilght_grn-f-${id})`} />
      </g>
      <g transform="translate(122 15)">
        <mask id={`spotlilght_grn-h-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-g-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.93 -4.15 18.38 -4.15 18.38 27.944 -4.93 27.944" mask={`url(#spotlilght_grn-h-${id})`} />
      </g>
      <g transform="translate(137 13)">
        <mask id={`spotlilght_grn-j-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-i-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.54 -4.76 13.7 -4.76 13.7 29.59 -4.54 29.59" mask={`url(#spotlilght_grn-j-${id})`} />
      </g>
      <g transform="translate(147 13)">
        <mask id={`spotlilght_grn-l-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-k-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.55 -4.731 13.714 -4.731 13.714 29.59 -4.55 29.59" mask={`url(#spotlilght_grn-l-${id})`} />
      </g>
      <g transform="translate(157 19)">
        <mask id={`spotlilght_grn-n-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-m-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.667 -4.061 23.08 -4.061 23.08 30.932 -4.667 30.932" mask={`url(#spotlilght_grn-n-${id})`} />
      </g>
      <g transform="translate(177 13)">
        <mask id={`spotlilght_grn-p-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-o-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.45 -4.76 22.361 -4.76 22.361 29.63 -4.45 29.63" mask={`url(#spotlilght_grn-p-${id})`} />
      </g>
      <g transform="translate(196 15)">
        <mask id={`spotlilght_grn-r-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-q-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.37 -4.15 18.94 -4.15 18.94 27.944 -4.37 27.944" mask={`url(#spotlilght_grn-r-${id})`} />
      </g>
      <g transform="translate(212 14)">
        <mask id={`spotlilght_grn-t-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-s-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.78 -4.15 23.97 -4.15 23.97 28.59 -4.78 28.59" mask={`url(#spotlilght_grn-t-${id})`} />
      </g>
      <g transform="translate(233 20)">
        <mask id={`spotlilght_grn-v-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-u-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.773 -4.616 19.346 -4.616 19.346 22.977 -4.773 22.977" mask={`url(#spotlilght_grn-v-${id})`} />
      </g>
      <g transform="translate(249 20)">
        <mask id={`spotlilght_grn-x-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-w-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.38 -4.27 28.4 -4.27 28.4 22.59 -4.38 22.59" mask={`url(#spotlilght_grn-x-${id})`} />
      </g>
      <g transform="translate(273 20)">
        <mask id={`spotlilght_grn-z-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-y-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.44 -4.652 17.87 -4.652 17.87 22.902 -4.44 22.902" mask={`url(#spotlilght_grn-z-${id})`} />
      </g>
      <g transform="translate(102 19)">
        <mask id={`spotlilght_grn-B-${id}`} fill="#fff">
          <use xlinkHref={`#spotlilght_grn-A-${id}`} />
        </mask>
        <polygon className="cls-1" points="-4.51 -4.36 23.99 -4.36 23.99 24.14 -4.51 24.14" mask={`url(#spotlilght_grn-B-${id}`} />
      </g>
      <text className="cls-1" fontFamily="SFProText-Light, SF Pro Text" fontSize="9" fontWeight="300">
        <tspan x="287.003" y="22">®</tspan>
      </text>
    </g>
  </svg>
));

Regular.propTypes = {
  color: PropTypes.string.isRequired,
  small: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
};

export default Regular;
