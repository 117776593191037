import React from 'react';
import PropTypes from 'prop-types';
import ContestDetail from '../../components/Contest/ContestDetail';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const ContestInternal = ({ match }) => {
  useBackgroundClass('contest');
  return (<ContestDetail match={match} />);
};

ContestInternal.propTypes = {
  match: PropTypes.shape(),
};

export default ContestInternal;
