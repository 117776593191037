/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatDistance } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
  Box,
  Link,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { findImageSources, getOptimalSource } from '../../../utils/media';
import useComponentWidth from '../../../hooks/useComponentWidth';
import routes from '../../../routes';
import PremiumDialog from '../../Premium';
import { useQuery } from '../../../hooks/useQuery';
import { readNotification } from '../../../actions/notifications';

const styles = makeStyles((theme) => ({
  cardRoot: {
    background: theme.palette.background.default,
  },
  cardMediaRoot: {
    paddingTop: '56.25%',
    boxShadow: 'none !important',
    backgroundPosition: 'top',
  },
  cardMediaNonArticleRoot: {
    paddingTop: '56.25%',
    boxShadow: 'none !important',
    backgroundSize: '40%',
    backgroundPosition: 'center',
  },
  cardContentRoot: {
    padding: theme.spacing(2),
  },
  publisherTitleRoot: {
    display: 'block',
    lineHeight: '13px',
    marginBottom: 9,
    height: 34,
    color: theme.palette.text.secondary,
    textTransform: 'capitalize',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  articleTitleRoot: {
    width: '100%',
    height: '85px',
    fontSize: '1.125rem',
    lineHeight: 1.5,
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h6.fontSize,
      height: 'auto',
      maxHeight: '85px',
    },
  },
  timestampParagraph: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  premiumLabel: {
    color: '#37BC98',
    fontWeight: 500,
    paddingTop: 8,
  },
}));

const ArticleCard = ({ article, userId, type }) => {
  const classes = styles();
  const { componentRef, componentWidth } = useComponentWidth();
  const timeSince = article?.published_at
    ? formatDistance(parseISO(article.published_at), new Date())
    : null;
  let source = getOptimalSource(findImageSources(article?.media), componentWidth);
  if (componentWidth && !source) {
    source = article?.publisher.default_image;
  }
  const history = useHistory();

  const [showPremiumDialog, setShowPremiumDialog] = useState(false);

  const readNotificationQuery = useQuery('readNotification', readNotification, {
    variables: [userId, article?.notificationId],
    enabled: false,
  });

  const handleArticleClick = (event) => {
    event.preventDefault();
    if (!userId) {
      history.push(routes.news.sharedArticle(article.id));
      return null;
    }

    if (type === 'notification') {
      readNotificationQuery.refetch();
    }

    switch (article?.type) {
      case 'writers::writer':
        history.push(routes.writers.main(article?.slug));
        return null;
      case 'topic':
        history.push(routes.interest.feed(article?.id));
        return null;
      case 'channel':
        history.push(routes.publishers.main(article?.slug));
        return null;
      default:
        break;
    }

    if (!article?.publisher?.subscription?.allowed) {
      setShowPremiumDialog(true);
      return false;
    }
    history.push(routes.news.article(article.id));
    return null;
  };

  return (
    <>
      <PremiumDialog
        channel={article?.publisher}
        show={showPremiumDialog}
        onCloseClick={() => setShowPremiumDialog(false)}
      />
      <Card className={classes.cardRoot}>
        <CardActionArea component="div">
          <Link href="#" onClick={handleArticleClick} color="inherit">
            <CardMedia
              image={source || 'data:,'}
              title={article?.tidy_title}
              classes={{ root: (type === 'notification' && article?.type !== 'post' ? classes.cardMediaNonArticleRoot : classes.cardMediaRoot) }}
              ref={componentRef}
            />
          </Link>
          <CardContent classes={{ root: classes.cardContentRoot }}>
            {article?.publisher?.slug && (
              <Link
                href={routes.publishers.main(article?.publisher.slug)}
                variant="overline"
                paragraph
                classes={{
                  root: classes.publisherTitleRoot,
                }}
                noWrap
              >
                {article?.publisher.title}
                {article?.publisher.premium && article?.publisher.slug !== 'time'
                  && <Box className={classes.premiumLabel}>Premium</Box>}
                {article?.publisher.slug === 'time'
                  && <Box className={classes.premiumLabel}>Premium+</Box>}
              </Link>
            )}
            <Link href="#" onClick={handleArticleClick} color="inherit">
              <Typography
                gutterBottom
                variant="h6"
                classes={{ root: classes.articleTitleRoot }}
              >
                {article?.tidy_title}
              </Typography>
            </Link>
            {timeSince
              && (
                <Typography
                  variant="caption"
                  classes={{ paragraph: classes.timestampParagraph }}
                  paragraph
                >
                  {timeSince} ago | {article?.estimated_reading_time}
                </Typography>
              )}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

ArticleCard.propTypes = {
  userId: PropTypes.string,
  article: PropTypes.shape().isRequired,
  type: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userId: state.user?.profile?.id,
});

export default connect(mapStateToProps)(ArticleCard);
