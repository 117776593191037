import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import PublisherBox, { PublisherBoxSkeleton } from '../PublisherBox';
import InfiniteList from '../../common/InfiniteList';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  headingRoot: {
    paddingTop: 50,
    marginBottom: 27,
  },
  channelCount: {
    opacity: 0.5,
    marginLeft: 10,
  },
}));

const PublisherBoxList = ({
  channels, channelCount, label, isLoading,
  hasMore, loadMore, initialLoad,
  EmptyStateComponent, children,
}) => {
  const classes = styles();

  const renderChannels = () => (
    <Grid container spacing={3} classes={{ root: classes.sectionsRoot }}>
      {isLoading && initialLoad ? (
        Array(12).fill().map((_, i) => (
          <Grid key={`publisher-skeleton-${i}`} item xs={6} sm={4} md={3} lg={2}>
            <PublisherBoxSkeleton />
          </Grid>
        ))
      ) : (
        channels.map((channel) => (
          <Grid item key={channel.id} lg={2} md={3} sm={4} xs={6}>
            <PublisherBox publisher={channel} />
          </Grid>
        ))
      )}
    </Grid>
  );

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <Box className={classes.headingRoot}>
        <Typography variant="h6">
          {label}
          {!isLoading
            && <span className={classes.channelCount}>({channelCount})</span>}
        </Typography>
        {children}
      </Box>
      {channels.length === 0 && !isLoading
        ? <EmptyStateComponent />
        : (
          <InfiniteList isLoading={isLoading} loadMore={loadMore} hasMore={hasMore}>
            <Grid container>
              {renderChannels()}
            </Grid>
          </InfiniteList>
        )}
    </Container>
  );
};

PublisherBoxList.propTypes = {
  children: PropTypes.node,
  channels: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  channelCount: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  initialLoad: PropTypes.bool.isRequired,
  EmptyStateComponent: PropTypes.elementType.isRequired,
};

export default PublisherBoxList;
