import React from 'react';
import { useHistory } from 'react-router-dom';

import { forgotPassword } from '../actions/user';
import ForgotPasswordForm from '../components/Auth/ForgotPasswordForm';

import routes from '../routes';

const message = 'If your email address exists in our database, you will receive'
      + ' a password recovery link at your email address in a few minutes.';

const ForgotPassword = () => {
  const history = useHistory();

  const handleSubmit = async ({ email }) => {
    await forgotPassword(email);
    history.push(routes.auth.signin, { flash: { message } });
  };

  return (
    <ForgotPasswordForm onSubmit={handleSubmit} />
  );
};

export default ForgotPassword;
