import client from '../utils/client';

const fetchChannel = async (slug, typeParam = null, valueParam = null) => {
  let params = {};
  if (typeParam) {
    params = { type: typeParam, value: valueParam };
  }

  const channelUrl = `/channels/${slug}`;
  const topicsUrl = `/channels/${slug}/topics`;

  const [{ data: channel }, { data: topics }] = await Promise.all([
    client().get(channelUrl, { params }),
    client().get(topicsUrl, { params: { nesting: 3 } }),
  ]);

  channel.topics = topics.map((topic) => ({
    ...topic,
    id: topic._id, // eslint-disable-line no-underscore-dangle
  }));

  return channel;
};

const getChannelFeed = async (channelId, topicId, perPage = 10, page = 1) => {
  const latestUrl = `/channels/${channelId}/articles`;
  const topicFeedUrl = `/topics/${topicId}/articles`;

  const url = topicId ? topicFeedUrl : latestUrl;

  const response = await client().get(url, { params: { page, per_page: perPage } });
  return {
    totalCount: response.total_count,
    totalPages: response.total_pages,
    currentPage: response.current_page,
    articles: response.data,
  };
};

const toggleSubscription = async (userId, subjectType, subjectId, isSubscribed) => {
  const url = `users/${userId}/feeds/${subjectType}/${subjectId}`;
  if (isSubscribed) {
    await client().put(url);
  } else {
    await client().delete(url);
  }
};

const toggleChannelNotification = async (userId, channelId, isNotifiable) => {
  const url = `notifications/${userId}/settings/channels/${channelId}`;
  try {
    await client().patch(url, { disabled: !isNotifiable });
  } catch (err) {
    console.log(err.message);
  }
};

const fetchAllPublishers = async (page = 1, asc = true) => {
  const url = asc ? 'discovery/sections/channels_by_name_asc' : 'discovery/sections/channels_by_name_desc';
  const response = await client().get(url, { params: { page } });

  return {
    channels: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const searchPublishers = async (keyword, page = 1) => {
  const url = '/search/channels/';
  const response = await client().get(url, {
    params: {
      s: keyword,
      page,
    },
  });

  return {
    channels: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const fetchSubscriptions = async (userId, page = null) => {
  try {
    const url = `/users/${userId}/feeds/`;
    const response = await client().get(url, { params: { per_page: 50, page } });
    const channelSubscriptions = response?.data.filter((uF) => uF.type === 'channel' || (uF.type === 'topic' && uF?.subject?.topic_type === 'channel'));

    // Ugly hack. Add `is_subscribed` to each item
    channelSubscriptions.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.is_subscribed = true;
    });

    return {
      data: channelSubscriptions,
      totalCount: response.total_count,
      numPages: response.total_pages,
      currentPage: response.current_page,
    };
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

const fetchAllSubscriptions = async (userId) => {
  try {
    let page = 1;
    const response = await fetchSubscriptions(userId, page);
    let subscriptions = response.data;
    const totalPages = response.total_pages;
    while (page < totalPages) {
      page += 1;
      // eslint-disable-next-line no-await-in-loop
      const responseRecursive = await fetchSubscriptions(userId, page);
      subscriptions = [...subscriptions, ...responseRecursive];
    }

    return subscriptions;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

export {
  fetchChannel, toggleSubscription, fetchAllPublishers,
  searchPublishers, toggleChannelNotification, getChannelFeed,
  fetchSubscriptions, fetchAllSubscriptions,
};
