import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import ThemeContext from '../../context/theme/ThemeContext';
import ElectionsDetail from '../../components/Elections/ElectionsDetail';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const ElectionsInternalWebView = ({ match }) => {
  useBackgroundClass('elections');
  const urlParams = new URLSearchParams(window.location.search);
  const darkModeParam = urlParams.get('darkMode') === '1';
  // eslint-disable-next-line no-unused-vars
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  useEffect(() => {
    setDarkMode(darkModeParam);
  }, []);
  return (<ElectionsDetail darkModeActive={darkMode} match={match} webView />);
};

ElectionsInternalWebView.propTypes = {
  match: PropTypes.shape(),
};

export default ElectionsInternalWebView;
