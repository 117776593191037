import client from '../utils/client';

const setFeedLoading = (bool) => ({
  type: 'NEWS_FEED_IS_LOADING',
  isLoading: bool,
});

const addFeed = (topicId, data) => ({
  type: 'NEWS_ADD_FEED',
  topicId,
  data,
});

const getFeed = (topicId, page = 1, perPage = 10) => {
  const myFeedUrl = '/feed';
  const topicFeedUrl = `/topics/${topicId}/articles`;

  const url = topicId === null ? myFeedUrl : topicFeedUrl;
  return async (dispatch) => {
    try {
      dispatch(setFeedLoading(true));
      const response = await client().get(url, { params: { page, per_page: perPage } });
      const data = {
        totalCount: response.total_count,
        totalPages: response.total_pages,
        currentPage: response.current_page,
        articles: response.data,
      };
      dispatch(addFeed(topicId, data));
    } catch (err) {
      console.log(err.message);
    } finally {
      dispatch(setFeedLoading(false));
    }
  };
};

const setArticleLoading = (bool) => ({
  type: 'NEWS_ARTICLE_IS_LOADING',
  isLoading: bool,
});

const addArticle = (articleId, data) => ({
  type: 'NEWS_ADD_ARTICLE',
  articleId,
  data,
});

const getArticle = (articleId, path = '/articles') => {
  const url = `${path}/${articleId}`;
  return async (dispatch) => {
    dispatch(setArticleLoading(true));
    try {
      const response = await client().get(url);
      dispatch(addArticle(articleId, response.data));
    } catch (err) {
      if (err.status !== 404) {
        console.log('error');
      }
    }
    dispatch(setArticleLoading(false));
  };
};

const getArticleById = async (articleId, typeParam = null, valueParam = null) => {
  let params = {};
  if (typeParam) {
    params = { type: typeParam, value: valueParam };
  }

  const url = `articles/${articleId}`;
  try {
    const response = await client().get(url, { params });
    return response.data;
  } catch (err) {
    return err;
  }
};

const searchArticlesByAuthor = async (author, page = 1) => {
  const url = '/search/articles/by_author';
  const response = await client().get(url, { params: { author, page } });
  return {
    articles: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

// eslint-disable-next-line camelcase
const searchArticlesByKeyword = async (s, page = 1, channel_id = null) => {
  const url = '/search/articles';
  // eslint-disable-next-line camelcase
  const response = await client().get(url, { params: { s, page, channel_id } });
  return {
    articles: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const searchArticlesByChannel = async (s, channelId = null, page = 1) => {
  const url = '/search/articles';
  let params;

  if (channelId) {
    params = { params: { s, page, channel_id: channelId } };
  } else {
    params = { params: { s, page } };
  }

  const response = await client().get(url, params);
  return {
    articles: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const searchArticlesByWriter = async (author, page = 1) => {
  const url = '/search/articles/by_author';
  const response = await client().get(url, { params: { author, page } });
  return {
    articles: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const fetchTopArticles = async (userId, page = 1) => {
  const url = '/feed/top';
  const response = await client().get(url, { params: { page } });
  return {
    articles: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const fetchFeedsCards = async (userId, page = 1) => {
  const url = `/users/${userId}/feeds/cards`;
  const response = await client().get(url, {
    params: {
      page,
      per_page: 5,
      articles_count: 4,
    },
  });
  return {
    feedsCards: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const fetchUserFeeds = async (userId, page = null) => {
  const url = `/users/${userId}/feeds/`;
  const response = await client().get(url, { params: { per_page: 50, page } });
  return response.data;
};

const setUserFeedsOrder = async (userId, feedOrderArray = []) => {
  const url = `/users/${userId}/feeds/order`;
  const response = await client().put(url, { ids: feedOrderArray });
  return response.data;
};

// TODO: To be replaced with user feed, when the correspondent API will be implemented
const fetchTopic = async (id, typeParam = null, valueParam = null) => {
  let params = {};
  if (typeParam) {
    params = { type: typeParam, value: valueParam };
  }
  const url = `/topics/${id}`;
  const topic = await client().get(url, { params });
  return topic.data;
};

export {
  setFeedLoading,
  getFeed,
  addArticle,
  getArticle,
  fetchTopArticles,
  fetchFeedsCards,
  fetchTopic,
  fetchUserFeeds,
  setUserFeedsOrder,
  searchArticlesByAuthor,
  searchArticlesByKeyword,
  getArticleById,
  searchArticlesByWriter,
  searchArticlesByChannel,
};
