import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import routes from '../../routes';
import PlanBox from '../Premium/PlanBox';

const styles = makeStyles((theme) => ({
  containerRoot: {
    display: 'flex',
  },
  title: {
    color: '#37bc98',
    fontSize: 24,
    fontWeight: 'bold',
  },
  paymentButton: {
    marginTop: 20,
  },
  cardElementContainer: {
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  cardElement: {
    width: 300,
  },
  planContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  cardBox: {
    padding: '15px',
    marginBottom: 30,
  },
  listItemTextTypography: {
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: 100,
    marginBottom: 30,
  },
  dividerRoot: {
    margin: '50px 15px',
  },
  submitButton: {
    marginTop: 20,
    padding: 10,
    fontSize: 14,
    fontWeight: 'bold',
    border: 'none',
    borderRadius: 10,
    minWidth: 150,
  },
  cancelButton: {
    marginTop: 20,
    padding: 10,
    fontSize: 14,
    fontWeight: 'bold',
    border: 'none',
    borderRadius: 10,
    minWidth: 150,
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    fontFamily: 'Roboto',
    color: '#757575',
    marginRight: 30,
    '&:hover': {
      backgroundColor: '#c1c1c1',
      color: '#ffffff',
      border: 'none',
    },
  },
  errorMessage: {
    color: '#df1b41',
    marginBottom: 20,
  },
}));

const CheckoutForm = ({ plan }) => {
  const classes = styles();
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://my.spotlightnews.us/premium-confirmation',
      },
    });

    if (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Box className={classes.cardBox}>
        <form onSubmit={handleSubmit}>
          <Typography className={classes.listItemTextTypography}>
            Please add your credit card information to complete your purchase:
          </Typography>
          {errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
          <PaymentElement />
          <Button href={routes.premium} variant="outlined" color="primary" className={classes.cancelButton} type="button" disabled={!stripe}>Cancel</Button>
          <Button variant="contained" color="primary" className={classes.submitButton} type="submit" disabled={!stripe}>Submit</Button>
        </form>
      </Box>
      {plan && (
        <Box className={classes.selectedPlanBox}>
          <Typography className={classes.title}>Selected plan</Typography>
          <Box className={classes.planContainer}>
            <PlanBox
              plan={plan}
              hideButton
            />
          </Box>
        </Box>
      )}
    </>
  );
};

CheckoutForm.propTypes = {
  plan: PropTypes.shape(),
};

export default CheckoutForm;
