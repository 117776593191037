import { useInfiniteQuery, useQuery as _useQuery } from 'react-query';

export function useQuery(key, queryFn, config = {}) {
  const { variables, ...queryConfig } = config;
  const queryKey = variables ? [key, ...variables] : [key];
  return _useQuery(
    queryKey,
    // Allow queryFn to keep its original arguments
    (_key, ...args) => queryFn(...args),
    { refetchOnWindowFocus: false, ...queryConfig },
  );
}

export function usePaginationQuery(key, queryFn, config = {}) {
  const { variables, ...queryConfig } = config;
  const queryKey = variables ? [key, ...variables] : [key];
  return useInfiniteQuery(
    queryKey,
    // Allow queryFn to keep its original arguments
    (_key, ...args) => queryFn(...args),
    {
      ...queryConfig,
      // eslint-disable-next-line max-len
      getFetchMore: (lastPage) => {
        const totalPages = lastPage.totalPages ? lastPage.totalPages : lastPage.numPages;
        return lastPage.currentPage < totalPages ? lastPage.currentPage + 1 : false;
      },
    },
  );
}
