import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery } from '../../hooks/useQuery';
import { fetchTopic } from '../../actions/news';
import InterestNavBar from '../../components/Nav/InterestNavBar';
import routes from '../../routes';
import ArticlePagination, { TopArticlesEmpty } from '../../components/Article/ArticlePagination';
import { fetchInterestArticles } from '../../actions/topics';
import MainContent from '../../components/MainContent';
import { language } from '../../utils/helpers';
import SignUpOverlay from '../../components/Auth/SignUpOverlay';

const Interest = ({ match, user }) => {
  const history = useHistory();
  const { interestId } = match.params;

  const urlParams = new URLSearchParams(window.location.search);
  const typeParam = urlParams.get('type');
  const valueParam = urlParams.get('value');

  const { data: interest } = useQuery('interestFeed', fetchTopic, {
    variables: [interestId, typeParam, valueParam],
  });

  useEffect(() => {
    if ((typeParam || valueParam) && interest) {
      history.push({}, document.title, window.location.pathname);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeParam, valueParam, interest]);

  const handleBackClick = () => {
    if (history.length <= 2) { // No back page
      history.push(routes.news.main);
    } else {
      window.history.back();
    }
  };

  return interest
    ? (
      <>
        <InterestNavBar
          feedTopic={interest}
          onBackClick={handleBackClick}
        />
        <MainContent includeBackgroundImage={false} paddingTop={0}>
          <Box>
            <ArticlePagination
              loadsContext="interestArticles"
              load={(guid, page) => fetchInterestArticles(interestId, page)}
              label={`Results for "${language(interest?.names)}"`}
              EmptyStateComponent={TopArticlesEmpty}
            />
            {!user.isSignedIn && <SignUpOverlay />}
          </Box>
        </MainContent>
      </>
    )
    : (
      <></>
    );
};

Interest.propTypes = {
  match: PropTypes.shape(),
  user: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Interest);
