import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { fetchAds } from '../../actions/ads';
import { useQuery } from '../../hooks/useQuery';

const styles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    cursor: 'pointer',
    margin: '0px auto',
    marginBottom: 15,
    maxWidth: '100%',
  },

  label: {
    display: 'block',
    fontSize: '12px',
    letterSpacing: '1.88px',
    fontWeight: 400,
    marginBottom: '3px',
    color: theme.palette.text.alpha,
  },

  banner: {
    backgroundColor: 'transparent',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    maxWidth: '100%',
    margin: '0px auto',
    width: (dimensions) => dimensions.width,
  },
}));

const getRenderWidth = (placement) => {
  switch (placement) {
    case 'side_bar':
      return {
        width: 300,
      };
    case 'user_feeds':
      return {
        width: 728,
      };
    case 'fab':
      return {
        width: 728,
      };
    default:
      return {
        width: 0,
      };
  }
};

const BannerAd = ({
  numberOfAds,
  placement,
  contextType,
  contextId,
  maxWidth,
  minWidth,
}) => {
  const [ads, setAds] = useState([]);

  const render = getRenderWidth(placement);

  const params = {
    number_of_ads: numberOfAds,
    placement,
    context_type: contextType,
    context_id: contextId,
    max_width: maxWidth,
    min_width: minWidth,
  };

  const { data: response } = useQuery('ads', fetchAds, {
    variables: [params],
  });

  useEffect(() => {
    setAds(response);
  }, [response]);

  const classes = styles(render);

  const handleClick = (ad) => {
    const targetUrl = ad.target_url;
    window.location = targetUrl;
  };

  if (ads && ads.length > 0) {
    return ads.map((ad) => (
      // eslint-disable-next-line no-underscore-dangle
      <Box key={ad._id} onClick={() => handleClick(ad)} className={classes.root}>
        <span className={classes.label}>{ad.label}</span>
        <Box
          className={classes.banner}
          style={{
            backgroundImage: `url(${ad.image_url})`,
            height: (ad.height * render.width) / ad.width,
          }}
        />
      </Box>
    ));
  }
  return <div />;
};

BannerAd.propTypes = {
  numberOfAds: PropTypes.number,
  placement: PropTypes.string.isRequired,
  contextType: PropTypes.string,
  contextId: PropTypes.number,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
};

export default BannerAd;
