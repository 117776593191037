import React from 'react';
import PropTypes from 'prop-types';

import Regular from './Regular';
import Premium from './Premium';
import ForReadersNotFollowers from './ForReadersNotFollowers';

const Container = ({ onClick, children, ...props }) => (
  onClick ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#0" onClick={onClick} {...props}>{children}</a>
  ) : (
    <span {...props}>{children}</span>
  )
);

const Logo = ({ premium, onClick, height, small, className, color, header, ...props }) => {
  const Image = premium ? Premium : (header ? ForReadersNotFollowers : Regular);

  return (
    <Container
      style={{
        display: 'inline-block',
        height: `${height}px`,
      }}
      onClick={onClick}
      className={className}
    >
      <Image small={small} color={color} height={height} {...props} />
    </Container>
  );
};

Logo.propTypes = {
  premium: PropTypes.bool,
  header: PropTypes.bool,
  onClick: PropTypes.func,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  small: PropTypes.bool,
};

Logo.defaultProps = {
  premium: false,
  height: 35,
  color: '#37bc98',
  small: false,
};

export default Logo;
