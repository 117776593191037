import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Button, makeStyles } from '@material-ui/core';
import routes from '../../routes';
import { toggleArticleBookmarked as toggleArticleBookmarkedAction } from '../../actions/bookmarks';
import { toggleArticleFavorite as toggleArticleFavoriteAction } from '../../actions/favorites';
import { readArticle as readArticleAction, registerArticleHistory } from '../../actions/read';
import { getArticleById } from '../../actions/news';
import ArticleDetail from '../../components/Article/ArticleDetail';
import Loader from '../../components/common/Loader';
import SignUpOverlay from '../../components/Auth/SignUpOverlay';
import { useQuery } from '../../hooks/useQuery';
import PremiumAboveLimitMessage from '../../components/FeedbackMessages/PremiumAboveLimitMessage';
import ArticleNotFoundMessage from '../../components/FeedbackMessages/ArticleNotFoundMessage';
import config from '../../config';

const styles = makeStyles((theme) => ({
  centerContent: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  button: {
    marginTop: 15,
  },
  buttonContainer: {
    textAlign: 'right',
    padding: 20,
  },
  containerWidth: {
    maxWidth: 800,
    margin: '0px auto',
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: '-0.32px',
    color: theme.palette.text.primary,
    marginTop: 30,
    marginBottom: 15,
  },
}));

let accTime = 0;
let numberOfCalls = 0;

const Article = ({
  user,
  history,
  match,
  isLoading,
  readArticle,
  toggleArticleBookmarked,
  toggleArticleFavorite,
  previousUrl,
}) => {
  const [queryVars, setQueryVars] = useState([]);
  const [accumulatedTime, setAccumulatedTime] = useState(0);
  const urlParams = new URLSearchParams(window.location.search);
  const typeParam = urlParams.get('type');
  const valueParam = urlParams.get('value');
  const { data: article, isFetching } = useQuery('getArticle', getArticleById, {
    variables: [match.params.id, typeParam, valueParam],
  });
  const readArticleToken = article && article?.read_article_token;
  const instanceId = article && article?.instance_id;
  const articleId = article && article?.id;
  const classes = styles();
  const seconds = (config.environment === 'development' || config.environment === 'staging') ? 10 : 60;
  const readingTimer = seconds * 1000;

  const registerArticleHistoryQuery = useQuery('registerArticleHistory', registerArticleHistory, {
    variables: queryVars,
    enabled: false,
  });

  const totalScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  // eslint-disable-next-line max-len, no-restricted-globals
  const currentScrollness = isNaN(parseFloat(window.pageYOffset / totalScroll)) ? 0 : parseFloat(window.pageYOffset / totalScroll);
  if (currentScrollness > sessionStorage.getItem('maxScrollness')) {
    sessionStorage.setItem('maxScrollness', currentScrollness);
  }
  // eslint-disable-next-line no-mixed-operators
  const timeLeft = (accumulatedTime - (numberOfCalls * readingTimer / 1000));

  const registerArticleHistoryFinalQuery = useQuery('registerArticleHistoryFinal', registerArticleHistory, {
    variables: [
      user?.profile?.id,
      readArticleToken,
      articleId,
      timeLeft,
      parseFloat(sessionStorage.getItem('maxScrollness')),
      currentScrollness,
      instanceId,
      true,
    ],
    enabled: false,
  });

  useEffect(() => {
    accTime = 0;
    numberOfCalls = 0;
    setAccumulatedTime(0);
    sessionStorage.setItem('maxScrollness', 0);
    sessionStorage.setItem('articleMount', true);
  }, []);

  useEffect(() => {
    if (queryVars.length > 0 && user?.profile?.id) {
      registerArticleHistoryQuery.refetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVars]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (article && !isFetching && user?.profile?.id) {
      const interval = (setInterval(() => {
        // eslint-disable-next-line max-len
        const totalScrollL = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        // eslint-disable-next-line max-len, no-restricted-globals
        const currentScrollnessL = isNaN(parseFloat(window.pageYOffset / totalScrollL)) ? 0 : parseFloat(window.pageYOffset / totalScrollL);

        if (currentScrollnessL > sessionStorage.getItem('maxScrollness')) {
          sessionStorage.setItem('maxScrollness', currentScrollnessL);
        }

        setQueryVars([
          user?.profile?.id,
          readArticleToken,
          articleId,
          (readingTimer / 1000),
          parseFloat(sessionStorage.getItem('maxScrollness')),
          currentScrollnessL,
          instanceId,
        ]);
      }, readingTimer));

      const intervalAccumulaTed = (setInterval(() => {
        accTime += 1;
        if (accTime % seconds === 0) {
          numberOfCalls += 1;
        }

        setAccumulatedTime(accTime);
      }, 1000));

      return () => {
        if (sessionStorage.getItem('articleMount') === 'false') registerArticleHistoryFinalQuery.refetch();
        clearInterval(interval);
        clearInterval(intervalAccumulaTed);
        return true;
      };
    }
    sessionStorage.setItem('articleMount', false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, article]);

  useEffect(() => {
    if ((typeParam || valueParam) && article) {
      history.push({}, document.title, window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeParam, valueParam, article]);

  const handleBackClick = () => {
    if (previousUrl) {
      window.history.back();
    } else {
      history.push(routes.news.main);
    }
  };

  useEffect(() => {
    if (articleId) {
      if (user.isSignedIn) {
        readArticle(user.profile.id, articleId);
      }
    }
  }, [articleId, user, readArticle]);

  const handleFavoriteClick = (isFavorite) => {
    toggleArticleFavorite(articleId, isFavorite);
  };

  const handleBookmarkClick = (isBookmarked) => {
    toggleArticleBookmarked(user.profile.id, articleId, isBookmarked);
  };

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    article?.isAxiosError
      ? (
        <Box className={classes.centerContent}>
          <Box className={classes.containerWidth}>
            {article?.status === 412 && (
              <>
                <PremiumAboveLimitMessage />
                <Box className={classes.buttonContainer}>
                  <Button className={classes.button} variant="outlined" color="primary" onClick={() => window.history.back()}>Go Back</Button>
                </Box>
              </>
            )}
            {article?.status === 404 && (
              <>
                <ArticleNotFoundMessage />
              </>
            )}
          </Box>
        </Box>
      )
      : (
        <>
          <ArticleDetail
            article={article}
            user={user}
            onBackClick={handleBackClick}
            onFavoriteClick={handleFavoriteClick}
            onBookmarkClick={handleBookmarkClick}
          />
          {!user.isSignedIn && <SignUpOverlay />}
        </>
      )
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleArticleFavorite: (articleId, isFavorite) => (
    dispatch(toggleArticleFavoriteAction(articleId, isFavorite))
  ),
  toggleArticleBookmarked: (userId, articleId, isBookmarked) => (
    dispatch(toggleArticleBookmarkedAction(userId, articleId, isBookmarked))
  ),
  readArticle: (userId, articleId) => (
    dispatch(readArticleAction(userId, articleId))
  ),
});

const mapStateToProps = (state) => ({
  user: state.user,
  articles: state.news.articles,
  selectedArticleId: state.news.selectedArticleId,
  isLoading: state.news.articleIsLoading,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps, ...ownProps, ...stateProps,
});

Article.propTypes = {
  match: PropTypes.shape(),
  user: PropTypes.shape(),
  history: PropTypes.shape(),
  previousUrl: PropTypes.shape(),
  isLoading: PropTypes.bool,
  readArticle: PropTypes.func,
  toggleArticleBookmarked: PropTypes.func,
  toggleArticleFavorite: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Article));
