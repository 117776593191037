import React from 'react';
import ContestList from '../../components/Contest/ContestList';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const Contests = () => {
  useBackgroundClass('contest');
  return (<ContestList />);
};

export default Contests;
