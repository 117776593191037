import tinycolor from 'tinycolor2';
import numeral from 'numeral';

const timeSince = (utcDateISO) => {
  const tzOffset = new Date().getTimezoneOffset();
  const date = (new Date() - new Date(utcDateISO)) + tzOffset;
  const seconds = Math.floor(date / 1000);

  let interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(date, options);
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `${interval} hour${interval > 1 ? 's' : ''} ago`;
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return `${interval} minute${interval > 1 ? 's' : ''} ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
};

const language = (languages, selectedLanguage = 'en') => {
  const lang = languages.filter((l) => l.lang === selectedLanguage);
  return (lang[0]) ? lang[0].title : '';
};

const getBrandTextColor = (branding) => {
  if (branding?.tint) return branding?.tint;
  return tinycolor(branding?.color).isLight() ? '#000000' : '#ffffff';
};

const formatArticleCount = (n) => {
  if (n < 1) {
    return 'Currently no articles';
  } if (n < 1000) {
    return `${n} articles`;
  }
  return `${numeral(n).format('0.0a')} articles`;
};

const removeDuplicate = (array, property) => {
  const unique = array.map((e) => e[property])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => array[e]).map((e) => array[e]);
  return unique;
};

export {
  timeSince, language, getBrandTextColor,
  formatArticleCount, removeDuplicate,
};
