import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';
import { Button } from '@material-ui/core';

import ErrorBox from '../ErrorBox';
import { reportError } from '../../utils/errors';

const makeSubmit = ({ isSubmitting, submittingLabel }) => (
  ({ component, children, ...props }) => (
    React.createElement(
      component || Button,
      { disabled: isSubmitting, type: 'submit', ...props },
      isSubmitting ? submittingLabel : children,
    )
  )
);

const InFormik = ({
  children,
  error,
  formik,
  submittingLabel,
}) => {
  const submit = useMemo(() => (
    makeSubmit({ isSubmitting: formik.isSubmitting, submittingLabel })
  ), [formik.isSubmitting, submittingLabel]);

  return (
    <FormikForm>
      <ErrorBox error={error} />
      {children({ Submit: submit, ...formik })}
    </FormikForm>
  );
};

InFormik.propTypes = {
  children: PropTypes.func.isRequired,
  submittingLabel: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
  }).isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Error),
  ]),
};

const Form = ({
  children,
  onSubmit,
  reportError: shouldReportError,
  submittingLabel,
  ...props
}) => {
  const [error, setError] = useState(null);

  const handleSubmit = async (values, actions) => {
    setError(null);
    try {
      await onSubmit(values, actions);
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      if (e && shouldReportError && typeof (e) !== 'string') {
        reportError(e);
      }
      setError(e || new Error('Unknown error'));
    }
  };

  return (
    <Formik onSubmit={handleSubmit} {...props}>
      {(formik) => (
        <InFormik formik={formik} error={error} submittingLabel={submittingLabel}>
          {children}
        </InFormik>
      )}
    </Formik>
  );
};

Form.propTypes = {
  children: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reportError: PropTypes.bool,
  submittingLabel: PropTypes.string,
};

Form.defaultProps = {
  reportError: true,
  submittingLabel: 'Submitting...',
};

export default Form;
