import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MyNewsFeedCards from './MyNewsFeedCards';
import MyNewsTopic from './MyNewsTopic';
import useBackgroundClass from '../../hooks/useBackgroundClass';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  containerRoot: {
    paddingTop: 0,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

const MyNews = ({ match, user }) => {
  const history = useHistory();
  const topicId = match.params.topicId ? match.params.topicId : null;
  const classes = styles();

  const checkOnboard = async () => {
    if (user?.is_onboarded === false) {
      history.push(routes.onboard.main);
    }
  };

  useBackgroundClass('default');

  useEffect(() => {
    checkOnboard();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user?.id && topicId) {
    return (
      <>
        <MyNewsTopic userId={user?.id} feedId={topicId} />
      </>
    );
  }
  return user?.id ? (
    <Container classes={{ root: classes.containerRoot }}>
      <MyNewsFeedCards
        userId={user?.id}
      />
    </Container>
  ) : (<></>);
};

MyNews.propTypes = {
  match: PropTypes.shape().isRequired,
  user: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  user: state.user?.profile,
});

export default connect(mapStateToProps)(MyNews);
