import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  skeleton: {
    height: 60,
    margin: 0,
  },
}));

const WriterBoxSkeleton = () => {
  const classes = styles();
  return (
    <Skeleton className={classes.skeleton} />
  );
};

export default WriterBoxSkeleton;
