import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import {
  toggleChannelNotification,
} from '../../actions/channel';
import routes from '../../routes';
import WriterProfile from '../../components/Writer/WriterProfile/WriterProfile';
import Loader from '../../components/common/Loader';
import { useQuery, usePaginationQuery } from '../../hooks/useQuery';
import ToastDialog from '../../components/ToastDialog/ToastDialog';
import SignUpOverlay from '../../components/Auth/SignUpOverlay';
import { fetchWriter, getWriterFeed } from '../../actions/writers';

const styles = makeStyles((theme) => ({
  spotlightToast: theme.toastDialog,
}));

const Writer = ({
  match, user, history, previousUrl,
}) => {
  const classes = styles();
  const writerId = match.params.slug;
  const feedKey = `${writerId}`;
  const perPage = 10;

  const { data, fetchMore, canFetchMore, isFetchingMore } = usePaginationQuery(
    'writerProfileFeed',
    getWriterFeed,
    {
      variables: [writerId, perPage],
    },
  );

  useEffect(() => {
    fetchMore();
  }, [feedKey]); // eslint-disable-line

  useEffect(() => () => {
    toast.dismiss();
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const typeParam = urlParams.get('type');
  const valueParam = urlParams.get('value');

  const { data: writer } = useQuery('writerProfile', fetchWriter, {
    variables: [writerId, typeParam, valueParam],
  });

  useEffect(() => {
    if ((typeParam || valueParam) && writer) {
      history.push({}, document.title, window.location.pathname);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeParam, valueParam, writer]);

  const handleNotificationClick = (isNotifiable) => {
    toggleChannelNotification(user.profile.id, writer.id, isNotifiable);

    toast.success(
      <ToastDialog
        active={isNotifiable}
        activateIcon={<NotificationsIcon fontSize="large" />}
        deactivateIcon={<NotificationsOffIcon fontSize="large" />}
        activateText="Enabled Notifications"
        deactivateText="Disabled Notifications"
        title={writer.title}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );
  };

  const handleBackClick = () => {
    const backFromPublisherPage = previousUrl?.pathname.includes('/writers/');
    if (backFromPublisherPage || !previousUrl) {
      history.push(routes.news.main);
    } else {
      window.history.back();
    }
  };

  if (writer === undefined) {
    return <Loader />;
  }

  const articles = (data ?? []).flatMap((page) => page.articles);

  return (
    <>
      <WriterProfile
        onBackClick={handleBackClick}
        writer={writer}
        feedIsLoading={Boolean(isFetchingMore)}
        articles={articles}
        hasMore={Boolean(canFetchMore)}
        loadMoreArticles={fetchMore}
        onNotificationClick={handleNotificationClick}
        isSignedIn={user.isSignedIn}
      />
      {!user.isSignedIn && <SignUpOverlay />}
    </>
  );
};

Writer.propTypes = {
  match: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  previousUrl: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(Writer));
