import client from '../utils/client';

const setSections = (data) => {
  const action = {
    type: 'DISCOVER_SET_SECTIONS',
    sections: data,
  };

  return action;
};

const setSectionsLoading = (isLoading) => ({
  type: 'DISCOVER_SECTIONS_IS_LOADING',
  isLoading,
});

const fetchSections = () => {
  const url = '/discovery/sections';

  return async (dispatch) => {
    dispatch(setSectionsLoading(true));
    try {
      const response = await client().get(url);
      dispatch(setSections(response.data));
    } catch (err) {
      console.log(err.message);
    } finally {
      dispatch(setSectionsLoading(false));
    }
  };
};

const clearSearch = () => ({
  type: 'DISCOVER_CLEAR_SEARCH',
});

const setSearchIsLoading = (isLoading) => ({
  type: 'DISCOVER_SEARCH_IS_LOADING',
  isLoading,
});

const setSearchChannels = (data) => ({
  type: 'DISCOVER_SET_SEARCH_CHANNELS',
  totalCount: data.total_count,
  totalPages: data.total_pages,
  currentPage: data.current_page,
  results: data.data,
});

const setSearchArticles = (data) => ({
  type: 'DISCOVER_SET_SEARCH_ARTICLES',
  totalCount: data.total_count,
  totalPages: data.total_pages,
  currentPage: data.current_page,
  results: data.data,
});

const setSearchTopics = (data) => ({
  type: 'DISCOVER_SET_SEARCH_TOPICS',
  totalCount: data.total_count,
  totalPages: data.total_pages,
  currentPage: data.current_page,
  results: data.data,
});

const setSearchKeyword = (keyword) => ({
  type: 'DISCOVER_SET_SEARCH_KEYWORD',
  keyword,
});

const searchEverything = (keyword) => {
  const url = '/search/everything';
  return async (dispatch) => {
    dispatch(setSearchIsLoading(true));
    dispatch(setSectionsLoading(true));
    dispatch(clearSearch());
    dispatch(setSearchKeyword(keyword));
    try {
      const response = await client().get(url, { params: { s: keyword } });
      dispatch(setSearchChannels(response.data.channels));
      dispatch(setSearchTopics(response.data.topics));
      dispatch(setSearchArticles(response.data.articles));
    } catch (err) {
      console.log(err.message);
    } finally {
      dispatch(setSearchIsLoading(false));
      dispatch(setSectionsLoading(false));
    }
  };
};

const searchArticles = (keyword, page, perPage = 20) => {
  const url = '/search/articles';
  return async (dispatch) => {
    dispatch(setSearchIsLoading(true));
    try {
      const response = await client().get(url, { params: {
        s: keyword,
        page,
        per_page: perPage,
      } });
      dispatch(setSearchArticles(response));
    } catch (err) {
      console.log(err.message);
    } finally {
      dispatch(setSearchIsLoading(false));
    }
  };
};

const searchTopics = (keyword, page, perPage = 30) => {
  const url = '/search/topics';
  return async (dispatch) => {
    dispatch(setSearchIsLoading(true));
    try {
      const response = await client().get(url, { params: {
        s: keyword,
        page,
        per_page: perPage,
      } });
      dispatch(setSearchTopics(response));
    } catch (err) {
      console.log(err.message);
    } finally {
      dispatch(setSearchIsLoading(false));
    }
  };
};

const searchChannels = (keyword, page, perPage = 30) => {
  const url = '/search/channels';
  return async (dispatch) => {
    dispatch(setSearchIsLoading(true));
    try {
      const response = await client().get(url, { params: {
        s: keyword,
        page,
        per_page: perPage,
      } });
      dispatch(setSearchChannels(response));
    } catch (err) {
      console.log(err.message);
    } finally {
      dispatch(setSearchIsLoading(false));
    }
  };
};

const getSections = async () => {
  const url = '/discovery/sections';
  try {
    const response = await client().get(url);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

const clearSearchHistory = async (userId, type, expressionId) => {
  const url = `history/${userId}/search/${type}/${expressionId}`;
  try {
    const response = await client().delete(url);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

const clearAllSearchHistory = async (userId, type) => {
  const url = `history/${userId}/search/${type}/all`;
  try {
    const response = await client().delete(url);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

export {
  fetchSections, clearSearch, searchEverything, searchArticles, searchTopics,
  searchChannels, getSections, clearSearchHistory, clearAllSearchHistory,
};
