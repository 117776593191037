import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';

import { toggleSubscription } from '../../actions/channel';

import Toggler from '../common/Toggler';

const Subscription = ({
  publisher,
  feedType,
  isSubscribed,
  toggleSubscriptionAction,
  showTooltip,
  onToggleSubscription,
  ...params
}) => (
  <Toggler
    loadsContext="toggleChannelSubscription"
    trueButton={<CheckCircleIcon titleAccess="Unsubscribe" />}
    falseButton={<AddIcon titleAccess="Subscribe" />}
      // eslint-disable-next-line no-underscore-dangle
    variables={{ publisherId: publisher?.subject ? publisher?.subject?._id : publisher?._id }}
    active={isSubscribed}
    publisher={publisher}
    feedType={feedType}
    onToggle={toggleSubscriptionAction}
    onToggleSubscription={onToggleSubscription}
    showTooltip={showTooltip}
    {...params}
  />
);

Subscription.propTypes = {
  publisher: PropTypes.shape(),
  isSubscribed: PropTypes.bool.isRequired,
  toggleSubscriptionAction: PropTypes.func,
  feedType: PropTypes.string,
  showTooltip: PropTypes.bool,
  onToggleSubscription: PropTypes.func,
};

Subscription.defaultProps = {
  toggleSubscriptionAction: ({ userId, publisherId, active, type }) => (
    toggleSubscription(userId, type, publisherId, active)
  ),
};

export default Subscription;
