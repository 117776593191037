import React from 'react';
import PropTypes from 'prop-types';

const PremiumIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g>
      <rect y="0.001" fill="none" width="24" height="24" />
    </g>
    <g>
      <g>
        <path fill={color} d="M21.596,8.197c-0.325-0.241-0.764-0.262-1.11-0.054l-4.183,2.51l-3.471-5.207c-0.038-0.057-0.101-0.083-0.148-0.128l0.963-0.964c0.194-0.194,0.194-0.513,0-0.707l-1.293-1.293c-0.194-0.194-0.513-0.194-0.707,0l-1.293,1.293c-0.194,0.194-0.194,0.513,0,0.707l0.963,0.964c-0.048,0.045-0.11,0.071-0.148,0.128l-3.471,5.207l-4.183-2.51C3.168,7.936,2.73,7.957,2.404,8.197C2.08,8.439,1.932,8.851,2.029,9.243l2,8C4.141,17.688,4.541,18.001,5,18.001h14c0.459,0,0.859-0.312,0.971-0.758l2-8C22.068,8.851,21.92,8.439,21.596,8.197z" />
        <rect x="4" y="19.001" fill={color} width="16" height="2" />
      </g>
    </g>
  </svg>
);

PremiumIcon.propTypes = {
  color: PropTypes.string,
};

PremiumIcon.defaultProps = {
  color: '#757575',
};

export default PremiumIcon;
