import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar as MaterialAvatar,
  CircularProgress,
  Box,
  makeStyles,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const styles = makeStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
  },
  notificationBall: {
    width: 13,
    height: 13,
    borderRadius: 13,
    position: 'absolute',
    top: 3,
    right: 2,
    zIndex: 1,
    background: 'red',
  },
  accountCircleIcon: {
    fontSize: 48,
    color: 'gray',
  },
}));

const Avatar = ({ user, classes, notificationsCount = 0 }) => {
  const internalClasses = styles();
  if (user?.isLoading) {
    return <CircularProgress />;
  } if (user?.profile?.id) {
    return (
      <>
        {notificationsCount > 0
          && <Box className={internalClasses.notificationBall} />}
        <MaterialAvatar
          src={user.profile.avatar}
          classes={classes}
        />
      </>
    );
  }
  return (
    <MaterialAvatar classes={classes}>
      {
        user.profile.username ? user.profile.username[0]
          : <AccountCircleIcon className={internalClasses.accountCircleIcon} />
      }
    </MaterialAvatar>
  );
};

Avatar.propTypes = {
  user: PropTypes.shape().isRequired,
  classes: PropTypes.shape(),
  notificationsCount: PropTypes.number,
};

export default Avatar;
