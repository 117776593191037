import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Alert as MaterialAlert, AlertTitle } from '@material-ui/lab';

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

const Alert = ({ message, title, severity }) => {
  const classes = styles();
  return (
    message ? (
      <MaterialAlert severity={severity} className={classes.root}>
        <AlertTitle>{title}</AlertTitle>
        {`${message}`}
      </MaterialAlert>
    ) : null
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  severity: PropTypes.string,
};

Alert.defaultProps = {
  severity: 'success',
};

export default Alert;
