import React from 'react';
import { useHistory } from 'react-router-dom';
import { getAuthToken, removeAuthToken, setAuthToken } from '../../utils/authToken';
import routes from '../../routes';

const ElectionsWebViewToken = () => {
  const history = useHistory();

  const location = window?.location.href;
  const newtoken = location.split('#').pop();
  const urlParams = new URLSearchParams(window.location.search);
  const darkModeParam = urlParams.get('darkMode');
  const removeToken = async () => {
    await removeAuthToken();
    history.push(`${routes.electionsWebViewToken}?removed=1&darkMode=${darkModeParam}#${newtoken}`);
    return true;
  };

  const setNewToken = async () => {
    await setAuthToken(newtoken, false);
    window.location.reload();
    return true;
  };

  const tokenRemoved = urlParams.get('removed');

  if (tokenRemoved && getAuthToken() !== null) {
    history.push(`${routes.electionsWebView}?darkMode=${darkModeParam}`);
  }

  if (!tokenRemoved) {
    removeToken();
    return (<></>);
  }

  setNewToken();
  return (<></>);
};

export default ElectionsWebViewToken;
