import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import HourglassIcon from '@material-ui/icons/HourglassEmpty';
import { toast } from 'react-toastify';
import { useQuery } from '../../hooks/useQuery';
import { withErrorReporting } from '../../utils/errors';
import ToastDialog from '../ToastDialog/ToastDialog';
import { language } from '../../utils/helpers';
import SpotlightTooltip from '../SpotlightTooltip/SpotlightTooltip';

const styles = makeStyles((theme) => ({
  okButton: {
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  spotlightToast: theme.toastDialog,
  iconCenter: {
    textAlign: 'center',
  },
}));

const Toggler = ({
  publisher,
  feedType,
  variables,
  active: initiallyActive,
  trueButton,
  falseButton,
  loadsContext,
  onToggle,
  onChanged,
  showTooltip,
  onToggleSubscription,
  ...params
}) => {
  const classes = styles();
  const userId = useSelector((state) => state.user.profile?.id);
  const [active, setActive] = useState(initiallyActive);
  const [subscriptionConfirmation, setSubscriptionConfirmation] = useState(null);

  let channelTitle = publisher?.title;
  if (publisher?.type === 'channel' || publisher?.type === 'topic') {
    channelTitle = language(publisher?.names);
  }

  useEffect(() => {
    onChanged(active);
  }, [onChanged, active]);

  useEffect(() => () => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    switch (subscriptionConfirmation) {
      case 'payment_required':
        toast.success((
          <>
            <Box className={classes.iconCenter}><CheckIcon fontSize="large" /></Box>
            <Typography paragraph align="center"><b>Subscribed</b></Typography>
            <Typography paragraph align="left"><b>{channelTitle}</b> is available on Spotlight Premium</Typography>
          </>
        ), {
          position: toast.POSITION.BOTTOM_CENTER,
          className: classes.spotlightToast,
          autoClose: 5000,
        });
        break;
      default:
        break;
    }

    return () => setSubscriptionConfirmation(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionConfirmation]);

  const type = feedType ? `${feedType}s` : 'channels';

  const query = useQuery(
    loadsContext,
    // eslint-disable-next-line no-shadow
    withErrorReporting(async (userId, status) => {
      await onToggle({ userId, active: status, ...variables, type });
      setActive(!active);
      if (onToggleSubscription) onToggleSubscription();
    }),
    {
      variables: [userId, !active, ...Object.values(variables)],
      enabled: false,
    },
  );

  const toggleSubscribeIcon = (query.isFetching) ? (
    <HourglassIcon />
  ) : active ? (
    trueButton
  ) : (
    falseButton
  );

  return (
    <>
      <SpotlightTooltip show={showTooltip} tooltipKey="channel-profile-subscription" text="Tap the + to add this publisher to My News">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            query.refetch();

            if (publisher.premium && publisher.subscription?.confirmation) {
              if (!active) {
                setSubscriptionConfirmation(publisher.subscription?.confirmation);
              } else {
                toast.success(
                  <ToastDialog
                    active={false}
                    deactivateIcon={<CancelIcon />}
                    deactivateText="Unsubscribed"
                    title={channelTitle}
                  />, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    className: classes.spotlightToast,
                    autoClose: 2500,
                  },
                );
              }
            } else if (!publisher.subscription?.confirmation) {
              toast.success(
                <ToastDialog
                  active={!active}
                  activateIcon={<CheckIcon fontSize="large" />}
                  deactivateIcon={<CancelIcon />}
                  activateText="Subscribed"
                  deactivateText="Unsubscribed"
                  title={channelTitle}
                />, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  className: classes.spotlightToast,
                  autoClose: 2500,
                },
              );
            }
          }}
          disabled={query.isFetching}
          {...params}
        >
          {toggleSubscribeIcon}
        </IconButton>
      </SpotlightTooltip>
    </>
  );
};

Toggler.propTypes = {
  publisher: PropTypes.shape(),
  variables: PropTypes.shape({}),
  active: PropTypes.bool.isRequired,
  trueButton: PropTypes.node.isRequired,
  falseButton: PropTypes.node.isRequired,
  loadsContext: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  onChanged: PropTypes.func,
  feedType: PropTypes.string,
  showTooltip: PropTypes.bool,
  onToggleSubscription: PropTypes.func,
};

Toggler.defaultProps = {
  variables: {},
  onChanged: () => { },
};

export default Toggler;
