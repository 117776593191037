import React from 'react';
import {
  Box, Button, Typography, makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import emptyGraphic from './empty-graphic.svg';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  graphic: {
    marginBottom: 34,
  },
  textRoot1: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: 9,
  },
  textRoot2: {
    fontSize: '16px',
    color: '#666',
    marginBottom: 34,
  },
}));

const MyNewsEmpty = () => {
  const history = useHistory();
  const classes = styles();

  const handleEmptyButtonClick = () => {
    history.push(routes.discover);
  };

  return (
    <Box className={classes.container}>
      <img src={emptyGraphic} alt="My News" className={classes.graphic} />
      <Typography classes={{ root: classes.textRoot1 }}>My News</Typography>
      <Typography classes={{ root: classes.textRoot2 }}>
        It&#39;s time to customize your newsfeed
      </Typography>
      <Button variant="contained" color="primary" onClick={handleEmptyButtonClick}>Get Started</Button>
    </Box>
  );
};

export default MyNewsEmpty;
