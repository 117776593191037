import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { toast } from 'react-toastify';
import { fetchUser, resendConfirmationEmail, setUserSession as setUserSessionAction, authenticate } from '../../actions/user';
import EmailIcon from '../../components/common/EmailIcon';
import routes from '../../routes';
import Form from '../../components/Form/Form';
import OrDivider from '../../components/Auth/OrDivider';
import Link from '../../components/common/Link';

const styles = makeStyles((theme) => ({
  boxContainer: {
    marginTop: 100,
    maxWidth: 530,
  },
  title: {
    marginTop: 20,
    marginBottom: 20,
    wordWrap: 'break-word',
  },
  subtitle: {
    marginBottom: 20,
  },
  button: {
    marginTop: 100,
    minWidth: 200,
  },
  buttonResend: {
    marginTop: 30,
    width: 290,
  },
  progressRoot: {
    width: '25px !important',
    height: '25px !important',
    marginRight: 15,
  },
  signIn: {
    marginTop: 30,
  },
  orDividerRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  signinLink: {
    marginBottom: theme.spacing(1.5),
  },
}));

const EmailConfirmation = ({ setUserSession }) => {
  const classes = styles();
  const history = useHistory();
  const [resendText, setResendText] = useState('Resend Confirmation Email');
  const emailToBeConfirmed = history.location.state?.emailToBeConfirmed;
  const password = history.location.state?.password;

  const dispatch = useDispatch();
  const handleClick = async () => {
    try {
      if (password) {
        const response = await dispatch(
          () => authenticate({ email: emailToBeConfirmed, password, dispatch, rememberMe: true }),
        );

        if (response?.user.is_onboarded === false) {
          history.push(routes.onboard.main);
          return;
        }
      }

      const user = await fetchUser();
      if (user.data['registered?']) {
        setUserSession({ ...user, email: user.data.email, username: user.data.username });
      }
    } catch (e) {
      console.log(e);
    }

    history.push(routes.news.main);
  };

  const handleClickResend = async () => {
    try {
      setResendText('Sending...');
      const response = await resendConfirmationEmail(emailToBeConfirmed);
      if (response.status === 'sent') {
        setResendText('Sent!');
        setTimeout(() => {
          setResendText('Resend Confirmation Email');
        }, 5000);
      }
    } catch (e) {
      setResendText('Resend Confirmation Email');
      toast.error(e.message);
    }
  };

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });
  const onSubmit = async (data) => {
    try {
      setResendText('Sending...');
      const response = await resendConfirmationEmail(data?.email);
      if (response.status === 'sent') {
        setResendText('Sent!');
        setTimeout(() => {
          setResendText('Resend Confirmation Email');
        }, 5000);
      }
    } catch (e) {
      setResendText('Resend Confirmation Email');
      toast.error(e.message);
    }
  };

  return (
    <Box className={classes.boxContainer}>
      <EmailIcon />
      {emailToBeConfirmed && (
        <>
          <Typography className={classes.title} variant="h5">Please confirm your email address<br />({emailToBeConfirmed})</Typography>
          <Typography className={classes.subTitle}>
            To confirm your account, check your email (including your Spam folder).<br />
            The confirmation email expires after 15 minutes.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            classes={{ root: classes.button }}
            onClick={handleClick}
          >
            Email Confirmed
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            classes={{ root: classes.buttonResend }}
            onClick={handleClickResend}
          >
            {resendText === 'Sending...' && (<CircularProgress classes={{ root: classes.progressRoot }} />)}
            {resendText}
          </Button>
        </>
      )}

      {!emailToBeConfirmed && (
        <>
          <Form
            validationSchema={SignInSchema}
            onSubmit={onSubmit}
            submittingLabel="Sending..."
          >
            {({ Submit }) => (
              <>
                <Typography align="left" className={classes.title} variant="h5">Resend confirmation email</Typography>
                <Typography align="left">
                  Enter your email and we&#x27;ll send you another confirmation email.
                </Typography>
                <Field
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  component={TextField}
                  variant="outlined"
                  classes={{ root: classes.inputRoot }}
                  margin="dense"
                  fullWidth
                  required
                />
                <Submit
                  variant="outlined"
                  color="primary"
                  size="large"
                  classes={{ root: classes.buttonResend }}
                >
                  {resendText === 'Sending...' && (<CircularProgress classes={{ root: classes.progressRoot }} />)}
                  {resendText}
                </Submit>
              </>
            )}
          </Form>

          <Box className={classes.signIn}>
            <OrDivider classes={{ root: classes.orDividerRoot }} />
            <Typography variant="body2" component="p" classes={{ root: classes.signinLink }}>
              Already have an account?&nbsp;
              <Link to={routes.auth.signin}>Sign In</Link>
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

const mapDispatchToProps = {
  setUserSession: setUserSessionAction,
};

EmailConfirmation.propTypes = {
  setUserSession: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(EmailConfirmation);
