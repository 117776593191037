import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CardMedia, makeStyles, Typography } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles.scss';
import useComponentWidth from '../../../hooks/useComponentWidth';
import { getOptimalSource } from '../../../utils/media';

const styles = makeStyles((theme) => ({
  carouselVerticalElement: {
    objectFit: 'contain',
  },
  carouselElementContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  imageDescription: {
    fontSize: 14,
    padding: 10,
    paddingBottom: 30,
    textAlign: 'left',
    color: '#fff',
    background: '#000000',
  },
  carouselContainer: {
    '& > * ul.control-dots': {
      maxHeight: 15,
    },
  },
}));

const HEIGHT_RATIOS = [
  3 / 4,
  6 / 9,
  9 / 16,
];

function closestRatio(ratio) {
  return HEIGHT_RATIOS.reduce((prev, curr) => { // eslint-disable-line arrow-body-style
    return Math.abs(curr - ratio) < Math.abs(prev - ratio) ? curr : prev;
  });
}

function MediaCarousel({ media }) {
  const classes = styles();
  const [showIndicators, setShowIndicators] = useState(true);
  const { componentRef, componentWidth } = useComponentWidth();

  const mediaItems = media
    .filter((item) => ['image', 'video'].includes(item.type) && item.sources.length > 0)
    .filter((item) => (item.sources[0].height * 3) > item.sources[0].width) // skip image strip
    .filter((item) => !item.src.includes('rmb.reuters.com'))
    .map((item) => ({
      id: item._id, // eslint-disable-line no-underscore-dangle
      type: item.type,
      source: getOptimalSource(item.sources, componentWidth),
      isVertical: item.sources[0].height > item.sources[0].width,
      ratio: item.sources[0].height / item.sources[0].width,
      description: item?.credit && item?.description
        ? `${item?.credit}<br>${item?.description}`
        : (item?.description ? item?.description : item?.credit),
    }))
    .filter((item) => item.source);

  const heightRatio = mediaItems.length && closestRatio(mediaItems[0].ratio);

  const handleChange = (index) => {
    setShowIndicators(mediaItems[index].type !== 'video');
    // pause video before showing next item
    [...componentRef.current.getElementsByTagName('video')].forEach((video) => {
      video.pause();
    });
  };

  const indicatorStyles = {
    top: 'calc(50% - 5px)',
    background: 'white',
    width: 8,
    height: 8,
    display: 'inline-block',
    borderRadius: '25px',
    flexDirection: 'row',
    margin: '0px 10px 0px 10px',
    opacity: '0.75',
    cursor: 'pointer',
  };

  return (
    <div ref={componentRef} className={classes.carouselContainer}>
      {mediaItems.length > 0 && (
        <Carousel
          key={componentWidth} // force carousel to render with new width
          useKeyboardArrows
          showArrows
          showThumbs={false}
          showStatus={false}
          showIndicators={showIndicators && mediaItems.length > 1}
          swipeable
          emulateTouch
          onChange={handleChange}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  style={{ ...indicatorStyles, opacity: '1' }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                style={indicatorStyles}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          {mediaItems.map((item) => (
            <>
              <Box
                key={`box-${item.id}`}
                height={Math.round(componentWidth * heightRatio)}
                className={classes.carouselElementContainer}
              >
                {item.type === 'image' ? (
                  <CardMedia
                    component="img"
                    src={item.source}
                    classes={{
                      root: item.isVertical && classes.carouselVerticalElement,
                    }}
                    key={`cardMedia-${item.id}`}
                  />
                ) : (
                  <CardMedia
                    component="video"
                    src={item.source}
                    controls
                    key={`cardMedia-${item.id}`}
                  />
                )}
              </Box>
              {item?.description && (
                <Typography
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                  className={classes.imageDescription}
                />
              )}
            </>
          ))}
        </Carousel>
      )}
    </div>
  );
}

MediaCarousel.propTypes = {
  media: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  })).isRequired,
};

export default MediaCarousel;
