import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import routes from '../routes';

const redirectMessage = 'You need to sign in or sign up before continuing.';

const useAuthentication = (callback) => {
  const isSignedIn = useSelector((state) => state.user.isSignedIn);
  const history = useHistory();

  if (!isSignedIn) {
    history.replace(routes.auth.signin, { flash: { message: redirectMessage, severity: 'warning' }, redirectPath: history.location.pathname });
    return null;
  }

  return callback();
};

export default useAuthentication;
