/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
  Link,
  Box,
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useQuery } from '../../hooks/useQuery';
import { downloadArticle } from '../../actions/download';
import LoadingIcon from '../common/LoadingIcon';

const styles = makeStyles((theme) => ({
  cardRoot: {
    background: theme.palette.background.default,
  },
  cardContentRoot: {
    padding: theme.spacing(2),
  },
  articleTitleRoot: {
    width: '100%',
    height: '85px',
    fontSize: '1.125rem',
    lineHeight: 1.5,
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h6.fontSize,
      height: 'auto',
      maxHeight: '85px',
    },
  },
  publisherParagraph: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  pdfIcon: {
    width: '100%',
    background: theme.palette.background.paper,
    fontSize: 200,
    padding: 40,
  },
  loadingIcon: {
    width: '100%',
    background: theme.palette.background.paper,
    padding: 71,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const DownloadCard = ({ download }) => {
  const classes = styles();

  const article = {
    id: download?.article_id,
    slug: download?.article_title
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, ''),
  };

  const downloadArticleQuery = useQuery('downloadArticle', downloadArticle, {
    variables: [article],
    enabled: false,
  });

  const handleClick = (event) => {
    event.preventDefault();
    downloadArticleQuery.refetch();
  };

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardActionArea component="div">
          <Link href="#" onClick={handleClick} color="inherit">
            {downloadArticleQuery.isFetching
              ? (
                <Box className={classes.loadingIcon}>
                  <LoadingIcon size={60} />
                </Box>
              )
              : (
                <PictureAsPdfIcon className={classes.pdfIcon} />
              )}
          </Link>
          <CardContent classes={{ root: classes.cardContentRoot }}>
            <Link href="#" onClick={handleClick} color="inherit">
              <Typography
                gutterBottom
                variant="h6"
                classes={{ root: classes.articleTitleRoot }}
              >
                {download.article_title}
              </Typography>
            </Link>
            <Typography
              variant="caption"
              classes={{ paragraph: classes.publisherParagraph }}
              paragraph
            >
              Publisher: {download.publisher_name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

DownloadCard.propTypes = {
  download: PropTypes.shape(),
};

export default DownloadCard;
