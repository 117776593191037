import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box } from '@material-ui/core';

function ErrorState({ retry, height }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height={height}>
      <Button onClick={retry} color="primary" variant="contained">Retry</Button>
    </Box>
  );
}

ErrorState.propTypes = {
  retry: PropTypes.func.isRequired,
  height: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};

export default ErrorState;
