import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const styles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dialog: {
    backgroundColor: theme.palette.background.paper,
    width: 1000,
    textAlign: 'center',
    position: 'relative',
    padding: '80px 10px',
    borderRadius: 20,
  },
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '& > svg': {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      top: 45,
    },
  },
  title: {
    textAlign: 'center',
    font: 'normal normal bold 24px/24px Roboto',
    letterSpacing: '-0.32px',
    color: theme.palette.text.primary,
    padding: '25px 70px',
    lineHeight: '36px',
  },
  button: {
    width: 350,
    padding: 10,
    fontFamily: 'Roboto',
    fontSize: 18,
    marginTop: 30,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
}));

const IntentDialog = ({ show, onCloseClick, message }) => {
  const classes = styles();

  return (
    <>
      <Modal
        open={show}
        className={classes.modal}
      >
        <Box className={classes.dialog}>
          <Box className={classes.closeIcon}><CloseIcon onClick={() => onCloseClick(false)} /></Box>
          <Typography className={classes.title}>{message}</Typography>
          <Box className={classes.buttonContainer}>
            <Button className={classes.button} variant="contained" color="primary" onClick={() => onCloseClick(true)}>Ok</Button>
            <Button className={classes.button} variant="contained" color="primary" onClick={() => onCloseClick()}>Cancel</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

IntentDialog.propTypes = {
  show: PropTypes.bool,
  onCloseClick: PropTypes.func,
  message: PropTypes.string,
};

export default IntentDialog;
