const initialState = {
  colleges: [],
  interests: [],
};

const adsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ONBOARDING_COLLEGES_ADD': {
      return {
        ...state,
        colleges: action.data,
        cancel: action.cancel,
      };
    }
    case 'ONBOARDING_INTERESTS_ADD': {
      return {
        ...state,
        interests: action.data,
      };
    }
    case 'CLEAR_COLLEGES': {
      return {
        ...state,
        colleges: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default adsReducer;
