import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ClickAwayListener, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getTooltipCookie, setTooltipCookie } from '../../utils/tooltip';

const styles = makeStyles((theme) => ({
  typography: {
    padding: '5px 5px',
    fontWeight: 300,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#37BC98',
    color: '#ffffff',
    maxWidth: 220,
    fontSize: 16,
  },
  arrow: {
    color: '#37BC98',
  },
}))(Tooltip);

const SpotlightTooltip = ({ children, tooltipKey, text, show, user }) => {
  const classes = styles();
  const tooltipCookie = getTooltipCookie(`spotlightTooltip-${tooltipKey}`);
  const [showTooltip, setShowTooltip] = useState(!tooltipCookie && show);

  const closeTooltip = () => {
    setTooltipCookie(`spotlightTooltip-${tooltipKey}`, true);
    setShowTooltip(false);
  };

  if (!user?.isSignedIn) return (<>{children}</>);

  return (
    <ClickAwayListener onClickAway={() => closeTooltip()}>
      <HtmlTooltip
        placement="left-start"
        arrow
        open={showTooltip}
        title={(
          <>
            <Typography className={classes.typography} color="inherit">{text}</Typography>
          </>
        )}
      >
        {children}
      </HtmlTooltip>
    </ClickAwayListener>
  );
};

SpotlightTooltip.propTypes = {
  children: PropTypes.shape(),
  text: PropTypes.string,
  tooltipKey: PropTypes.string,
  show: PropTypes.bool,
  user: PropTypes.shape(),
};

SpotlightTooltip.defaultProps = {
  show: true,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(SpotlightTooltip);
