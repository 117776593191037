import React from 'react';

const AppRedirect = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (userAgent.includes('Android')) {
    window.location.replace('https://play.google.com/store/apps/details?id=net.spotlightlabs.spotlight');
  } else if (userAgent.includes('iPhone')) {
    window.location.replace('https://apps.apple.com/us/app/spotlightnews/id1291820344');
  }

  return (<></>);
};

export default AppRedirect;
