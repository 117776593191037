import React from 'react';
import {
  Box,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';

const styles = makeStyles((theme) => ({
  typographyRoot: {
    position: 'relative',
    top: -12,
    display: 'inline-block',
    backgroundColor: theme.palette.background.default,
    paddingLeft: 20,
    paddingRight: 20,
    zIndex: 0,
    lineHeight: '16px',
    marginBottom: -12,
  },
}));

const OrDivider = (props) => {
  const classes = styles();
  return (
    <Box className={props.classes ? classes.root : ''} {...props}>
      <Divider />
      <Typography variant="body2" classes={{ root: classes.typographyRoot }}>or</Typography>
    </Box>
  );
};

export default OrDivider;
