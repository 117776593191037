import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import bgImageManager from '../utils/bgImageManager';
import {
  setBackgroundClass,
  clearBackgroundClass,
} from '../actions/app';

export default function useBackgroundClass(topicName) {
  const dispatch = useDispatch();
  useEffect(() => {
    const bgClass = bgImageManager(topicName && topicName.toLowerCase());
    if (bgClass) {
      dispatch(setBackgroundClass(bgClass));
    } else {
      dispatch(clearBackgroundClass());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicName]);
}
