import React from 'react';
import { fetchTopArticles } from '../../actions/news';
import ArticlePagination, { TopArticlesEmpty } from '../../components/Article/ArticlePagination';

const TopArticles = () => (
  <ArticlePagination
    loadsContext="topArticles"
    load={fetchTopArticles}
    label="Top Articles"
    EmptyStateComponent={TopArticlesEmpty}
  />
);

export default TopArticles;
