/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

const ArticlesSharedIcon = ({ color = '#37bc98', height = 50, width = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 38 32"
  >
    <g>
      <path
        fill={color}
        d="M36.5,0h-35C0.673,0,0,0.673,0,1.5v29C0,31.327,0.673,32,1.5,32h35c0.827,0,1.5-0.673,1.5-1.5v-29
   C38,0.673,37.327,0,36.5,0z M37,30.5c0,0.275-0.225,0.5-0.5,0.5h-35C1.225,31,1,30.775,1,30.5v-29C1,1.225,1.225,1,1.5,1h35
   C36.775,1,37,1.225,37,1.5V30.5z"
      />
      <path
        fill={color}
        d="M17.5,16h-11C5.673,16,5,16.673,5,17.5v8C5,26.327,5.673,27,6.5,27h11c0.827,0,1.5-0.673,1.5-1.5v-8
   C19,16.673,18.327,16,17.5,16z M18,25.5c0,0.275-0.225,0.5-0.5,0.5h-11C6.225,26,6,25.775,6,25.5v-8C6,17.225,6.225,17,6.5,17h11
   c0.275,0,0.5,0.225,0.5,0.5V25.5z"
      />
      <path
        fill={color}
        d="M31.5,5h-25C5.673,5,5,5.673,5,6.5v5C5,12.327,5.673,13,6.5,13h25c0.827,0,1.5-0.673,1.5-1.5v-5
   C33,5.673,32.327,5,31.5,5z M32,11.5c0,0.275-0.225,0.5-0.5,0.5h-25C6.225,12,6,11.775,6,11.5v-5C6,6.225,6.225,6,6.5,6h25
   C31.775,6,32,6.225,32,6.5V11.5z"
      />
      <path fill={color} d="M32,17H22c-0.276,0-0.5,0.224-0.5,0.5S21.724,18,22,18h10c0.276,0,0.5-0.224,0.5-0.5S32.276,17,32,17z" />
      <path fill={color} d="M32,21H22c-0.276,0-0.5,0.224-0.5,0.5S21.724,22,22,22h10c0.276,0,0.5-0.224,0.5-0.5S32.276,21,32,21z" />
      <path fill={color} d="M32,25H22c-0.276,0-0.5,0.224-0.5,0.5S21.724,26,22,26h10c0.276,0,0.5-0.224,0.5-0.5S32.276,25,32,25z" />
    </g>
  </svg>
);

ArticlesSharedIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ArticlesSharedIcon;
