import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import { toggleSubscription } from '../../actions/channel';
import InterestToggler from './InterestToggler';

const InterestSubscription = ({
  interest,
  isSubscribed,
  toggleSubscriptionAction,
  showTooltip,
  ...params
}) => (
  <InterestToggler
    loadsContext="toggleChannelSubscription"
    trueButton={<CheckCircleIcon titleAccess="Unsubscribe" />}
    falseButton={<AddIcon titleAccess="Subscribe" />}
    // eslint-disable-next-line no-underscore-dangle
    variables={{ interestId: interest?._id }}
    active={isSubscribed}
    interest={interest}
    onToggle={toggleSubscriptionAction}
    {...params}
    showTooltip={showTooltip}
  />
);

InterestSubscription.propTypes = {
  interest: PropTypes.shape(),
  isSubscribed: PropTypes.bool.isRequired,
  toggleSubscriptionAction: PropTypes.func,
  showTooltip: PropTypes.bool,
};

InterestSubscription.defaultProps = {
  toggleSubscriptionAction: ({ userId, interestId, active }) => (
    toggleSubscription(userId, 'topics', interestId, active)
  ),
};

export default InterestSubscription;
