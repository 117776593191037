import React from 'react';
import { connect } from 'react-redux';

import Logo from './Logo';

const SpotlightLogo = (props) => (
  <Logo {...props} />
);

const mapStateToProps = (state) => ({
  premium: state.user.profile?.subscription?.premium,
});

export default connect(mapStateToProps)(SpotlightLogo);
