import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from '../../routes';
import SignedOutLayout from '../SignedOutLayout';
import NavigationLayout from '../NavigationLayout';

const SmartRedirectLayout = ({ isSignedIn, component: Component, ...rest }) => {
  if (isSignedIn) {
    return (
      <NavigationLayout path={routes.elections} exact component={Component} {...rest} />
    );
  }
  return (
    <SignedOutLayout
      path={routes.elections}
      exact
      component={Component}
      maxWidth={1024}
      {...rest}
    />
  );
};

SmartRedirectLayout.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
};

const mapStateToProps = (state) => ({
  isSignedIn: state.user.isSignedIn,
});

export default connect(mapStateToProps)(SmartRedirectLayout);
