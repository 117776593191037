import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfiniteList from '../../common/InfiniteList';
import DeleteDialog from '../../common/DeleteDialog';
import ArticleCard, { ArticleCardSkeleton } from '../ArticleCard';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  boxRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 50,
    marginBottom: 27,
  },
  headingRoot: {
    marginBottom: 27,
  },
  articleCount: {
    opacity: 0.5,
    marginLeft: 10,
  },
}));

const ArticleCardList = ({
  articles, articleCount, label, isLoading,
  hasMore, loadMore, initialLoad,
  EmptyStateComponent, onEmptyButtonClick,
  onClearAllClick, channel, bigger, type,
}) => {
  const classes = styles({ ...channel });
  const [anchorEl, setAnchorEl] = useState(null);

  const renderArticles = () => {
    const colSize = bigger
      ? {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      }
      : {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      };
    if (isLoading && initialLoad) {
      return Array(12).fill().map((_, i) => (
        <Grid key={`article-skeleton-${i}`} item xs={colSize.xs} sm={colSize.sm} md={colSize.md} lg={colSize.lg}>
          <ArticleCardSkeleton />
        </Grid>
      ));
    }
    return articles.map((article, i) => (
      <Grid key={`article-card-${i}`} item xs={colSize.xs} sm={colSize.sm} md={colSize.md} lg={colSize.lg}>
        <ArticleCard type={type} article={article} />
      </Grid>
    ));
  };

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <Box className={classes.boxRoot}>
        <Typography variant="h6">
          {label}
          {!isLoading
            && <span className={classes.articleCount}>({articleCount})</span>}
        </Typography>
        {articles.length > 0 && onClearAllClick && (
          <DeleteDialog
            title="Are you sure you want to clear all items?"
            buttonLabel="Clear All"
            onConfirm={onClearAllClick}
          >
            {({ open }) => (
              <>
                <IconButton
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVertIcon classes={{ root: classes.iconColorClass }} />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                >
                  <MenuItem
                    onClick={open}
                    key="clear-all"
                  >
                    Clear All
                  </MenuItem>
                </Menu>
              </>
            )}
          </DeleteDialog>
        )}
      </Box>
      {articles.length === 0 && !isLoading
        ? <EmptyStateComponent onButtonClick={onEmptyButtonClick} />
        : (
          <InfiniteList isLoading={isLoading} loadMore={loadMore} hasMore={hasMore}>
            <Grid container spacing={3}>
              {renderArticles()}
            </Grid>
          </InfiniteList>
        )}
    </Container>
  );
};

ArticleCardList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  articleCount: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  initialLoad: PropTypes.bool.isRequired,
  EmptyStateComponent: PropTypes.elementType.isRequired,
  onEmptyButtonClick: PropTypes.func.isRequired,
  onClearAllClick: PropTypes.func,
  channel: PropTypes.shape(),
  bigger: PropTypes.bool,
  type: PropTypes.string,
};

export default ArticleCardList;
