import client from '../utils/client';

const fetchNotifications = async (userId, page = 1) => {
  const url = `/notifications/${userId}`;
  const response = await client().get(url, { params: { page } });
  const notifications = response?.data.map((notification) => ({
    publisher: {
      ...notification?.subject?.channel,
      subscription: {
        allowed: true,
      },
    },
    slug: notification?.subject?.slug,
    title: notification?.subject?.title,
    tidy_title: notification?.subject?.title,
    type: notification?.object.type,
    media: [{
      src: notification?.subject?.icon,
      type: 'image',
      sources: [{
        source_url: notification?.subject?.icon,
      }],
    }],
    // eslint-disable-next-line no-underscore-dangle
    _id: notification?.subject?._id,
    // eslint-disable-next-line no-underscore-dangle
    id: notification?.subject?._id,
    notificationId: notification?.id,
  }));

  return {
    totalCount: response.total_count,
    totalPages: response.total_pages,
    currentPage: response.current_page,
    articles: notifications,
  };
};

const readNotification = async (userId, notificationId) => {
  const url = `/notifications/${userId}/${notificationId}/read`;
  try {
    client().put(url);
  } catch (err) {
    if (err.status !== 404) {
      console.log('error');
    }
  }
};

const getNotificationsCount = async (userId) => {
  const url = `/notifications/${userId}/new/counter`;
  const response = await client().get(url);
  return response.count;
};

const clearAllNotifications = async (userId) => {
  const url = `/notifications/${userId}/read`;
  await client().put(url);
};

export {
  fetchNotifications, getNotificationsCount, clearAllNotifications, readNotification,
};
