import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import {
  fetchChannel,
  getChannelFeed,
  toggleChannelNotification,
} from '../../actions/channel';
import routes from '../../routes';
import ChannelProfile from '../../components/ChannelProfile';
import Loader from '../../components/common/Loader';
import { language } from '../../utils/helpers';
import { useQuery, usePaginationQuery } from '../../hooks/useQuery';
import ToastDialog from '../../components/ToastDialog/ToastDialog';
import trackEvent from '../../actions/events';
import SignUpOverlay from '../../components/Auth/SignUpOverlay';

const styles = makeStyles((theme) => ({
  spotlightToast: theme.toastDialog,
}));

const Channel = ({
  match, user, history, previousUrl,
}) => {
  const classes = styles();
  const channelId = match.params.slug;
  const perPage = 10;

  const urlParams = new URLSearchParams(window.location.search);
  const typeParam = urlParams.get('type');
  const valueParam = urlParams.get('value');
  const { data: channel } = useQuery('channelProfile', fetchChannel, {
    variables: [channelId, typeParam, valueParam],
  });

  const topicId = (match.params.topicId && match.params.topicId !== 'latest') ? match.params.topicId : channel?.topics[0]?.id;

  const { data, fetchMore, canFetchMore, isFetchingMore } = usePaginationQuery(
    'channelProfileFeed',
    getChannelFeed,
    {
      variables: [channelId, topicId, perPage],
    },
  );

  useEffect(() => {
    if ((typeParam || valueParam) && channel) {
      history.push({}, document.title, window.location.pathname);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeParam, valueParam, channel]);

  useEffect(() => () => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    if (!channel) return;
    trackEvent({
      event_type: 'Publisher View',
      publisher_id: channel?.id,
      timestamp: Math.ceil(new Date().valueOf() / 1000),
    });
  }, [channel]);

  const topics = useMemo(() => {
    if (!channel) return undefined;

    if (channel.topics.length === 0) {
      return [{
        index: 0,
        id: 'latest',
        name: 'Latest News',
        url: routes.publishers.topic(channelId, 'latest'),
      }];
    }
    return channel.topics.map((topic, idx) => ({
      index: idx + 1,
      id: topic.id,
      name: language(topic.names),
      shortName: language(topic.short_names),
      url: routes.publishers.topic(channelId, topic.id),
    }));
  }, [channelId, channel]);

  const handleNotificationClick = (isNotifiable) => {
    toggleChannelNotification(user.profile.id, channel.id, isNotifiable);

    toast.success(
      <ToastDialog
        active={isNotifiable}
        activateIcon={<NotificationsIcon fontSize="large" />}
        deactivateIcon={<NotificationsOffIcon fontSize="large" />}
        activateText="Enabled Notifications"
        deactivateText="Disabled Notifications"
        title={channel.title}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );
  };

  const handleBackClick = () => {
    const backFromPublisherPage = previousUrl?.pathname.includes('/publishers/');
    if (backFromPublisherPage || !previousUrl) {
      history.push(routes.news.main);
    } else {
      window.history.back();
    }
  };

  if (channel === undefined) {
    return <Loader />;
  }

  const articles = (data ?? []).flatMap((page) => page.articles);

  return (
    <>
      <ChannelProfile
        onBackClick={handleBackClick}
        channel={channel}
        selectedTopicId={topicId}
        topics={topics}
        feedIsLoading={Boolean(isFetchingMore)}
        articles={articles}
        hasMore={Boolean(canFetchMore)}
        loadMoreArticles={fetchMore}
        onNotificationClick={handleNotificationClick}
        isSignedIn={user.isSignedIn}
      />
      {!user.isSignedIn && <SignUpOverlay />}
    </>
  );
};

Channel.propTypes = {
  match: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  previousUrl: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(Channel));
