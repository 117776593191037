import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ViewListIcon from '@material-ui/icons/ViewList';
import PropTypes from 'prop-types';
import ElectionBar from './ElectionBar';
import { elections, getPercentages } from '../../actions/elections';
import ElectionTap from './ElectionTap';
import ElectionRecentChange from './ElectionRecentChange';
import ElectionCurrentStructure from './ElectionCurrentStructure';
import ElectionTopBar from './ElectionTopBar';
import { useQuery } from '../../hooks/useQuery';
import SpotlightTooltip from '../SpotlightTooltip/SpotlightTooltip';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    paddingTop: 21,
    paddingBottom: 21,
  },
  title: {
    fontSize: '1.725rem',
    marginTop: 15,
    marginBottom: 15,
  },
  center: {
    width: 500,
    margin: '0px auto',
    [theme.breakpoints.down('xs')]: {
      width: 340,
    },
  },
  currentStructure: {
    marginBottom: 10,
  },
  taps: {
    paddingTop: 25,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  buttons: {
    width: 530,
    display: 'flex',
    margin: '0px auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  legendTitle: {
    marginLeft: 5,
  },
}));

const ElectionDetail = ({ webView, match, darkModeActive }) => {
  const classes = styles();
  const history = useHistory();

  const { type } = match.params;

  const { data: electionData } = useQuery('elections', elections);

  if (!electionData) return (<></>);

  const electionDetailMap = Object.entries(electionData).map((election) => {
    if (election[1]?.name.toLowerCase().replace(' ', '-') === type) return election[1];
    return null;
  }).filter((el) => el != null);

  const electionDetail = electionDetailMap[0];

  const goToLegend = () => {
    if (webView) {
      const urlWV = (darkModeActive) ? `${routes.electionsLegendWebView}?darkMode=1` : routes.electionsLegendWebView;
      history.push(urlWV);
      return;
    }

    history.push(routes.electionsLegend);
  };

  const getCurrentStructureTitle = () => {
    if (type === 'us-house') return '118th Congress';
    if (type === 'us-senate') return 'Current Senate Structure';
    if (type === 'governors') return 'Current Governor Structure';
    return null;
  };

  const getTitle = () => {
    if (type === 'us-house') return 'House';
    if (type === 'us-senate') return 'Senate';
    return electionDetail?.name;
  };

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <ElectionTopBar
        title={getTitle()}
        lastUpdate={electionDetail?.polled_at}
        lastRatingChanged={electionDetail?.last_rating_changed_at}
      />
      <Box className={classes.buttons} style={{ justifyContent: webView ? 'flex-end' : 'space-between' }}>
        {!webView && (
          <Button
            onClick={() => window.history.back()}
          >
            <ArrowBackIcon />
            <Typography className={classes.legendTitle}>Back</Typography>
          </Button>
        )}
        <Button onClick={goToLegend}>
          <ViewListIcon />
          <Typography className={classes.legendTitle}>Legend</Typography>
        </Button>
      </Box>
      <Box className={classes.center}>
        {type !== 'electoral-college' && (
          <Box className={classes.currentStructure}>
            <Typography className={classes.title}>{getCurrentStructureTitle()}</Typography>

            <ElectionCurrentStructure type={type} />
          </Box>
        )}
        <Box className={classes.ratings}>
          <Typography className={classes.title}>2024 CPR Race Ratings</Typography>
          <ElectionBar
            type={electionDetail?.name}
            percentages={getPercentages(electionDetail)}
          />
        </Box>
        {electionDetail?.ratings && (
          <Box className={classes.tapForDetail}>
            <SpotlightTooltip tooltipKey="elections-tap" text="Tap/Click in the boxes below to see more information.">
              <Typography className={classes.title}>Tap For Detail</Typography>
            </SpotlightTooltip>
            <Box className={classes.taps}>
              <ElectionTap darkModeActive={darkModeActive} electionData={electionDetail} type="Toss" webView={webView} />
              <ElectionTap darkModeActive={darkModeActive} electionData={electionDetail} type="Lean" webView={webView} />
              <ElectionTap darkModeActive={darkModeActive} electionData={electionDetail} type="Likely" webView={webView} />
              <ElectionTap darkModeActive={darkModeActive} electionData={electionDetail} type="Solid" webView={webView} />
            </Box>
          </Box>
        )}
        {electionDetail?.recent_changes?.length > 0 && (
          <Box className={classes.recentChanges}>
            <Typography className={classes.title}>Recent Changes</Typography>
            <ul>
              {Object.entries(electionDetail?.recent_changes).map((recData) => (
                <li>
                  <ElectionRecentChange data={recData[1]} />
                </li>
              ))}
            </ul>
          </Box>
        )}
      </Box>
    </Container>
  );
};

ElectionDetail.propTypes = {
  webView: PropTypes.bool,
  match: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  darkModeActive: PropTypes.bool,
};

export default ElectionDetail;
