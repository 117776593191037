import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  makeStyles,
  Tabs,
} from '@material-ui/core';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import NavLinkTab from '../../Nav/NavLinkTab';
import { toggleChannelTopicSubscription } from '../../../actions/topics';
import { useQuery } from '../../../hooks/useQuery';
import { fetchUserFeeds } from '../../../actions/news';
import SpotlightTooltip from '../../SpotlightTooltip/SpotlightTooltip';

const styles = makeStyles((theme) => ({
  topicsProgressWrapper: {
    textAlign: 'center',
  },
  topicsProgressRoot: {
    margin: 13,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  topicsNavLinkTabRoot: {
    fontSize: 16,
    lineHeight: '56px',
  },
  scrollButtonRoot: {
    color: theme.palette.text.primary,
  },
  followButton: {
    marginLeft: 10,
    background: theme.palette.background.paper,
  },
  followButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
  },
  progressContainer: {
    width: 50,
  },
}));

const TopicNavigation = ({
  isLoading, topics, selectedTopicId, width, userId,
}) => {
  const classes = styles();
  const [isSubscribed, setIsSubscribed] = useState(null);

  const userFeedsQuery = useQuery('fetchUserFeeds', fetchUserFeeds, {
    variables: [userId],
  });

  const { data: userFeeds } = userFeedsQuery;

  const currentTopic = topics.find((topic) => topic.id === selectedTopicId);

  useEffect(() => {
    userFeedsQuery.refetch();
    // eslint-disable-next-line no-underscore-dangle
    setIsSubscribed(userFeeds?.filter((uF) => uF.type === 'topic' && uF.subject._id === selectedTopicId)?.shift());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopicId, userFeeds]);

  const a11yProps = (index) => ({
    id: `topics-tab-${index}`,
    'aria-controls': `topics-tab-${index}`,
  });

  const getSelectedTabIndex = () => {
    const selected = topics.findIndex((topic) => topic.id === selectedTopicId);
    return selected !== -1 ? selected : 0;
  };
  /* eslint-disable */
  const getScrollableClass = () => {
    let topicCharsLength = 0;
    topics.forEach((topic) => {
      topicCharsLength += topic.name.length;
    });
    if (topicCharsLength >= 150) {
      return "scrollable";
    }
    return isWidthUp('sm', width) ? "standard" : "scrollable";
  }
  /* eslint-disable */
  const renderNavLinkTabs = () => (
    topics.map((topic) => (
      <NavLinkTab
        key={`topics-tab-${topic.id}`}
        exact
        to={topic.url}
        label={topic?.shortName ? topic?.shortName : topic?.name}
        classes={{ root: classes.topicsNavLinkTabRoot }}
        disableRipple
        {...a11yProps(topic.id)}
      />
    )
    ));

  if (isLoading) {
    return (
      <div className={classes.topicsProgressWrapper}>
        <CircularProgress classes={{ root: classes.topicsProgressRoot }} />
      </div>
    )
  }


  return (
    <>
      <Tabs
        value={getSelectedTabIndex()}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="on"
        classes={{ scrollButtons: classes.scrollButtonRoot }}
      >
        {renderNavLinkTabs()}
      </Tabs>

      <Divider />

      <Box className={classes.followButtonContainer}>
        {userFeedsQuery.isFetching
          ? (
            <Box className={classes.followButtonContainer}>
              <div key="loader" className={classes.progressContainer}>
                <CircularProgress />
              </div>
            </Box>
          )
          : (
            <>
              {currentTopic && (
                <>
                  <Box>{isSubscribed ? 'Following' : 'Follow'} - </Box>
                  <SpotlightTooltip tooltipKey="channel-topic-subscription" text="Only interested in this category? Tap to follow only this section from this publisher">
                    <Button
                      className={classes.followButton}
                      style={isSubscribed ? { backgroundColor: '#37BC98', color: '#ffffff' } : {}}
                      onClick={async () => {
                        await toggleChannelTopicSubscription(userId, currentTopic?.id, isSubscribed);
                        setIsSubscribed(!isSubscribed);
                      }}
                    >
                      {currentTopic?.name}
                    </Button>
                  </SpotlightTooltip>
                </>
              )}
            </>
          )}
      </Box >
    </>
  )
}

const mapStateToProps = (state) => ({
  userId: state.user?.profile?.id,
});

TopicNavigation.propTypes = {
  isLoading: PropTypes.bool,
  topics: PropTypes.arrayOf(PropTypes.shape()),
  selectedTopicId: PropTypes.string,
  width: PropTypes.string,
  userId: PropTypes.string,
};


export default withWidth()(connect(mapStateToProps)(TopicNavigation));
