import React from 'react';
import PropTypes from 'prop-types';

import {
  Divider,
  Hidden,
  Grid,
  makeStyles,
} from '@material-ui/core';

import { ArticleCardSkeleton } from '../ArticleCard';
import ArticleList from '../ArticleList';

const styles = makeStyles((theme) => ({
  headersRoot: {
    marginTop: 12,
    marginBottom: 12,
  },
  latestRoot: {
    marginBottom: 32,
    marginTop: 16,
    display: 'block',
    '& > section': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& > section > div.MuiGrid-grid-md-3': {
      padding: 10,
    },
  },
  emptyDivider: {
    marginBottom: 32,
  },
  bannerWrapperRoot: {
    margin: '15px auto',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

const ArticleFeed = ({
  feedIsLoading, articles, hasMore,
  loadMore, EmptyStateComponent,
  topic, channel,
}) => {
  const classes = styles();
  const showPlaceholders = articles.length === 0 && feedIsLoading;

  if (!feedIsLoading && articles.length === 0) {
    return (
      <>
        <Divider classes={{ root: classes.emptyDivider }} />
        {EmptyStateComponent && <EmptyStateComponent />}
      </>
    );
  }

  return (
    <>
      {/* Desktop View */}
      <Hidden smDown>
        <Grid container wrap="wrap" spacing={3} classes={{ root: classes.latestRoot }}>
          <ArticleList
            showPlaceholders={showPlaceholders}
            articles={articles}
            isLoading={feedIsLoading}
            hasMore={hasMore}
            loadMore={loadMore}
            key="article-list-desktop"
          />
        </Grid>
      </Hidden>

      {/* Mobile View */}
      <Hidden mdUp>
        {showPlaceholders && <ArticleCardSkeleton />}
        <ArticleList
          showPlaceholders={showPlaceholders}
          articles={articles}
          isLoading={feedIsLoading}
          hasMore={hasMore}
          loadMore={loadMore}
          key="article-list-mobile"
        />
      </Hidden>
    </>
  );
};

ArticleFeed.propTypes = {
  feedIsLoading: PropTypes.bool.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  EmptyStateComponent: PropTypes.elementType,
  topic: PropTypes.shape(),
  channel: PropTypes.shape(),
};

export default ArticleFeed;
