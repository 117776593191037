import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { searchArticlesByKeyword } from '../../actions/news';
import ArticlePagination, { TopArticlesEmpty } from '../Article/ArticlePagination';
import SearchInfoDialog from './SearchInfoDialog';
import SearchField from '../common/SearchField';
import InterestCustom from '../Interest/InterestCustom';
import { useQuery } from '../../hooks/useQuery';
import { searchInterests } from '../../actions/topics';
import { getSearchHistory, saveSearchExpression } from '../../actions/search';

const styles = makeStyles((theme) => ({
  discoverHeaderRoot: {
    marginBottom: 30,
  },
  searchContainer: {
    textAlign: 'center',
    marginBottom: 30,
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
  },
  articleContainer: {
    marginTop: -40,
  },
  searchForArticleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchIcon: {
    fontSize: 100,
  },
  noSearchTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  infoOutlinedIcon: {
    marginLeft: 20,
    cursor: 'pointer',
  },
  tips: {
    textAlign: 'center',
    color: '#37BC98',
    fontWeight: 'bold',
    marginBottom: 15,
    display: 'block',
  },
  spacer: {
    marginBottom: 20,
  },
}));

const DiscoverArticles = ({
  searchText,
  updateSearchText,
  userId,
}) => {
  const classes = styles();
  const [showDialog, setShowDialog] = useState(false);
  const [currentSearchValue, setCurrentSearchValue] = useState(null);
  const [saveText, setSaveText] = useState(null);

  const onCloseSearchInfoDialog = () => {
    setShowDialog(false);
  };

  const saveSearchExpressionQuery = useQuery('saveSearchExpressionArticle', saveSearchExpression, {
    variables: [userId, 'articles', saveText],
    enabled: false,
  });

  const getSearchExpressionQuery = useQuery('getSearchExpressionArticles', getSearchHistory, {
    variables: [userId, 'articles'],
  });
  const expressions = getSearchExpressionQuery?.data?.history;
  const history = currentSearchValue
    ? expressions.filter((ex) => ex.expression.includes(currentSearchValue))
    : expressions;

  useEffect(() => {
    if (saveSearchExpressionQuery.isFetched) {
      getSearchExpressionQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSearchExpressionQuery.isFetched]);

  useEffect(() => {
    if (saveText) {
      saveSearchExpressionQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveText]);

  const onSearchChange = (value) => {
    updateSearchText(value);
    setSaveText(value);
  };

  const clearSearch = () => {
    updateSearchText(null);
  };

  const { data } = useQuery('interestsSelectArticleSearch', () => {
    if (searchText) {
      return searchInterests(searchText, 1);
    }
    return null;
  }, {
    variables: [searchText],
  });
  const interests = data?.topics ?? [];

  const label = `Results for ${searchText}`;

  return (
    <>
      <SearchInfoDialog show={showDialog} onCloseClick={onCloseSearchInfoDialog} />
      <Box className={classes.discoverHeaderRoot}>
        <Box className={classes.searchContainer}>
          {getSearchExpressionQuery.isFetched && (
            <SearchField
              placeholder="Search Articles"
              clearSearch={clearSearch}
              onSearchChange={onSearchChange}
              style={{ marginTop: 0, width: '100%' }}
              searchFieldStyle={{ width: '100%' }}
              fieldValue={searchText}
              autocomplete
              setCurrentSearchValue={setCurrentSearchValue}
              type="articles"
              expressions={history}
              allExpressions={expressions}
              refreshExpressions={() => getSearchExpressionQuery.refetch()}
            />
          )}
        </Box>
        <Box>
          <Link target="_blank" href="https://spotlightnews.us/learn-more.html" className={classes.tips}>Helpful tips on how to search</Link>
        </Box>
      </Box>

      {(interests && interests.length < 1 && searchText)
        && (
          <>
            <InterestCustom
              searchQuery={searchText}
              discover
              preview={false}
            />
            <Box className={classes.spacer} />
          </>
        )}

      {searchText
        ? (
          <Box className={classes.articleContainer}>

            <ArticlePagination
              loadsContext={`searchArticles-${searchText}`}
              load={(guid, page) => searchArticlesByKeyword(searchText, page)}
              label={label}
              EmptyStateComponent={TopArticlesEmpty}
              key={`searchArticles-${searchText}`}
            />

          </Box>
        )
        : (
          <Box className={classes.searchForArticleContainer}>
            <SearchIcon className={classes.searchIcon} />
            <Typography className={classes.noSearchTitle}>Search Articles</Typography>
          </Box>
        )}

    </>
  );
};

DiscoverArticles.propTypes = {
  searchText: PropTypes.string,
  updateSearchText: PropTypes.func,
  userId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    userId: state.user.profile.id,
  };
}

export default connect(mapStateToProps)(DiscoverArticles);
