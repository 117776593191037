import React from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { authenticateDelete } from '../../actions/user';

import routes from '../../routes';
import SignInForm from '../../components/Auth/SignInForm';
import DeleteAccountContent from '../../components/DeleteAccount/DeleteAccountContent';
import config from '../../config';

const DeleteAccountVerification = () => {
  const history = useHistory();

  const handleSubmit = async (data) => {
    try {
      const loginData = await authenticateDelete(data);
      history.push(routes.auth.deleteAccount, {
        deleteToken: loginData?.data?.token,
      });
    } catch (e) {
      Cookies.remove(config.tokenKeyDelete, { domain: '.spotlightnews.us' });
      history.push(routes.auth.deleteAccountVerification, { flash: { message: e, severity: 'warning' }, logout: true });
    }
  };

  // eslint-disable-next-line max-len
  const handleGuestLogin = () => history.push(routes.onboard.main, { redirectPath: routes.auth.deleteAccount });

  return (
    <>
      <DeleteAccountContent />
      <SignInForm onSubmit={handleSubmit} handleGuestLogin={handleGuestLogin} deleteAccount />
    </>
  );
};

export default DeleteAccountVerification;
