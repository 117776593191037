import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import { language } from '../../utils/helpers';
import WriterIcon from '../../components/Writer/WriterIcon/WriterIcon';

const styles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const MyNewsWriterTitle = ({ writer }) => {
  const classes = styles();

  return (
    <Box className={classes.title}>
      <WriterIcon />
      <span>{language(writer.names)}</span>
    </Box>
  );
};

MyNewsWriterTitle.propTypes = {
  writer: PropTypes.shape().isRequired,
};

export default MyNewsWriterTitle;
