import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, makeStyles } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useQuery } from '../../hooks/useQuery';
import { setDefaultPaymentMethod } from '../../actions/subscription';
import ThemeContext from '../../context/theme/ThemeContext';

const styles = makeStyles((theme) => ({
  button: {
    width: 350,
    padding: 10,
    fontFamily: 'Roboto',
    fontSize: 18,
    marginTop: 30,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  formMessage: {
    color: 'red',
    padding: 20,
  },
  successMessage: {
    color: 'green',
    padding: 20,
  },
}));

const optionsDark = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: '500',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#FFF',
      },
      '::placeholder': {
        color: '#FFF',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
};

const options = {
  style: {
    base: {
      fontWeight: '500',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    },
  },
};

const UpdateCardCheckoutForm = ({ userId, onCloseClick, clientSecret, refreshState }) => {
  const classes = styles();
  const [formCompleted, setFormCompleted] = useState(false);
  const [formMessage, setFormMessage] = useState(false);
  const [queryVariables, setQueryVariables] = useState([]);
  const darkMode = useContext(ThemeContext);

  const setDefaultPaymentQuery = useQuery('setDefaultPaymentQuery', setDefaultPaymentMethod, {
    variables: queryVariables,
    enabled: false,
  });
  if (setDefaultPaymentQuery?.data?.id) {
    setTimeout(() => {
      onCloseClick();
      refreshState();
    }, 3000);
  }

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    }).then((result) => {
      setQueryVariables(
        [userId, result?.setupIntent?.payment_method],
      );
    });
  };

  useEffect(() => {
    if (queryVariables.length > 1) {
      setDefaultPaymentQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVariables]);

  const handleChange = (event) => {
    setFormMessage(event?.error?.message);
    setFormCompleted(Boolean(event?.complete));
  };

  return (
    <form onSubmit={handleSubmit}>

      {setDefaultPaymentQuery?.data?.id && (
        <Box className={classes.successMessage}>Card updated successfully</Box>
      )}

      <Box className={classes.formMessage}>{formMessage}</Box>

      <CardElement
        onChange={handleChange}
        options={darkMode?.darkMode ? optionsDark : options}
      />

      <Box className={classes.buttonContainer}>
        <Button className={classes.button} variant="contained" disabled={!formCompleted} type="submit" color="primary">Ok</Button>
        <Button className={classes.button} variant="contained" type="button" onClick={onCloseClick} color="primary">Cancel</Button>
      </Box>
    </form>
  );
};

UpdateCardCheckoutForm.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  refreshState: PropTypes.func.isRequired,
};
export default UpdateCardCheckoutForm;
