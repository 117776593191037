import uaParser from 'ua-parser-js';
import { fetchUser, setUserSession } from './user';
import { setAuthTokenFromCookie } from '../utils/authToken';
import trackEvent from './events';

const setInitialized = () => ({
  type: 'SET_INITIALIZED',
});

const setBackgroundClass = (image) => ({
  type: 'SET_BACKGROUND_IMAGE',
  image: `url(${image})`,
});

const clearBackgroundClass = () => ({
  type: 'SET_BACKGROUND_IMAGE',
  image: 'none',
});

const initialize = () => {
  setAuthTokenFromCookie();

  return async (dispatch) => {
    try {
      const response = await fetchUser();
      await dispatch(setUserSession(response.data));
    } catch (error) {
      console.log(error);
    }

    const ua = uaParser(navigator.userAgent);
    trackEvent({
      event_type: 'App Open',
      open_type: 'Normal',
      os_type: ua?.os?.name,
      os_version: ua?.os?.version,
      device_type: ua?.device?.model,
      // country_code: "",
      preferred_language: window.navigator.userLanguage || window.navigator.language,
      // app_version: "",
      timestamp: Math.ceil(new Date().valueOf() / 1000),
    });

    dispatch(setInitialized());
  };
};

export { initialize, setBackgroundClass, clearBackgroundClass };
