import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import routes from '../../routes';
import SpotlightLogo from '../common/SpotlightLogo';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  publisherLogo: {
    height: 80,
    backgroundSize: '80px',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'border-box',
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: '-0.32px',
    color: theme.palette.text.primary,
    marginTop: 30,
    marginBottom: 15,
  },
  paragraph: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    color: theme.palette.text.primary,
  },
  button: {
    marginTop: 15,
    color: '#ffffff !important',
  },
}));

const PremiumAboveLimitMessage = ({ channel }) => {
  const classes = styles();

  return (
    <>
      <Box className={classes.container}>
        {channel && (<Box className={classes.publisherLogo} style={{ backgroundImage: `url('${channel.avatar}')` }} />)}
        {!channel && (<Box className={classes.iconCenter}><SpotlightLogo small /></Box>)}
        <Typography className={classes.title}>
          You&apos;ve reached your monthly limit!
        </Typography>
        <Typography className={classes.paragraph}>Like what you&apos;re reading?</Typography>
        <Typography className={classes.paragraph}>
          Get unlimited access <strong>{channel && (`to ${channel.username}`)}</strong> and
          numerous other paywalled publication by upgrading to a Premium subscription.
        </Typography>
        <Button className={classes.button} href={routes.premium} component={Link} variant="contained" color="primary">Upgrade</Button>
      </Box>
    </>
  );
};

PremiumAboveLimitMessage.propTypes = {
  channel: PropTypes.shape(),
};

export default PremiumAboveLimitMessage;
