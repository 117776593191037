import { Box, List, ListItem, ListItemText, ListItemIcon, makeStyles, RootRef } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import MyNewsTopicTitle from '../../containers/MyNews/MyNewsTopicTitle';
import { language } from '../../utils/helpers';
import LoadingIcon from '../common/LoadingIcon';

const styles = makeStyles((theme) => ({
  listDrag: {
    backgroundColor: theme.palette.background.paper,
  },
  listContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  listItem: {
    padding: 0,
    borderTop: `1px solid ${theme.palette.text.primary}`,
  },
  listItemText: {
    marginTop: 12,
    marginBottom: 12,
  },
  icons: {
    minWidth: 'auto',
  },
  iconsDrag: {
    color: '#ffffff',
  },
  reorderIcon: {
    color: theme.palette.text.primary,
  },
  reorderIconDragging: {
    color: '#ffffff',
  },
  loading: {
    display: 'flex',
    padding: '15px 0px',
    justifyContent: 'center',
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,

  ...(isDragging && {
    background: '#37bc98',
    color: '#ffffff',
    paddingLeft: 10,
    border: 'none',
  }),
});

const SortableList = ({ items: _items, isLoading, onChangeOrder, EmptyListComponent }) => {
  const classes = styles();
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(_items ?? []);
  }, [_items]);

  const onDragStart = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const orderedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    setItems(orderedItems);
    // eslint-disable-next-line no-underscore-dangle
    const orderedItemsIds = orderedItems.map((orderedItem) => orderedItem?._id);
    onChangeOrder(orderedItemsIds);
  };

  return isLoading ? (
    <Box className={classes.loading}><LoadingIcon /></Box>
  ) : items.length ? (
    <Box>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <List className={snapshot.isDraggingOver ? classes.listDrag : ''}>
                {items.map((item, index) => (
                  // eslint-disable-next-line no-underscore-dangle
                  <Draggable key={item._id} draggableId={item._id} index={index}>
                    {(providedDrag, snapshotDrag) => (
                      <ListItem
                        className={classes.listItem}
                        ref={providedDrag.innerRef}
                        {...providedDrag.draggableProps}
                        {...providedDrag.dragHandleProps}
                        style={getItemStyle(
                          snapshotDrag.isDragging,
                          providedDrag.draggableProps.style,
                        )}
                      >
                        <Box className={classes.listContent}>
                          <ListItemText
                            primary={(item.type === 'topic' ? <MyNewsTopicTitle topic={item} /> : language(item.names))}
                            className={classes.listItemText}
                          />
                          <ListItemIcon
                            className={snapshotDrag.isDragging ? classes.iconsDrag : classes.icons}
                          >
                            <ImportExportIcon
                              className={
                                snapshotDrag.isDragging
                                  ? classes.reorderIconDragging : classes.reorderIcon
                              }
                            />
                          </ListItemIcon>
                        </Box>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  ) : (
    <EmptyListComponent />
  );
};

SortableList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChangeOrder: PropTypes.func.isRequired,
  EmptyListComponent: PropTypes.elementType.isRequired,
};

export default SortableList;
