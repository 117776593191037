import React from 'react';
import useBackgroundClass from '../../hooks/useBackgroundClass';
import ElectionsLegendList from '../../components/Elections/ElectionsLegendList';

const ElectionsLegend = () => {
  useBackgroundClass('elections');
  return (<ElectionsLegendList />);
};

export default ElectionsLegend;
