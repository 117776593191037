import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Container, makeStyles, Tab, Tabs } from '@material-ui/core';
import routes from '../../routes';
import Subscriptions from '../../components/Subscriptions';
import SubscriptionsEmpty from '../../components/SubscriptionsEmpty';
import { fetchAllSubscriptions } from '../../actions/channel';
import { useQuery } from '../../hooks/useQuery';
import useBackgroundClass from '../../hooks/useBackgroundClass';
import { fetchWritersSubscription } from '../../actions/writers';
import { fetchUserInterests } from '../../actions/topics';

const styles = makeStyles((theme) => ({
  containerRoot: {
    paddingTop: 15,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tabItem: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      padding: 5,
    },
    '& > .MuiTab-wrapper': {
      minWidth: 80,
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

const MyLibrary = ({ userId }) => {
  const classes = styles();

  const a11yProps = (index) => ({
    id: `discover-tab-${index}`,
    'aria-controls': `discover-tab-${index}`,
  });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const history = useHistory();
  const handleEmptyButtonClick = (h) => {
    h.push(routes.discover);
  };
  useBackgroundClass('default');

  const subscriptionsQuery = useQuery('mySubscriptions', fetchAllSubscriptions, {
    variables: [userId],
  });
  const subscriptions = subscriptionsQuery.data;

  const subscriptionsWritersQuery = useQuery('fetchWritersSubscription', fetchWritersSubscription, {
    variables: [userId],
  });
  const subscriptionsWriters = subscriptionsWritersQuery.data;

  const subscriptionsInterestsQuery = useQuery('fetchInterestsSubscription', fetchUserInterests, {
    variables: [userId],
  });
  const subscriptionsInterests = subscriptionsInterestsQuery.data;

  const refetchSubscriptions = () => {
    subscriptionsQuery.refetch();
  };

  const refetchWritersSubscriptions = () => {
    subscriptionsWritersQuery.refetch();
  };

  const refetchInterestsSubscriptions = () => {
    subscriptionsInterestsQuery.refetch();
  };

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <Box className={classes.tabContainer}>
        <Tabs value={value} onChange={handleChange} aria-label="mysubscriptions-tabs">
          <Tab key="tab-publishers" className={classes.tabItem} label="Publishers" {...a11yProps(0)} />
          <Tab key="tab-interests" className={classes.tabItem} label="Topics" {...a11yProps(1)} />
          <Tab key="tab-writers" className={classes.tabItem} label="Writers" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel key="tabPanel-publishers" value={value} index={0}>
        {subscriptions
          ? (
            <Subscriptions
              subscriptions={subscriptions}
              EmptyStateComponent={SubscriptionsEmpty}
              onEmptyButtonClick={() => handleEmptyButtonClick(history)}
              refetchSubscriptions={refetchSubscriptions}
              type="channels"
            />
          ) : (<></>)}
      </TabPanel>
      <TabPanel key="tabPanel-interests" value={value} index={1}>
        {subscriptionsInterests
          ? (
            <Subscriptions
              subscriptions={subscriptionsInterests}
              EmptyStateComponent={SubscriptionsEmpty}
              onEmptyButtonClick={() => handleEmptyButtonClick(history)}
              refetchSubscriptions={refetchInterestsSubscriptions}
              type="interests"
            />
          ) : (<></>)}
      </TabPanel>
      <TabPanel key="tabPanel-writers" value={value} index={2}>
        {subscriptionsWriters
          ? (
            <Subscriptions
              subscriptions={subscriptionsWriters}
              EmptyStateComponent={SubscriptionsEmpty}
              onEmptyButtonClick={() => handleEmptyButtonClick(history)}
              refetchSubscriptions={refetchWritersSubscriptions}
              type="writers"
            />
          ) : (<></>)}
      </TabPanel>
    </Container>

  );
};

const mapStateToProps = (state) => ({
  userId: state.user.profile.id,
});

MyLibrary.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default withRouter(connect(mapStateToProps)(MyLibrary));
