import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography, Divider, makeStyles, Container, Modal, Button } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { connect } from 'react-redux';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  premiumBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  premiumBoxLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  premiumBoxBg: {
    zIndex: 10,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderTop: '1px solid rgba(112, 112, 112, .2)',
    position: 'sticky',
    top: '68px',
  },
  premiumText: {
    letterSpacing: '2.2px',
    font: 'Bold 11px/13px Roboto',
    padding: '12px 21px',
    textTransform: 'capitalize',
  },
  tryFreeText: {
    font: 'Italic 14px/16px Roboto',
    letterSpacing: '0.18px',
    color: theme.palette.text.secondary,
    padding: '12px 21px',
  },
  meter: {
    font: 'Bold 14px/16px Roboto',
    color: theme.palette.text.primary,
    marginLeft: '12px',
  },
  infoIcon: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalDialog: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    position: 'relative',
    width: 500,
    outline: 'none',
    padding: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 8,
    top: 8,
  },
  listItem: {
    textAlign: 'left',
    display: 'flex',
    marginTop: 10,
    marginBottom: 10,
    '& > p': {
      marginLeft: 15,
      color: theme.palette.text.primary,
    },
  },
  titleDialog: {
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 15,
    color: theme.palette.text.primary,
  },
  button: {
    marginTop: 15,
    color: '#ffffff !important',
  },
}));

const Meter = ({ meterLimit, meterValue, className }) => {
  const left = meterLimit - meterValue;
  const prefix = left > 0 ? (
    left > 1 ? `${left} free articles are` : `${left} free article is`
  ) : 'No free articles are';
  const title = `${prefix} left for this month`;
  return (
    <span className={className} title={title}>
      {`${String(meterValue).padStart(String(meterLimit).length, '0')}/${meterLimit}`}
    </span>
  );
};

const PremiumBar = ({ publisher, user }) => {
  const classes = styles();
  const [openModal, setOpenModal] = useState(false);

  const subscription = publisher?.subscription;

  const {
    type,
    meter_limit: meterLimit,
    meter_value: meterValue,
  } = subscription ?? {};

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (type === 'free' || !publisher?.premium) {
    return null;
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        className={classes.modal}
      >
        <Box className={classes.modalDialog}>
          <CloseIcon className={classes.closeIcon} onClick={handleCloseModal} />

          <Typography className={classes.titleDialog}>Spotlight Premium</Typography>
          <Box className={classes.listItem}>
            <CheckCircleIcon color="primary" />
            <Typography>
              Get unlimited access to dozens of publications worth more than $200/month
            </Typography>
          </Box>
          <Box className={classes.listItem}>
            <CheckCircleIcon color="primary" />
            <Typography>Get a big discount for annual purchase (17% save)</Typography>
          </Box>

          <Button className={classes.button} href={routes.premium} component={Link} variant="contained" color="primary">Learn More</Button>
        </Box>
      </Modal>
      <Box className={classes.premiumBoxBg}>
        <Container classes={{ root: classes.containerRoot }}>
          <Box className={classes.premiumBox}>
            <Box className={classes.premiumBoxLeft}>
              <Typography color="primary" className={classes.premiumText}>Spotlight Premium</Typography>
              <InfoOutlinedIcon onClick={handleOpenModal} fontSize="small" className={classes.infoIcon} />
            </Box>
            {!user?.profile?.subscription?.premium && (
              <Typography className={classes.tryFreeText}>
                Try it Free!
                {meterLimit && meterValue >= 0 ? (
                  <Meter
                    className={classes.meter}
                    meterLimit={meterLimit}
                    meterValue={meterValue}
                  />
                ) : null}
              </Typography>
            )}
          </Box>
        </Container>
      </Box>
      <Divider />
    </>
  );
};

PremiumBar.propTypes = {
  publisher: PropTypes.shape(),
  user: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(PremiumBar);
