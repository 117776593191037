import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Edit from '@material-ui/icons/Edit';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { language } from '../../../utils/helpers';
import InterestIcon from '../../common/InterestIcon';
import { fetchUserInterests } from '../../../actions/topics';
import { useQuery } from '../../../hooks/useQuery';
import InterestCustomForm from '../../Interest/InterestCustomForm';
import InterestSubscription from '../../Interest/InterestSubscription';

const styles = makeStyles((theme) => ({
  containerRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
    padding: '5px 0px',
  },
  publisherLogo: {
    maxHeight: 38,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
    },
  },
  backIcon: {
    color: theme.palette.text.primary,
    marginRight: 15,
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  interestName: {
    display: 'flex',
    alignItems: 'center',
  },
  interestNameLink: {
    color: theme.palette.text.primary,
    fontSize: 22,
    textDecoration: 'none',
  },
  moreVertIcon: {
    minWidth: '0px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  editIcon: {
    color: '#666666',
    cursor: 'pointer',
  },
}));

const InterestNavBar = ({
  onBackClick,
  feedTopic,
  userId,
  subscribable,
  reloadTopic,
}) => {
  const classes = styles();
  const [showCustomInterestForm, setShowCustomInterestForm] = useState(false);
  const [interests, setInterests] = useState();

  const currentInterestsQuery = useQuery('currentInterests', fetchUserInterests, {
    variables: [userId],
  });
  const response = currentInterestsQuery.data;

  useEffect(() => {
    setInterests(response);
  }, [response]);

  const topicLabel = feedTopic ? language(feedTopic?.names) : '';
  const isCustomInterest = feedTopic?.subject?.search_query?.query;

  return feedTopic
    ? (
      <>
        <AppBar position="sticky" className={classes.appBar}>
          <Container classes={{ root: classes.containerRoot }}>
            <Box className={classes.iconContainer}>
              <IconButton
                color="inherit"
                onClick={onBackClick}
                classes={{ colorInherit: classes.backIcon }}
              >
                <ChevronLeftIcon />
              </IconButton>
              {isCustomInterest && (
                <IconButton onClick={() => setShowCustomInterestForm(true)}>
                  <Edit className={classes.editIcon} />
                </IconButton>
              )}
            </Box>
            <Box
              className={classes.interestNameLink}
            >
              <Box className={classes.interestName}>
                <InterestIcon size={25} color="#37BC98" /> {topicLabel}
              </Box>
            </Box>
            {subscribable && !isCustomInterest && !feedTopic?.type ? (
              <>
                <InterestSubscription
                  classes={{ root: classes.iconColorClass }}
                  interest={feedTopic}
                  isSubscribed={feedTopic.is_subscribed}
                />
              </>
            ) : (<Box />)}
          </Container>
        </AppBar>

        <Box>
          {showCustomInterestForm && (
            <InterestCustomForm
              interests={interests}
              reloadInterests={() => currentInterestsQuery.refetch()}
              searchQuery={feedTopic?.subject?.search_query?.query?.expression}
              interestLabel={topicLabel}
              userId={userId}
              show={setShowCustomInterestForm}
              reloadTopic={() => {
                currentInterestsQuery.refetch();
                reloadTopic();
                setShowCustomInterestForm(false);
              }}
            />
          )}
        </Box>
      </>
    )
    : (
      <></>
    );
};

InterestNavBar.propTypes = {
  feedTopic: PropTypes.shape(),
  onBackClick: PropTypes.func.isRequired,
  userId: PropTypes.string,
  subscribable: PropTypes.bool,
  reloadTopic: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    userId: state.user?.profile?.id,
  };
}

InterestNavBar.defaultProps = {
  subscribable: true,
};

export default connect(mapStateToProps)(withRouter(InterestNavBar));
