/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';
import { Box, Button, CircularProgress, Link, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import CheckoutForm from './CheckoutForm';
import { fetchRcOfferings, findOrCreateStripeSubscription } from '../../actions/subscription';
import config from '../../config';
import routes from '../../routes';
import ThemeContext from '../../context/theme/ThemeContext';

const styles = makeStyles((theme) => ({
  h1: {
    fontFamily: 'Roboto',
    fontSize: 38,
    fontWeight: 'bold',
    marginBottom: 60,
  },
  premiumContainer: {
    textAlign: 'center',
    paddingTop: 50,
  },
  button: {
    padding: '10px 20px',
    color: '#ffffff',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const { stripePk } = config;
const stripePromise = loadStripe(stripePk);

const Stripe = ({ user, priceId, changePlan }) => {
  const classes = styles();
  const history = useHistory();

  const [clientSecret, setClientSecret] = useState(null);

  const paymentQuery = useQuery('findOrCreateStripeSubscription', findOrCreateStripeSubscription, {
    enabled: false,
    variables: [user?.id, priceId],
  });

  const fetchRcOfferingsQuery = useQuery('fetchRcOfferings', fetchRcOfferings, {
    variables: [user],
  });
  const offerings = fetchRcOfferingsQuery?.data?.offerings;

  let plan;
  let of;
  if (offerings) {
    for (of of offerings) {
      plan = of.products.find((product) => product.stripe_price_id === priceId);
      if (plan) break;
    }
  }

  useEffect(() => {
    if (!clientSecret) {
      if (paymentQuery.isSuccess) {
        setClientSecret(paymentQuery?.data?.data?.data?.client_secret);
      } else {
        paymentQuery.refetch();
      }
    }
  }, [clientSecret, paymentQuery]);

  const darkMode = useContext(ThemeContext);

  const options = {
    clientSecret,
    appearance: {
      theme: darkMode?.darkMode ? 'night' : 'stripe',
    },
  };

  if (!clientSecret && paymentQuery?.data?.status !== 201) return (<CircularProgress />);

  return paymentQuery?.data?.status === 201
    ? (
      <Box className={classes.premiumContainer}>
        <Typography className={classes.h1} variant="h1">Your subscription was changed successfully.</Typography>
        <Button className={classes.button} onClick={() => history.push(routes.news.main)} component={Link} variant="contained" color="primary">Go to My News</Button>
      </Box>
    )
    : clientSecret && (
      <>
        <Box>
          <Typography className={classes.h1} variant="h1">Complete Your Purchase</Typography>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              changePlan={changePlan}
              plan={plan}
            />
          </Elements>
        </Box>
      </>
    );
};

const mapStateToProps = (state) => ({
  user: state.user?.profile,
});

Stripe.propTypes = {
  user: PropTypes.shape(),
  priceId: PropTypes.string,
  changePlan: PropTypes.func,
};

export default connect(mapStateToProps)(Stripe);
