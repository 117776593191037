/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Container, ListItem, ListItemText, makeStyles, Modal, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { useQuery } from '../../hooks/useQuery';
import ToastDialog from '../ToastDialog/ToastDialog';
import { customQueryTopicSubscribe, topicEdit } from '../../actions/topics';
import { reportAndRenderError } from '../../utils/errors';
import Form from '../Form/Form';
import InterestIcon from '../common/InterestIcon';

const CustomInterestSchema = Yup.object().shape({
  keywords: Yup.string().required().label('Keywords'),
  name: Yup.string().required().label('Name'),
});

const styles = makeStyles((theme) => ({
  infoIcon: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalDialog: {
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    position: 'relative',
    width: 500,
    padding: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 8,
    top: 8,
  },
  field: {
    marginTop: 25,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
    padding: '25px 0px',
  },
}));

const InterestCustomForm = ({
  interests,
  reloadInterests,
  searchQuery,
  interestLabel,
  userId,
  show,
  setIsSubscribed,
  toastClassName,
  reloadTopic,
}) => {
  const classes = styles();
  const [openModal, setOpenModal] = useState(false);
  const [customInterestVariable, setCustomInterestVariables] = useState([]);

  const subscribeInterestQuery = useQuery('subscribeInterest', customQueryTopicSubscribe, {
    variables: customInterestVariable,
    enabled: false,
  });

  const initialValues = {
    keywords: searchQuery,
    name: (interestLabel) || searchQuery,
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubscribe = async (data) => {
    try {
      setCustomInterestVariables([userId, data.keywords, data.name]);
      const interestExists = interests.find((interestObj) => interestObj.name === interestLabel);
      if (interestExists) {
        await topicEdit(userId, interestExists.subjectId, data);
        if (reloadTopic) reloadTopic();
      } else {
        await subscribeInterestQuery.refetch();

        reloadInterests();
        show(false);
        if (setIsSubscribed) setIsSubscribed(true);

        toast.success(
          <ToastDialog
            active
            activateIcon={<CheckIcon fontSize="large" />}
            activateText="Now Following"
            deactivateIcon={<CancelIcon />}
            deactivateText="Unfollowed"
            title={`#${data.name}`}
          />, {
            position: toast.POSITION.BOTTOM_CENTER,
            className: toastClassName,
          },
        );
      }
    } catch (err) {
      reportAndRenderError(err);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        className={classes.modal}
      >
        <Box className={classes.modalDialog}>

          <CloseIcon className={classes.closeIcon} onClick={handleCloseModal} />

          <Typography align="left">
            A SpotlightNews Interest returns articles with the highest relevancy scores starting with the most recently published.
            The more specific, the search the more likely it will return articles of interest to you.
          </Typography>

          <ListItem>
            <ListItemText
              primary="• Use quotes to search for specific phrases as in 'college football playoffs'. Add multiple words or phrases to search for any of them."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="• Add multiple words or phrases to search for any of them. Separate words or phrases by using commas or spaces. For example: College, football playoffs, would show articles with any of those words."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="• Use a plus sign between multiple words or phrases to refine the search. For example: college + football + playoffs, would show articles that include college AND football AND playoffs."
            />
          </ListItem>
        </Box>
      </Modal>
      <Container classes={{ root: classes.containerRoot }}>
        <Box className={classes.container}>
          <Box>
            <Box className={classes.titleContainer}>
              <InterestIcon size={25} color="#37BC98" />
              <Typography variant="h5">Create Interest</Typography>
              <InfoIcon onClick={handleOpenModal} fontSize="small" className={classes.infoIcon} color="primary" />
            </Box>
            <Form
              validationSchema={CustomInterestSchema}
              initialValues={initialValues}
              onSubmit={handleSubscribe}
            >
              {({ Submit }) => (
                <>
                  <Field
                    id="keywords"
                    name="keywords"
                    label="Search"
                    placeholder="Enter keywords for your Custom Interest"
                    component={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                    className={classes.field}
                  />
                  <Field
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="Name your Custom Interest"
                    component={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                    className={classes.field}
                  />
                  <Box className={classes.buttonContainer}>
                    <Button
                      variant="text"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={() => show(false)}
                    >
                      Cancel
                    </Button>
                    <Submit
                      variant="text"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      Save
                    </Submit>
                  </Box>
                </>
              )}
            </Form>
          </Box>
        </Box>
      </Container>
    </>
  );
};

InterestCustomForm.propTypes = {
  interests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  reloadInterests: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  interestLabel: PropTypes.string,
  userId: PropTypes.string.isRequired,
  setIsSubscribed: PropTypes.func,
  toastClassName: PropTypes.shape(),
  reloadTopic: PropTypes.func,
};

export default InterestCustomForm;
