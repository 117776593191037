import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  infoLabel: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
  },
  boxBg: {
    zIndex: 10,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    top: '68px',
  },
  containerRoot: {
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  box: {
    width: '100%',
    textAlign: 'center',
  },
}));

const WriterAlternativeNameBar = ({
  writer,
}) => {
  const classes = styles();

  if (writer?.alternative_names.length < 1) return (<></>);

  return (
    <Box className={classes.boxBg}>
      <Container classes={{ root: classes.containerRoot }}>
        <Box className={classes.box}>
          <Typography color="primary" className={classes.infoLabel}>
            Also write as:&nbsp;
            {writer?.alternative_names && writer?.alternative_names.join(', ')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

WriterAlternativeNameBar.propTypes = {
  writer: PropTypes.shape().isRequired,
};

export default WriterAlternativeNameBar;
