import React from 'react';
import {
  Box, Button, Typography, makeStyles,
} from '@material-ui/core';

import emptyGraphic from './empty-graphic.svg';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  graphic: {
    marginBottom: 34,
  },
  textRoot1: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: 9,
  },
  textRoot2: {
    fontSize: '16px',
    color: '#666',
    marginBottom: 34,
  },
}));

const DownloadEmpty = ({ onButtonClick }) => {
  const classes = styles();
  return (
    <Box className={classes.container}>
      <img src={emptyGraphic} alt="Download Article" className={classes.graphic} />
      <Typography classes={{ root: classes.textRoot1 }}>
        Read your favorite articles offline
      </Typography>
      <Typography classes={{ root: classes.textRoot2 }}>
        Download articles you love so they&#39;re always there to be read.
      </Typography>
      <Button variant="contained" color="primary" onClick={onButtonClick}>Find More</Button>
    </Box>
  );
};

export default DownloadEmpty;
