import React from 'react';

const StudentIcon = () => (
  <svg width="156" height="156" viewBox="0 0 156 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="78" cy="78" r="76.3404" fill="#EBF5F3" stroke="#37BC98" strokeWidth="3.31915" />
    <path d="M69.0081 62.0957V60.0212H64.8591V62.0957H69.0081ZM94.4549 62.0957V60.0212H90.3059V62.0957H94.4549ZM90.3059 62.0957V74.5425H94.4549V62.0957H90.3059ZM85.7421 79.1063H73.5719V83.2553H85.7421V79.1063ZM69.0081 74.5425V62.0957H64.8591V74.5425H69.0081ZM73.5719 79.1063C71.0514 79.1063 69.0081 77.063 69.0081 74.5425H64.8591C64.8591 79.3544 68.76 83.2553 73.5719 83.2553V79.1063ZM90.3059 74.5425C90.3059 77.063 88.2626 79.1063 85.7421 79.1063V83.2553C90.554 83.2553 94.4549 79.3544 94.4549 74.5425H90.3059Z" fill="#37BC98" />
    <path d="M54.2148 55.7359L79.6616 46.1934L105.108 55.7359L79.6616 65.2785L54.2148 55.7359Z" stroke="#37BC98" strokeWidth="4.14894" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M105.102 55.7363V73.231" stroke="#37BC98" strokeWidth="4.14894" strokeLinecap="round" />
    <path d="M103.034 109.809C103.034 110.955 103.963 111.883 105.108 111.883C106.254 111.883 107.183 110.955 107.183 109.809H103.034ZM52.1404 109.809C52.1404 110.955 53.0691 111.883 54.2148 111.883C55.3605 111.883 56.2893 110.955 56.2893 109.809H52.1404ZM87.5773 88.2061L86.1023 86.7474L87.5773 88.2061ZM89.1376 87.7707L89.6717 85.7662L89.1376 87.7707ZM80.8417 95.0171L79.3666 93.5584L80.8417 95.0171ZM78.4816 95.0171L79.9566 93.5584L78.4816 95.0171ZM82.3167 96.4758L89.0523 89.6648L86.1023 86.7474L79.3666 93.5584L82.3167 96.4758ZM88.6036 89.7752C97.6044 92.1733 103.034 97.7507 103.034 103.447H107.183C107.183 94.9367 99.3928 88.3561 89.6717 85.7662L88.6036 89.7752ZM70.271 89.6648L77.0066 96.4758L79.9566 93.5584L73.221 86.7474L70.271 89.6648ZM56.2893 103.447C56.2893 97.7507 61.7189 92.1733 70.7197 89.7752L69.6516 85.7662C59.9305 88.3561 52.1404 94.9367 52.1404 103.447H56.2893ZM103.034 103.447V109.809H107.183V103.447H103.034ZM52.1404 103.447V109.809H56.2893V103.447H52.1404ZM73.221 86.7474C72.3129 85.8292 70.9607 85.4174 69.6516 85.7662L70.7197 89.7752C70.5299 89.8258 70.3645 89.7594 70.271 89.6648L73.221 86.7474ZM89.0523 89.6648C88.9588 89.7594 88.7934 89.8258 88.6036 89.7752L89.6717 85.7662C88.3626 85.4174 87.0104 85.8292 86.1023 86.7474L89.0523 89.6648ZM79.3666 93.5584C79.529 93.3942 79.7943 93.3942 79.9566 93.5584L77.0066 96.4758C78.4682 97.9537 80.8551 97.9537 82.3167 96.4758L79.3666 93.5584Z" fill="#37BC98" />
  </svg>
);

export default StudentIcon;
