import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import { connect } from 'react-redux';
import DiscoverChannels from './DiscoverChannels';
import DiscoverInterests from './DiscoverInterests';
import DiscoverArticles from './DiscoverArticles';
import DiscoverWriters from './DiscoverWriters';

const styles = makeStyles((theme) => ({
  containerRoot: {
    paddingTop: 15,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tabItem: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      padding: 5,
    },
    '& > .MuiTab-wrapper': {
      minWidth: 80,
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

const Discover = () => {
  const classes = styles();

  const a11yProps = (index) => ({
    id: `discover-tab-${index}`,
    'aria-controls': `discover-tab-${index}`,
  });

  const searchHistory = JSON.parse(localStorage.getItem('exploreHistory'));

  const [value, setValue] = useState(searchHistory?.tab ?? 0);
  const [searchText, setSearchText] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    localStorage.setItem('exploreHistory', JSON.stringify({
      tab: newValue,
      search: searchText,
    }));
  };

  const updateSearchText = (text) => {
    localStorage.setItem('exploreHistory', JSON.stringify({
      tab: value,
      search: text,
    }));

    setSearchText(text);
  };

  useEffect(() => {
    if (searchHistory?.tab) {
      setValue(searchHistory?.tab);
    }

    if (searchHistory?.search) {
      updateSearchText(searchHistory?.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <Box className={classes.tabContainer}>
        <Tabs value={searchHistory?.tab ?? value} onChange={handleChange} aria-label="discover-tabs">
          <Tab key="tab-channels" className={classes.tabItem} label="Publishers" {...a11yProps(0)} />
          <Tab key="tab-interests" className={classes.tabItem} label="Topics" {...a11yProps(1)} />
          <Tab key="tab-articles" className={classes.tabItem} label="Articles" {...a11yProps(2)} />
          <Tab key="tab-writers" className={classes.tabItem} label="Writers" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel key="tabPanel-channels" value={value} index={0}>
        <DiscoverChannels
          searchText={searchHistory?.search ?? searchText}
          updateSearchText={updateSearchText}
        />
      </TabPanel>
      <TabPanel key="tabPanel-interests" value={value} index={1}>
        <DiscoverInterests
          searchText={searchHistory?.search ?? searchText}
          updateSearchText={updateSearchText}
        />
      </TabPanel>
      <TabPanel key="tabPanel-articles" value={value} index={2}>
        <DiscoverArticles
          searchText={searchHistory?.search ?? searchText}
          updateSearchText={updateSearchText}
        />
      </TabPanel>
      <TabPanel key="tabPanel-writers" value={value} index={3}>
        <DiscoverWriters
          searchText={searchHistory?.search ?? searchText}
          updateSearchText={updateSearchText}
        />
      </TabPanel>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.user.profile.id,
  };
}

export default connect(mapStateToProps)(Discover);
