import React from 'react';
import { clearAllReadArticles, fetchReadArticles } from '../../actions/read';
import ArticlePagination, { RecentlyReadEmpty } from '../../components/Article/ArticlePagination';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const ReadArticles = () => {
  useBackgroundClass('default');
  return (
    <ArticlePagination
      loadsContext="readArticles"
      load={fetchReadArticles}
      label="Recently Read"
      EmptyStateComponent={RecentlyReadEmpty}
      clearAll={clearAllReadArticles}
    />
  );
};

export default ReadArticles;
