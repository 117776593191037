const initialState = {
  initialized: false,
  backgroundImage: 'none',
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INITIALIZED': {
      return { ...state, initialized: true };
    }
    case 'SET_BACKGROUND_IMAGE': {
      return { ...state, backgroundImage: action.image };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
