import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Initialize from './containers/Initialize';
import store from './store';
import MainRouter from './components/MainRouter';
import ThemeProvider from './context/theme/ThemeProvider';

const App = () => (
  <Provider store={store}>
    <ThemeProvider>
      <ToastContainer />
      <CssBaseline />
      <Initialize>
        <Router>
          <MainRouter />
        </Router>
      </Initialize>
    </ThemeProvider>
  </Provider>
);

export default App;
