import React from 'react';
import {
  Box, Button, Typography, makeStyles,
} from '@material-ui/core';

import emptyGraphic from './empty-graphic.svg';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  graphic: {
    marginBottom: 34,
  },
  textRoot1: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: 9,
  },
  textRoot2: {
    fontSize: '16px',
    color: '#666',
    marginBottom: 34,
  },
}));

const SubscriptionsEmpty = ({ onButtonClick }) => {
  const classes = styles();
  return (
    <Box className={classes.container}>
      <img src={emptyGraphic} alt="Bookmarks" className={classes.graphic} />
      <Typography classes={{ root: classes.textRoot1 }}>
        Your values. Your priorities. Your subscriptions.
      </Typography>
      <Typography classes={{ root: classes.textRoot2 }}>
        Add your favorite publishers to your personal Spotlight
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
      >
        Update Subscriptions
      </Button>
    </Box>
  );
};

export default SubscriptionsEmpty;
