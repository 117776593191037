import { Box, makeStyles } from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import routes from '../../routes';

function easeInOut(t, b, c, d) {
  let time = t / (d / 2);
  if (time < 1) return (c / 2) * time * time + b;
  time -= 1;
  return (-c / 2) * (time * (time - 2) - 1) + b;
}

let animateTimeout;
function scrollLeftAnimate(element, change, duration) {
  if (animateTimeout) return;
  const start = element.scrollLeft;
  let currentTime = 0;
  const increment = 20;

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOut(currentTime, start, change, duration);
    // eslint-disable-next-line no-param-reassign
    element.scrollLeft = val;
    if (currentTime < duration) {
      animateTimeout = setTimeout(animateScroll, increment);
    } else {
      animateTimeout = 0;
    }
  };
  animateScroll();
}

const styles = makeStyles((theme) => ({
  publishersContainer: {
    position: 'relative',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  publisherContainer: {
    borderRadius: 50,
    overflow: 'hidden',
    width: 50,
    height: 50,
    margin: '10px 5px',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
  },
  publishersScrollable: {
    display: 'flex',
    overflow: 'scroll',
    'scrollbar-width': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
      background: 'transparent',
    },
    paddingLeft: 28,
    marginRight: 24,
  },
  publishersNotScrollable: {
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
  },
  publisherImage: {
    width: 50,
    display: 'block',
  },
  arrows: {
    position: 'absolute',
    width: '100%',
  },
  arrowLeft: {
    position: 'absolute',
    left: -2,
    height: 70,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      left: 0,
    },
  },
  arrowRight: {
    position: 'absolute',
    right: 0,
    height: 70,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
  },
}));

const NetworkMembers = ({ channel }) => {
  const classes = styles();
  const [publishersHasScroll, setPublisherHasScroll] = useState(null);
  const scrollableContainerRef = useRef();
  const publishersContainerRef = useRef();

  const handleNavigation = (direction) => {
    scrollLeftAnimate(scrollableContainerRef.current, (direction === 'right') ? 300 : -300, 1000);
  };

  useEffect(() => {
    let timeout;
    const checkScroll = (scrollWidth, componentWidth) => {
      if (timeout) return;
      timeout = setTimeout(() => {
        timeout = undefined;
        if (scrollableContainerRef.current?.offsetWidth > 0) {
          setPublisherHasScroll(scrollWidth > componentWidth);
        }
      }, 500);
    };

    window.addEventListener('resize', () => {
      checkScroll(
        scrollableContainerRef.current?.scrollWidth,
        publishersContainerRef.current?.offsetWidth,
      );
    });

    checkScroll(
      scrollableContainerRef.current?.scrollWidth,
      publishersContainerRef.current?.offsetWidth,
    );

    return () => window.removeEventListener('resize', checkScroll);
  }, []);

  return (
    <>
      {(channel.publishers)
      && (
      <Box className={classes.publishersContainer} ref={publishersContainerRef}>
        {(publishersHasScroll)
          ? (
            <>
              <ArrowLeft className={classes.arrowLeft} onClick={() => handleNavigation('left')} />
              <ArrowRight className={classes.arrowRight} onClick={() => handleNavigation('right')} />
            </>
          ) : ''}

        <Box
          className={(publishersHasScroll)
            ? classes.publishersScrollable
            : classes.publishersNotScrollable}
          ref={scrollableContainerRef}
        >
          {channel.publishers.map((publisher) => (
            <Link key={publisher.id} to={routes.publishers.main(publisher.slug)}>
              <Box className={classes.publisherContainer}>
                <img className={classes.publisherImage} src={publisher.avatar} alt="" />
              </Box>
            </Link>
          ))}
        </Box>

      </Box>
      )}
    </>
  );
};

NetworkMembers.propTypes = {
  channel: PropTypes.shape().isRequired,
};

export default NetworkMembers;
