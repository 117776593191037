import React from 'react';
import PropTypes from 'prop-types';
import ElectionBar from './ElectionBar';

const ElectionCurrentStructure = ({ type }) => {
  let data;
  if (type === 'us-house') {
    data = {
      democrat: 211,
      vacant: 4,
      republican: 220,
      type: 'US House CS',
    };
  }
  if (type === 'us-senate') {
    data = {
      democrat: 48,
      vacant: 3,
      republican: 49,
      type: 'US Senate CS',
    };
  }
  if (type === 'governors') {
    data = {
      democrat: 211,
      vacant: 4,
      republican: 220,
      type: 'Governor CS',
    };
  }

  return (
    <ElectionBar
      midPoint={data?.midPoint}
      type={data?.type}
      currentStructure
      percentages={
        [
          { color: '#245c94', value: data?.democrat },
          { color: '#bcc171', value: data?.vacant },
          { color: '#bc2c3c', value: data?.republican },
        ]
      }
    />
  );
};

ElectionCurrentStructure.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ElectionCurrentStructure;
