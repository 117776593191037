import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import ScrollToTop from '../NavigationLayout/ScrollTop';
import NavigationBar from '../../components/Nav/NavigationBar';
import Stripe from '../../components/Stripe/Stripe';

const styles = makeStyles((theme) => ({
  h1: {
    fontFamily: 'Roboto',
    fontSize: 38,
    fontWeight: 'bold',
    marginBottom: 30,
  },
  premiumContainer: {
    textAlign: 'center',
    paddingTop: 50,
  },
  boxCard: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
}));

const PremiumPurchase = ({ user, match }) => {
  const classes = styles();
  const { priceId } = match.params;

  return (
    <>
      {user && (
        <>
          <ScrollToTop />
          <NavigationBar user={{ profile: user }} />
        </>
      )}
      <Box className={classes.premiumContainer}>
        <Box className={classes.boxCard}>
          <Stripe
            priceId={priceId}
          />
        </Box>
      </Box>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.profile,
  };
}

PremiumPurchase.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, null)(PremiumPurchase);
