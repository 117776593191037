import React from 'react';
import MyStatsPanel from '../../components/MyStats/MyStatsPanel';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const MyStats = () => {
  useBackgroundClass('default');
  return (
    <>
      <MyStatsPanel />
    </>
  );
};

export default MyStats;
