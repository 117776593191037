import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import PublisherNavBar from '../Nav/PublisherNavBar';
import MainContent from '../MainContent';
import ArticleFeed from '../Article/ArticleFeed';
import TopicNavigation from '../Article/ArticleFeed/TopicNavigation';
import routes from '../../routes';
import PremiumBar from '../PremiumBar';
import NetworkMembers from '../NetworkMember';
import ChannelProfileHeroImage from './ChannelProfileHeroImage';
import InfoBar from '../InfoBar/InfoBar';

const styles = makeStyles((theme) => ({
  articleFeed: {
    paddingLeft: 21,
    paddingRight: 21,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

const ChannelProfile = ({
  channel, topics, selectedTopicId,
  feedIsLoading, articles, hasMore,
  loadMoreArticles, onNotificationClick, onBackClick,
  isSignedIn,
}) => {
  const classes = styles();
  const [_isNotifiable, setIsNotifiable] = useState(channel.is_notifiable);

  useEffect(() => {
    setIsNotifiable(channel.is_notifiable);
  }, [channel]);

  const handleNotificationClick = () => {
    onNotificationClick(!_isNotifiable);
    setIsNotifiable(!_isNotifiable);
  };

  const background = {
    primary: 'transparent',
    secondary: (channel?.branding?.color) ? channel?.branding?.color : 'transparent',
  };

  return (
    <>
      <PublisherNavBar
        onBackClick={onBackClick}
        isSignedIn={isSignedIn}
        channel={channel}
        onNotificationClick={handleNotificationClick}
        publisherUrl={routes.publishers.main(channel.slug)}
        isNotifiable={_isNotifiable}
      />
      <PremiumBar publisher={channel} />
      <InfoBar channel={channel} />

      <MainContent includeBackgroundImage={false} paddingTop={0} background={background}>
        <ChannelProfileHeroImage channel={channel} />

        <NetworkMembers channel={channel} />

        <Box className={classes.articleFeed}>
          {topics && (
            <TopicNavigation
              topics={topics}
              selectedTopicId={selectedTopicId}
              isLoading={false}
            />
          )}
          <ArticleFeed
            feedIsLoading={feedIsLoading}
            articles={articles}
            hasMore={hasMore}
            loadMore={loadMoreArticles}
            channel={channel}
          />
        </Box>
      </MainContent>
    </>
  );
};

ChannelProfile.propTypes = {
  channel: PropTypes.shape().isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedTopicId: PropTypes.string,
  feedIsLoading: PropTypes.bool.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMoreArticles: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
};

export default ChannelProfile;
