import React from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import MainContent from '../../components/MainContent';
import routes from '../../routes';

const WebViewLayout = ({
  component: Component, user, backgroundImage, onlySignedIn = false, ...rest
}) => {
  const history = useHistory();
  const isSignedIn = user?.isSignedIn;
  const redirectMessage = 'You need to sign in or sign up before continuing.';
  if (onlySignedIn && !isSignedIn) {
    history.replace(routes.auth.signin, { flash: { message: redirectMessage, severity: 'warning' }, redirectPath: history.location.pathname });
    return null;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <MainContent
            paddingTop={0}
            backgroundImage={backgroundImage}
            mobilePadding={false}
            showGetApp={false}
          >
            <Component {...matchProps} />
          </MainContent>
        </>
      )}
    />
  );
};

WebViewLayout.propTypes = {
  component: PropTypes.elementType.isRequired,
  user: PropTypes.shape().isRequired,
  backgroundImage: PropTypes.string.isRequired,
  onlySignedIn: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  backgroundImage: state.app.backgroundImage,
  user: state.user,
});

export default connect(mapStateToProps)(WebViewLayout);
