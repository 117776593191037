import axios from 'axios';

import config from '../config';
import { getAuthToken } from './authToken';

export default function client(auth = true, options = {}) {
  const onSuccess = (response) => {
    if (!options?.returnStatus) return response.data;

    return {
      data: response.data,
      status: response.status,
    };
  };

  const onError = (err) => {
    const responseError = err;
    responseError.status = err.response?.status;
    responseError.data = err.response?.data;
    return Promise.reject(responseError);
  };

  // Build config
  const axiosConfig = {
    headers: options?.headers ?? {},
    baseURL: options?.isEvent ? config.apiEventServer : config.apiServer,
  };
  if (auth) axiosConfig.headers.authorization = getAuthToken();

  const instance = axios.create(axiosConfig);
  instance.interceptors.response.use(onSuccess, onError);
  return instance;
}
