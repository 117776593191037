import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import { useQuery } from '../../hooks/useQuery';
import { getOnboardGroups } from '../../actions/onboarding';

const styles = makeStyles((theme) => ({
  onboardingGroups: {
    paddingBottom: 10,
    overflowX: 'auto',
  },
  groupTitle: {
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    marginBottom: 10,
    marginTop: 20,
    textAlign: 'left',
  },
  toggleButtonGroup: {
    textAlign: 'left',
    width: 'max-content',
  },
  toggleButton: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    background: '#dedddd',
    color: '#888888',
    padding: '10px',
    border: 'none',
    margin: '10px 5px',
  },
  toggleButtonSelected: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    background: '#37bc98',
    color: '#ffffff',
    padding: '10px',
    border: 'none',
    margin: '10px 5px',
    '&:hover': {
      background: '#37bc98',
      color: '#ffffff',
    },
  },
  interestTitleButton: {
    padding: 0,
    margin: 0,
    marginLeft: 2,
    background: 'transparent !important',
    color: 'inherit',
  },
  avatar: {
    height: 40,
    width: 40,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 30,
    backgroundSize: '40px',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'border-box',
  },
}));

const InterestGroup = ({
  selectedInterests,
  handleClick,
  handleTitleClick,
  fullWidth = false,
  filterType = null,
}) => {
  const classes = styles();

  const onboardGroupsQuery = useQuery('getOnboardGroups', getOnboardGroups);
  const groups = onboardGroupsQuery.data;

  return groups ? (
    <>
      {groups.map((group, index) => (
        <Box key={`group-channel-or-interest-${index}`} className={classes.onboardingGroups} style={{ maxWidth: fullWidth ? 'auto' : 430 }}>
          <Typography variant="h3" className={classes.groupTitle}>{group?.name}</Typography>
          {group?.sections.map((section, indexSection) => (
            <Box key={`section-channel-or-interest-${indexSection}`} className={classes.toggleButtonGroup}>
              {section.map((interestOrChannel) => (
                <Box
                  key={`channel-or-interest-${interestOrChannel?.subject_id}`}
                  className={
                    selectedInterests.filter(
                      (select) => select?.subject_id === interestOrChannel.subject_id,
                    ).length > 0
                      ? classes.toggleButtonSelected
                      : classes.toggleButton
                  }
                  color="default"
                  variant="outlined"
                  style={{ display: (filterType && filterType !== interestOrChannel.subject_type) ? 'none' : 'auto' }}
                >

                  {selectedInterests.filter(
                    (select) => select?.subject_id === interestOrChannel.subject_id,
                  ).length > 0
                    ? (<CheckCircleIcon onClick={() => handleClick(interestOrChannel)} titleAccess="Unsubscribe" />)
                    : (<AddIcon onClick={() => handleClick(interestOrChannel)} titleAccess="Subscribe" />)}

                  <Button
                    key={`section-channel-or-interest-title-${indexSection}`}
                    className={classes.interestTitleButton}
                    onClick={() => handleTitleClick(interestOrChannel)}
                  >
                    {interestOrChannel.icon && (
                      <Box
                        className={classes.avatar}
                        style={{
                          backgroundImage: `url(${interestOrChannel.icon})`,
                        }}
                      />
                    )}
                    <Box>
                      {interestOrChannel?.subject_type === 'Topic' && '#'}
                      {interestOrChannel?.title}
                    </Box>
                  </Button>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      ))}
    </>
  ) : (
    <>
      <CircularProgress />
    </>
  );
};

InterestGroup.propTypes = {
  selectedInterests: PropTypes.arrayOf(PropTypes.shape()),
  handleClick: PropTypes.func,
  handleTitleClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  filterType: PropTypes.string,
};

export default InterestGroup;
