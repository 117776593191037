import { setAuthToken, removeAuthToken } from '../utils/authToken';
import client from '../utils/client';

export const setUserSession = (data) => ({
  type: 'USER_SET_SESSION',
  data,
});

export const signOut = () => {
  removeAuthToken();
  return { type: 'USER_SIGN_OUT' };
};

export const fetchUser = () => {
  const url = '/users/me';
  return new Promise((resolve, reject) => {
    client().get(url)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

const messages = {
  404: 'No user found with this email and password.',
  412: 'You have to confirm your email address before continuing.',
};

export const authenticate = async ({ email, password, rememberMe, dispatch }) => {
  const url = '/users/token';
  const data = { auth: { email, password } };
  try {
    const response = await client().post(url, data);
    setAuthToken(response.data.jwt, rememberMe);
    const userResponse = await fetchUser();
    dispatch(setUserSession(userResponse.data));
    return { success: true, user: userResponse.data };
  } catch (e) {
    if (e.status === 404 || e.status === 412) {
      return {
        success: false,
        code: e.status,
        error: e,
        message: messages[e.status],
      };
    }
    throw e;
  }
};

export const authenticateGuest = () => async (dispatch) => {
  const url = '/users/token/guest';
  try {
    const response = await client().post(url);
    setAuthToken(response.data.jwt);
    const userResponse = await fetchUser();
    dispatch(setUserSession(userResponse.data));
    return { success: true, user: userResponse.data };
  } catch (err) {
    if (err.status !== 404) {
      console.log('error');
    }
  }
  return null;
};

export const authenticateDelete = async ({ email, password }) => {
  const url = '/users/deletion_token ';
  const data = { auth: { email, password } };
  try {
    const response = await client().post(url, data);
    return response;
  } catch (e) {
    if (e.status === 404 || e.status === 412 || e.status === 422) {
      throw Error(messages[e.status]);
    }
  }
  return null;
};

export const loginSubmit = (data) => async (dispatch) => {
  const auth = await authenticate({ dispatch, ...data });

  if (!auth.success) {
    return Promise.reject(auth.message || auth.error);
  }
  return auth.user;
};

export const signUp = ({
  email, name, password, captchaToken, guest, userId, landingCode,
}) => async (dispatch) => {
  const data = {
    email,
    username: name,
    password,
    captcha_token: captchaToken,
    user_id: userId,
    landing_code: landingCode,
  };

  try {
    if (guest) {
      const url = `/users/${userId}/registration`;
      await client().put(url, data);
    } else {
      const url = '/users';
      await client().post(url, data);
    }
  } catch (e) {
    if (e.status === 422) {
      return Promise.reject(e.data?.message || e);
    }
    throw e;
  }
  const auth = await authenticate({ email, password, dispatch, rememberMe: true });
  return { auth };
};

export const updateUser = async (userId, data) => {
  const url = `/users/${userId}`;
  const response = await client().patch(url, data);
  return response.data;
};

export const updateUserEmail = async (userId, newEmail) => {
  const url = `/users/${userId}/email`;

  return client().patch(url, { new_email: newEmail });
};

export const resendConfirmationEmail = async (email) => {
  const url = '/users/reconfirmation';
  try {
    return client().post(url, { email });
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const forgotPassword = (email) => {
  const url = '/users/password/reset';
  const data = { email };
  return client().post(url, data);
};

export const forgotPasswordConfirm = async (token, password) => {
  const url = '/users/password/reset';
  const data = {
    token,
    password,
  };
  try {
    return await client().patch(url, data);
  } catch (e) {
    if (e.status === 404) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('The password reset token is wrong or expired.');
    }
    throw e;
  }
};

const deleteAccountMessages = {
  403: 'User not authorized.',
  404: 'No user found with this email and password.',
  412: 'Can\'t delete the user. The user has active managed subscriptions.',
  422: 'Token expired.',
};

export const deleteAccount = async (token) => {
  const url = `/users/token/${token}`;

  try {
    return await client().delete(url);
  } catch (e) {
    if (e.status === 404 || e.status === 412 || e.status === 422) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        deleteAccountMessages[e.status],
      );
    }
    throw e;
  }
};

export const deleteAccountConfirm = async (token) => {
  const url = `/users/token/${token}`;
  try {
    return await client().delete(url);
  } catch (e) {
    if (e.status === 404) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('The confirmation token is wrong or expired.');
    }
    if (e.status === 422) {
      return Promise.reject(e.data?.message || e);
    }
    throw e;
  }
};

export const finishOnboarding = (userId) => async (dispatch) => {
  const url = `/users/${userId}/onboarding`;
  await client().put(url);
  dispatch({
    type: 'USER_FINISH_ONBOARDING',
  });
};

export const getSettings = async (userId) => {
  const url = `users/${userId}/settings/`;
  try {
    const data = await client().get(url);
    return data;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

export const updateSettings = async (userId, settings = null) => {
  if (settings) {
    const url = `users/${userId}/settings/`;
    try {
      const data = await client().patch(url, settings);
      return data;
    } catch (err) {
      console.log(err.message);
    }
  }
  return null;
};

export default null;
