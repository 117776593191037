import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import routes from '../../../routes';

const styles = makeStyles({
  deleteButton: {
    color: '#ff0000',
  },
});

function ListItemLink(props) {
  return (
    <ListItem
      button
      component="a"
      target="_blank"
      rel="nofollow noopener"
      {...props}
    />
  );
}

const SettingsDialog = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = styles();
  const history = useHistory();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const goToDeleteAccount = () => {
    handleClose();
    history.push(routes.auth.deleteAccountVerification);
  };

  return (
    <>
      {children({ isOpen, open: handleOpen, close: handleClose })}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="settings-dialog-title"
        fullWidth
      >
        <DialogTitle id="settings-dialog-title">
          Settings
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <List
            component="nav"
            aria-labelledby="settings-legal-list"
            subheader={(
              <ListSubheader component="div" id="settings-legal-list">
                Legal
              </ListSubheader>
            )}
          >
            <ListItemLink href="https://spotlightnews.us/privacy.html">
              <ListItemText primary="Privacy Policy" />
            </ListItemLink>
            <ListItemLink href="https://spotlightnews.us/terms.html">
              <ListItemText primary="Terms & Conditions" />
            </ListItemLink>
            <ListItemLink href="https://spotlightnews.us/licenses.html">
              <ListItemText primary="Licenses" />
            </ListItemLink>
          </List>
          <Button
            className={classes.deleteButton}
            size="large"
            color="secondary"
            fullWidth
            onClick={goToDeleteAccount}
          >
            Delete Account
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SettingsDialog.propTypes = {
  children: PropTypes.func.isRequired,
};

export const Pure = SettingsDialog;

export default SettingsDialog;
