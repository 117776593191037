import React from 'react';
import { fetchDownloadHistory } from '../../actions/download';
import DownloadEmpty from '../../components/Download/DownloadEmpty';
import DownloadPagination from '../../components/Download/DownloadPagination';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const DownloadHistory = () => {
  useBackgroundClass('default');

  return (
    <DownloadPagination
      loadsContext="downloadHistory"
      load={fetchDownloadHistory}
      label="Download History"
      EmptyStateComponent={DownloadEmpty}
    />
  );
};

export default DownloadHistory;
