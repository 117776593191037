import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import ViewListIcon from '@material-ui/icons/ViewList';
import { format } from 'date-fns';
import ElectionBar from './ElectionBar';
import { elections, getPercentages } from '../../actions/elections';
import ElectionTopBar from './ElectionTopBar';
import routes from '../../routes';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    paddingTop: 21,
    paddingBottom: 21,
  },
  title: {
    fontSize: '1.725rem',
    marginTop: 10,
  },
  lastChange: {
    fontSize: '0.9rem',
    marginBottom: 10,
  },
  button: {
    display: 'block',
    width: 530,
    padding: 15,
    margin: '0px auto',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttons: {
    width: 530,
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0px auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  legendTitle: {
    marginLeft: 5,
  },
  viewMore: {
    marginTop: 20,
    textAlign: 'center',
  },
}));

const ElectionList = ({ webView, darkModeActive }) => {
  const classes = styles();
  const history = useHistory();
  const { data: electionData } = useQuery('elections', elections);

  const goToInternal = (slug) => {
    if (webView) {
      const urlWV = (darkModeActive) ? `${routes.electionsInternalWebView(slug)}?darkMode=1` : routes.electionsInternalWebView(slug);
      history.push(urlWV);
      return;
    }

    history.push(routes.electionsInternal(slug));
  };

  const goToLegend = () => {
    if (webView) {
      const urlWV = (darkModeActive) ? `${routes.electionsLegendWebView}?darkMode=1` : routes.electionsLegendWebView;
      history.push(urlWV);
      return;
    }

    history.push(routes.electionsLegend);
  };

  const getDate = (date) => {
    if (!date) return null;
    return format(new Date(`${date} 00:00:00`), 'MM/dd/Y');
  };

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <ElectionTopBar
        title="Election Ratings"
        lastUpdate={electionData?.governor?.polled_at}
      />
      <Box className={classes.buttons}>
        <Button
          onClick={goToLegend}
        >
          <ViewListIcon />
          <Typography className={classes.legendTitle}>Legend</Typography>
        </Button>
      </Box>
      {electionData && Object.entries(electionData).map((election) => (
        <Box>
          <Button className={classes.button} onClick={() => goToInternal(election[1]?.name.toLowerCase().replace(' ', '-'))}>
            <Box className={classes.centerContent}>
              <Typography className={classes.title}>{election[1]?.name}</Typography>
              <Typography className={classes.lastChange}>
                Last Rating Change: {getDate(election[1]?.last_rating_changed_at)}
              </Typography>
              <ElectionBar
                type={election[1]?.name}
                percentages={getPercentages(election[1])}
              />
              <Box className={classes.viewMore}>
                <Button
                  variant="contained"
                  color="primary"
                >
                  View more details
                </Button>
              </Box>
            </Box>
          </Button>
        </Box>
      ))}
    </Container>
  );
};

ElectionList.propTypes = {
  webView: PropTypes.bool,
  darkModeActive: PropTypes.bool,
};

export default ElectionList;
