const initialState = {
  selectedArticleId: null,
  feeds: {},
  feedIsLoading: false,
  articles: {},
  bookmarksIsLoading: false,
  bookmarks: [],
  favoritesIsLoading: false,
  favorites: [],
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'NEWS_FEED_IS_LOADING': {
      return { ...state, feedIsLoading: action.isLoading };
    }
    case 'NEWS_ADD_FEED': {
      const currentState = (
        state.feeds[action.topicId] === undefined ? [] : state.feeds[action.topicId].articles
      );
      const newState = currentState.slice(0).concat(action.data.articles);
      return {
        ...state,
        feeds: {
          ...state.feeds,
          [action.topicId]: {
            totalPages: action.data.totalPages,
            totalCount: action.data.totalCount,
            currentPage: action.data.currentPage,
            articles: newState,
          },
        },
      };
    }
    case 'NEWS_ARTICLE_IS_LOADING': {
      return { ...state, articleIsLoading: action.isLoading };
    }
    case 'NEWS_ADD_ARTICLE': {
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.articleId]: action.data,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default newsReducer;
