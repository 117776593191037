import React from 'react';
import { clearAllFavorites, fetchFavorites } from '../../actions/favorites';
import ArticlePagination, { FavoritesEmpty } from '../../components/Article/ArticlePagination';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const Favorites = () => {
  useBackgroundClass('default');
  return (
    <ArticlePagination
      loadsContext="likes"
      load={fetchFavorites}
      label="Likes"
      EmptyStateComponent={FavoritesEmpty}
      clearAll={clearAllFavorites}
    />
  );
};

export default Favorites;
