/* eslint-disable no-underscore-dangle */
import client from '../utils/client';
import { language } from '../utils/helpers';
import { fetchUserFeeds } from './news';

const fetchWriter = async (slug, typeParam = null, valueParam = null) => {
  let params = {};
  if (typeParam) {
    params = { type: typeParam, value: valueParam };
  }
  const url = `/writers/${slug}`;
  try {
    const response = await client().get(url, { params });
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

const searchWriters = async (userId, author, page = 1) => {
  const url = '/search/writers';
  try {
    const response = await client().get(url, { params: { s: author, page } });
    const { data } = response;

    const userFeeds = await fetchUserFeeds(userId);
    const writersSubscriptions = userFeeds.filter((uF) => uF.type === 'writer');

    const writersArray = [];
    data.forEach((item) => {
      writersArray.push({
        ...item,
        // eslint-disable-next-line no-underscore-dangle
        is_subscribed: Boolean(writersSubscriptions.find((wS) => wS?.subject?._id === item._id)),
      });
    });

    return {
      data: writersArray,
      totalCount: response.total_count,
      numPages: response.total_pages,
      currentPage: response.current_page,
    };
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

const fetchWritersSubscription = async (userId) => {
  try {
    const userFeeds = await fetchUserFeeds(userId);
    const writersSubscriptions = userFeeds.filter((uF) => uF.type === 'writer');

    const writersArray = [];
    writersSubscriptions.forEach(async (item) => {
      // eslint-disable-next-line no-underscore-dangle
      writersArray.push({
        name: language(item.names),
        slug: item?.subject?.slug,
        // eslint-disable-next-line no-underscore-dangle
        _id: item?.subject?._id,
        articles_count: item.articles_count,
        is_subscribed: Boolean(
          writersSubscriptions.find((wS) => wS?.subject?._id === item?.subject?._id),
        ),
      });
    });

    return writersArray;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

const getWriterFeed = async (writerId, perPage = 10, page = 1) => {
  const url = `/writers/${writerId}/articles`;
  try {
    const response = await client().get(url, { params: { page, per_page: perPage } });
    return {
      totalCount: response.total_count,
      totalPages: response.total_pages,
      currentPage: response.current_page,
      articles: response.data,
    };
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

export {
  searchWriters,
  getWriterFeed,
  fetchWriter,
  fetchWritersSubscription,
};
