import React from 'react';
import ElectionsList from '../../components/Elections/ElectionsList';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const Elections = () => {
  useBackgroundClass('elections');
  return (<ElectionsList />);
};

export default Elections;
