import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography, Link } from '@material-ui/core';
import { toast } from 'react-toastify';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import LanguageIcon from '@material-ui/icons/Language';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainContent from '../../components/MainContent';
import PublisherNavBar from '../../components/Nav/PublisherNavBar';
import PremiumBar from '../../components/PremiumBar';
import { fetchChannel, toggleChannelNotification } from '../../actions/channel';
import routes from '../../routes';
import { useQuery } from '../../hooks/useQuery';
import ToastDialog from '../../components/ToastDialog/ToastDialog';

const styles = makeStyles((theme) => ({
  linksContainer: {
    display: 'flex',
    marginTop: 15,
  },
  linkIcon: {
    marginRight: 15,
    color: theme.palette.text.primary,
  },
  about: {
    padding: 25,
  },
  title: {
    marginBottom: 15,
  },
  spotlightToast: theme.toastDialog,
}));

const ChannelAbout = ({ user, match }) => {
  const channelId = match.params.slug;
  const { data: channel } = useQuery('channelAbout', fetchChannel, {
    variables: [channelId],
  });
  const classes = styles({ ...channel });
  const [isNotifiable, setIsNotifiable] = useState(false);

  useEffect(() => {
    if (channel) {
      setIsNotifiable(channel.is_notifiable);
    }
  }, [channel]);

  useEffect(() => () => {
    toast.dismiss();
  }, []);

  const history = useHistory();
  const onBackClick = () => {
    history.push(routes.publishers.main(channel.slug));
  };

  const handleNotificationClick = () => {
    toggleChannelNotification(user.profile.id, channel.id, !isNotifiable);
    setIsNotifiable(!isNotifiable);

    toast.success(
      <ToastDialog
        active={!isNotifiable}
        activateIcon={<NotificationsIcon fontSize="large" />}
        deactivateIcon={<NotificationsOffIcon fontSize="large" />}
        activateText="Enabled Notifications"
        deactivateText="Disabled Notifications"
        title={channel.title}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );
  };

  return channel ? (
    <>
      <PublisherNavBar
        isSignedIn
        channel={channel}
        onBackClick={onBackClick}
        onNotificationClick={handleNotificationClick}
        publisherUrl={routes.publishers.main(channel.slug)}
        isNotifiable={isNotifiable}
      />
      <MainContent includeBackgroundImage={false} paddingTop={0}>
        <PremiumBar publisher={channel} />

        <Box>
          <Box className={classes.about}>
            <Typography variant="h6" className={classes.title}>About</Typography>
            <Typography>{channel.about}</Typography>

            <Box className={classes.linksContainer}>
              {(channel.phone)
                && (
                  <Box>
                    <Link href={`tel:${channel.phone}`}>
                      <PhoneIcon className={classes.linkIcon} />
                    </Link>
                  </Box>
                )}

              {(channel.email)
                && (
                  <Box>
                    <Link href={`mailto:${channel.email}`}>
                      <MailIcon className={classes.linkIcon} />
                    </Link>
                  </Box>
                )}

              {(channel.url)
                && (
                  <Box>
                    <Link href={channel.url}>
                      <LanguageIcon className={classes.linkIcon} />
                    </Link>
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
      </MainContent>
    </>
  ) : (<></>);
};

ChannelAbout.propTypes = {
  user: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ChannelAbout);
