import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { TopArticlesEmpty } from '../Article/ArticlePagination';
import SearchInfoDialog from './SearchInfoDialog';
import SearchField from '../common/SearchField';
import { searchWriters } from '../../actions/writers';
import WriterPagination from '../Writer/WriterPagination/WriterPagination';
import { getSearchHistory, saveSearchExpression } from '../../actions/search';
import { useQuery } from '../../hooks/useQuery';

const styles = makeStyles((theme) => ({
  searchContainer: {
    textAlign: 'center',
    marginBottom: 30,
    width: '100%',
  },
  writerContainer: {
    marginTop: -40,
  },
  searchForWriterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },
  searchIcon: {
    fontSize: 100,
  },
  noSearchTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  discoverHeaderRoot: {
    marginBottom: 30,
  },
  learnMoreButton: {
    marginLeft: 15,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      marginTop: 15,
    },
  },
  tips: {
    textAlign: 'center',
    color: '#37BC98',
    fontWeight: 'bold',
    marginBottom: 15,
    display: 'block',
  },
}));

const DiscoverWriters = ({
  userId,
  searchText,
  updateSearchText,
}) => {
  const classes = styles();
  const [showDialog, setShowDialog] = useState(false);
  const [currentSearchValue, setCurrentSearchValue] = useState(null);
  const [saveText, setSaveText] = useState(null);

  const saveSearchExpressionQuery = useQuery('saveSearchExpressionWriter', saveSearchExpression, {
    variables: [userId, 'writers', saveText],
    enabled: false,
  });

  const getSearchExpressionQuery = useQuery('getSearchExpressionArticles', getSearchHistory, {
    variables: [userId, 'writers'],
  });
  const expressions = getSearchExpressionQuery?.data?.history;
  const history = currentSearchValue
    ? expressions.filter((ex) => ex.expression.includes(currentSearchValue))
    : expressions;

  useEffect(() => {
    if (saveSearchExpressionQuery.isFetched) {
      getSearchExpressionQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSearchExpressionQuery.isFetched]);

  useEffect(() => {
    if (saveText) {
      saveSearchExpressionQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveText]);

  const onSearchChange = (value) => {
    updateSearchText(value);
    setSaveText(value);
  };

  const clearSearch = () => {
    updateSearchText(null);
  };

  const onCloseSearchInfoDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <SearchInfoDialog show={showDialog} onCloseClick={onCloseSearchInfoDialog} />
      <Box className={classes.discoverHeaderRoot}>
        <Box className={classes.searchContainer}>
          {getSearchExpressionQuery.isFetched && (
            <SearchField
              placeholder="Search Writers"
              clearSearch={clearSearch}
              onSearchChange={onSearchChange}
              style={{ marginTop: 0, width: '100%' }}
              searchFieldStyle={{ width: '100%' }}
              fieldValue={searchText}
              autocomplete
              setCurrentSearchValue={setCurrentSearchValue}
              type="writers"
              expressions={history}
              allExpressions={expressions}
              refreshExpressions={() => getSearchExpressionQuery.refetch()}
            />
          )}
        </Box>
        <Box>
          <Link target="_blank" href="https://spotlightnews.us/learn-more.html" className={classes.tips}>Helpful tips on how to search</Link>
        </Box>
      </Box>

      {searchText
        ? (
          <Box className={classes.writerContainer}>

            <WriterPagination
              loadsContext={`searchWriters${searchText}`}
              load={(guid, page) => searchWriters(userId, searchText, page)}
              label={`Results for ${searchText}`}
              EmptyStateComponent={TopArticlesEmpty}
            />

          </Box>
        )
        : (
          <Box className={classes.searchForWriterContainer}>
            <SearchIcon className={classes.searchIcon} />
            <Typography className={classes.noSearchTitle}>Search Writers</Typography>
          </Box>
        )}

    </>
  );
};

DiscoverWriters.propTypes = {
  userId: PropTypes.string.isRequired,
  searchText: PropTypes.string,
  updateSearchText: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userId: state.user?.profile?.id,
});

export default connect(mapStateToProps, null)(DiscoverWriters);
