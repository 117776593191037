import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useComponentWidth from '../../hooks/useComponentWidth';

const styles = makeStyles((theme) => ({
  barRoot: {
    marginTop: -5,
    zIndex: 0,
    position: 'relative',
    display: 'flex',
    width: '100%',
    '& > div:first-child': {
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
    },
    '& > div:last-child': {
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
    },
  },
  bar: {
    height: 42,
  },
  value: {
    textAlign: 'center',
    width: '100%',
    fontWeight: 300,
  },
  line: {
    zIndex: 1,
    position: 'relative',
    width: 1,
    height: 20,
    backgroundColor: theme.palette.text.primary,
    margin: '0px auto',
  },
}));

const ElectionBar = ({ percentages, type, currentStructure }) => {
  const [width, setWidth] = useState(0);

  const classes = styles();
  let total = 0;
  percentages.forEach((p) => {
    total += p?.value;
  });

  const { componentRef, componentWidth } = useComponentWidth();

  useEffect(() => {
    setWidth(componentWidth);
  }, [componentWidth]);

  const getMidValue = (t) => {
    switch (t) {
      case 'Electoral College':
        return 270;
      case 'US Senate':
        return 50;
      case 'US House':
        return 218;
      case 'US House CS':
        return 218;
      case 'Governor CS':
        return 25;
      case 'US Senate CS':
        return 50;
      default:
        break;
    }
    return null;
  };

  const addStyle = currentStructure ? {
    borderRadius: 'initial',
  } : {};

  return (
    <>
      <Box ref={componentRef}>
        {getMidValue(type) && (
          <Box className={classes.midValue}>
            <Box className={classes.value}>{getMidValue(type)}</Box>
            <Box className={classes.line} />
          </Box>
        )}
        <Box className={classes.barRoot} style={{ width }}>
          {percentages.map((p) => (
            <Box
              className={classes.bar}
              style={{
                background: p.color,
                width: `${(p.value * width) / total}px`,
                ...addStyle,
              }}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

ElectionBar.propTypes = {
  percentages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  type: PropTypes.string.isRequired,
  currentStructure: PropTypes.bool,
};

export default ElectionBar;
