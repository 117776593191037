import React from 'react';

import {
  CircularProgress,
  Container,
  makeStyles,
} from '@material-ui/core';

const styles = makeStyles((theme) => ({
  progressRoot: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
}));

const Loader = () => {
  const classes = styles();

  return (
    <Container>
      <CircularProgress classes={{ root: classes.progressRoot }} />
    </Container>
  );
};

export default Loader;
