import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  container: {
    textAlign: 'center',
  },
  textRoot1: {
    fontSize: '18px',
    fontWeight: 500,
  },
});

const AllPublishersEmpty = () => {
  const classes = styles();
  return (
    <Box className={classes.container}>
      <Typography classes={{ root: classes.textRoot1 }}>
        No publishers available
      </Typography>
    </Box>
  );
};

export default AllPublishersEmpty;
