import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Link, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import SearchField from '../common/SearchField';
import SearchInfoDialog from './SearchInfoDialog';
import { toggleTopicSubscription as toggleTopicSubscriptionAction, searchInterests } from '../../actions/topics';
import { usePaginationQuery, useQuery } from '../../hooks/useQuery';
import Interest from '../Interest/Interest';
import InterestCustom from '../Interest/InterestCustom';
import InterestGroup from '../Interest/InterestGroup';
import { language } from '../../utils/helpers';
import { fetchUserFeeds } from '../../actions/news';
import routes from '../../routes';
import ToastDialog from '../ToastDialog/ToastDialog';
import { getSearchHistory, saveSearchExpression } from '../../actions/search';
import InfiniteList from '../common/InfiniteList/InfiniteList';

const styles = makeStyles((theme) => ({
  discoverHeaderRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  searchContainer: {
    width: '100%',
    textAlign: 'center',
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'end',
    },
  },
  infoOutlinedIcon: {
    marginLeft: 20,
    cursor: 'pointer',
  },
  learnMoreButton: {
    marginLeft: 15,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      marginTop: 15,
    },
  },
  tips: {
    textAlign: 'center',
    color: '#37BC98',
    fontWeight: 'bold',
    marginBottom: 15,
    display: 'block',
  },
  spotlightToast: theme.toastDialog,
}));

const DiscoverInterests = ({
  userId,
  toggleTopicSubscription,
  searchText,
  updateSearchText,
}) => {
  const classes = styles();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [selection, setSelection] = useState([]);
  const [currentSearchValue, setCurrentSearchValue] = useState(null);
  const [saveText, setSaveText] = useState(null);

  const fetchUserFeedsQuery = useQuery('fetchUserFeeds', fetchUserFeeds, {
    variables: [userId],
  });
  const currentInterests = fetchUserFeedsQuery?.data;
  const isFetched = fetchUserFeedsQuery?.isFetched;

  const handleSelection = (channelOrTopic) => {
    let newSelection = [...selection];

    let active = null;

    if (newSelection.filter((s) => s.subject_id === channelOrTopic.subject_id).length > 0) {
      newSelection = newSelection.filter((cT) => cT.subject_id !== channelOrTopic.subject_id);
      toggleTopicSubscription(userId, channelOrTopic.subject_id, false);
      active = true;
    } else {
      newSelection.push(channelOrTopic);
      toggleTopicSubscription(userId, channelOrTopic.subject_id, true);
      active = false;
    }

    setSelection(newSelection);

    toast.success(
      <ToastDialog
        active={!active}
        activateIcon={<CheckIcon fontSize="large" />}
        deactivateIcon={<CancelIcon />}
        activateText="Subscribed"
        deactivateText="Unsubscribed"
        title={`#${channelOrTopic?.title}`}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );
  };

  const handleInterestTitleClick = (topic) => {
    history.push(routes.interest.feed(topic?.subject_id));
  };

  const buildData = () => {
    if (!currentInterests) return [];
    return currentInterests.filter((cIF) => cIF.type === 'topic').map((cI) => ({
      // eslint-disable-next-line no-underscore-dangle
      subject_id: parseInt(cI.subject._id, 10),
      subject_type: 'Topic',
      title: language(cI.names),
    }));
  };

  useEffect(() => {
    if (!isFetched) return;
    const initialSelection = buildData();
    setSelection(initialSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInterests]);

  useEffect(() => {
    fetchUserFeedsQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const query = usePaginationQuery(`interestsSearchSelect${searchText}`, (guid, page) => searchInterests(searchText, page), {
    variables: [userId],
  });
  const { canFetchMore, fetchMore, data, isFetching } = query;
  const interests = (data ?? []).flatMap((page) => page.topics);

  const saveSearchExpressionQuery = useQuery('saveSearchExpressionTopic', saveSearchExpression, {
    variables: [userId, 'topics', saveText],
    enabled: false,
  });

  const getSearchExpressionQuery = useQuery('getSearchExpressionTopics', getSearchHistory, {
    variables: [userId, 'topics'],
  });
  const expressions = getSearchExpressionQuery?.data?.history;
  const searchHistory = currentSearchValue
    ? expressions.filter((ex) => ex.expression.includes(currentSearchValue))
    : expressions;

  useEffect(() => {
    if (saveSearchExpressionQuery.isFetched) {
      getSearchExpressionQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSearchExpressionQuery.isFetched]);

  useEffect(() => {
    if (saveText) {
      saveSearchExpressionQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveText]);

  const onSearchChange = (value) => {
    updateSearchText(value);
    setSaveText(value);
  };

  const clearSearch = () => {
    updateSearchText(null);
  };

  const onCloseSearchInfoDialog = () => {
    setShowDialog(false);
  };

  const renderTopics = () => interests.map((interest) => (
    <Interest
      isLoadingChildren={false}
      key={interest.id}
      data={interest}
      userId={userId}
      onChangeInterest={() => { }}
      fullName
    />
  ));

  return (
    <>
      <SearchInfoDialog show={showDialog} onCloseClick={onCloseSearchInfoDialog} />
      <Box>
        <Box className={classes.discoverHeaderRoot}>
          <Box className={classes.searchContainer}>
            {getSearchExpressionQuery.isFetched && (
              <SearchField
                placeholder="Search Topics"
                clearSearch={clearSearch}
                onSearchChange={onSearchChange}
                style={{ marginTop: 0, width: '100%' }}
                searchFieldStyle={{ width: '100%' }}
                fieldValue={searchText}
                autocomplete
                expressions={searchHistory}
                allExpressions={expressions}
                setCurrentSearchValue={setCurrentSearchValue}
                type="topics"
                refreshExpressions={() => getSearchExpressionQuery.refetch()}
              />
            )}
          </Box>
        </Box>
        <Box>
          <Link target="_blank" href="https://spotlightnews.us/learn-more.html" className={classes.tips}>Helpful tips on how to search</Link>
        </Box>

        {interests && interests.length > 0
          && (
            <InfiniteList
              isLoading={isFetching}
              loadMore={() => fetchMore()}
              hasMore={Boolean(canFetchMore)}
            >
              {renderTopics()}
            </InfiniteList>
          )}

        {(interests && searchText)
          && (
            <InterestCustom
              searchQuery={searchText}
              discover
            />
          )}

        {(interests && interests.length < 1 && !searchText) && (
          <InterestGroup
            handleClick={handleSelection}
            handleTitleClick={handleInterestTitleClick}
            selectedInterests={selection}
            fullWidth
            filterType="Topic"
          />
        )}

      </Box>
    </>
  );
};

DiscoverInterests.propTypes = {
  userId: PropTypes.string.isRequired,
  toggleTopicSubscription: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  updateSearchText: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    userId: state.user.profile.id,
  };
}

const mapDispatchToProps = (dispatch) => ({
  toggleTopicSubscription: (userId, topicId, isSubscribed) => {
    dispatch(toggleTopicSubscriptionAction(userId, topicId, isSubscribed));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverInterests);
