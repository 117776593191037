import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const styles = makeStyles((theme) => ({
  rCRoot: {
  },
}));

const ElectionRecentChange = ({ data }) => {
  const classes = styles();

  return (
    <Box className={classes.rCRoot}>
      {data?.title}: {data?.rating_changed_from} to {data?.rating_changed_to} {`(${data?.rating_date})`}
    </Box>
  );
};

ElectionRecentChange.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ElectionRecentChange;
