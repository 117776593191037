/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

const StreakIcon = ({ color = '#37bc98', height = 50, width = 50 }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 50.000000 50.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path d="M218 416 c4 -54 4 -55 -45 -108 -71 -76 -83 -97 -83 -146 0 -78 40
-135 109 -157 23 -7 22 -5 -9 33 -43 54 -50 113 -22 168 l21 39 1 -30 c0 -41
23 -71 71 -95 43 -23 58 -57 40 -96 -13 -30 -3 -30 40 -1 67 45 88 141 48 223
-29 62 -45 72 -41 26 3 -38 -11 -72 -24 -59 -4 4 -11 38 -16 75 -9 73 -41 147
-74 168 -20 14 -21 13 -16 -40z"
      />
    </g>
  </svg>

);

StreakIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default StreakIcon;
