import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Hidden,
  Grid,
  makeStyles,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import { Skeleton } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { connect } from 'react-redux';
import ArticleCard, { ArticleCardSkeleton } from '../ArticleCard';
import ArticleListItem from '../ArticleList/ArticleListItem';
import { language } from '../../../utils/helpers';
import MyNewsTopicTitle from '../../../containers/MyNews/MyNewsTopicTitle';
import { toggleChannelNotification } from '../../../actions/channel';
import { toggleTopicNotification } from '../../../actions/topics';
import ToastDialog from '../../ToastDialog/ToastDialog';
import MyNewsWriterTitle from '../../../containers/MyNews/MyNewsWriterTitle';

const styles = makeStyles((theme) => ({
  headersRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
    marginBottom: 8,
  },
  moreRoot: {
    marginTop: 12,
    marginBottom: 12,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  moreButtonRoot: {
    fontSize: '12px',
    cursor: 'pointer',
  },
  moreButton: {
    textTransform: 'capitalize',
  },
  moreButtonHighlight: {
    color: theme.palette.primary.main,
  },
  feedTitle: {
    cursor: 'pointer',
  },
  spotlightToast: theme.toastDialog,
}));

const ArticleGridSkeletons = () => (
  <>
    <Grid item sm={4} lg={3}>
      <ArticleCardSkeleton />
    </Grid>
    <Grid item sm={4} lg={3}>
      <ArticleCardSkeleton />
    </Grid>
    <Grid item sm={4} lg={3}>
      <ArticleCardSkeleton />
    </Grid>
    <Hidden mdDown>
      <Grid item lg={3}>
        <ArticleCardSkeleton />
      </Grid>
    </Hidden>
  </>
);

const FeedCards = ({
  feedCards, showPlaceholders, onClickMore,
  onChannelSubscribeClick, onTopicSubscribeClick, user,
  onWriterSubscribeClick,
}) => {
  const classes = styles();
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isNotifiable, setIsNotifiable] = useState(feedCards.is_notifiable);

  useEffect(() => {
    setIsNotifiable(feedCards.is_notifiable);
  }, [feedCards]);

  useEffect(() => () => {
    toast.dismiss();
  }, []);

  const handleSubscribeClick = () => {
    // eslint-disable-next-line no-underscore-dangle
    const subjectId = feedCards.subject._id;
    setIsSubscribed(!isSubscribed);

    let dialogTitle = (feedCards.type === 'topic') ? '#' : '';
    dialogTitle += language(feedCards.names);
    toast.success(
      <ToastDialog
        active={!isSubscribed}
        activateIcon={<CheckIcon fontSize="large" />}
        deactivateIcon={<CancelIcon fontSize="large" />}
        activateText="Subscribed"
        deactivateText="Unsubscribed"
        title={dialogTitle}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
        onClose: () => {
          if (feedCards.type === 'writer') {
            onWriterSubscribeClick(subjectId, !isSubscribed);
          } else if (feedCards.type === 'topic') {
            onTopicSubscribeClick(subjectId, !isSubscribed);
          } else {
            onChannelSubscribeClick(subjectId, !isSubscribed);
          }
        },
      },
    );
  };

  const handleNotificationClick = () => {
    if (feedCards.type === 'channel') {
      // eslint-disable-next-line no-underscore-dangle
      toggleChannelNotification(user.profile.id, feedCards.subject._id, !isNotifiable);
    } else {
      // eslint-disable-next-line no-underscore-dangle
      toggleTopicNotification(user.profile.id, feedCards.subject._id, !isNotifiable);
    }

    setIsNotifiable(!isNotifiable);

    let dialogTitle = (feedCards.type === 'topic') ? '#' : '';
    dialogTitle += language(feedCards.names);
    toast.success(
      <ToastDialog
        active={!isNotifiable}
        activateIcon={<NotificationsIcon fontSize="large" />}
        deactivateIcon={<NotificationsOffIcon fontSize="large" />}
        activateText="Enabled Notifications"
        deactivateText="Disabled Notifications"
        title={dialogTitle}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );
  };

  return (
    <>
      <Box className={classes.headersRoot}>
        <Typography className={classes.feedTitle} variant="h6" onClick={() => onClickMore(feedCards)}>
          {showPlaceholders
            ? <Skeleton variant="text" width={200} />
            : feedCards.type === 'topic'
              ? <MyNewsTopicTitle topic={feedCards} />
              : feedCards.type === 'writer'
                ? <MyNewsWriterTitle writer={feedCards} />
                : language(feedCards.names)}
        </Typography>
        {feedCards.type && (
          <Box>
            <IconButton color="primary" onClick={handleSubscribeClick}>
              {isSubscribed ? <CheckCircleIcon /> : <AddIcon />}
            </IconButton>

            <IconButton onClick={handleNotificationClick}>
              {(isSubscribed && isNotifiable)
                ? <NotificationsIcon color="primary" />
                : <NotificationsOffIcon color="primary" />}
            </IconButton>
          </Box>
        )}
      </Box>

      {/* Desktop View */}
      <Hidden smDown>
        <Grid container wrap="nowrap" spacing={3}>
          {showPlaceholders && <ArticleGridSkeletons />}
          {!showPlaceholders && feedCards.articles.slice(0, 4).map((article, i) => (
            i < 3 ? (
              <Grid key={article.id} item sm={4} lg={3}>
                <ArticleCard key={article.id} article={article} />
              </Grid>
            ) : (
              <Hidden key={article.id} mdDown>
                <Grid item lg={3}>
                  <ArticleCard key={article.id} article={article} />
                </Grid>
              </Hidden>
            )
          ))}
        </Grid>
      </Hidden>

      {/* Mobile View */}
      <Hidden mdUp>
        {showPlaceholders && <ArticleCardSkeleton />}
        {!showPlaceholders && <ArticleCard article={feedCards.articles[0]} />}
        {!showPlaceholders && feedCards.articles.slice(1).map((article, i) => (
          <ArticleListItem key={article.id} article={article} />
        ))}
      </Hidden>

      <Box className={classes.moreRoot}>
        {showPlaceholders && <Skeleton variant="text" width={200} />}
        {!showPlaceholders && (
          <Typography classes={{ root: classes.moreButtonRoot, button: classes.moreButton }} variant="button" onClick={() => onClickMore(feedCards)}>
            {feedCards.type === 'topic' ? 'More stories ' : 'More from '}
            <span className={classes.moreButtonHighlight}>
              {feedCards.type === 'topic' ? 'about ' : ''}{language(feedCards.names)}
            </span>
          </Typography>
        )}
      </Box>
    </>
  );
};

FeedCards.propTypes = {
  feedCards: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    articles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    names: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    type: PropTypes.string.isRequired,
    subject: PropTypes.shape().isRequired,
  }),
  showPlaceholders: PropTypes.bool.isRequired,
  onClickMore: PropTypes.func.isRequired,
  onChannelSubscribeClick: PropTypes.func.isRequired,
  onTopicSubscribeClick: PropTypes.func.isRequired,
  onWriterSubscribeClick: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(FeedCards);
