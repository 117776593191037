import React, { useState } from 'react';
import {
  Box, Button, IconButton, Drawer, Typography, makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SpotlightLogo from '../../common/SpotlightLogo';
import routes from '../../../routes';

const styles = makeStyles((theme) => ({
  overlayText: {
    [theme.breakpoints.up('md')]: {
      margin: '15px 30px',
    },
    margin: 15,
  },
  blurredBg: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    backgroundColor: 'rgba(0,0,0, 0.8)',
    backdropFilter: 'blur(10px)',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    alignItems: 'center',
    padding: 25,
    flexDirection: 'column',
  },
  overlayPaper: {
    backgroundColor: 'transparent',
    opacity: 0.99,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    color: '#999',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 5,
  },
}));

export default function SignUpOverlay() {
  const classes = styles();
  const [overlayOpen, setOverlayOpen] = useState(true);

  return (
    <>
      <Drawer
        classes={{ paper: classes.overlayPaper }}
        ModalProps={{
          hideBackdrop: true,
          disableScrollLock: true,
        }}
        anchor="bottom"
        open={overlayOpen}
      >
        <Box className={classes.blurredBg}>
          <SpotlightLogo height={36} />
          <Typography classes={{ root: classes.overlayText }}>
            Want to see more? Start using SpotlightNews for free
            -- the best information experience ever.
          </Typography>
          <Box className={classes.buttonContainer}>
            <Button href={routes.auth.signup} variant="contained" color="primary">
              Get Started
            </Button>
          </Box>
          <IconButton
            className={classes.closeButton}
            onClick={() => setOverlayOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Drawer>
    </>
  );
}
