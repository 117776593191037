/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

const ArticlesReadIcon = ({ color = '#37bc98', height = 50, width = 50 }) => (

  <svg
    width={width}
    height={height}
    viewBox="0 0 50.000000 50.000000"
    preserveAspectRatio="xMidYMid meet"
  >

    <g
      transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path d="M20 434 c-4 -76 3 -371 8 -376 4 -4 108 -8 232 -9 171 -1 225 2 225
11 0 9 -55 12 -228 11 l-228 -2 1 181 c1 100 -1 184 -4 187 -3 4 -6 2 -6 -3z"
      />
      <path d="M350 235 l0 -156 38 3 37 3 0 150 0 150 -37 3 -38 3 0 -156z" />
      <path d="M254 311 c-2 -2 -4 -56 -4 -118 l0 -114 38 3 37 3 0 114 0 114 -33 1
c-19 1 -36 -1 -38 -3z"
      />
      <path d="M150 159 l0 -80 38 3 37 3 3 78 3 77 -41 0 -40 0 0 -81z" />
      <path d="M50 119 l0 -40 38 3 c35 3 37 5 40 41 l3 37 -40 0 -41 0 0 -41z" />
    </g>
  </svg>

);

ArticlesReadIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ArticlesReadIcon;
