import React from 'react';

const ActiveIcon = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.26172 8.32422L8.65923 11.5018L5.39729 17.2627L3.11165 14.7357L-0.000221791 14.0852L3.26172 8.32422Z" fill="#ED6362" />
    <path d="M7.35938 10.7891L13.0437 8.18281L16.0031 14.8938L12.6125 15.2075L10.3188 17.5001L7.35938 10.7891Z" fill="#ED6362" />
    <ellipse cx="8.23275" cy="7.33741" rx="7.2015" ry="6.83741" fill="#FDBC4B" />
    <path d="M5.66016 6.84998L7.18215 8.65628C7.46678 8.99408 7.98285 9.00663 8.28356 8.68306L11.5757 5.14062" stroke="#EC9922" strokeWidth="0.735626" strokeLinecap="round" />
    <path d="M13.8882 7.33704C13.8882 4.50489 11.47 2.20898 8.48707 2.20898M7.07248 12.2874C4.77564 11.6972 3.08594 9.70447 3.08594 7.33704C3.08594 6.78305 3.17846 6.24957 3.34964 5.74978" stroke="#EC9922" strokeWidth="0.735626" />
  </svg>
);

export default ActiveIcon;
