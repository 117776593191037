import client from '../utils/client';

const getStats = async (userId) => {
  const url = `/stats/${userId}`;
  try {
    const response = await client().get(url);
    return {
      ...response?.data,
      recent_days: response?.data?.recent_days.reverse(),
    };
  } catch (e) {
    console.log(e);
  }
  return null;
};

const updateStatsSettings = async (
  userId, readingTimeDailyGoal, articleReadsDailyGoal, timezone,
) => {
  const url = `/stats/${userId}/settings`;
  await client().patch(url, {
    reading_time_daily_goal: readingTimeDailyGoal,
    article_reads_daily_goal: articleReadsDailyGoal,
    timezone });
};

export {
  getStats, updateStatsSettings,
};
