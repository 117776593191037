import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import routes from '../../../routes';

const styles = makeStyles((theme) => ({
  studentModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  studentDialog: {
    backgroundColor: theme.palette.background.paper,
    width: 1000,
    textAlign: 'center',
    position: 'relative',
    padding: '80px 10px',
    borderRadius: 20,
  },
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '& > svg': {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      top: 45,
    },
  },
  title: {
    textAlign: 'center',
    font: 'normal normal bold 32px/32px Roboto',
    letterSpacing: '-0.32px',
    color: theme.palette.text.primary,
    paddingTop: 30,
  },
  yesButton: {
    width: 258,
    padding: '10px 0px',
    fontFamily: 'Roboto',
    fontSize: 18,
    marginTop: 30,
  },
  noButton: {
    width: 258,
    padding: '10px 0px',
    fontFamily: 'Roboto',
    fontSize: 18,
    marginTop: 30,
    marginLeft: 20,
  },
  downloadIcon: {
    width: 100,
    height: 100,
  },
}));

const DownloadDialog = ({ guest, show, onCloseClick }) => {
  const classes = styles();
  const history = useHistory();

  const title = guest
    ? 'Sign up to download this article'
    : 'Sign in to download this article';

  const yesLabel = guest
    ? 'Yes, Sign me up'
    : 'Yes, Sign me in';

  const route = guest ? routes.auth.signup : routes.auth.signin;

  return (
    <>
      <Modal
        open={show}
        className={classes.studentModal}
      >
        <Box className={classes.studentDialog}>
          <Box className={classes.closeIcon}><CloseIcon onClick={() => onCloseClick(false)} /></Box>
          <GetAppIcon className={classes.downloadIcon} />
          <Typography className={classes.title}>{title}</Typography>
          <Button className={classes.yesButton} variant="contained" color="primary" onClick={() => history.push(route)}>{yesLabel}</Button>
          <Button className={classes.noButton} variant="contained" color="primary" onClick={() => onCloseClick(false)}>No, Cancel it</Button>
        </Box>
      </Modal>
    </>
  );
};

DownloadDialog.propTypes = {
  guest: PropTypes.bool,
  show: PropTypes.bool,
  onCloseClick: PropTypes.func,
};

export default DownloadDialog;
