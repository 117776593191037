import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MaterialLink } from '@material-ui/core';

// The use of React.forwardRef will no longer be required for react-router-dom v6.
// See https://github.com/ReactTraining/react-router/issues/6056
/* eslint-disable */
const Link = (props) => {
  const renderLink = () => (
    React.forwardRef((props, ref) => (
      <RouterLink innerRef={ref} {...props} />
    ))
  );

  return (
    <MaterialLink component={props.to ? renderLink() : undefined} {...props} />
  )
}
/* eslint-disable */
export default Link;
