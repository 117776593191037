import {
  Box, Button, makeStyles, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { getBrandTextColor } from '../../utils/helpers';
import emptyGraphic from './empty-graphic.svg';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  graphic: {
    marginBottom: 34,
  },
  textRoot1: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: 9,
    color: (channel) => (channel?.branding ? getBrandTextColor(channel?.branding) : 'inherit'),
  },
  textRoot2: {
    fontSize: '16px',
    color: '#666',
    marginBottom: 34,
  },
}));

const NotificationsEmpty = ({ onButtonClick, channel }) => {
  const classes = styles({ ...channel });

  return (
    <Box className={classes.container}>
      <img src={emptyGraphic} alt="Notifications" className={classes.graphic} />
      <Typography classes={{ root: classes.textRoot1 }}>
        There are no notifications to display at this time.
      </Typography>
      <Typography classes={{ root: classes.textRoot2 }}>
        Stay tuned to get content as soon as they show up.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
      >
        Find Great Stories
      </Button>
    </Box>
  );
};

NotificationsEmpty.propTypes = {
  onButtonClick: PropTypes.func,
  channel: PropTypes.shape(),
};

export default NotificationsEmpty;
