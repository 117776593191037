import React from 'react';
import PropTypes from 'prop-types';
import Alert from './Alert';

const formatError = (error) => {
  if (!error) { return null; }
  if (error.join) {
    return error.join(', ');
  }
  return `${error}`;
};

const ErrorBox = ({ error }) => (
  <Alert severity="error" title="Oops :(" message={formatError(error)} />
);

ErrorBox.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Error),
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default ErrorBox;
