import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  contentBox: {
    padding: '15px 0px',
    textAlign: 'left',
  },
}));

const DeleteAccountContent = () => {
  const classes = styles();
  return (
    <Box className={classes.contentBox}>
      <Typography variant="h6">Deleting Your Account</Typography>
      <p>
        We&apos;re sorry to see you go!
        If you still wish to delete your SpotlightNews account, please follow the steps below.
        Your account will then be scheduled for deletion in 3 days.
      </p>
      <p>
        We value each reader and hope this pause gives you a chance to reconsider.
        Your personalized news feed will be waiting if you change your mind!
        Please don&apos;t hesitate to reach out with any questions or issues that led to this.
        Our top priority is your experience as a member of the Spotlight community.
      </p>
      <p>If you have an individual paid premium subscription, please cancel that first.</p>
      <p>Wishing you all the best!</p>
      <p>The SpotlightNews Team</p>
    </Box>
  );
};

export default DeleteAccountContent;
