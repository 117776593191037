import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import PropTypes from 'prop-types';
import ElectionTopBar from './ElectionTopBar';
import { useQuery } from '../../hooks/useQuery';
import { elections } from '../../actions/elections';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    paddingTop: 21,
  },
  center: {
    width: 500,
    margin: '0px auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '1.2rem',
    fontWeight: 700,
    textAlign: 'left',
    background: 'red',
    padding: '10px 10px',
    color: '#ffffff',
  },
  table: {
    width: '100%',
    marginBottom: 30,
  },
  tH: {
    fontFamily: 'Roboto',
    fontSize: '1.2rem',
    fontWeight: 700,
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  row: {
  },
  tD: {
    textAlign: 'center',
    fontSize: '1.2rem',
    borderTop: '1px solid',
    borderColor: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  buttons: {
    width: 530,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px auto',
    marginBottom: 25,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  legendTitle: {
    marginLeft: 5,
  },
}));

const ElectionDrillDown = ({ webView, match }) => {
  const classes = styles();

  const { type, party } = match.params;

  const { data: electionData } = useQuery('elections', elections);

  if (!electionData) return (<></>);

  const electionDetailMap = Object.entries(electionData).map((election) => {
    if (election[1]?.name.toLowerCase().replace(' ', '-') === type) return election[1];
    return null;
  }).filter((el) => el != null);

  const drillDown = electionDetailMap[0]?.ratings.filter((rate) => rate?.label.toLowerCase().replace(' ', '-') === party);

  const electionDetail = electionDetailMap[0];
  const drillDownDetail = drillDown[0];
  const headers = drillDownDetail?.drill_down?.length > 0
    ? Object.keys(drillDownDetail?.drill_down[0]) : [];

  const camelCase = (str) => str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase().replace(/\s+/g, ''));

  const fixHeadValue = (headValue) => camelCase(headValue.replace('_', ' '));

  const getColor = (electionTapType) => {
    if (electionTapType.includes('Toss')) return '#acacac';
    if (electionTapType.includes('Solid D')) return '#245c94';
    if (electionTapType.includes('Likely D')) return '#347cbc';
    if (electionTapType.includes('Lean D')) return '#74a4d4';
    if (electionTapType.includes('Lean R')) return '#f47484';
    if (electionTapType.includes('Likely R')) return '#ec3c4c';
    if (electionTapType.includes('Solid R')) return '#bc2c3c';
    return null;
  };

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <ElectionTopBar
        title={electionDetail?.name}
        lastUpdate={electionDetail?.polled_at}
        lastRatingChanged={electionDetail?.last_rating_changed_at}
      />
      <Box className={classes.buttons}>
        {!webView && (
          <Button
            onClick={() => window.history.back()}
          >
            <ArrowBackIcon />
            <Typography className={classes.legendTitle}>Back</Typography>
          </Button>
        )}
      </Box>
      <Box className={classes.center}>
        <Box
          className={classes.title}
          style={{ backgroundColor: getColor(drillDownDetail?.label) }}
        >
          {drillDownDetail?.label}
        </Box>
        {drillDownDetail?.drill_down?.length > 0 && (
          <Box className={classes.tableBox}>
            <table className={classes.table}>
              <tr>
                {headers.map((h) => (<th className={classes.tH}>{fixHeadValue(h)}</th>))}
              </tr>
              {drillDownDetail?.drill_down.map((d) => (
                <tr className={classes.row}>
                  {headers.map((h) => (<td className={classes.tD}>{d[h]}</td>))}
                </tr>
              ))}
            </table>
          </Box>
        )}
      </Box>
    </Container>
  );
};

ElectionDrillDown.propTypes = {
  webView: PropTypes.bool,
  match: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ElectionDrillDown;
