import React from 'react';
import { SvgIcon } from '@material-ui/core';

const AppleIcon = (props) => (
  <SvgIcon {...props}>
    <path id="apple" d="M18.71,19.5c-.83,1.24-1.71,2.45-3.05,2.47s-1.77-.79-3.29-.79-2,.77-3.27.82c-1.31.05-2.3-1.32-3.14-2.53C4.25,17,2.94,12.45,4.7,9.39A4.884,4.884,0,0,1,8.82,6.88c1.28-.02,2.5.87,3.29.87s2.26-1.07,3.81-.91a4.644,4.644,0,0,1,3.64,1.98,4.555,4.555,0,0,0-2.15,3.81,4.413,4.413,0,0,0,2.68,4.04,11.053,11.053,0,0,1-1.38,2.83M13,3.5A4.546,4.546,0,0,1,15.94,2,4.376,4.376,0,0,1,14.9,5.19a3.651,3.651,0,0,1-2.95,1.42A4.274,4.274,0,0,1,13,3.5Z" transform="translate(-3.864 -2)" />
  </SvgIcon>
);

export default AppleIcon;
