import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MyStatsPanel from '../../components/MyStats/MyStatsPanel';
import ThemeContext from '../../context/theme/ThemeContext';

const MyStatsWebView = ({ userId }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const darkModeParam = urlParams.get('darkMode') === '1';
  // eslint-disable-next-line no-unused-vars
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  useEffect(() => {
    setDarkMode(darkModeParam);
  }, []);
  if (userId) return (<MyStatsPanel webView />);
  return (<div>Not Authenticated</div>);
};

MyStatsWebView.propTypes = {
  userId: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  userId: state.user?.profile?.id,
});

export default connect(mapStateToProps)(MyStatsWebView);
