import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getArticle as getArticleAction } from '../../actions/news';

import Article from './Article';

const SharedArticle = ({ getArticle }) => (
  <Article
    getArticle={getArticle}
  />
);

const mapDispatchToProps = (dispatch) => ({
  getArticle: (articleId) => {
    dispatch(getArticleAction(articleId, '/articles'));
  },
});

export default withRouter(connect(null, mapDispatchToProps)(SharedArticle));
