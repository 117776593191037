import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { format as formatDate, parseISO } from 'date-fns';
import tinycolor from 'tinycolor2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { language } from '../../utils/helpers';
import { useQuery } from '../../hooks/useQuery';
import { cancelSubscription, createIntentCancellation, createIntentSubscription } from '../../actions/subscription';
import routes from '../../routes';
import StudentDialog from './StudentDialog';
import OtherStoreDialog from './OtherStoreDialog';
import IntentDialog from './IntentDialog';
import CancellationDialog from './CancellationDialog';

const styles = makeStyles((theme) => ({
  listBox: {
    backgroundColor: theme.palette.background.paper,
    margin: '30px 20px',
    maxWidth: 240,
    minWidth: 240,
    paddingBottom: 30,
    position: 'relative',
  },
  listItem: {
    alignItems: 'flex-start',
  },
  listTitleContainer: {
    margin: '40px 10px',
    marginBottom: 10,
  },
  expiresAt: {
    marginBottom: 10,
  },
  listTitle: {
    fontFamily: 'Roboto',
    fontSize: 18,
  },
  listSubtitle: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
  },
  listButton: {
    width: '70%',
    maxWidth: 220,
    padding: '10px 0px',
    color: '#ffffff',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  listCancelButton: {
    width: '100%',
    maxWidth: 220,
    padding: '10px 0px',
    color: '#ffffff',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(255,0,0,1)',
    },
    backgroundColor: 'rgba(255,0,0,0.8)',
    border: '1px solid red',
  },
  listItemIcon: {
    minWidth: 'auto',
    paddingRight: 10,
  },
  listItemText: {
    margin: 0,
  },
  listItemTextTypography: {
    fontSize: 18,
  },
  currentPlan: {
    backgroundColor: '#37BC98',
    padding: '10px 20px',
    color: '#ffffff',
    maxWidth: 144,
    borderRadius: 10,
    fontFamily: 'Roboto',
    fontSize: 16,
    position: 'absolute',
    top: -20,
    left: 62,
  },
  dividerRoot: {
    margin: '30px 12px',
  },
  list: {
    padding: 0,
  },
}));

const PlanBox = ({
  plan,
  user,
  hideButton,
  type,
  currentState,
}) => {
  const classes = styles();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [showCancellationDialog, setShowCancellationDialog] = useState(false);
  const [showOtherStoreDialog, setShowOtherStoreDialog] = useState(false);
  const [showIntentSubscriptionDialog, setShowIntentSubscriptionDialog] = useState(false);
  const [showIntentCancellationDialog, setShowIntentCancellationDialog] = useState(false);
  const [cancelButtonLabel, setCancelButtonLabel] = useState('Cancel Plan');
  const [dataIntentMessage, setDataIntentMessage] = useState(null);
  const [hasSubscription, setHasSubscription] = useState(
    Boolean(plan?.user_subscription?.id)
    || plan?.subscribed,
  );
  const isGuest = !user?.email;

  const cancelSubscriptionQuery = useQuery('cancelSubscription', cancelSubscription, {
    enabled: false,
    variables: [user?.id, plan?.user_subscription?.id],
  });

  const intentSubscriptionQuery = useQuery('createIntentSubscription', createIntentSubscription, {
    enabled: false,
    variables: [user?.id, plan?.product_id],
  });
  const dataIntentSubscription = intentSubscriptionQuery?.data;

  const intentCancellationQuery = useQuery('createIntentintentCancellationQuery', createIntentCancellation, {
    enabled: false,
    variables: [user?.id, plan?.product_id],
  });
  const dataIntentCancellation = intentCancellationQuery?.data;

  const currentPlanObj = currentState?.map((cS) => {
    if (
      cS.product_id?.APP_STORE === plan?.product_id
      || cS.product_id?.PLAY_STORE === plan?.product_id
      || cS.product_id?.STRIPE === plan?.product_id
    ) {
      return cS;
    }
    return null;
  });
  const currentStoreArr = currentPlanObj?.map((cP) => cP?.store);
  const currentStore = currentStoreArr?.shift();

  const cancelSubscriptionClick = () => {
    if (currentStore !== 'STRIPE') {
      setShowOtherStoreDialog(true);
      return null;
    }

    intentCancellationQuery.refetch();
    return null;
  };

  const getPlanRoute = (currentPlan) => routes.premiumPurchase(currentPlan?.stripe_price_id);

  const onCloseStudentDialog = async (isStudent = false) => {
    if (isStudent) {
      await intentSubscriptionQuery.refetch();
    }
    setShowDialog(false);
  };

  const onCloseCancellationDialog = async (response = false) => {
    if (response) {
      cancelSubscriptionQuery.refetch();
    }
    setShowCancellationDialog(false);
  };

  const onCloseOtherStoreDialog = () => {
    setShowOtherStoreDialog(false);
  };

  const onCloseIntentSubscriptionDialog = (response = false) => {
    if (response) {
      setDataIntentMessage(null);
      const selectPlanRoute = getPlanRoute(plan);
      history.push(selectPlanRoute);
    }

    setShowIntentSubscriptionDialog(false);
  };

  const onCloseIntentCancellationDialog = (response = false) => {
    if (response) {
      setDataIntentMessage(null);
      setShowCancellationDialog(true);
    }

    setShowIntentCancellationDialog(false);
  };

  useEffect(() => {
    if (cancelSubscriptionQuery.isFetching) {
      setCancelButtonLabel('Cancelling...');
    }
    if (cancelSubscriptionQuery.isSuccess) {
      setCancelButtonLabel('Cancelled!');
      setTimeout(() => {
        setHasSubscription(false);
        window.location.reload();
      }, 2000);
    }
  }, [cancelSubscriptionQuery.isFetching, cancelSubscriptionQuery.isSuccess]);

  const planDescription = plan.details ? language(plan.details) : [];

  const selectPlanClick = async (currentPlan) => {
    if (isGuest) {
      const selectPlanRoute = routes.auth.signup;
      history.push(selectPlanRoute);
    } else if (currentPlan?.stripe_price_id) {
      if (type === 'student') {
        setShowDialog(true);
        return;
      }

      await intentSubscriptionQuery.refetch();
    }
  };

  useEffect(() => {
    if (!hideButton) {
      setDataIntentMessage(dataIntentSubscription?.message);
      setShowIntentSubscriptionDialog(Boolean(dataIntentSubscription?.message));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentSubscriptionQuery?.isFetching]);

  useEffect(() => {
    if (!hideButton) {
      setDataIntentMessage(dataIntentCancellation?.message);
      setShowIntentCancellationDialog(Boolean(dataIntentCancellation?.message));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentCancellationQuery?.isFetching]);

  useEffect(() => {
    setDataIntentMessage(null);
    setShowIntentSubscriptionDialog(false);
    setShowIntentCancellationDialog(false);
  }, []);

  let style = {};
  if (plan?.color) {
    const textColor = tinycolor(plan?.color).isLight() ? '#000000' : '#ffffff';
    style = { backgroundColor: plan?.color, color: textColor };
  }
  return (
    <Box key={plan.product_id} className={classes.listBox} style={style}>
      <StudentDialog plan={plan} show={showDialog} onCloseClick={onCloseStudentDialog} />
      <CancellationDialog
        plan={plan}
        show={showCancellationDialog}
        onCloseClick={onCloseCancellationDialog}
      />
      <OtherStoreDialog
        show={showOtherStoreDialog}
        onCloseClick={onCloseOtherStoreDialog}
        store={currentStore}
      />
      <IntentDialog
        show={showIntentSubscriptionDialog}
        onCloseClick={onCloseIntentSubscriptionDialog}
        message={dataIntentMessage}
      />
      <IntentDialog
        show={showIntentCancellationDialog}
        onCloseClick={onCloseIntentCancellationDialog}
        message={dataIntentMessage}
      />
      {hasSubscription
        && <Box className={classes.currentPlan}>Current Plan</Box>}
      <Box className={classes.listTitleContainer} style={plan.customStyle}>
        <Typography className={classes.listTitle}>{plan.name}</Typography>
        {(plan.price || plan.interval)
          && (
            <Typography className={classes.listSubtitle}>
              {plan.price && `$${plan.price}/`}{plan.interval}
            </Typography>
          )}
      </Box>

      {hasSubscription
        && plan?.user_subscription?.expires_at
        && (<Box className={classes.expiresAt}>Expires at: {formatDate(parseISO(plan?.user_subscription?.expires_at), 'LLLL dd, yyyy')}</Box>)}

      {!hideButton
        && !hasSubscription
        && (plan?.stripe_price_id || (isGuest && plan?.type === 'free'))
        && (
          <Button onClick={() => selectPlanClick(plan)} className={classes.listButton} variant="contained" color="primary">Select Plan</Button>
        )}

      {hasSubscription
        && !plan?.subscribed
        && !plan?.user_subscription?.cancellation
        && (
          <Button onClick={cancelSubscriptionClick} className={classes.listCancelButton} variant="outlined">{cancelButtonLabel}</Button>
        )}

      {hasSubscription
        && plan?.user_subscription?.cancellation
        && (<Box>Cancelled: {plan?.user_subscription?.cancellation}</Box>)}

      {planDescription && (
        <>
          <Divider classes={{ root: classes.dividerRoot }} />
          <List className={classes.list}>
            {planDescription.map((p) => (
              <ListItem key={plan.product_id} className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <CheckIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={(
                    <Typography className={classes.listItemTextTypography}>
                      {p}
                    </Typography>
                  )}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

PlanBox.propTypes = {
  plan: PropTypes.shape(),
  user: PropTypes.shape(),
  hideButton: PropTypes.bool,
  type: PropTypes.string,
  currentState: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
});

export default connect(mapStateToProps)(PlanBox);
