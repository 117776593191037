const initialState = {
  isLoading: false,
  channels: [],
  articles: [],
  topics: [],
  searchKeyword: '',
  searchIsLoading: false,
  searchArticles: {
    currentPage: 0,
    totalPages: 0,
    hasMore: 0,
    results: [],
  },
  searchTopics: {
    currentPage: 0,
    totalPages: 0,
    hasMore: 0,
    results: [],
  },
  searchChannels: {
    currentPage: 0,
    totalPages: 0,
    hasMore: 0,
    results: [],
  },
};

const discoverReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DISCOVER_SECTIONS_IS_LOADING': {
      return { ...state, isLoading: action.isLoading };
    }
    case 'DISCOVER_SET_SECTIONS': {
      return {
        ...state,
        sections: action.sections,
      };
    }
    case 'DISCOVER_CLEAR_SEARCH': {
      const searchArticles = { ...initialState.searchArticles };
      searchArticles.results = [];
      const searchTopics = { ...initialState.searchTopics };
      searchTopics.results = [];
      const searchChannels = { ...initialState.searchChannels };
      searchChannels.results = [];
      return {
        ...state,
        searchKeyword: '',
        searchArticles,
        searchTopics,
        searchChannels,
      };
    }
    case 'DISCOVER_SEARCH_IS_LOADING': {
      return { ...state, searchIsLoading: action.isLoading };
    }
    case 'DISCOVER_SET_SEARCH_TOPICS': {
      const newResults = state.searchTopics.results.slice(0).concat(action.results);
      return {
        ...state,
        searchTopics: {
          totalCount: action.totalCount,
          currentPage: action.currentPage,
          totalPages: action.totalPages,
          hasMore: action.currentPage < action.totalPages,
          results: newResults,
        },
      };
    }
    case 'DISCOVER_SET_SEARCH_CHANNELS': {
      const newResults = state.searchChannels.results.slice(0).concat(action.results);
      return {
        ...state,
        searchChannels: {
          totalCount: action.totalCount,
          currentPage: action.currentPage,
          totalPages: action.totalPages,
          hasMore: action.currentPage < action.totalPages,
          results: newResults,
        },
      };
    }
    case 'DISCOVER_SET_SEARCH_ARTICLES': {
      const newResults = state.searchArticles.results.slice(0).concat(action.results);
      return {
        ...state,
        searchArticles: {
          totalCount: action.totalCount,
          currentPage: action.currentPage,
          totalPages: action.totalPages,
          hasMore: action.currentPage < action.totalPages,
          results: newResults,
        },
      };
    }
    case 'DISCOVER_SET_SEARCH_KEYWORD': {
      return {
        ...state,
        searchKeyword: action.keyword,
      };
    }
    default: {
      return state;
    }
  }
};

export default discoverReducer;
