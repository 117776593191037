import React, { useContext, useEffect } from 'react';
import ThemeContext from '../../context/theme/ThemeContext';
import ElectionsLegend from './ElectionsLegend';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const ElectionsLegendWebView = () => {
  useBackgroundClass('elections');
  const urlParams = new URLSearchParams(window.location.search);
  const darkModeParam = urlParams.get('darkMode') === '1';
  // eslint-disable-next-line no-unused-vars
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  useEffect(() => {
    setDarkMode(darkModeParam);
  }, []);
  return (<ElectionsLegend />);
};

export default ElectionsLegendWebView;
