import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory } from 'react-router';
import { useQuery } from '../../hooks/useQuery';
import InterestNavBar from '../../components/Nav/InterestNavBar';
import MainContent from '../../components/MainContent';
import routes from '../../routes';
import { fetchInterest, fetchInterestArticles } from '../../actions/topics';
import ArticlePagination, { TopArticlesEmpty } from '../../components/Article/ArticlePagination';
import { language } from '../../utils/helpers';

const styles = makeStyles((theme) => ({
  articleFeed: {
    paddingLeft: 21,
    paddingRight: 21,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

const MyNewsTopic = ({ userId, feedId }) => {
  const classes = styles();
  const history = useHistory();

  const fetchInterestQuery = useQuery('fetchInterestDetail', fetchInterest, {
    variables: [userId, feedId],
  });
  const { data: interest } = fetchInterestQuery;

  // eslint-disable-next-line no-underscore-dangle
  const topicId = interest?.subject?._id;

  useEffect(() => {
    fetchInterestQuery.refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interest?.subject?.search_query?.query?.expression]);

  const handleBackClick = () => {
    if (history.length <= 2) { // No back page
      history.push(routes.news.main);
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return !topicId
    ? (
      <></>
    ) : (
      <>
        <InterestNavBar
          feedTopic={interest}
          isSignedIn
          onBackClick={handleBackClick}
          reloadTopic={() => {
            fetchInterestQuery.refetch();
          }}
        />
        <MainContent includeBackgroundImage={false} paddingTop={0}>
          <Box className={classes.articleFeed}>
            {!fetchInterestQuery.isFetching && (
              <ArticlePagination
                loadsContext="interestArticles"
                load={(guid, page) => fetchInterestArticles(topicId, page)}
                label={language(interest?.names)}
                EmptyStateComponent={TopArticlesEmpty}
              />
            )}
          </Box>
        </MainContent>

      </>
    );
};

MyNewsTopic.propTypes = {
  userId: PropTypes.string.isRequired,
  feedId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  feeds: state.news.feeds,
  feedIsLoading: state.news.feedIsLoading,
});

export default connect(mapStateToProps, null)(MyNewsTopic);
