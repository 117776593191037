import React from 'react';
import { SvgIcon } from '@material-ui/core';

const GoogleIcon = (props) => (
  <SvgIcon {...props}>
    <path id="google" d="M21.35,11.1H12.18v2.73h6.51c-.33,3.81-3.5,5.44-6.5,5.44A7.17,7.17,0,0,1,5,12a7.167,7.167,0,0,1,7.2-7.27A7.028,7.028,0,0,1,17.1,6.7L19,4.72A9.669,9.669,0,0,0,12.1,2,10.168,10.168,0,0,0,2.03,12,10.125,10.125,0,0,0,12.25,22c5.35,0,9.25-3.67,9.25-9.09a9.012,9.012,0,0,0-.15-1.81Z" transform="translate(-2.03 -2)" />
  </SvgIcon>
);

export default GoogleIcon;
