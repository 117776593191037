import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import querystring from 'query-string';

import { forgotPasswordConfirm } from '../actions/user';

import ForgotPasswordConfirmForm from '../components/Auth/ForgotPasswordConfirmForm';

import routes from '../routes';

const message = 'Your password has been changed successfully.';

const ForgotPasswordConfirm = () => {
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = async ({ password }) => {
    const { token } = querystring.parse(location.search);
    await forgotPasswordConfirm(token, password);
    history.push(routes.auth.signin, { flash: { message } });
  };

  return (
    <ForgotPasswordConfirmForm onSubmit={handleSubmit} />
  );
};

export default ForgotPasswordConfirm;
