import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogTitle,
  Fab,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = makeStyles((theme) => ({
  fab: {
    bottom: 10,
    position: 'fixed',
    border: 10,
    zIndex: 10000,
    right: 10,
    textTransform: 'capitalize',
    background: '#000000',
    color: '#eaeaea',
    borderColor: '#eaeaea',
    '&:hover': {
      backgroundColor: '#000000',
    },
  },
  qrCode: {
    '& > img': {
      width: 280,
      maxWidth: '100%',
    },
    textAlign: 'center',
    marginBottom: 20,
  },
  Or: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: 20,
  },
  dialogContainer: {
    padding: '20px 50px',
  },
  line: {
    height: 1,
    width: 150,
    background: '#404040',
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogSubTitle: {
    textAlign: 'center',
    marginBottom: 20,
  },
  store: {
    margin: 10,
  },
  stores: {
    textAlign: 'center',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 8,
    top: 8,
  },
}));

const GetAppDialog = ({ show, setShow }) => {
  const classes = styles();

  return (
    <>
      <Fab onClick={() => setShow(true)} variant="extended" className={classes.fab}>
        Get App
      </Fab>
      <Dialog className={classes.dialog} onClose={() => false} aria-labelledby="simple-dialog-title" open={show}>
        <CloseIcon className={classes.closeIcon} onClick={() => setShow(false)} />
        <Box className={classes.dialogContainer}>
          <DialogTitle className={classes.dialogTitle}>Download the SpotlightNews app</DialogTitle>
          <Typography className={classes.dialogSubTitle}>Scan the QR Code below</Typography>

          <Box className={classes.qrCode}>
            <img src="/images/qrcode.png" alt="Scan the QR Code" />
          </Box>

          <Box className={classes.Or}>
            <Box className={classes.line} />
            <span>OR</span>
            <Box className={classes.line} />
          </Box>

          <Box className={classes.stores}>
            <Link target="_blank" href="https://apps.apple.com/us/app/spotlightnews/id1291820344">
              <img className={classes.store} src="https://spotlightnews.us/images/new-design/ico-appstore.png" alt="Get it on App Store" />
            </Link>
            <Link target="_blank" href="https://play.google.com/store/apps/details?id=net.spotlightlabs.spotlight">
              <img className={classes.store} src="https://spotlightnews.us/images/new-design/ico-android.png" alt="Get it on Google Play" />
            </Link>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

GetAppDialog.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
};

export default GetAppDialog;
