import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const styles = makeStyles({
  root: {
    lineHeight: 'initial',
    paddingLeft: 2,
    paddingRight: 2,
  },
});

export default ({ size = 16 }) => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <CircularProgress size={size} />
    </div>
  );
};
