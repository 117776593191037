import React from 'react';
import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ElectionTopBar from './ElectionTopBar';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    paddingTop: 21,
    paddingBottom: 21,
  },
  color: {
    width: 80,
    height: 40,
  },
  centerContent: {
    width: 530,
    padding: 15,
    margin: '0px auto',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
    '& > button > span > svg': {
      fontSize: '3rem',
    },
  },
  title: {
    fontSize: '2.125rem',
    fontWeight: 400,
    textAlign: 'left',
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.825rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.525rem',
    },
  },
  label: {
    fontSize: '1.525rem',
    marginLeft: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
  },
}));

const ElectionsLegendList = () => {
  const classes = styles();
  const legends = {
    democrat: {
      label: 'Democrat - Not up for election',
      color: "url('/images/bg-democrat.jpg') center/cover",
    },
    solidDemocrat: {
      label: 'Solid Democrat',
      color: '#245c94',
    },
    likelyDemocrat: {
      label: 'Likely Democrat',
      color: '#347cbc',
    },
    leanDemocrat: {
      label: 'Lean Democrat',
      color: '#74a4d4',
    },
    tossUps: {
      label: 'Toss Ups',
      color: '#acacac',
    },
    leanRepublican: {
      label: 'Lean Republican',
      color: '#f47484',
    },
    likelyRepublican: {
      label: 'Likely Republican',
      color: '#ec3c4c',
    },
    solidRepublican: {
      label: 'Solid Republican',
      color: '#bc2c3c',
    },
    republican: {
      label: 'Republican - Not up for election',
      color: "url('/images/bg-republican.jpg') center/cover",
    },
  };

  const closeButton = () => {
    window.history.back();
  };

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <ElectionTopBar title="Election Ratings" />
      <Box className={classes.centerContent}>
        <Box className={classes.closeIcon}>
          <Button onClick={closeButton}>
            <HighlightOffIcon />
          </Button>
        </Box>
        <Typography className={classes.title}>Legend</Typography>
        <Box className={classes.legendList}>
          {Object.values(legends).map((l) => (
            <Box className={classes.legendItem}>
              <Box className={classes.color} style={{ background: l.color }} />
              <Box className={classes.label}>{l.label}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default ElectionsLegendList;
