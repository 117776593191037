import React from 'react';
import mainLogo from './spotlight-logo.png';

const ForReadersNotFollowers = React.memo(() => (
  <img
    src={mainLogo}
    alt="Spotlight - For Readers, Not Followers"
    style={{ width: 230 }}
  />
));

export default ForReadersNotFollowers;
