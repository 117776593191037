import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const styles = makeStyles({
  title: {
    paddingTop: 27,
    marginBottom: 16,
  },
});

const Title = ({ title }) => {
  const classes = styles();
  return (
    <Typography variant="h5" className={classes.title}>
      {title}
    </Typography>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;
