/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

const TopicsSharedIcon = ({ color = '#37bc98', height = 50, width = 50 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 52 52"
    fill={color}
  >

    <path d="M49.2,13.3h-6.5l2.8-10.7V2.4c0-0.4-0.3-0.8-0.8-0.8h-5.2c-0.4,0-0.7,0.3-0.8,0.7l-2.8,11.1h-13l2.8-10.7
	V2.4c0-0.4-0.3-0.8-0.8-0.8h-5.2c-0.4,0-0.7,0.3-0.8,0.7l-2.9,11H8.8c-0.4,0-0.7,0.2-0.8,0.6l-1.3,4.9v0.2c0,0.4,0.3,0.8,0.8,0.8
	h6.8l-3.2,12.5h-7c-0.4,0-0.7,0.2-0.8,0.6L2,37.6v0.2c0,0.4,0.3,0.8,0.8,0.8h6.6L6.6,49.5v0.2c0,0.4,0.3,0.8,0.8,0.8h5.2
	c0.4,0,0.7-0.2,0.8-0.7l2.9-11.2h12.9l-2.8,10.8v0.2c0,0.4,0.3,0.8,0.8,0.8h5.2c0.4,0,0.7-0.2,0.8-0.7L36,38.5h7.1
	c0.4,0,0.7-0.2,0.8-0.7l1.3-4.9v-0.2c0-0.4-0.3-0.8-0.8-0.8h-6.7L41,19.4h6.9c0.4,0,0.7-0.2,0.8-0.7l1.3-4.9v-0.2
	C50,13.6,49.6,13.3,49.2,13.3L49.2,13.3z M30.9,32.3H18l3.2-12.5h12.9C34.1,19.8,30.9,32.3,30.9,32.3z"
    />
  </svg>
);

TopicsSharedIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default TopicsSharedIcon;
