import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import WriterIcon from '../WriterIcon/WriterIcon';
import WriterSubscription from '../WriterSubscription/WriterSubscription';

const styles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  button: {
    color: theme.palette.text.primary,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  writerTitle: {
    fontSize: 14,
    color: theme.palette.text.primary,
    fontWeight: '500',
  },
  writerLink: {
    textDecoration: 'none',
  },
  infoLabel: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: 12,
  },
  articlesCount: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    fontSize: 12,
  },
  icon: {
    background: '#37BC98',
    borderRadius: 65,
    width: 35,
    height: 35,
    marginRight: 10,
    padding: 5,
    '& > svg': {
      width: 25,
    },
  },
  writerInfo: {

  },
  writerInfoContainer: {
    padding: '10px 0px',
    '& > a': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const WriterBox = ({ writer, onClick, subscribable, onToggleSubscription }) => {
  const classes = styles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.writerInfoContainer}>
        <Link
          className={classes.writerLink}
          to={routes.writers.main(writer.slug)}
          onClick={onClick}
        >
          <WriterIcon />

          <Box className={classes.writerInfo}>
            <Typography className={classes.writerTitle}>{writer.name}</Typography>

            <Box className={classes.infoLabel}>{writer?.alternative_names && writer?.alternative_names.join(', ')}</Box>

            <Box className={classes.articlesCount}>{writer.articles_count} Article{writer.articles_count > 1 && 's'}</Box>
          </Box>
        </Link>
      </Box>
      {subscribable && (
        <WriterSubscription
          classes={{ root: classes.button }}
          writer={writer}
          isSubscribed={writer.is_subscribed}
          showTooltip={false}
          onToggleSubscription={onToggleSubscription}
        />
      )}
    </Box>
  );
};

WriterBox.propTypes = {
  writer: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
  subscribable: PropTypes.bool,
  onToggleSubscription: PropTypes.func,
};

WriterBox.defaultProps = {
  subscribable: true,
};

export default WriterBox;
