import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  makeStyles,
  Avatar,
  Box,
} from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { Form, Formik, Field } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  updateUser,
  updateUserEmail,
  setUserSession as setUserSessionAction,
} from '../../../actions/user';
import { withErrorReporting } from '../../../utils/errors';

const styles = makeStyles({
  avatarRoot: {
    width: 150,
    height: 150,
    margin: '0 auto 15px',
    fontSize: '3rem',
  },
  avatarButton: {
    color: 'white',
    position: 'absolute',
    marginLeft: '50%',
    marginTop: -70,
    left: 28,
  },
});

const EditProfileSchema = Yup.object().shape({
  username: Yup.string().required().label('Username'),
  email: Yup.string().email().required().label('Email'),
});

function getBase64Content(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(btoa(reader.result));
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });
}

const EditProfileDialog = ({
  profile,
  children,
  setUserSession,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = styles();
  const inputRef = useRef(null);
  const [file, setFile] = useState();
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    setFile(undefined); // cleanup file
  }, [isOpen]);

  useEffect(() => {
    const url = file ? URL.createObjectURL(file) : undefined;
    setFileUrl(url);
    return () => URL.revokeObjectURL(url);
  }, [file]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = withErrorReporting(async ({ username, email }, actions) => {
    try {
      const updatedProfile = await updateUser(profile.id, {
        username,
        avatar: file
          ? {
            base64_content: await getBase64Content(file),
            filename: file.name,
          }
          : undefined,
      });
      setUserSession({ ...updatedProfile, email: profile.email });

      if (email !== profile.email) {
        await updateUserEmail(profile.id, email);
        toast.info((
          <div>
            <p>Email update pending.</p>
            <p>Please check <b>{email}</b> to confirm email update.</p>
          </div>
        ));
      }
      setIsOpen(false);
    } finally {
      actions.setSubmitting(false);
    }
  });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <>
      {children({ isOpen, open: handleOpen, close: handleClose })}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="edit-profile-dialog-title"
      >
        <Formik
          validationSchema={EditProfileSchema}
          onSubmit={handleSubmit}
          initialValues={{
            username: profile.username,
            email: profile.email,
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogTitle id="edit-profile-dialog-title">
                Edit Profile
              </DialogTitle>
              <DialogContent>
                <Box>
                  <Avatar
                    classes={{ root: classes.avatarRoot }}
                    src={fileUrl || profile.avatar}
                  >
                    {profile.username ? profile.username[0] : ''}
                  </Avatar>
                  <Fab
                    classes={{ root: classes.avatarButton }}
                    color="primary"
                    size="small"
                    aria-label="choose picture"
                    onClick={() => inputRef.current.click()}
                  >
                    <input
                      ref={inputRef}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <PhotoCameraIcon />
                  </Fab>
                </Box>
                <Field
                  id="username"
                  name="username"
                  label="Edit Display Name"
                  type="text"
                  component={TextField}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  required
                />
                {!profile.provider && (
                  <Field
                    id="email"
                    name="email"
                    label="Edit Email"
                    type="email"
                    component={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Cancel
                </Button>
                <Button disabled={isSubmitting} type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

EditProfileDialog.propTypes = {
  children: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string,
  }).isRequired,
  setUserSession: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setUserSession: setUserSessionAction,
};

export const Pure = EditProfileDialog;

export default connect(null, mapDispatchToProps)(EditProfileDialog);
