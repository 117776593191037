import React from 'react';
import PropTypes from 'prop-types';
import { Box, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import PremiumAboveLimitMessage from '../FeedbackMessages/PremiumAboveLimitMessage';

const styles = makeStyles((theme) => ({
  premiumModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  premiumDialog: {
    backgroundColor: theme.palette.background.default,
    width: 500,
    padding: 20,
    textAlign: 'center',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '& > svg': {
      fontSize: 36,
    },
  },
}));

const PremiumDialog = ({ show, onCloseClick, channel }) => {
  const classes = styles();

  return (
    <>
      <Modal
        open={show}
        className={classes.premiumModal}
      >
        <Box className={classes.premiumDialog}>
          <Box className={classes.closeIcon}><CloseIcon onClick={onCloseClick} /></Box>
          <PremiumAboveLimitMessage channel={channel} />
        </Box>
      </Modal>
    </>
  );
};

PremiumDialog.propTypes = {
  show: PropTypes.bool,
  onCloseClick: PropTypes.func,
  channel: PropTypes.shape(),
};

export default PremiumDialog;
