import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Box,
  Button,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import OrDivider from './OrDivider';
import OAuth from './OAuth';
import Link from '../common/Link';
import Form from '../Form/Form';

import routes from '../../routes';
import SpotlightLogo from '../common/SpotlightLogo/SpotlightLogo';
import { authenticateGuest } from '../../actions/user';

const styles = makeStyles((theme) => ({
  orDividerRoot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
  },
  dividerRoot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  forgotPasswordLinkRoot: {
    display: 'block',
    marginBottom: theme.spacing(2),
  },
  signInButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
  },
  errorMessageRoot: {
    color: theme.palette.error.main,
  },
  spotlightLogo: {
    marginRight: 15,
    height: 35,
  },
  logoDark: {
    display: theme.palette.type === 'light' ? 'none' : 'block',
  },
  logoLight: {
    display: theme.palette.type === 'light' ? 'block' : 'none',
  },
  rememberMe: {
    textAlign: 'left',
  },
  createAccountButton: {
    color: theme.palette.text.primary,
  },
  continueAsGuest: {
    backgroundColor: '#4c4c4c',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#3f3f3f',
    },
  },
}));

const SignInSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().min(8).required(),
});
const initialValues = { email: '', password: '', rememberMe: true };

const SignInForm = ({ onSubmit, handleGuestLogin, deleteAccount }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const handleGuestClick = async () => {
    const response = await dispatch(authenticateGuest());
    if (response?.success) {
      handleGuestLogin();
    }
  };

  return (
    <>
      <Form
        validationSchema={SignInSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        submittingLabel="Signing In..."
      >
        {({ Submit }) => (
          <>
            <Field
              id="email"
              name="email"
              label="Email"
              type="email"
              component={TextField}
              variant="outlined"
              classes={{ root: classes.inputRoot }}
              margin="dense"
              fullWidth
              required
            />
            <Field
              id="password"
              name="password"
              label="Password"
              type="password"
              component={TextField}
              variant="outlined"
              classes={{ root: classes.inputRoot }}
              margin="dense"
              fullWidth
              required
            />
            {!deleteAccount && (
            <Box className={classes.rememberMe}>
              <Field
                color="primary"
                name="rememberMe"
                Label={{ label: 'Remember Me' }}
                component={CheckboxWithLabel}
              />
            </Box>
            )}
            <Submit
              variant="contained"
              color="primary"
              size="large"
              classes={{ root: classes.submitButton }}
            >
              <Box className={classes.spotlightLogo}>
                <SpotlightLogo color="#fff" small />
              </Box>
              Sign in with Spotlight
            </Submit>
            <Link
              variant="subtitle2"
              to={routes.auth.forgotPassword}
              classes={{
                root: classes.forgotPasswordLinkRoot,
              }}
            >
              Forgot your password?
            </Link>
            {!deleteAccount && (
              <Link
                variant="subtitle2"
                to={routes.emailConfirmation}
                classes={{
                  root: classes.forgotPasswordLinkRoot,
                }}
              >
                Need a confirmation email?
              </Link>
            )}
          </>
        )}
      </Form>
      <OrDivider classes={{ root: classes.orDividerRoot }} />
      {!deleteAccount && (
        <>
          <Button
            className={classes.createAccountButton}
            variant="outlined"
            size="large"
            fullWidth
            component={Link}
            to={routes.auth.signup}
          >
            <Box className={classes.spotlightLogo}>
              <Box className={classes.logoLight}>
                <SpotlightLogo color="#000" small />
              </Box>
              <Box className={classes.logoDark}>
                <SpotlightLogo color="#fff" small />
              </Box>
            </Box>
            Create an Account
          </Button>
          <OrDivider classes={{ root: classes.orDividerRoot }} />
          <Button
            className={classes.continueAsGuest}
            variant="outlined"
            size="large"
            fullWidth
            onClick={handleGuestClick}
          >
            <Box className={classes.spotlightLogo}>
              <Box>
                <SpotlightLogo color="#fff" small />
              </Box>
            </Box>
            Continue as Guest
          </Button>
          <Divider classes={{ root: classes.dividerRoot }} />
        </>
      )}
      <OAuth deleteAccount={deleteAccount} />
    </>
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleGuestLogin: PropTypes.func.isRequired,
  deleteAccount: PropTypes.bool,
};

export default SignInForm;
