import { useState, useRef, useEffect } from 'react';

export default function useComponentWidth() {
  const [componentWidth, setComponentWidth] = useState(0);
  const componentRef = useRef(null);

  useEffect(() => {
    let timeout;
    const getComponentWidth = () => {
      if (timeout) return;
      timeout = setTimeout(() => {
        timeout = undefined;
        if (componentRef.current?.offsetWidth > 0) {
          setComponentWidth(componentRef.current.offsetWidth);
        }
      }, 200);
    };

    getComponentWidth();
    window.addEventListener('resize', getComponentWidth);
    return () => window.removeEventListener('resize', getComponentWidth);
  }, []);

  return { componentRef, componentWidth };
}
