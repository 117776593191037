/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

const ReadPercentageIcon = ({ color = '#37bc98', height = 50, width = 50 }) => (

  <svg
    width={width}
    height={height}
    viewBox="0 0 58 58"
  >
    <g>
      <g>
        <circle style={{ fill: 'transparent' }} cx="29" cy="29" r="29" />
        <path
          style={{ fill: color }}
          d="M29,4c13.785,0,25,11.215,25,25S42.785,54,29,54S4,42.785,4,29S15.215,4,29,4 M29,0
          C12.984,0,0,12.984,0,29s12.984,29,29,29s29-12.984,29-29S45.016,0,29,0L29,0z"
        />
        <g>
          <path
            style={{ fill: color }}
            d="M29,5.879c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1
            C30,6.326,29.552,5.879,29,5.879z"
          />
          <path
            style={{ fill: color }}
            d="M29,48.879c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1
            C30,49.326,29.552,48.879,29,48.879z"
          />
          <path
            style={{ fill: color }}
            d="M51,27.879h-1c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1S51.552,27.879,51,27.879z"
          />
          <path style={{ fill: color }} d="M8,27.879H7c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1S8.552,27.879,8,27.879z" />
          <path
            style={{ fill: color }}
            d="M43.849,12.615l-0.707,0.707c-0.391,0.391-0.391,1.023,0,1.414
            c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l0.707-0.707c0.391-0.391,0.391-1.023,0-1.414
            S44.24,12.224,43.849,12.615z"
          />
          <path
            style={{ fill: color }}
            d="M13.444,43.021l-0.707,0.707c-0.391,0.391-0.391,1.023,0,1.414
            c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l0.707-0.707c0.391-0.391,0.391-1.023,0-1.414
            S13.834,42.631,13.444,43.021z"
          />
          <path
            style={{ fill: color }}
            d="M44.556,43.021c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l0.707,0.707
            c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L44.556,43.021z"
          />
          <path
            style={{ fill: color }}
            d="M14.151,12.615c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l0.707,0.707
            c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L14.151,12.615z"
          />
        </g>
        <path
          style={{ fill: color }}
          d="M26,29.879h-8c-0.553,0-1-0.447-1-1s0.447-1,1-1h8c0.553,0,1,0.447,1,1S26.553,29.879,26,29.879z"
        />
        <path
          style={{ fill: color }}
          d="M29,26.879c-0.553,0-1-0.447-1-1v-11c0-0.553,0.447-1,1-1s1,0.447,1,1v11
          C30,26.432,29.553,26.879,29,26.879z"
        />
        <path
          style={{ fill: color }}
          d="M29,32.879c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S31.206,32.879,29,32.879z M29,26.879
          c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S30.103,26.879,29,26.879z"
        />
      </g>
      <g>
        <circle style={{ fill: '#72e9c8' }} cx="44.89" cy="45.5" r="12.5" />
        <path
          style={{ fill: '#229a7a' }}
          d="M44.89,35c-4.718,0-8.88,3.182-10.121,7.737c-0.146,0.533,0.169,1.083,0.702,1.228
          c0.528,0.143,1.082-0.168,1.228-0.702C37.703,39.575,41.071,37,44.89,37c4.687,0,8.5,3.813,8.5,8.5s-3.813,8.5-8.5,8.5
          c-3.365,0-6.378-2.001-7.734-5h5.234v-2h-6h-1h-1v8h2v-3.341l0,0v-0.023C38.323,54.31,41.456,56,44.89,56
          c5.79,0,10.5-4.71,10.5-10.5S50.68,35,44.89,35z"
        />
      </g>
    </g>
  </svg>
);

ReadPercentageIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ReadPercentageIcon;
