/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { format as formatDate } from 'date-fns';

const styles = makeStyles((theme) => ({
  dayName: {
    textAlign: 'center',
  },
}));

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
    textAlign: 'center',
    width: 100,
    height: 126,
    marginTop: 10,
  },
  rootMobile: {
    position: 'relative',
    textAlign: 'center',
    width: 25,
    height: 28,
    marginTop: 10,
  },
  bottom: {
    color: theme.palette.background.paper,
    position: 'absolute',
    left: 0,
  },
  top: {
    color: '#37BC98',
    position: 'absolute',
    left: 0,
  },
  svg: {
    transform: 'rotate(180deg)',
  },
  circle: {
    strokeLinecap: 'round',
  },
  percentage: {
    fontSize: 28,
    paddingTop: 24,
  },
  greenDot: {
    height: 10,
    width: 10,
    background: theme.palette.primary.main,
    margin: '0px auto',
    marginTop: 19,
    position: 'relative',
    zIndex: 1,
    borderRadius: 20,
    [theme.breakpoints.down('md')]: {
      height: 5,
      width: 5,
      position: 'absolute',
      marginTop: 21,
      marginLeft: 10,
    },
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={props.isMobile ? classes.rootMobile : classes.root}>
      <CircularProgress
        variant="determinate"
        classes={{ root: classes.bottom }}
        size={80}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        classes={{ root: classes.top, svg: classes.svg }}
        size={80}
        thickness={4}
        {...props}
      />
      {!props.isMobile && (
        <Typography className={classes.percentage} variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}
        </Typography>
      )}
      {props.value <= 0 && (
        <div className={classes.greenDot} />
      )}
    </div>
  );
}

const MyStatsGoalTracker = ({ days, readingTimeDailyGoal, isMobile = false }) => {
  const classes = styles();

  const getWeekday = (date) => {
    if (!date) return null;
    return formatDate(new Date(`${date} 00:00:00`), 'EEEE');
  };

  return (
    <>
      {days && days.map((recentDay) => {
        // eslint-disable-next-line max-len, no-mixed-operators
        if (!recentDay) return null;
        let progressValue = ((recentDay?.reading_time / readingTimeDailyGoal) * 100);
        progressValue = (progressValue > 100) ? 100 : progressValue;
        return (
          <>
            <Box
              className={classes.circularProgressBox}
            >
              <Typography className={classes.dayName}>
                {getWeekday(recentDay?.date)?.slice(0, 3)}
              </Typography>
              <FacebookCircularProgress
                size={isMobile ? '25px' : '100px'}
                isMobile={isMobile}
                value={progressValue}
                className={classes.circularProgress}
              />
            </Box>
          </>
        );
      })}
    </>
  );
};

MyStatsGoalTracker.propTypes = {
  days: PropTypes.arrayOf(PropTypes.shape()),
  readingTimeDailyGoal: PropTypes.number,
  isMobile: PropTypes.bool,
};

export default MyStatsGoalTracker;
