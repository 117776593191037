import React from 'react';

const EmailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="162" height="117" viewBox="0 0 162 117">
    <g fill="none" fillRule="evenodd">
      <path fill="#34C47C" fillOpacity=".1" fillRule="nonzero" d="M94.015,17.801 C83.641,17.441 73.762,14.101 64.327,10.45 C54.892,6.799 45.548,2.75 35.427,0.814 C28.915,-0.431 21.466,-0.607 16.22,2.874 C11.17,6.23 9.538,12.006 8.661,17.374 C8.001,21.41 7.613,25.659 9.421,29.437 C10.678,32.061 12.906,34.266 14.447,36.78 C19.812,45.524 16.019,56.307 10.207,64.845 C7.482,68.851 4.317,72.675 2.212,76.935 C0.107,81.195 -0.865,86.083 0.976,90.435 C2.8,94.749 7.148,97.984 11.859,100.261 C21.426,104.887 32.696,106.21 43.693,106.961 C68.029,108.621 92.493,107.902 116.893,107.183 C125.923,106.916 134.993,106.646 143.875,105.254 C148.808,104.481 153.901,103.254 157.483,100.292 C159.663,98.57 161.135,96.106 161.619,93.37 C162.103,90.635 161.565,87.815 160.108,85.45 C154.998,77.542 140.874,75.578 137.298,67.091 C135.333,62.419 137.351,57.216 140.209,52.882 C146.338,43.588 156.609,35.434 157.151,24.811 C157.523,17.511 152.585,10.211 144.951,6.754 C136.951,3.136 125.851,3.59 119.951,9.581 C113.859,15.745 103.174,18.122 94.015,17.801 Z" />
      <path fill="#000" fillRule="nonzero" d="M39.385,83.801 C39.459,83.801 39.531,83.776 39.588,83.73 C39.646,83.684 39.686,83.62 39.703,83.548 C39.719,83.476 39.711,83.4 39.679,83.334 C39.647,83.267 39.593,83.213 39.526,83.181 C39.46,83.149 39.384,83.141 39.312,83.157 C39.24,83.174 39.176,83.214 39.13,83.272 C39.084,83.329 39.059,83.401 39.059,83.475 C39.059,83.561 39.093,83.644 39.154,83.706 C39.216,83.767 39.299,83.801 39.385,83.801 Z" />
      <path d="M139.769,45.821 L139.769,45.641 L80.469,0.041 C80.432,0.013 80.386,-0.002 80.34,-0.002 C80.293,-0.002 80.247,0.013 80.21,0.041 L20.91,45.641 L20.91,116.32 L21.023,116.32 L21.023,116.433 L139.889,116.433 L139.889,45.753 L139.769,45.821 Z" />
      <polygon fill="#FFF" fillRule="nonzero" points="137.721 114.348 22.965 114.348 22.965 46.104 80.343 1.984 137.721 46.104" />
      <polygon fill="#E0E0E0" fillRule="nonzero" points="133.593 110.303 27.095 110.303 27.095 46.971 80.344 6.026 133.593 46.971" />
      <polygon fill="#37BC98" fillRule="nonzero" points="33.445 22.11 98.409 5.079 120.069 87.702 55.105 104.733" />
      <polygon fill="#F5F5F5" fillRule="nonzero" points="47.09 17.632 114.249 17.632 114.249 103.046 47.09 103.046" />
      <polygon fill="#000" fillOpacity=".05" fillRule="nonzero" points="23.508 46.212 80.886 80.334 23.508 114.456" />
      <polygon fill="#FFF" fillRule="nonzero" points="23.074 46.212 80.452 80.334 23.074 114.456" />
      <polygon fill="#000" fillOpacity=".05" fillRule="nonzero" points="137.395 46.212 80.017 80.334 137.395 114.456" />
      <polygon fill="#FFF" fillRule="nonzero" points="137.83 46.212 80.452 80.334 137.83 114.456" />
      <polygon fill="#000" fillOpacity=".05" fillRule="nonzero" points="23.074 113.37 80.452 68.598 137.83 113.37" />
      <polygon fill="#FFF" fillRule="nonzero" points="23.074 114.457 80.452 69.685 137.83 114.457" />
      <polygon fill="#34C47C" fillOpacity=".5" fillRule="nonzero" points="51.002 21.544 77.952 21.544 77.952 23.717 51.002 23.717" />
      <polygon fill="#34C47C" fillOpacity=".5" fillRule="nonzero" points="51.002 30.238 109.25 30.238 109.25 32.411 51.002 32.411" />
      <polygon fill="#34C47C" fillOpacity=".5" fillRule="nonzero" points="51.002 35.888 109.25 35.888 109.25 38.061 51.002 38.061" />
      <polygon fill="#34C47C" fillOpacity=".4" fillRule="nonzero" points="51.002 41.539 109.25 41.539 109.25 43.712 51.002 43.712" />
      <polygon fill="#34C47C" fillOpacity=".4" fillRule="nonzero" points="51.002 47.19 109.25 47.19 109.25 49.363 51.002 49.363" />
      <polygon fill="#34C47C" fillOpacity=".3" fillRule="nonzero" points="51.002 52.841 84.255 52.841 84.255 55.014 51.002 55.014" />
      <polygon fill="#34C47C" fillOpacity=".3" fillRule="nonzero" points="51.002 58.492 96.426 58.492 96.426 60.665 51.002 60.665" />
    </g>
  </svg>

);

export default EmailIcon;
