import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import InfiniteList from '../../common/InfiniteList';
import WriterBox from '../WriterBox/WriterBox';
import WriterBoxSkeleton from '../WriterBox/WriterBoxSkeleton';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  boxRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 50,
    marginBottom: 27,
  },
  articleCount: {
    opacity: 0.5,
    marginLeft: 10,
  },
}));

const WriterList = ({
  writers, writersCount, label, isLoading,
  hasMore, loadMore, initialLoad,
  EmptyStateComponent, onEmptyButtonClick,
}) => {
  const classes = styles();

  const renderWriters = () => {
    if (isLoading && initialLoad) {
      return Array(12).fill().map((_, i) => (
        <WriterBoxSkeleton />
      ));
    }
    return writers.map((writer, i) => (
      <WriterBox writer={writer} />
    ));
  };

  return (
    <Container classes={{ root: classes.containerRoot }}>
      <Box className={classes.boxRoot}>
        <Typography variant="h6">
          {label}
          {!isLoading
            && <span className={classes.articleCount}>({writersCount})</span>}
        </Typography>
      </Box>
      {writers.length === 0 && !isLoading
        ? <EmptyStateComponent onButtonClick={onEmptyButtonClick} />
        : (
          <InfiniteList isLoading={isLoading} loadMore={loadMore} hasMore={hasMore}>
            {renderWriters()}
          </InfiniteList>
        )}
    </Container>
  );
};

WriterList.propTypes = {
  writers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  writersCount: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  initialLoad: PropTypes.bool.isRequired,
  EmptyStateComponent: PropTypes.elementType.isRequired,
  onEmptyButtonClick: PropTypes.func.isRequired,
};

export default WriterList;
