/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import InnerHTML from 'dangerously-set-html-content';
import { format as formatDate, isToday, isYesterday } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import BannerAd from '../../BannerAd';
import PublisherNavBar from '../../Nav/PublisherNavBar';
import './styles.scss';
import routes from '../../../routes';
import MediaCarousel from '../MediaCarousel/MediaCarousel';
import PremiumBar from '../../PremiumBar';
import CanceledHeartIcon from '../../common/CanceledHeartIcon/CanceledHeartIcon';
import CanceledBookmarkIcon from '../../common/CanceledBookmarkIcon/CanceledBookmarkIcon';
import ToastDialog from '../../ToastDialog/ToastDialog';
import SpotlightTooltip from '../../SpotlightTooltip/SpotlightTooltip';
import DownloadDialog from './DownloadDialog';
import LoadingIcon from '../../common/LoadingIcon';
import { useQuery } from '../../../hooks/useQuery';
import { downloadArticle } from '../../../actions/download';
import GetAppDialog from '../../GetAppDialog/GetAppDialog';
import InfoBar from '../../InfoBar/InfoBar';

const styles = makeStyles((theme) => ({
  containerRoot: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingLeft: '86px',
    },
  },
  titleRoot: {
    fontSize: '56px',
    lineHeight: '60px',
    fontWeight: '500',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '48px',
      marginBottom: theme.spacing(3),
    },
  },
  contentColumn: {
    [theme.breakpoints.up('md')]: {
      marginRight: '34px',
    },
  },
  author: {
    fontSize: '18px',
    marginTop: '1rem',
    marginBottom: '0.5rem',
    marginRight: 5,
    fontWeight: 'bold',
  },
  date: {
    fontSize: '18px',
    color: theme.palette.text.secondary,
    opacity: '50%',
    fontWeight: 'bold',
  },
  authorDateContainer: {
    marginBottom: '33px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  spotlightToast: theme.toastDialog,
  writerContainer: {
    display: 'flex',
  },
  writerLink: {
    color: '#37BC98',
    textDecoration: 'none',
  },
  downloadButton: {
    color: '#ffffff !important',
  },
}));

const getArticleTime = (a) => {
  const date = parseISO(a.published_at);
  let time;
  if (isToday(date)) {
    time = 'Today';
  } else if (isYesterday(date)) {
    time = 'Yesterday';
  } else {
    time = formatDate(date, 'LLLL dd, yyyy');
  }

  time += ' at ';
  time += formatDate(date, 'H:mm aaaa').toUpperCase();
  return time;
};

const ArticleDetail = ({
  user, article, onBackClick, onBookmarkClick, onFavoriteClick,
}) => {
  const classes = styles();
  const [isFavorite, setIsFavorite] = useState(article.liked);
  const [isBookmarked, setIsBookmarked] = useState(article.bookmarked);
  const [showDialog, setShowDialog] = useState(false);
  const [showGetAppDialog, setShowGetAppDialog] = useState(false);
  const isGuest = !user?.profile?.email;

  useEffect(() => () => {
    toast.dismiss();
  }, []);

  const handleBookmarkClick = () => {
    onBookmarkClick(!isBookmarked);
    setIsBookmarked(!isBookmarked);

    toast.success(
      <ToastDialog
        active={!isBookmarked}
        activateIcon={<BookmarkIcon fontSize="large" />}
        deactivateIcon={<CanceledBookmarkIcon />}
        activateText="Bookmarked"
        deactivateText="Unbookmarked"
        title={article.title}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );
  };

  const handleFavoriteClick = () => {
    onFavoriteClick(!isFavorite);
    setIsFavorite(!isFavorite);

    toast.success(
      <ToastDialog
        active={!isFavorite}
        activateIcon={<FavoriteIcon fontSize="large" />}
        deactivateIcon={<CanceledHeartIcon />}
        activateText="Liked"
        deactivateText="Unliked"
        title={article.title}
      />, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: classes.spotlightToast,
        autoClose: 2500,
      },
    );
  };

  useEffect(() => () => {
    if (window.googletag) {
      window.googletag.destroySlots();
    }
  }, []);

  const downloadArticleQuery = useQuery('downloadArticle', downloadArticle, {
    variables: [article],
    enabled: false,
  });

  const downloadArticleClick = (articleObj) => {
    if (!user.isSignedIn || isGuest) {
      setShowDialog(true);
    } else {
      downloadArticleQuery.refetch();
    }
  };

  return (
    <>
      {article.url && (
        <Helmet>
          <link rel="canonical" href={article.url} />
        </Helmet>
      )}
      <GetAppDialog show={showGetAppDialog} setShow={setShowGetAppDialog} />
      <PublisherNavBar
        isSignedIn={user.isSignedIn}
        channel={article.publisher}
        onBackClick={onBackClick}
        onFavoriteClick={handleFavoriteClick}
        onBookmarkClick={handleBookmarkClick}
        publisherUrl={routes.publishers.main(article.publisher.slug)}
        isFavorite={isFavorite}
        isBookmarked={isBookmarked}
        subscribable={false}
        article={article}
        showAboutMenu={false}
      />
      <PremiumBar publisher={article.publisher} />
      <InfoBar channel={article.publisher} />
      <DownloadDialog
        guest={user.isSignedIn && isGuest}
        show={showDialog}
        onCloseClick={() => setShowDialog(false)}
      />
      <Container component="main" classes={{ root: classes.containerRoot }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h3"
              classes={{ root: classes.titleRoot }}
              paragraph
              dangerouslySetInnerHTML={{ __html: article.title }}
              key="typography-h3"
            />
            <MediaCarousel key="media-carousel" media={article.media} />

            <Box className={classes.authorDateContainer}>
              <Box>
                {article.writers.length > 0 && (
                  <SpotlightTooltip tooltipKey="articledetail-writer" text="Tap on the writer name to see what else they published">
                    <Box className={classes.writerContainer}>
                      <Typography
                        variant="body2"
                        classes={{ root: classes.author }}
                        key="typography-by"
                      >
                        By
                      </Typography>

                      {article.writers?.map((writer) => (
                        <>
                          {article.writers[0]._id !== writer._id && (
                            <Typography
                              variant="body2"
                              classes={{ root: classes.author }}
                              key={`typography-and-${writer._id}`}
                            >
                              And
                            </Typography>
                          )}
                          <Typography
                            variant="body2"
                            classes={{ root: classes.author }}
                            paragraph
                            key={`typography-${writer._id}`}
                          >
                            <Link
                              to={routes.writers.main(writer.slug)}
                              className={classes.writerLink}
                              key={`link-${writer._id}`}
                            >
                              {writer.name}
                            </Link>
                          </Typography>
                        </>
                      ))}
                    </Box>
                  </SpotlightTooltip>
                )}

                <Typography
                  variant="body2"
                  classes={{ root: classes.date }}
                  paragraph
                >
                  {getArticleTime(article)} | {article?.estimated_reading_time}
                </Typography>
              </Box>

              {downloadArticleQuery.isFetching
                ? (
                  <Box style={{ width: 100 }}>
                    <LoadingIcon />
                  </Box>
                )
                : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => downloadArticleClick(article)}
                    className={classes.downloadButton}
                  >
                    Download Article
                  </Button>
                )}
            </Box>

            <div className="article-content">
              <InnerHTML html={article.body} />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <BannerAd
              placement="side_bar"
              contextType="article"
              contextId={article.id}
              maxWidth={350}
              minWidth={250}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

ArticleDetail.propTypes = {
  user: PropTypes.shape().isRequired,
  article: PropTypes.shape().isRequired,
  onBackClick: PropTypes.func.isRequired,
  onBookmarkClick: PropTypes.func.isRequired,
  onFavoriteClick: PropTypes.func.isRequired,
};

export default ArticleDetail;
