import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  textRoot1: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: 9,
  },
}));

const TopArticlesEmpty = () => {
  const classes = styles();
  return (
    <Box className={classes.container}>
      <Typography classes={{ root: classes.textRoot1 }}>
        No articles available
      </Typography>
    </Box>
  );
};

export default TopArticlesEmpty;
