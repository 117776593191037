/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

const CurrentStreakIcon = ({ color = '#37bc98', height = 50, width = 50 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    <path
      d="M0 0 C4.95 0 9.9 0 15 0 C13.74835013 3.12912467 12.69858563 5.71373125 11.0546875 8.55078125 C10.70019531 9.16630859 10.34570312 9.78183594 9.98046875 10.41601562 C9.61566406 11.04185547 9.25085938 11.66769531 8.875 12.3125 C8.50246094 12.95767578 8.12992188 13.60285156 7.74609375 14.26757812 C6.83367787 15.84675945 5.91724491 17.42361864 5 19 C7.31 19.33 9.62 19.66 12 20 C10.55454275 23.8617903 8.76719216 27.36725026 6.7421875 30.95703125 C5.80439453 32.62475586 5.80439453 32.62475586 4.84765625 34.32617188 C4.1901709 35.48831901 3.53261797 36.65042791 2.875 37.8125 C2.20935359 38.99398189 1.54398616 40.175621 0.87890625 41.35742188 C-0.74465934 44.23983905 -2.37105015 47.12062331 -4 50 C-4.66 50 -5.32 50 -6 50 C-5.35451147 47.24991865 -4.70862234 44.49993244 -4.0625 41.75 C-3.78889648 40.58404297 -3.78889648 40.58404297 -3.50976562 39.39453125 C-3.33251953 38.64042969 -3.15527344 37.88632812 -2.97265625 37.109375 C-2.72914429 36.07248535 -2.72914429 36.07248535 -2.48071289 35.01464844 C-2.0014338 33.00600899 -1.50453398 31.00243058 -1 29 C-2.98 29 -4.96 29 -7 29 C-4.88979216 19.26305112 -2.73830907 9.58408173 0 0 Z"
      fill={color}
      transform="translate(21,0)"
    />
  </svg>
);

CurrentStreakIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CurrentStreakIcon;
