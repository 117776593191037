import { createMuiTheme } from '@material-ui/core';

const createTheme = (darkMode) => createMuiTheme({
  breakpoints: {
    keys: [
      'xs',
      'sm',
      'md',
      'lg',
      'xl',
    ],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1344,
    },
  },
  palette: {
    type: darkMode ? 'dark' : 'light',
    primary: {
      light: '#6bd3b7',
      main: '#37bc98',
      dark: '#34a788',
      contrastText: '#000',
    },
    secondary: {
      light: '#6bd3b7',
      main: '#37bc98',
      dark: '#34a788',
      contrastText: '#fff',
    },
    text: darkMode
      ? {
        primary: '#fff',
        secondary: '#8c8b91',
        alpha: 'rgba(231, 231, 231, .44)',
      }
      : {
        primary: '#000',
        secondary: '#000',
        alpha: 'rgba(24, 24, 24, .44)',
      },
    background: darkMode
      ? {
        paper: '#272727',
        default: '#181818',
      }
      : {
        paper: '#f1f1f1',
        default: '#fff',
      },
    backgroundAlpha: darkMode ? 'rgba(24, 24, 24, .95)' : 'rgba(255, 255, 255, .95)',
  },
  spacing: 7,
  typography: {
    overline: {
      letterSpacing: 2,
    },
    caption: {
      color: darkMode ? 'rgba(140, 139, 145, .5)' : 'rgba(0, 0, 0, .5)',
    },
  },
  toastDialog: {
    backgroundColor: '#37BC98',
    borderRadius: 5,
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': darkMode
          ? {
            WebkitBoxShadow: '0 0 0 100px #272727 inset',
            WebkitTextFillColor: '#fff',
          }
          : {
            WebkitBoxShadow: '0 0 0 100px #f1f1f1 inset',
            WebkitTextFillColor: '#000',
          },
      },
    },
    MuiCardActionArea: {
      root: {
        '&:hover $focusHighlight': {
          opacity: '0.1',
        },
        '&:active $focusHighlight': {
          opacity: '0.1',
        },
      },
      focusHighlight: {
        backgroundColor: '#666',
      },
    },
    MuiCard: {
      root: {
        borderRadius: 0,
      },
    },
    MuiIconButton: {
      root: {
        color: darkMode ? '#fff' : '#000',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
      root: {
        textTransform: 'capitalize',
      },
    },
    MuiTab: {
      wrapper: {
        minWidth: 90,
      },
      root: {
        textTransform: 'none',
        letterSpacing: 0,
        color: '#666',
        fontWeight: 400,
        minWidth: '90px !important',

        '&$selected': {
          color: darkMode ? '#fff' : '#000',
          fontWeight: 500,
        },
      },
      textColorInherit: {
        color: darkMode ? '#8c8b91' : '#666',
        opacity: 1,
      },
    },
    MuiTabs: {
      root: {
        color: '#666',
      },
      indicator: {
        color: '#000',
      },
    },
  },
});

export default createTheme;
