import React from 'react';
import { clearAllBookmarks, fetchBookmarks } from '../../actions/bookmarks';
import ArticlePagination, { BookmarksEmpty } from '../../components/Article/ArticlePagination';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const Bookmarks = () => {
  useBackgroundClass('default');

  return (
    <ArticlePagination
      loadsContext="bookmarks"
      load={fetchBookmarks}
      label="Bookmarks"
      EmptyStateComponent={BookmarksEmpty}
      clearAll={clearAllBookmarks}
    />
  );
};

export default Bookmarks;
