import React from 'react';
import PropTypes from 'prop-types';
import ContestDetail from '../../components/Contest/ContestDetail';

const ContestInternalWebView = ({ match }) => (<ContestDetail match={match} webView />);

ContestInternalWebView.propTypes = {
  match: PropTypes.shape(),
};

export default ContestInternalWebView;
