import client from '../utils/client';

const toggleArticleFavorite = (articleId, isFavorite) => {
  const url = `/articles/${articleId}/like`;
  return async (dispatch) => {
    if (isFavorite) {
      client().put(url);
    } else {
      client().delete(url);
    }
  };
};

const fetchFavorites = async (userId, page = 1) => {
  const url = `/users/${userId}/liked_articles`;
  const response = await client().get(url, { params: { page } });
  return {
    articles: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const clearAllFavorites = async (userId) => {
  const url = `/users/${userId}/liked_articles`;
  await client().delete(url);
};

export { toggleArticleFavorite, fetchFavorites, clearAllFavorites };
