import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  progressContainer: {
    padding: 10,
    textAlign: 'center',
  },
});

const InfiniteList = ({ isLoading, loadMore, hasMore, children }) => {
  const classes = styles();
  const dialogScroll = document.querySelector('.MuiDialog-paperFullScreen');
  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={() => (!isLoading && loadMore())}
      loader={(
        <div key="loader" className={classes.progressContainer}>
          <CircularProgress />
        </div>
      )}
      hasMore={hasMore}
      useWindow={!dialogScroll}
      initialLoad={false}
      getScrollParent={() => dialogScroll}
    >
      {children}
    </InfiniteScroll>
  );
};

InfiniteList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default InfiniteList;
