import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import config from '../../config';
import ThemeContext from '../../context/theme/ThemeContext';
import UpdateCardCheckoutForm from './UpdateCardCheckoutForm';

const styles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dialog: {
    backgroundColor: theme.palette.background.paper,
    width: 1000,
    textAlign: 'center',
    position: 'relative',
    padding: '40px 10px',
    borderRadius: 20,
    '& > form > .StripeElement': {
      padding: '20px 70px',
    },
    '& > form > input': {
      color: 'green',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '& > svg': {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      top: 45,
    },
  },
  title: {
    textAlign: 'center',
    font: 'normal normal bold 24px/24px Roboto',
    letterSpacing: '-0.32px',
    color: theme.palette.text.primary,
    padding: '25px 70px',
    lineHeight: '36px',
  },
}));

const { stripePk } = config;
const stripePromise = loadStripe(stripePk);

const UpdateCardDialog = ({ show, onCloseClick, user, clientSecret, refreshState }) => {
  const classes = styles();
  const darkMode = useContext(ThemeContext);

  const options = {
    clientSecret,
    appearance: {
      theme: darkMode?.darkMode ? 'night' : 'stripe',
    },
  };

  if (!clientSecret) return (<></>);

  return (
    <>
      <Modal
        open={show}
        className={classes.modal}
      >
        <Box className={classes.dialog}>
          <Box className={classes.closeIcon}><CloseIcon onClick={onCloseClick} /></Box>
          <Typography className={classes.title}>Update Payment Method</Typography>

          <Elements stripe={stripePromise} options={options}>
            <UpdateCardCheckoutForm
              clientSecret={clientSecret}
              userId={user?.id}
              onCloseClick={onCloseClick}
              refreshState={refreshState}
            />
          </Elements>
        </Box>
      </Modal>
    </>
  );
};

UpdateCardDialog.propTypes = {
  show: PropTypes.bool,
  onCloseClick: PropTypes.func,
  user: PropTypes.shape().isRequired,
  clientSecret: PropTypes.string.isRequired,
  refreshState: PropTypes.func,
};

export default UpdateCardDialog;
