import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import Subscription from '../Subscription';
import { language } from '../../../utils/helpers';

const styles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    padding: '0px 50px',
    paddingTop: 35,
    border: '1px solid #D0CDCD',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 1px 3px #00000029',
    minHeight: 200,
    backgroundColor: theme.palette.background.default,
  },
  avatar: {
    height: 80,
    backgroundSize: '80px',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'border-box',
  },
  button: {
    color: theme.palette.text.primary,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  premiumLabel: {
    color: '#37BC98',
    textAlign: 'center',
    fontWeight: 500,
  },
  publisherTitle: {
    fontSize: 15,
    color: theme.palette.text.primary,
    fontWeight: '500',
    marginTop: 14,
    textAlign: 'center',
    lineHeight: '20px',
    width: 150,
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  publisherLink: {
    textDecoration: 'none',
  },
  infoLabel: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
}));

const PublisherBox = ({ publisher, subscribable, onClick, onToggleSubscription }) => {
  const classes = styles();

  let channel = { ...publisher };
  if (publisher?.type === 'channel' || publisher?.type === 'topic') {
    channel = publisher?.subject?.channel ?? publisher?.subject;
  }

  let channelLink = routes.publishers.main(publisher.slug ?? publisher?.subject?.slug);
  if (publisher?.type === 'topic') {
    // eslint-disable-next-line no-underscore-dangle
    channelLink = routes.publishers.topic(channel.slug, publisher?.subject?._id);
  }

  return (
    <Box className={classes.wrapper}>
      <Link
        className={classes.publisherLink}
        to={channelLink}
        onClick={onClick}
      >
        <Box
          className={classes.avatar}
          style={{
            backgroundImage: `url(${publisher.avatar ?? publisher?.subject?.channel?.icon ?? publisher?.subject?.icon})`,
          }}
        />

        {publisher.title
          && <Typography className={classes.publisherTitle}>{publisher.title}</Typography>}

        {
          publisher?.names
          && <Typography className={classes.publisherTitle}>{language(publisher.names)}</Typography>
        }

        {
          (channel.premium)
          && (publisher.slug !== 'time')
          && <Box className={classes.premiumLabel}>Premium</Box>
        }

        {(channel.slug === 'time')
          && <Box className={classes.premiumLabel}>Premium+</Box>}

        {publisher.info
          && <Box className={classes.infoLabel}>{language(publisher.info)}</Box>}

        {publisher?.subject?.subtitle
          && <Box className={classes.infoLabel}>{language(publisher?.subject?.subtitle)}</Box>}

      </Link>
      {subscribable && (
        <Subscription
          classes={{ root: classes.button }}
          publisher={publisher}
          isSubscribed={publisher.is_subscribed}
          feedType={publisher?.type}
          showTooltip={false}
          onToggleSubscription={onToggleSubscription}
        />
      )}
    </Box>
  );
};

PublisherBox.propTypes = {
  publisher: PropTypes.shape().isRequired,
  subscribable: PropTypes.bool,
  onClick: PropTypes.func,
  onToggleSubscription: PropTypes.func,
};

PublisherBox.defaultProps = {
  subscribable: true,
};

export default PublisherBox;
