import React from 'react';
import {
  Box, Button, Typography, makeStyles,
} from '@material-ui/core';

import emptyGraphic from './empty-graphic.svg';

const styles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  graphic: {
    marginBottom: 34,
  },
  textRoot1: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: 9,
  },
  textRoot2: {
    fontSize: '16px',
    color: '#666',
    marginBottom: 34,
  },
}));

const FavoritesEmpty = ({ onButtonClick }) => {
  const classes = styles();
  return (
    <Box className={classes.container}>
      <img src={emptyGraphic} alt="Likes" className={classes.graphic} />
      <Typography classes={{ root: classes.textRoot1 }}>What do you like?</Typography>
      <Typography classes={{ root: classes.textRoot2 }}>
        Find stories that resonate with you
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
      >
        Start Exploring
      </Button>
    </Box>
  );
};

export default FavoritesEmpty;
