import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FavoriteIcon from '@material-ui/icons/Favorite';

import {
  AppBar,
  Box,
  Container,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import WriterSubscription from '../WriterSubscription/WriterSubscription';

const styles = makeStyles((theme) => ({
  containerRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
    padding: '5px 0px',
  },
  iconColorClass: {
    color: theme.palette.text.primary,
  },
  writerName: {
    color: theme.palette.text.primary,
    fontSize: 22,
  },
  writerNameLink: {
    textDecoration: 'none',
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const WriterNavBar = ({
  isSignedIn,
  writer,
  isFavorite,
  isBookmarked,
  onBackClick,
  onFavoriteClick,
  onBookmarkClick,
  writerUrl,
  subscribable,
}) => {
  const classes = styles();

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Container classes={{ root: classes.containerRoot }}>
        <IconButton
          color="inherit"
          onClick={onBackClick}
          classes={{ colorInherit: classes.iconColorClass }}
        >
          <ChevronLeftIcon />
        </IconButton>

        <Link className={classes.writerNameLink} to={writerUrl}>
          <Typography className={classes.writerName}>{writer.name}</Typography>
        </Link>

        <Box className={classes.iconContainer}>
          {isSignedIn && (
            <>
              {onFavoriteClick && (
                <IconButton
                  color={isFavorite ? 'primary' : 'inherit'}
                  onClick={onFavoriteClick}
                  classes={{ colorInherit: classes.iconColorClass }}
                >
                  <FavoriteIcon />
                </IconButton>
              )}
              {onBookmarkClick && (
                <IconButton
                  color={isBookmarked ? 'primary' : 'inherit'}
                  onClick={onBookmarkClick}
                  classes={{ colorInherit: classes.iconColorClass }}
                >
                  <BookmarkIcon />
                </IconButton>
              )}
              {subscribable && (
                <>
                  <WriterSubscription
                    classes={{ root: classes.iconColorClass }}
                    writer={writer}
                    isSubscribed={writer.is_subscribed}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Container>
    </AppBar>
  );
};

WriterNavBar.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  writer: PropTypes.shape().isRequired,
  isFavorite: PropTypes.bool,
  isBookmarked: PropTypes.bool,
  onBackClick: PropTypes.func.isRequired,
  onFavoriteClick: PropTypes.func,
  onBookmarkClick: PropTypes.func,
  writerUrl: PropTypes.string.isRequired,
  subscribable: PropTypes.bool,
};

WriterNavBar.defaultProps = {
  subscribable: true,
};

export default withRouter(WriterNavBar);
