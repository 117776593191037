import React from 'react';
import { connect } from 'react-redux';

import { initialize as initializeAction } from '../../actions/app';

import Loader from '../../components/common/Loader';

class Initialize extends React.Component {
  componentDidMount() {
    this.props.initialize();
  }

  render() {
    return (
      this.props.app.initialized ? this.props.children : <Loader />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  initialize: () => {
    dispatch(initializeAction());
  },
});

const mapStateToProps = (state) => ({
  app: state.app,
});

export default connect(mapStateToProps, mapDispatchToProps)(Initialize);
