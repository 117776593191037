import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

const CanceledBookmarkIcon = ({ width = 80 }) => (
  <Container
    style={{
      display: 'inline-block',
      width: `${width}px`,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="vector"
    >
      <path
        id="path"
        d="M 20 20.72 L 18.73 22 L 16.78 20.05 L 12 18 L 5 21 L 5 8.27 L 2 5.27 L 3.28 4 L 20 20.72 M 19 17.16 L 19 5 C 19 3.89 18.1 3 17 3 L 7 3 C 6.41 3 5.89 3.27 5.5 3.68 L 19 17.16 Z"
        fill="#ffffff"
      />
    </svg>

  </Container>
);

CanceledBookmarkIcon.propTypes = {
  width: PropTypes.number,
};

export default CanceledBookmarkIcon;
