import React from 'react';
import {
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const styles = makeStyles((theme) => ({
  skeletonRoot: {
    paddingTop: '56.25%',
  },
  cardContentRoot: {
    padding: theme.spacing(2),
  },
}));

const DownloadCardSkeleton = () => {
  const classes = styles();
  return (
    <Card>
      <Skeleton variant="rect" height={0} classes={{ root: classes.skeletonRoot }} />
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Skeleton width={150} />
        <Skeleton />
        <Skeleton />
        <Skeleton width={100} />
      </CardContent>
    </Card>
  );
};

export default DownloadCardSkeleton;
