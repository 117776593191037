import client from '../utils/client';

const getOnboardChannels = async () => {
  const url = '/discovery/sections/onboarding';
  try {
    const response = await client().get(url);
    return response?.data;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

const getOnboardGroups = async () => {
  const url = '/onboarding/groups';
  try {
    const response = await client().get(url);
    return response?.data;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

const saveOnboard = async (userId, itemsToFollow) => {
  const url = `/users/${userId}/onboarding`;
  try {
    const userFeeds = itemsToFollow.map((item) => ({
      subject_type: item.subject_type,
      subject_id: item.subject_id,
    }));
    const response = await client().put(url, { user_feeds: userFeeds });
    return response?.data;
  } catch (err) {
    console.log(err.message);
  }
  return null;
};

export {
  getOnboardGroups, saveOnboard, getOnboardChannels,
};
