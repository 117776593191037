import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider, makeStyles, Container } from '@material-ui/core';
import tinycolor from 'tinycolor2';
import ThemeContext from '../../context/theme/ThemeContext';
import { language } from '../../utils/helpers';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  infoBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoBoxBg: {
    zIndex: 10,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderTop: '1px solid rgba(112, 112, 112, .2)',
    position: 'sticky',
    top: '68px',
  },
  infoText: {
    font: 'Bold 15px/17px Roboto',
    padding: '12px 21px',
    textTransform: 'capitalize',
    color: (obj) => {
      if (obj.darkMode.darkMode !== obj.isLight) {
        return '#37bc98';
      }

      return obj.channel?.branding?.color;
    },
  },
}));

const InfoBar = ({ channel }) => {
  const darkMode = useContext(ThemeContext);
  const isLight = tinycolor(channel?.branding?.color).isLight();
  const classes = styles({ darkMode, isLight, channel });
  const universityName = language(channel.info);
  return universityName
    ? (
      <>
        <Box className={classes.infoBoxBg}>
          <Container classes={{ root: classes.containerRoot }}>
            <Box className={classes.infoBox}>
              <Typography color="primary" className={classes.infoText}>{language(channel.info)}</Typography>
            </Box>
          </Container>
        </Box>
        <Divider />
      </>
    ) : (
      <></>
    );
};

InfoBar.propTypes = {
  channel: PropTypes.shape().isRequired,
};

export default InfoBar;
