import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  makeStyles,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect, useDispatch } from 'react-redux';
import OrDivider from './OrDivider';
import Link from '../common/Link';
import Form from '../Form/Form';
import routes from '../../routes';
import { getConfigurations } from '../../actions/configurations';
import { withErrorReporting } from '../../utils/errors';
import { useQuery } from '../../hooks/useQuery';
import SpotlightLogo from '../common/SpotlightLogo';
import { authenticateGuest } from '../../actions/user';

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email().required().label('Email'),
  name: Yup.string().required().label('Display Name'),
  password: Yup.string().min(8).required().label('Password'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password')], 'Passwords must match',
  ).required('Password should be confirmed'),
});

const initialValues = {
  name: '', email: '', password: '', confirmPassword: '',
};

const styles = makeStyles((theme) => ({
  orDividerRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  dividerRoot: {
    marginBottom: theme.spacing(2),
  },
  disclaimerRoot: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  submitButton: {
    marginBottom: theme.spacing(2),
    marginTop: 15,
  },
  signinLink: {
    marginBottom: theme.spacing(1.5),
  },
  emailLabel: {
    fontSize: 12,
    textAlign: 'left',
  },
  termsLink: {
    color: theme.palette.text.primary,
  },
  continueAsGuest: {
    backgroundColor: '#4c4c4c',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#3f3f3f',
    },
  },
  spotlightLogo: {
    marginRight: 15,
    height: 35,
  },
}));

const SignUpForm = ({ onSubmit, handleGuestLogin, user }) => {
  const { data: reCaptchaConfig } = useQuery('configurations', withErrorReporting(getConfigurations));

  const recaptchaRef = useRef();
  const classes = styles();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const landingCode = urlParams.getAll('p').shift();

  const renderReCaptcha = () => {
    if (reCaptchaConfig?.captcha_required) {
      return (
        <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={reCaptchaConfig.recaptcha_web_v2_site_key}
        />
      );
    }
    return null;
  };

  const submitWithRecaptcha = async (data) => {
    const token = recaptchaRef.current
      ? await recaptchaRef.current.executeAsync()
      : undefined;
    if (landingCode) {
      const dataWLC = { ...data, landingCode };
      return onSubmit({ ...dataWLC, captchaToken: token });
    }
    return onSubmit({ ...data, captchaToken: token });
  };

  const dispatch = useDispatch();
  const handleGuestClick = async () => {
    const response = await dispatch(authenticateGuest());
    if (response?.success) {
      handleGuestLogin();
    }
  };

  return (
    <>
      <Form
        validationSchema={SignUpSchema}
        initialValues={initialValues}
        onSubmit={submitWithRecaptcha}
      >
        {({ Submit }) => (
          <>
            <Field
              id="name"
              name="name"
              label="Display Name"
              component={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              required
            />
            <Field
              id="email"
              name="email"
              label="Email"
              type="email"
              component={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              required
            />
            <Typography className={classes.emailLabel}>
              Optionally, use your .edu for added benefits and perks.
            </Typography>
            <Field
              id="password"
              name="password"
              label="Password"
              type="password"
              component={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              required
            />
            <Field
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              component={TextField}
              variant="outlined"
              margin="dense"
              fullWidth
              required
            />
            {renderReCaptcha()}
            <Submit
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              classes={{ root: classes.submitButton }}
            >
              Sign Up
            </Submit>
          </>
        )}
      </Form>
      {!user?.isSignedIn && !landingCode && (
        <>
          <OrDivider classes={{ root: classes.orDividerRoot }} />
          <Button
            className={classes.continueAsGuest}
            variant="outlined"
            size="large"
            fullWidth
            onClick={handleGuestClick}
          >
            <Box className={classes.spotlightLogo}>
              <Box>
                <SpotlightLogo color="#fff" small />
              </Box>
            </Box>
            Continue as Guest
          </Button>
          <OrDivider classes={{ root: classes.orDividerRoot }} />
          <Typography variant="body2" component="p" classes={{ root: classes.signinLink }}>
            Already have an account?&nbsp;
            <Link to={routes.auth.signin}>Sign In</Link>
          </Typography>
          <Typography variant="caption" paragraph classes={{ root: classes.disclaimerRoot }}>
            By clicking Sign up, you agree to Spotlight&#39;s&nbsp;
            <Link
              href="https://www.spotlightnews.us/terms"
              target="_blank"
              className={classes.termsLink}
            >
              Terms and Conditions of Use
            </Link>.
          </Typography>
        </>
      )}
    </>
  );
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleGuestLogin: PropTypes.func.isRequired,
  user: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(SignUpForm);
