import React from 'react';
import PropTypes from 'prop-types';
import ElectionDrillDown from '../../components/Elections/ElectionDrillDown';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const ElectionInternalDrillDown = ({ match }) => {
  useBackgroundClass('elections');
  return (<ElectionDrillDown match={match} />);
};

ElectionInternalDrillDown.propTypes = {
  match: PropTypes.shape(),
};

export default ElectionInternalDrillDown;
