import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import routes from '../routes';
import SignUpForm from '../components/Auth/SignUpForm';

import {
  fetchUser,
  signUp as signUpAction,
} from '../actions/user';

const title = 'Welcome to SpotlightNews®';

const SignUp = ({ signUp, user }) => {
  const history = useHistory();

  const handleSubmit = async (data) => {
    if (user?.profile && !user?.profile?.email) {
      const dataObj = { guest: true, userId: user?.profile?.id, ...data };
      const { auth } = await signUp(dataObj);
      const updatedUser = await fetchUser();
      if (!updatedUser.data['registered?']) {
        return history.push({
          pathname: routes.emailConfirmation,
          state: { emailToBeConfirmed: data.email },
        });
      }

      if (auth.success) {
        return history.push(routes.news.main);
      }
    } else {
      const { auth } = await signUp(data);
      if (auth.success) {
        return history.push(routes.auth.signin, { flash: { title, message: auth.message } });
      } if (auth.code === 412) {
        return history.push({
          pathname: routes.emailConfirmation,
          state: { emailToBeConfirmed: data.email, password: data.password },
        });
      }
      return Promise.reject(auth.message || auth.error);
    }
    return null;
  };

  const handleGuestLogin = () => history.push(routes.onboard.main);

  useEffect(() => {
    if (user?.profile?.email) {
      history.push(routes.news.main);
    }
  }, [user, history]);

  return (
    <SignUpForm onSubmit={handleSubmit} handleGuestLogin={handleGuestLogin} />
  );
};

SignUp.propTypes = {
  signUp: PropTypes.func,
  user: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  signUp: async (data) => (
    dispatch(signUpAction(data))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
