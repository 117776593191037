import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import MainContent from '../../MainContent';
import ArticleFeed from '../../Article/ArticleFeed';
import routes from '../../../routes';
import WriterNavBar from '../WriterNavBar/WriterNavBar';
import WriterAlternativeNameBar from '../WriterAlternativeNameBar/WriterAlternativeNameBar';

const styles = makeStyles((theme) => ({
  articleFeed: {
    paddingLeft: 21,
    paddingRight: 21,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

const WriterProfile = ({
  writer,
  feedIsLoading, articles, hasMore,
  loadMoreArticles, onNotificationClick, onBackClick,
  isSignedIn,
}) => {
  const classes = styles();
  const [_isNotifiable, setIsNotifiable] = useState(writer.is_notifiable);

  useEffect(() => {
    setIsNotifiable(writer.is_notifiable);
  }, [writer]);

  const handleNotificationClick = () => {
    onNotificationClick(!_isNotifiable);
    setIsNotifiable(!_isNotifiable);
  };

  return (
    <>
      <WriterNavBar
        onBackClick={onBackClick}
        isSignedIn={isSignedIn}
        writer={writer}
        onNotificationClick={handleNotificationClick}
        writerUrl={routes.writers.main(writer.slug)}
        isNotifiable={_isNotifiable}
      />
      <WriterAlternativeNameBar writer={writer} />
      <MainContent includeBackgroundImage={false} paddingTop={0}>
        <Box className={classes.articleFeed}>
          <ArticleFeed
            feedIsLoading={feedIsLoading}
            articles={articles}
            hasMore={hasMore}
            loadMore={loadMoreArticles}
            writer={writer}
          />
        </Box>
      </MainContent>
    </>
  );
};

WriterProfile.propTypes = {
  writer: PropTypes.shape().isRequired,
  feedIsLoading: PropTypes.bool.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMoreArticles: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
};

export default WriterProfile;
