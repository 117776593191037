import client from '../utils/client';

const saveSearchExpression = async (userId, searchType, expression) => {
  const url = `/history/${userId}/search/${searchType}`;
  await client().post(url, { expression });
};

const getSearchHistory = async (userId, searchType, page = 1) => {
  const url = `/history/${userId}/search/${searchType}`;
  const response = await client().get(url, { params: { page } });
  return {
    history: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

export {
  saveSearchExpression,
  getSearchHistory,
};
