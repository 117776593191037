import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IconButton, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import HourglassIcon from '@material-ui/icons/HourglassEmpty';
import { toast } from 'react-toastify';
import { useQuery } from '../../hooks/useQuery';
import { withErrorReporting } from '../../utils/errors';
import ToastDialog from '../ToastDialog/ToastDialog';
import SpotlightTooltip from '../SpotlightTooltip/SpotlightTooltip';
import { language } from '../../utils/helpers';

const styles = makeStyles((theme) => ({
  spotlightToast: theme.toastDialog,
}));

const InterestToggler = ({
  interest,
  variables,
  active: initiallyActive,
  trueButton,
  falseButton,
  loadsContext,
  onToggle,
  onChanged,
  showTooltip,
  ...params
}) => {
  const classes = styles();
  const userId = useSelector((state) => state.user.profile?.id);
  const [active, setActive] = useState(initiallyActive);
  // eslint-disable-next-line max-len
  const [subscriptionConfirmation, setSubscriptionConfirmation] = useState(Boolean(interest?.is_subscribed));

  useEffect(() => {
    onChanged(active);
  }, [onChanged, active]);

  useEffect(() => () => {
    toast.dismiss();
  }, []);

  const query = useQuery(
    loadsContext,
    // eslint-disable-next-line no-shadow
    withErrorReporting(async (userId, status) => {
      await onToggle({ userId, active: status, ...variables });
      setActive(!active);
    }),
    {
      variables: [userId, !active, ...Object.values(variables)],
      enabled: false,
    },
  );

  const toggleSubscribeIcon = (query.isFetching) ? (
    <HourglassIcon />
  ) : active ? (
    trueButton
  ) : (
    falseButton
  );

  return (
    <>
      <SpotlightTooltip show={showTooltip} tooltipKey="interest-profile-subscription" text="Tap the + to add this topic to My News">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            query.refetch();

            if (subscriptionConfirmation) {
              setSubscriptionConfirmation(false);
              toast.success(
                <ToastDialog
                  active={false}
                  deactivateIcon={<CancelIcon />}
                  deactivateText="Unsubscribed"
                  title={language(interest?.names)}
                />, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  className: classes.spotlightToast,
                  autoClose: 2500,
                },
              );
            } else {
              setSubscriptionConfirmation(true);
              toast.success(
                <ToastDialog
                  active={!active}
                  activateIcon={<CheckIcon fontSize="large" />}
                  deactivateIcon={<CancelIcon />}
                  activateText="Subscribed"
                  deactivateText="Unsubscribed"
                  title={language(interest?.names)}
                />, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  className: classes.spotlightToast,
                  autoClose: 2500,
                },
              );
            }
          }}
          disabled={query.isFetching}
          {...params}
        >
          {toggleSubscribeIcon}
        </IconButton>
      </SpotlightTooltip>
    </>
  );
};

InterestToggler.propTypes = {
  interest: PropTypes.shape(),
  variables: PropTypes.shape({}),
  active: PropTypes.bool.isRequired,
  trueButton: PropTypes.node.isRequired,
  falseButton: PropTypes.node.isRequired,
  loadsContext: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  onChanged: PropTypes.func,
  showTooltip: PropTypes.bool,
};

InterestToggler.defaultProps = {
  variables: {},
  onChanged: () => { },
};

export default InterestToggler;
