import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import { reportAndRenderError } from '../../utils/errors';
import { saveOnboard } from '../../actions/onboarding';
import routes from '../../routes';
import InterestGroup from '../../components/Interest/InterestGroup';

const styles = makeStyles((theme) => ({
  containerRoot: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    marginTop: 50,
  },
  content: {
    maxWidth: 430,
    marginTop: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    paddingBottom: theme.spacing(6),
    overflowX: 'none',
  },
  header: {
    textAlign: 'left',
    marginBottom: 15,
  },
  title: {
    fontFamily: 'GT Walsheim',
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '-0.5px',
    color: theme.palette.text.primary,
    maxWidth: 190,
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: 10,
    marginBottom: 10,
  },
  button: {
    textTransform: 'uppercase',
    maxWidth: 350,
    marginTop: 50,
  },
}));

const OnboardInterests = ({ user }) => {
  const history = useHistory();

  if (user?.isOnboarded) {
    history.push(routes.news.main);
  }
  const location = useLocation();
  const redirectPath = location?.state?.redirectPath;

  const classes = styles();
  const [selection, setSelection] = useState([]);

  const handleSelection = (channelOrTopic) => {
    const checkArray = selection.filter((cT) => cT?.subject_id === channelOrTopic?.subject_id);

    if (checkArray.length > 0) {
      const newSelection = [];
      selection.map((channel) => {
        if (channel?.subject_id !== channelOrTopic?.subject_id) {
          newSelection.push(channel);
        }
        return null;
      });
      setSelection(newSelection);
    } else {
      const newSelection = [...selection];
      newSelection.push(channelOrTopic);
      setSelection(newSelection);
    }
  };

  const saveOnboardClick = async () => {
    if (selection.length < 2) {
      reportAndRenderError('You need to select at least two interests to move forward.', 'Select at least two interests');
      return;
    }

    await saveOnboard(user?.id, selection);
    if (redirectPath) {
      history.push(redirectPath, { signedIn: true });
    } else {
      history.push(routes.news.main);
    }
  };

  return (
    <>
      <Box className={classes.containerRoot}>
        <Container component="main">
          <Box className={classes.content}>
            <Box classes={{ root: classes.header }}>
              <Typography variant="h1" className={classes.title}>Begin building your newsfeed.</Typography>
              <Typography variant="h2" className={classes.subtitle}>Get started, pick two or more topics or publications.</Typography>
              <Divider />
            </Box>

            <InterestGroup
              handleClick={handleSelection}
              handleTitleClick={handleSelection}
              selectedInterests={selection}
            />

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={saveOnboardClick}
            >
              Let&apos;s crush this!
            </Button>
          </Box>
        </Container>
      </Box>

    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.profile,
  };
}

OnboardInterests.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, null)(OnboardInterests);
