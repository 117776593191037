import React from 'react';
import DiscoverComponent from '../../components/Discover';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const Discover = () => {
  useBackgroundClass('default');
  return <DiscoverComponent />;
};

export default Discover;
