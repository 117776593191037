import React from 'react';
import { clearAllNotifications, fetchNotifications } from '../../actions/notifications';
import ArticlePagination from '../../components/Article/ArticlePagination';
import NotificationsEmpty from '../../components/NotificationsEmpty/NotificationsEmpty';
import useBackgroundClass from '../../hooks/useBackgroundClass';

const Notifications = () => {
  useBackgroundClass('default');
  return (
    <ArticlePagination
      loadsContext="fetchNotifications"
      load={fetchNotifications}
      label="Notifications"
      EmptyStateComponent={NotificationsEmpty}
      clearAll={clearAllNotifications}
      type="notification"
    />
  );
};

export default Notifications;
