import client from '../utils/client';

const toggleArticleBookmarked = (userId, articleId, isBookmarked) => {
  const url = `/users/${userId}/bookmarks/articles/${articleId}`;
  return async (dispatch) => {
    if (isBookmarked) {
      client().put(url);
    } else {
      client().delete(url);
    }
  };
};

const fetchBookmarks = async (userId, page = 1) => {
  const url = `/users/${userId}/bookmarks/articles`;
  const response = await client().get(url, { params: { page } });
  return {
    articles: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

const clearAllBookmarks = async (userId) => {
  const url = `/users/${userId}/bookmarks/articles`;
  await client().delete(url);
};

export { toggleArticleBookmarked, fetchBookmarks, clearAllBookmarks };
