import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
} from '@material-ui/core';

import GoogleSignInButton from './GoogleSignInButton';
import AppleSignInButton from './AppleSignInButton';

const styles = makeStyles((theme) => ({
  signInButtonRoot: {
    width: '100%',
    marginBottom: theme.spacing(1),
    minWidth: 'auto',
    padding: '12px 12px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  googleButton: {
    marginRight: 20,
  },
}));

const OAuth = ({ deleteAccount }) => {
  const classes = styles();
  return (
    <Box className={classes.buttonContainer}>
      <Box className={classes.googleButton}>
        <GoogleSignInButton
          size="large"
          classes={{ root: classes.signInButtonRoot }}
          delete={deleteAccount}
        />
      </Box>
      <Box>
        <AppleSignInButton
          size="large"
          classes={{ root: classes.signInButtonRoot }}
          delete={deleteAccount}
        />
      </Box>
    </Box>
  );
};

OAuth.propTypes = {
  deleteAccount: PropTypes.bool,
};

export default OAuth;
