import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../routes';
import ErrorState from '../common/ErrorState';
import { usePaginationQuery } from '../../hooks/useQuery';
import DownloadCardList from './DownloadCardList';

const DownloadPagination = ({
  userId,
  loadsContext,
  load,
  label,
  EmptyStateComponent,
}) => {
  const history = useHistory();

  const query = usePaginationQuery(loadsContext, load, {
    variables: [userId],
  });

  if (query.error) return <ErrorState retry={query.refetch} height="90vh" />;

  const { canFetchMore, fetchMore, data, isFetching, isFetchingMore } = query;
  const totalCount = (data) ? data[0].totalCount : 0;
  const downloads = (data ?? []).flatMap((page) => page.downloads);

  const handleEmptyButtonClick = () => {
    history.push(routes.discover);
  };

  return (
    <DownloadCardList
      initialLoad={!isFetchingMore}
      label={label}
      isLoading={isFetching}
      hasMore={Boolean(canFetchMore)}
      loadMore={() => fetchMore()}
      EmptyStateComponent={EmptyStateComponent}
      onEmptyButtonClick={handleEmptyButtonClick}
      downloads={downloads}
      downloadCount={totalCount}
    />
  );
};

DownloadPagination.propTypes = {
  userId: PropTypes.string,
  label: PropTypes.string.isRequired,
  loadsContext: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  EmptyStateComponent: PropTypes.elementType.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.user?.profile?.id,
});

export default connect(mapStateToProps)(DownloadPagination);
