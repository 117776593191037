import Cookies from 'js-cookie';

const setTooltipCookie = (key) => {
  Cookies.set(key, true, { expires: (365 * 10) });
};

const getTooltipCookie = (key) => {
  const tooltipCookie = Cookies.get(key);
  return tooltipCookie;
};

export { setTooltipCookie, getTooltipCookie };
