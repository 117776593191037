import client from '../utils/client';
import config from '../config';

const downloadArticle = async (article) => {
  const url = `${config.newsServer}/pdf/articles/${article?.id}/download`;
  const token = sessionStorage.getItem(config.tokenKey);
  const pdfContent = await client().get(url, {
    responseType: 'blob',
    headers: { Authorization: `Bearer ${token}` },
  });

  const pdfFileName = article?.slug;
  const pdfBlobUrl = window.URL.createObjectURL(new Blob([pdfContent]));
  const pdfLink = document.createElement('a');
  pdfLink.href = pdfBlobUrl;
  pdfLink.setAttribute('download', `${pdfFileName}.pdf`);
  pdfLink.click();
  setTimeout(() => window.URL.revokeObjectURL(url), 0);
};

const fetchDownloadHistory = async (userId, page = 1) => {
  const url = `/users/${userId}/download_history`;
  const response = await client().get(url, { params: { page } });
  return {
    downloads: response.data,
    totalCount: response.total_count,
    numPages: response.total_pages,
    currentPage: response.current_page,
  };
};

export {
  downloadArticle,
  fetchDownloadHistory,
};
